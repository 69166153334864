/* eslint-disable @typescript-eslint/no-explicit-any */
import produce from "immer"
import { Reducer } from "redux"
import { IActions } from "./types"

const genReducers = <InitialState, Actions extends IActions>(
  initialState: InitialState,
  actions: Actions
): Reducer<InitialState> => {
  const reducer = (
    state = initialState,
    action: { type: keyof Actions; payload: { field?: keyof InitialState } & Record<string, any> }
  ) => {
    return produce<InitialState, InitialState>(state, (draft) => {
      switch (action.type) {
        case actions.SET_STATE: {
          const { field, ...newState } = action.payload

          if (field) {
            const prevState = state[field]
            draft[field] = { ...prevState, ...newState }

            return draft
          }

          return { ...state, ...newState }
        }

        case actions.RESET_STATE: {
          const { field, ...rest } = action.payload || {}

          if (field) {
            draft[field] = { ...initialState[field], ...rest }

            return draft
          }

          return initialState
        }

        default: {
          return state
        }
      }
    })
  }

  return reducer as Reducer<InitialState>
}

export default genReducers
