export const WEATHER_KEY = {
  WIND_BARB: "w_b",
  OCEAN_CURRENT: "o_c",
  GLOBAL_STORM: "g_s",
  WAVE_HEIGHT: "_sigwh",
  SURFACE_PRESSURE: "_ssp",
}

export const WEATHER_FIELD = {
  [WEATHER_KEY.WIND_BARB]: "coor",
  [WEATHER_KEY.OCEAN_CURRENT]: "coor",
  [WEATHER_KEY.GLOBAL_STORM]: "g_s",
  [WEATHER_KEY.WAVE_HEIGHT]: "_sigwh",
  [WEATHER_KEY.SURFACE_PRESSURE]: "_ssp",
}

export const WEATHER_KEY_LIST = [
  WEATHER_KEY.WIND_BARB,
  WEATHER_KEY.OCEAN_CURRENT,
  WEATHER_KEY.GLOBAL_STORM,
  WEATHER_KEY.WAVE_HEIGHT,
  WEATHER_KEY.SURFACE_PRESSURE,
]

export interface WEATHER_LIST {
  wind_barb: any[]
  ocean_current: any[]
  global_storm: any[]
  wave_height: any[]
  surface_pressure: any[]
}

export const WEATHER_LEGEND = ["0", "1", "", "2", "", "3", ""]
