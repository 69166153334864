export enum SagaInjectionModes {
  RESTART_ON_REMOUNT = "@@saga-injector/restart-on-remount",
  DAEMON = "@@saga-injector/daemon",
  ONCE_TILL_UNMOUNT = "@@saga-injector/once-till-unmount",
}

export const CHART_COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#42c1ff",
  "#ffdf42",
  "#c1ff42",
  "#df42ff",
  "#ff42c6",
  "#8a3df5",
  "#a35b07",
  "#9da851",
  "#cc7a7e",
  "#ff427b",
  "#0d5c0e",
  "#ff4242",
  "#77ba78",
  "#7d511e",
  "#717d1e",
  "#0b6b61",
  "#4e342e",
  "#bf360c",
  "#1a237e",
  "#ff6f00",
]

export const SUPER_ADMIN = "SUPER_ADMIN"
export const ADMIN_USER = "ADMIN"
export const NORMAL_USER = "USER"
export const USERS_ROLE = {
  super_admin: SUPER_ADMIN,
  admin: ADMIN_USER,
  user: NORMAL_USER,
}
export const SEARCH_INTERVAL = 500

export const MORNITOR = {
  small: { width: 1500, height: 800 },
  smallest: { width: 1300, height: 740 },
}

export const IMPORT_CHANNEL = "Analytic::ImportingJobStatusChannel"
export const EXPORT_CHANNEL = "Analytic::ExportingJobStatusChannel"
export const NOTIFY_CHANNEL = "Ais::NotifyJobStatusChannel"

export const LC_UNIT = 1000
export const YAXIS_STEP = 10
