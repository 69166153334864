import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons"
import { Layout } from "antd"
import React, { memo } from "react"
import WeatherSettting from "components/Header/WeatherSetting"
import HistoricalVoyageSetting from "components/Header/HistoricalVoyageSetting"
import { useLocation } from "react-router-dom"
import {
  SHIP_DATA,
  DASHBOARD,
  HISTORY_VOYAGE,
  VOYAGES_MAP,
  VOYAGES_COMPARISON,
} from "core/constants/routes"
import ShipDataFlow from "components/Header/ShipDataFlow"
import { useSelector } from "react-redux"
import { ShipDataSelector } from "services/shipData/selectors"
import HeaderVoyageSummary from "components/Header/HeaderVoyageSummary"
import VoyageComparisonSetting from "components/Header/VoyageComparisonSetting"

interface HeaderProps {
  collapsed: boolean
  onToggle: () => void
}

function Header({ collapsed, onToggle }: HeaderProps) {
  const { pathname } = useLocation()
  const { filterParams } = useSelector(ShipDataSelector) || {}

  return (
    <Layout.Header
      className={
        pathname === SHIP_DATA
          ? "site-layout-shipdata-background"
          : "site-layout-dashboard-background"
      }
      id="layoutHeader"
    >
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: "trigger",
        onClick: onToggle,
      })}
      {pathname === SHIP_DATA && <ShipDataFlow filterParams={filterParams} />}
      {pathname === DASHBOARD && <WeatherSettting />}
      {pathname === HISTORY_VOYAGE && <HistoricalVoyageSetting />}
      {pathname === VOYAGES_COMPARISON && <VoyageComparisonSetting />}
      {pathname.includes(VOYAGES_MAP) && <HeaderVoyageSummary />}
    </Layout.Header>
  )
}

export default memo(Header)
