import { Layout } from "antd"
import { memo } from "react"

function Footer() {
  return (
    <Layout.Footer id="layoutFooter">
      <div className="copyright">Ship Data Viewer ©Symphony Creative Solutions Pte. Ltd.</div>
    </Layout.Footer>
  )
}

export default memo(Footer)
