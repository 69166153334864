import { Icon, Stroke, Style } from "ol/style"
import { CHART_COLORS } from "./common"

export const blueRouteStyle = new Style({
  image: new Icon({
    src: "/icons/comparison_route.png",
    size: [29, 29],
    offset: [4, 4],
    scale: 0.2,
  }),
  stroke: new Stroke({
    width: 1,
    color: CHART_COLORS[0],
  }),
})

export const yellowRouteStyle = new Style({
  image: new Icon({
    src: "/icons/comparison_route.png",
    size: [29, 29],
    offset: [41, 4],
    scale: 0.2,
  }),
  stroke: new Stroke({
    width: 1,
    color: CHART_COLORS[5],
  }),
})

export const limeRouteStyle = new Style({
  image: new Icon({
    src: "/icons/comparison_route.png",
    size: [29, 29],
    offset: [78, 4],
    scale: 0.2,
  }),
  stroke: new Stroke({
    width: 1,
    color: CHART_COLORS[6],
  }),
})

export const greenRouteStyle = new Style({
  image: new Icon({
    src: "/icons/comparison_route.png",
    size: [29, 29],
    offset: [115, 4],
    scale: 0.2,
  }),
  stroke: new Stroke({
    width: 1,
    color: CHART_COLORS[1],
  }),
})

export const redRouteStyle = new Style({
  image: new Icon({
    src: "/icons/comparison_route.png",
    size: [29, 29],
    offset: [152, 4],
    scale: 0.2,
  }),
  stroke: new Stroke({
    width: 1,
    color: CHART_COLORS[3],
  }),
})

export const ALL_ROUTE_STYLE = [
  blueRouteStyle,
  yellowRouteStyle,
  limeRouteStyle,
  greenRouteStyle,
  redRouteStyle,
]

export const ALL_ROUTE_COLOR = {
  blue: CHART_COLORS[0],
  yellow: CHART_COLORS[5],
  lime: CHART_COLORS[6],
  green: CHART_COLORS[1],
  red: CHART_COLORS[3],
}

export const ALL_ROUTE_COLOR_STYLE = {
  blue: blueRouteStyle,
  yellow: yellowRouteStyle,
  lime: limeRouteStyle,
  green: greenRouteStyle,
  red: redRouteStyle,
}

export const ALL_ROUTE_COLOR_NAME = ["blue", "yellow", "lime", "green", "red"]
