import { createMemoSelector } from "services/_base/selectors"
import { IAppState } from "types/redux"

export const LayoutSelector = createMemoSelector(
  (state: IAppState) => {
    const { isCollapsed, isFullscreen, isPinHeader, isShowHeader } = state.asyncReducer.layout
    return { isCollapsed, isFullscreen, isPinHeader, isShowHeader }
  },
  (layoutResult) => layoutResult
)
