import produce from "immer"
import { Reducer } from "redux"
import genReducers from "services/_base/reducers"
import { FacenNotification } from "components/Common/Notification"
import { SUPER_ADMIN, USERS_ROLE } from "core/constants/common"
import { BaseActions } from "./actions"
import { AuthActions, AuthActionTypes, IAuthState } from "./types"

export const initialState: IAuthState = {
  auth: null,
  loading: false,
  error: null,
  authorized: false,
  authorizing: false,
  refreshing: false,
  companies: {
    loading: false,
    error: null,
    data: null,
    company_id: null,
  },
  current_user: {
    loading: false,
    error: null,
    isSuperAdmin: false,
    data: null,
  },
}

const MESSAGE = {
  get_current_vessel: {
    failed: "Session Expired ",
  },
  refresh_token: {
    success: "Session Refreshed",
    failed: "Session Expired ",
  },
  sign_in: {
    failed: "Email / Password Is Invalid",
  },
}

const reducer: Reducer<IAuthState, AuthActionTypes> = (state = initialState, action) => {
  return produce<IAuthState>(state, (draft) => {
    switch (action.type) {
      case AuthActions.SIGNIN: {
        draft.loading = true
        return draft
      }
      case AuthActions.SIGNIN_SUCCESS: {
        draft.loading = false
        FacenNotification.destroy()
        return draft
      }
      case AuthActions.SIGNIN_FAILED: {
        const { error } = action.payload
        draft.loading = false
        draft.error = error
        FacenNotification.error({ message: error })
        return draft
      }
      case AuthActions.SIGNOUT: {
        draft.loading = true
        return draft
      }
      case AuthActions.SIGNOUT_SUCCESS: {
        draft.loading = false
        draft.authorizing = false
        draft.authorized = false
        draft.loading = false
        return draft
      }
      case AuthActions.SIGNOUT_FAILED: {
        const { error } = action.payload
        draft.loading = false
        draft.error = error

        return draft
      }
      case AuthActions.GET_CURRENT_USER: {
        draft.authorizing = true
        draft.current_user.loading = true
        return draft
      }
      case AuthActions.GET_CURRENT_USER_SUCCESS: {
        const { data } = action.payload
        draft.authorizing = false
        draft.authorized = true
        draft.loading = false
        draft.current_user.loading = false
        draft.current_user.data = data
        draft.current_user.isSuperAdmin = USERS_ROLE[data.role].toUpperCase() === SUPER_ADMIN
        return draft
      }
      case AuthActions.GET_CURRENT_USER_FAILED: {
        const { error } = action.payload
        draft.authorizing = false
        draft.authorized = false
        draft.error = error
        draft.current_user.isSuperAdmin = false
        draft.current_user.loading = false

        return draft
      }
      case AuthActions.REFRESH_TOKEN: {
        draft.refreshing = true
        return draft
      }
      case AuthActions.REFRESH_TOKEN_SUCCESSS: {
        draft.refreshing = false
        draft.authorized = true
        return draft
      }
      case AuthActions.REFRESH_TOKEN_FAILED: {
        const { error } = action.payload
        draft.refreshing = false
        draft.authorized = false
        draft.error = error
        FacenNotification.warning({ message: MESSAGE.refresh_token.failed })

        return draft
      }
      case AuthActions.REFRESH_TOKEN_INVALIDED: {
        draft.refreshing = false
        return draft
      }

      case AuthActions.FETCH_LIST_COMPANY_ALL: {
        draft.companies.error = null
        draft.companies.loading = true

        return draft
      }
      case AuthActions.FETCH_LIST_COMPANY_ALL_SUCCESS: {
        const { data } = action.payload
        draft.companies.loading = false
        draft.companies.data = data

        return draft
      }
      case AuthActions.FETCH_LIST_COMPANY_ALL_FINISHED: {
        draft.companies.loading = false

        return draft
      }
      case AuthActions.FETCH_LIST_COMPANY_ALL_FAILED: {
        const { error } = action.payload

        draft.companies.loading = false
        draft.companies.error = error
        return draft
      }

      case AuthActions.SET_AUTH_COMPANY_ID: {
        const { company_id } = action.payload

        draft.companies.company_id = company_id
        return draft
      }
      default:
        return genReducers(initialState, BaseActions)(state, action)
    }
  })
}

export default reducer
