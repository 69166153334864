interface SvgProps {
  name?: string
  fill?: string
  width?: string
  height?: string
}

const Index: React.FC<SvgProps> = ({
  name = "",
  fill = "currentColor",
  width = "14",
  height = "14",
}) => {
  return (
    <svg
      className={name}
      width={width}
      height={height}
      fill={fill}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <g xmlns="http://www.w3.org/2000/svg">
          <path
            d="m497 462c-19.598 0-30.047 6.931-38.442 12.5-6.81 4.517-11.308 7.5-21.861 7.5s-15.051-2.983-21.86-7.5c-8.396-5.569-18.845-12.5-38.443-12.5-19.591 0-30.034 6.933-38.427 12.503-6.801 4.515-11.295 7.497-21.835 7.497-10.523 0-15.012-2.981-21.804-7.494-8.389-5.571-18.826-12.506-38.407-12.506-19.587 0-30.028 6.933-38.418 12.504-6.799 4.514-11.29 7.496-21.824 7.496-10.529 0-15.019-2.981-21.814-7.495-8.389-5.571-18.829-12.505-38.413-12.505s-30.023 6.934-38.412 12.505c-6.796 4.514-11.285 7.495-21.814 7.495-10.528 0-15.018-2.981-21.813-7.495-8.39-5.571-18.829-12.505-38.413-12.505-8.284 0-15 6.716-15 15s6.716 15 15 15c10.529 0 15.019 2.981 21.814 7.495 8.389 5.571 18.829 12.505 38.412 12.505 19.584 0 30.023-6.934 38.412-12.505 6.796-4.514 11.285-7.495 21.814-7.495s15.019 2.981 21.814 7.495c8.389 5.571 18.828 12.505 38.412 12.505 19.587 0 30.028-6.933 38.418-12.504 6.799-4.514 11.29-7.496 21.824-7.496 10.524 0 15.012 2.981 21.805 7.494 8.388 5.571 18.825 12.506 38.405 12.506 19.591 0 30.034-6.933 38.427-12.503 6.802-4.515 11.295-7.497 21.835-7.497 10.554 0 15.052 2.983 21.861 7.5 8.396 5.569 18.844 12.5 38.442 12.5s30.048-6.931 38.443-12.5c6.81-4.517 11.308-7.5 21.86-7.5 8.284 0 15-6.716 15-15s-6.714-15-14.998-15z"
            fill="#555555"
            data-original="#000000"
          />
          <circle cx="256" cy="147" r="15" fill="#555555" data-original="#000000" />
          <circle cx="316" cy="147" r="15" fill="#555555" data-original="#000000" />
          <circle cx="196" cy="147" r="15" fill="#555555" data-original="#000000" />
          <path
            d="m15 422c10.529 0 15.019 2.981 21.814 7.495 8.389 5.571 18.829 12.505 38.412 12.505 19.584 0 30.023-6.934 38.412-12.505 6.796-4.514 11.285-7.495 21.814-7.495s15.019 2.981 21.814 7.495c8.389 5.571 18.828 12.505 38.412 12.505 19.587 0 30.028-6.933 38.418-12.504 6.799-4.514 11.29-7.496 21.824-7.496 10.524 0 15.012 2.981 21.805 7.494 8.388 5.571 18.825 12.506 38.405 12.506 19.591 0 30.034-6.933 38.427-12.503 6.802-4.515 11.295-7.497 21.835-7.497 10.554 0 15.052 2.983 21.861 7.5 8.396 5.569 18.844 12.5 38.442 12.5s30.048-6.931 38.443-12.5c6.81-4.517 11.308-7.5 21.86-7.5 8.284 0 15-6.716 15-15s-6.716-15-15-15c-19.598 0-30.047 6.931-38.442 12.5-6.81 4.517-11.308 7.5-21.861 7.5s-15.051-2.983-21.86-7.5c-4.823-3.2-10.335-6.842-17.859-9.368l43.038-112.784c2.898-7.598-.784-16.121-8.304-19.218l-50.71-20.881v-155.249c0-8.284-6.716-15-15-15h-45v-57c0-8.284-6.716-15-15-15h-100c-8.284 0-15 6.716-15 15v57h-45c-8.284 0-15 6.716-15 15v155.249l-50.711 20.881c-7.514 3.094-11.198 11.608-8.309 19.203l42.909 112.799c-7.52 2.527-13.029 6.172-17.849 9.373-6.796 4.514-11.285 7.495-21.814 7.495-10.528 0-15.018-2.981-21.813-7.495-8.39-5.571-18.829-12.505-38.413-12.505-8.284 0-15 6.716-15 15s6.716 15 15 15zm350.77-29.237c-13.019 1.943-21.077 7.275-27.804 11.74-6.801 4.515-11.295 7.497-21.835 7.497-10.523 0-15.012-2.981-21.804-7.494-5.918-3.931-12.875-8.53-23.327-10.904v-164.204l135.775 55.908zm-144.77-362.763h70v42h-70zm-60 72h190v127.896l-89.289-36.766c-3.658-1.506-7.764-1.506-11.422 0l-89.289 36.766zm80 127.398v164.168c-10.545 2.362-17.547 6.986-23.497 10.937-6.799 4.514-11.29 7.496-21.824 7.496-10.529 0-15.019-2.981-21.814-7.495-6.722-4.464-14.774-9.794-27.779-11.74l-40.876-107.452z"
            fill="#555555"
            data-original="#000000"
          />
        </g>
      </g>
    </svg>
  )
}

export default Index
