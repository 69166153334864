import { transformExtent } from "ol/proj"
import { WeatherParams } from "services/historical/types"

export const getBounds = (guardianMap) => {
  const result = []
  const view = guardianMap.getView()
  const boundsCalc = view.calculateExtent(guardianMap.getSize())
  const bounds = transformExtent(boundsCalc, "EPSG:3857", "EPSG:4326")
  let top = bounds[3]
  let right = bounds[2]
  let bottom = bounds[1]
  let left = bounds[0]
  let world_index = 0
  let right2 = 0
  let left2 = 0
  if (left < 0) {
    left2 = left + 360
    right2 = right + 360
    world_index = 1
    result.push([top, right2, bottom, left2, world_index])
  }
  if (right > 360) {
    left2 = left - 360
    right2 = right - 360
    world_index = 2
    result.push([top, right2, bottom, left2, world_index])
  }
  if (top > 90) {
    top = 90
  }
  if (right > 360) {
    right = 360
  }
  if (bottom < -90) {
    bottom = -90
  }
  if (left < 0) {
    left = 0
  }
  result.push([top, right, bottom, left, 0])
  return result
}

export const changeWeathersChecked = (
  isCheck: boolean,
  tmpWeathersChecked: any[],
  weatherType: string
) => {
  return isCheck
    ? [...tmpWeathersChecked, ...[weatherType]]
    : tmpWeathersChecked.filter((weather: string) => weather !== weatherType)
}

export const setLocalStoragePayload = (payload: WeatherParams) => {
  if (!payload) return
  const value = {
    element: payload.element,
    bounds: payload.bounds,
    zoom: payload.zoom,
    zoomForDraw: payload.zoomForDraw,
    date: payload.date,
  }
  localStorage.setItem(payload.element, JSON.stringify(value))
}
export const setLocalStorageData = (weatherData = null) => {
  const store = localStorage.getItem(weatherData.element)
  if (store.length < 0) return
  const value = { ...JSON.parse(store), ...{ data: weatherData.data } }
  localStorage.setItem(weatherData.element, JSON.stringify(value))
}

export const removeLocalStorageData = (key: string) => {
  localStorage.removeItem(key)
}

export const storedMapView = (zoom: number, center: any) => {
  localStorage.setItem("historical_map_view", JSON.stringify({ zoom, center }))
}
