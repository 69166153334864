/* eslint-disable no-param-reassign */
import produce from "immer"
import GeoJSON from "ol/format/GeoJSON"
import { compact, isEmpty, filter, get, cloneDeep, round } from "lodash"
import { subSeconds, isWithinInterval } from "date-fns"
import { createDateWihoutTimeZone } from "core/model/DateTime"
import arc from "arc"
import LineString from "ol/geom/LineString"
import KML from "ol/format/KML"
import { register } from "ol/proj/proj4"
import proj4 from "proj4"
import FacenNotification from "components/Common/Notification"

proj4.defs(
  "EPSG:3857P",
  "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs"
)
register(proj4)

const TIME_RANGE_SECONDS = 3599
export enum MapLayers {
  Vessel = "vessel",
  Port = "port",
}

export enum trackingTypes {
  historyRoute = "historyRoute",
  planRoute = "planRoute",
  currentRoute = "currentRoute",
  filterRoute = "filterRoute",
  ecdisRoute = "ecdisRoute",
}

export enum pointTypeKeys {
  rhumbLine = "RL",
  greatCircle = "GC",
}

export const pointTypeNames = {
  [pointTypeKeys.rhumbLine]: "Rhumb Line",
  [pointTypeKeys.greatCircle]: "Great Circle",
}
export interface GeometryProfile {
  type: "Point" | string
  coordinates: Array<number>
}

export interface FeaturesProfile {
  type: "Feature"
  geometry: GeometryProfile
  properties?: any
}

const defaultGeojsonObject = {
  type: "FeatureCollection",
  crs: {
    type: "name",
    properties: {
      name: "EPSG:4326",
    },
  },
}
export function deg2Rad(_degree: any): number {
  const degree = Number(_degree)
  return degree ? (degree / 180) * Math.PI : 0
}

export const CONVERT_PROJECTION = {
  featureProjection: "EPSG:3857",
  dataProjection: "EPSG:4326",
}

export const CONVERT_PROJECTION_EXTEND = {
  featureProjection: "EPSG:3857P",
  dataProjection: "EPSG:4326",
}

export const getHeadingRad = (_degree: any, offset = Math.PI / 2): number => {
  const degree = deg2Rad(_degree)
  return degree - offset
}

export const convertDegreesToRadians = (degrees: number): number => {
  return degrees * (Math.PI / 180)
}

export const formatFeatures = (features: FeaturesProfile[]) => {
  const geoJson = { ...defaultGeojsonObject, features }
  return new GeoJSON(CONVERT_PROJECTION).readFeatures(geoJson)
}

export const formatFeaturesExtend = (features: FeaturesProfile[]) => {
  const geoJson = { ...defaultGeojsonObject, features }
  return new GeoJSON(CONVERT_PROJECTION_EXTEND).readFeatures(geoJson)
}

export const formatFeatureExtend = (feature: FeaturesProfile) => {
  return new GeoJSON(CONVERT_PROJECTION_EXTEND).readFeature(feature)
}

export const formatXMLFeature = (data: any) => {
  const geoJson = { ...defaultGeojsonObject, data }
  return new KML({ extractStyles: false }).readFeatures(geoJson)
}

export const formatVesselFeatures = (dataFeatures: FeaturesProfile[]) => {
  const features = dataFeatures.map((feature: any) => ({
    ...feature,
    geometry:
      feature.properties.type === MapLayers.Port
        ? produce(feature.geometry, (draft: any) => {
            draft.coordinates = [draft.coordinates[0], draft.coordinates[1] - 0.0009]
          })
        : feature.geometry,
  }))
  const geoJson = { ...defaultGeojsonObject, features }
  return new GeoJSON(CONVERT_PROJECTION).readFeatures(geoJson)
}

export const convertLineStringToMultiLineString = (coordinates: any[]) => {
  const newCoordinates = []
  coordinates.map((coord, index) => {
    const prevCoord = coordinates[index - 1]
    const nextCoord = coordinates[index + 1]
    if (!nextCoord) return null
    const [currentLong, currentLat] = coord
    const [nextLong, nextLat] = nextCoord
    if (prevCoord && Math.abs(currentLong) % 180 !== 0 && Math.abs(nextLong) % 180 !== 0) {
      if (
        (currentLong > 0 && currentLong < 90) ||
        (currentLong < 0 &&
          currentLong > -90 &&
          prevCoord[0] * currentLong > 0 &&
          currentLong * nextLong < 0)
      ) {
        return newCoordinates.push([coord, nextCoord])
      }
    }
    if (currentLong > 0 && nextLong < 0) {
      // const variance = Math.abs(currentLat - nextLat)
      // const afterPointNumb = round(variance, 2) / 6
      // betweenlat = nextLat > currentLat ? nextLat - afterPointNumb : nextLat + afterPointNumb
      let betweenlat = (currentLat + nextLat) / 2
      if (currentLong === 180) betweenlat = currentLat

      newCoordinates.push([coord, [180, betweenlat]])
      return newCoordinates.push([nextCoord, [-180, betweenlat]])
    }
    if (currentLong < 0 && nextLong > 0) {
      const betweenlat = (currentLat + nextLat) / 2
      // const betweenlat = Math.abs(currentLat - nextLat) + currentLat
      newCoordinates.push([[-180, betweenlat], coord])
      return newCoordinates.push([nextCoord, [180, betweenlat]])
    }
    return newCoordinates.push([coord, nextCoord])
  })
  return newCoordinates
}

export const formatRoute = (dataFeatures: FeaturesProfile[]) => {
  const coordinates = dataFeatures.map(({ geometry: { coordinates } }: any) => coordinates)
  const MultiLineString = compact(convertLineStringToMultiLineString(coordinates))
  const data = {
    type: "Feature",
    properties: {
      click_disabled: true,
    },
    geometry: {
      type: "MultiLineString",
      coordinates: MultiLineString,
    },
  }
  return new ol.format.GeoJSON(CONVERT_PROJECTION).readFeatures(data, CONVERT_PROJECTION)
}

const drawArc = (from, to) => {
  const start = { x: from[0], y: from[1] }
  const end = { x: to[0], y: to[1] }
  try {
    const generator = new arc.GreatCircle(start, end)
    let line = generator.Arc(99, { offset: 0 })
    line = line.json()
    return line
  } catch (err) {
    console.log(err)
  }
}

const importGreatCirleFeatures = (routesFeatures, source) => {
  const greatCircleFeatures = []
  let canDraw = true
  routesFeatures.forEach((routesFeature, index) => {
    if (!index) return
    const fromPoint = routesFeatures[index - 1]
    const toPoint = routesFeature
    const coordFrom: number[] = fromPoint.properties.coordinates
    const coordTo = toPoint.properties.coordinates
    if (
      coordFrom.filter((record) => Number.isNaN(record)).length === 0 &&
      coordTo.filter((record) => Number.isNaN(record)).length === 0
    ) {
      const line = drawArc(coordFrom, coordTo)
      greatCircleFeatures.push(formatGreatCircle(get(line, "geometry.coordinates")))
    } else canDraw = false
  })
  if (!canDraw)
    FacenNotification.error({ message: "The server encountered an error when loading ECDIS route" })
  source.addFeatures(greatCircleFeatures)
}

const importRhumbLineFeatures = (routesFeatures, source) => {
  const routes = formatRoute(routesFeatures)
  return source.addFeatures(routes)
}

const importLineFeatures = (routesFeatures, source, featureType) => {
  if (featureType === pointTypeKeys.greatCircle)
    return importGreatCirleFeatures(routesFeatures, source)
  return importRhumbLineFeatures(routesFeatures, source)
}

export const createRouteFeatures = (source, routesData, routeType, selectedFeature) => {
  try {
    if (selectedFeature && !isEmpty(routesData)) {
      const dataFeatures: any[] = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < routesData.length; i++) {
        const { attributes } = routesData[i]
        dataFeatures.push(
          produce(attributes, (draft) => {
            draft.properties = {
              ...attributes,
              coordinates: attributes.geometry.coordinates,
              routeType,
              click_disabled: true,
              imo: selectedFeature.get("imo"),
            }
            delete draft.properties.geometry
          })
        )
      }

      let routesFeatures = []
      dataFeatures.forEach((feature, index) => {
        if (!index) return routesFeatures.push(feature)
        const prevFeatureType = get(dataFeatures[index - 1], "leg_type")
        if (index === dataFeatures.length - 1) {
          routesFeatures.push(feature)
          return importLineFeatures(routesFeatures, source, feature.leg_type)
        }

        if (prevFeatureType === feature.leg_type) {
          return routesFeatures.push(feature)
        }

        importLineFeatures(routesFeatures, source, prevFeatureType)
        routesFeatures = []
        const clonedPoint = cloneDeep(dataFeatures[index - 1])
        clonedPoint.leg_type = feature.leg_type
        routesFeatures.push(clonedPoint)
        return routesFeatures.push(feature)
      })

      const features = formatFeatures(dataFeatures)
      source.addFeatures(features)
    }
  } catch (err) {
    console.log(err)
  }
}
export const createRouteFeaturesComparison = (source, routesData, routeType) => {
  if (!isEmpty(routesData)) {
    const dataFeatures: any[] = []
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < routesData.length; i++) {
      const { attributes } = routesData[i]

      dataFeatures.push(
        produce(attributes, (draft) => {
          draft.properties = {
            ...attributes,
            coordinates: attributes.geometry.coordinates,
            routeType,
            click_disabled: true,
          }
          delete draft.properties.geometry
        })
      )
    }

    let routesFeatures = []
    dataFeatures.forEach((feature, index) => {
      if (!index) return routesFeatures.push(feature)
      const prevFeatureType = get(dataFeatures[index - 1], "leg_type")
      if (index === dataFeatures.length - 1) {
        routesFeatures.push(feature)
        return importLineFeatures(routesFeatures, source, feature.leg_type)
      }

      if (prevFeatureType === feature.leg_type) {
        return routesFeatures.push(feature)
      }

      importLineFeatures(routesFeatures, source, prevFeatureType)
      routesFeatures = []
      const clonedPoint = cloneDeep(dataFeatures[index - 1])
      clonedPoint.leg_type = feature.leg_type
      routesFeatures.push(clonedPoint)
      return routesFeatures.push(feature)
    })

    const features = formatFeatures(dataFeatures)
    source.addFeatures(features)
  }
}
export const getRoutePointsWithinPeriod = (routePoints, currentDate) => {
  const filterpoints = filter(routePoints, (point) => {
    const start = subSeconds(currentDate, TIME_RANGE_SECONDS)
    const end = currentDate
    const comparedPointUTC = createDateWihoutTimeZone(get(point, "attributes.last_ais_updated_at"))
    return isWithinInterval(comparedPointUTC, {
      start,
      end,
    })
  })
  return filterpoints
}

const formatGreatCircle = (coordinates: any) => {
  let flattenCoordinates = coordinates
  try {
    if (coordinates.length === 2) {
      // temporary solution when the line across 2 world maps
      flattenCoordinates =
        coordinates[1].length > coordinates[0].length ? coordinates[1] : coordinates[0]
    }
    const linestring = new LineString(flattenCoordinates)
    linestring.transform("EPSG:4326", "EPSG:3857")
    return new ol.Feature({
      geometry: linestring,
      name: "linestring",
    })
  } catch (err) {
    console.log(err)
  }
}

export class RhumbLine {
  lat1: number

  lon1: number

  lat2: number

  lon2: number

  m_lon1: number

  m_lon2: number

  m_y1: number

  m_y2: number

  m_gradient: number

  m_heading: number

  m_omega: number

  constructor(lat1, lon1, lat2, lon2) {
    this.lat1 = lat1
    this.lon1 = lon1
    this.lat2 = lat2
    this.lon2 = lon2

    let neg = false

    if (lon2 < lon1) {
      neg = true
      let t
      t = lon1
      lon1 = lon2
      lon2 = t
      t = lat1
      lat1 = lat2
      lat2 = t
    }
    const offset = lon1
    lon1 = 0
    lon2 -= offset

    lat1 *= Math.PI / 180
    lat2 *= Math.PI / 180

    this.m_lon1 = (lon1 * Math.PI) / 180
    this.m_lon2 = (lon2 * Math.PI) / 180

    if (this.m_lon2 - this.m_lon1 > Math.PI) this.m_lon2 -= Math.PI * 2
    if (this.m_lon1 - this.m_lon2 > Math.PI) this.m_lon1 -= Math.PI * 2

    this.m_y1 = Math.log(Math.tan(lat1 / 2 + Math.PI / 4))
    this.m_y2 = Math.log(Math.tan(lat2 / 2 + Math.PI / 4))

    if (Math.abs(this.m_lon2 - this.m_lon1) < 0.0001) {
      this.m_gradient = 0
      this.m_heading = lat2 > lat1 ? 180 : 0
      this.m_omega = Math.abs(lat2 - lat1)
      return
    }

    this.m_gradient = (this.m_y2 - this.m_y1) / (this.m_lon2 - this.m_lon1)

    if (Math.abs(this.m_gradient) < 0.0001)
      this.m_omega = Math.abs((this.m_lon2 - this.m_lon1) * Math.cos((lat1 + lat2) / 2))
    else
      this.m_omega = Math.abs(
        (Math.sqrt(1 + this.m_gradient * this.m_gradient) * (lat2 - lat1)) / this.m_gradient
      )

    this.m_heading = Math.acos(this.m_gradient / Math.sqrt(this.m_gradient * this.m_gradient + 1))
  }

  getDistanceByKm = () => {
    const rEarth = 3443.96 // nm Radius of Earth at equator
    return this.m_omega * rEarth
  }
}
