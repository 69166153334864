import { createMemoSelector } from "services/_base/selectors"
import { IAppState } from "types/redux"

export const VoyagesSelector = createMemoSelector(
  (state: IAppState) => {
    const {
      voyages,
      filters,
      vessels,
      arrival_ports,
      dept_ports,
      selected_voyage,
      manual_ports,
      voyage_charts,
      export_voyage,
    } = state.voyages
    return {
      voyages,
      filters,
      vessels,
      arrival_ports,
      dept_ports,
      selected_voyage,
      voyage_charts,
      manual_ports,
      export_voyage,
    }
  },
  (voyagesResult) => voyagesResult
)
