import { routerMiddleware } from "connected-react-router"
import { SagaInjectionModes } from "core/constants/common"
import { History } from "history"
import { applyMiddleware, compose, createStore } from "redux"
import { logger } from "redux-logger"
import createSagaMiddleware from "redux-saga"
import { createStateSyncMiddleware, initStateWithPrevTab } from "redux-state-sync"
import authSagas, { getCurrentUser } from "services/auth/sagas"
import { BaseActions } from "services/auth/actions"
import createReducer from "services/reducers"
import { IAppState, InjectedStore } from "types/redux"

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

export function createInjectorsEnhancer(params: any) {
  return (_createStore: any) => (...args: any[]) => {
    const store = _createStore(...args)

    return {
      ...store,
      createReducer: params.createReducer,
      runSaga: params.runSaga,
      injectedReducers: {}, // Reducer registry
      injectedSagas: {}, // Saga registry
    }
  }
}

export function forceReducerReload(
  { injectedReducers, replaceReducer }: InjectedStore,
  history: History
) {
  replaceReducer(createReducer(injectedReducers, history))
}

export default function configureStore(initialState = {}, history: History) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const reduxSagaMonitorOptions = {}

  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions)
  const { run: runSaga } = sagaMiddleware

  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  // 3. stateSyncMiddleware: Syncs reducers in all tabs
  const middlewares = [sagaMiddleware, routerMiddleware(history)]

  // if (process.env.NODE_ENV === "development") {
  //   middlewares.push(logger)
  // }

  const enhancers = [
    applyMiddleware(...middlewares),
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ]

  const store = createStore(
    createReducer({}, history),
    initialState,
    composeEnhancers(...enhancers)
  ) as InjectedStore

  // Extensions
  store.runSaga = sagaMiddleware.run
  store.injectedReducers = {} // Reducer registry
  store.injectedSagas = {
    auth: {
      mode: SagaInjectionModes.RESTART_ON_REMOUNT,
      saga: authSagas,
      task: store.runSaga(authSagas, {}),
    },
  } // Saga registry
  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("services/reducers", () => {
      forceReducerReload(store, history)
    })
  }

  initStateWithPrevTab(store)

  return store
}
