import axios from "services/_base/_axios"
import _, { isEmpty } from "lodash"
import { API_METHODS } from "core/model/Api"
import qs from "qs"

let refreshing = false
let company_id = null
interface OriginalRequests {
  method?: string
  pathname?: string
  params?: string
  url?: string
}

interface Params {
  pathname?: string
  params?: string
}

let originalRequests: OriginalRequests[] = []

let paramsValue: Params = {}

function getParams(method: string, request: any) {
  const { url, data } = request
  if (method === API_METHODS.DELETE || method === API_METHODS.PUT) {
    const period = url.lastIndexOf("/")
    const pathname = url.substring(0, period)
    const payload = JSON.parse(data)
    const params = !payload ? url.substring(period + 1) : payload
    paramsValue = { pathname, params }
  } else if (method === API_METHODS.POST) {
    paramsValue = { pathname: url, params: JSON.parse(data) }
  } else {
    const [pathname, stringParams] = url.split("?")
    const params = qs.parse(stringParams)
    paramsValue = { pathname, params }
  }
}

class Axios {
  public setOriginalRequests(url?: string, method?: string, data?: any) {
    if (!url && !method) {
      paramsValue = {}
      originalRequests = []
    } else {
      const originalRequest = { url, data }
      getParams(method, originalRequest)

      originalRequests = [
        ...originalRequests,
        ...[
          {
            method,
            pathname: paramsValue.pathname,
            params: paramsValue.params,
            url,
          },
        ],
      ]
    }
  }

  public getOriginalRequests() {
    return originalRequests
  }

  public recall() {
    if (isEmpty(originalRequests)) return null

    originalRequests = []
    return true
  }

  public setRefreshing(refreshingData = false) {
    refreshing = refreshingData
  }

  public setCompanyId(companyId) {
    company_id = companyId
  }

  public setRequestHeader(company_id_params?: number) {
    axios.defaults.headers.common["company-id"] = company_id_params ?? company_id // eslint-disable-line
  }

  public setBaseURL() {
    if (window.location.host.includes("localhost"))
      axios.defaults.baseURL = `${window.location.origin}/api`
    else axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`
  }

  public async get(endpoint?: string, option?: any, query?: any, company_id_params?: number) {
    this.setBaseURL()
    if (refreshing) {
      this.setOriginalRequests(endpoint, "get")
      return undefined
    }

    this.setRequestHeader(company_id_params)

    let queryString = ""

    if (query instanceof Object) {
      if (!_.isEmpty(query)) {
        const temp = Object.entries(query)
          .map(([key, value]) => {
            if (!value) return undefined
            return `${key}=${value}`
          })
          .filter((item) => item)
          .join("&")
        queryString = `?${temp}`
      }
    } else {
      queryString = query ?? ""
    }

    const cancelToken = option?.cancelToken ? { cancelToken: option.cancelToken } : {}
    return axios.get(endpoint + queryString, cancelToken)
  }

  public async post(endpoint?: string, payload?: Record<string, any>, company_id_params?: number) {
    this.setRequestHeader(company_id_params)
    return axios.post(endpoint, payload)
  }

  public async put(endpoint?: string, payload?: Record<string, any>, company_id_params?: number) {
    this.setRequestHeader(company_id_params)
    return axios.put(endpoint, payload)
  }

  public async delete(
    endpoint?: string,
    payload?: Record<string, any>,
    company_id_params?: number
  ) {
    this.setRequestHeader(company_id_params)
    return axios.delete(endpoint, payload)
  }
}

export default new Axios()
