import { createMemoSelector } from "services/_base/selectors"
import { IAppState } from "types/redux"

export const HistoricalSelector = createMemoSelector(
  (state: IAppState) => {
    const { durationSettings, selectedImo, weathers, selectedVesselId } = state.historical || {}
    return { durationSettings, selectedImo, weathers, selectedVesselId }
  },
  (historicalResult) => historicalResult
)
