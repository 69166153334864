import {
  YoutubeOutlined,
  DownloadOutlined,
  CompassOutlined,
  TeamOutlined,
  HistoryOutlined,
  CalculatorOutlined,
  DatabaseOutlined,
  ApartmentOutlined,
  FundViewOutlined,
  BankOutlined,
  ReadOutlined,
} from "@ant-design/icons"
import React from "react"
import MCScheduleApp from "components/Icons/MCScheduleApp"

// Lazy load component
export const loadable = <T extends React.ComponentType>(factory: () => Promise<{ default: T }>) =>
  React.lazy(factory)

// type of Route
export interface IRoute {
  path?: string
  icon?: React.ComponentType
  name?: string
  exact?: boolean
  component?:
    | React.LazyExoticComponent<() => JSX.Element>
    | React.LazyExoticComponent<React.MemoExoticComponent<() => JSX.Element>>
    | React.LazyExoticComponent<React.FC>
  children?: IRoute[]
  parentPath?: string
  showChild?: boolean
  external?: boolean
}

export const MAX_HIGH_LIGHT_DAY = 3
export const MESSAGES = {
  hitorical_route: {
    fetching: {
      message: "Be Patient!",
      description: "We are getting data, please wait!",
    },
  },
}
// Public route path
export const HOME = "/"
export const SIGNIN = "/signin"
export const FORGOT_PASSWORD = "/forgot_password"

// Private route path
export const TODO = "/todos"
export const DASHBOARD = "/dashboard"
export const VESSEL_MANAGEMENT = "/vessels_management"
export const USERS_MANAGEMENT = "/users_management"
export const SHIP_DATA = "/ship_data"
export const HEEL_EDQ_RESULT = "/heel_edq"
export const HEEL_EDQ_EDIT_ITEM = "/heel_edq/:id"
export const HEEL_EDQ_CREATE_ITEM = "/heel_edq/create"
export const DATA_SETTINGS = "/data_settings"
export const DATA_SETTINGS_FOC = "/foc"
export const DATA_SETTINGS_ROUTE = "/master_routes"
export const DATA_SETTINGS_PORT = "/master_ports"
export const DATA_SETTINGS_DISTANCE_ROUTES = "/distance_routes"
export const DATA_SETTINGS_PANAMA_LNG_CONS = "/panama_lng_cons"
export const NOTFOUND = "*"
export const HISTORY_VOYAGE = "/historical_mode"
export const VOYAGES = "/voyages"
export const VOYAGES_SUMMARY = "/voyage_summary"
export const VOYAGES_COMPARISON = "/voyage_comparison"
export const VOYAGES_MAP = "/voyage_map"
export const VOYAGES_MAP_ITEM = "/voyage_map/:id"
export const MC_SCHEDULE_APP = "https://nykk-tst.outsystemsenterprise.com/MCScheduleApp/"
export const COMPANIES_MANAGEMENT = "/companies"
export const USER_MANUAL = "/user_manual"
// Public route component
export const PUBLIC_ROUTES: IRoute[] = [
  {
    path: NOTFOUND,
    exact: false,
    component: loadable(() => import("pages/not-found")),
  },
]

export const PRIVATE_ROUTES: IRoute[] = [
  {
    path: DASHBOARD,
    icon: YoutubeOutlined,
    name: "Dashboard",
    component: loadable(() => import("pages/dashboard")),
  },
  {
    path: HISTORY_VOYAGE,
    icon: HistoryOutlined,
    name: "Historical Voyage",
    component: loadable(() => import("pages/historicalMode")),
  },
  {
    path: VOYAGES_SUMMARY,
    icon: ApartmentOutlined,
    name: "Voyage Summary",
    component: loadable(() => import("pages/voyages/summary")),
    children: [
      {
        path: VOYAGES_SUMMARY,
        name: "Voyage Summary",
        component: loadable(() => import("pages/voyages/summary")),
        exact: true,
      },
      {
        path: VOYAGES_MAP_ITEM,
        name: "Voyage Map",
        component: loadable(() => import("pages/voyages/map")),
      },
    ],
  },
  {
    path: VOYAGES_COMPARISON,
    icon: FundViewOutlined,
    name: "Voyage Comparison",
    component: loadable(() => import("pages/voyagesComparison")),
  },
  {
    path: SHIP_DATA,
    icon: DownloadOutlined,
    name: "Ship Data",
    component: loadable(() => import("pages/shipData")),
  },
  {
    path: HEEL_EDQ_RESULT,
    icon: CalculatorOutlined,
    name: "Heel & EDQ Result",
    component: loadable(() => import("pages/heelEdq/heelEdqResult")),
    children: [
      {
        path: HEEL_EDQ_RESULT,
        name: "Heel & EDQ Result",
        component: loadable(() => import("pages/heelEdq/heelEdqResult")),
        exact: true,
      },
      {
        path: HEEL_EDQ_EDIT_ITEM,
        name: "Edit Calculator Item",
        component: loadable(() => import("pages/heelEdq/heelEdqCalc")),
      },
      {
        path: HEEL_EDQ_CREATE_ITEM,
        name: "Create Calculator Item",
        component: loadable(() => import("pages/heelEdq/heelEdqCalc")),
      },
    ],
  },
  {
    path: DATA_SETTINGS,
    icon: DatabaseOutlined,
    name: "Data Settings",
    showChild: true,
    children: [
      {
        path: DATA_SETTINGS_FOC,
        name: "TCP FOC Table",
        component: loadable(() => import("pages/dataSettings/tcpFOC")),
      },
      {
        path: DATA_SETTINGS_PORT,
        name: "Port List",
        component: loadable(() => import("pages/dataSettings/portList")),
      },
      {
        path: DATA_SETTINGS_ROUTE,
        name: "Route List",
        component: loadable(() => import("pages/dataSettings/routeList")),
      },
      {
        path: DATA_SETTINGS_DISTANCE_ROUTES,
        name: "Route Distance List",
        component: loadable(() => import("pages/dataSettings/distanceRouteList")),
      },
      {
        path: DATA_SETTINGS_PANAMA_LNG_CONS,
        name: "LNG Consumption in Panama",
        component: loadable(() => import("pages/dataSettings/lngConsInPanama")),
      },
    ],
  },
  {
    path: VESSEL_MANAGEMENT,
    icon: CompassOutlined,
    name: "Vessels Management",
    component: loadable(() => import("pages/vesselsManagement")),
  },
  {
    path: USERS_MANAGEMENT,
    icon: TeamOutlined,
    name: "Users Management",
    component: loadable(() => import("pages/usersManagement")),
  },
  {
    path: MC_SCHEDULE_APP,
    icon: MCScheduleApp,
    name: "Schedule Management",
    external: true,
  },
  {
    path: COMPANIES_MANAGEMENT,
    icon: BankOutlined,
    name: "Companies Management",
    component: loadable(() => import("pages/company")),
  },
  {
    path: USER_MANUAL,
    icon: ReadOutlined,
    name: "User Manual",
    component: loadable(() => import("pages/userManual")),
  },
]

export const PRIVATE_ROUTES_ITEMS: IRoute[] = [
  {
    path: HEEL_EDQ_CREATE_ITEM,
    parentPath: HEEL_EDQ_RESULT,
    name: "Create Calculator Item",
    component: loadable(() => import("pages/heelEdq/heelEdqCalc")),
  },
  {
    path: HEEL_EDQ_EDIT_ITEM,
    parentPath: HEEL_EDQ_RESULT,
    name: "Edit Calculator Item",
    component: loadable(() => import("pages/heelEdq/heelEdqCalc")),
  },
  {
    path: VOYAGES_MAP_ITEM,
    parentPath: VOYAGES_MAP,
    name: "Voyage Map",
    component: loadable(() => import("pages/voyages/map")),
  },
  {
    path: VOYAGES_SUMMARY,
    parentPath: VOYAGES_SUMMARY,
    name: "Voyage Summary",
    component: loadable(() => import("pages/voyages/summary")),
  },
]

export const AUTH_ROUTES: IRoute[] = [
  {
    path: SIGNIN,
    exact: true,
    component: loadable(() => import("pages/auth/signin")),
  },
]
