import genActions from "services/_base/actions"
import {
  IUserDeleteAction,
  IUserDeleteFailedAction,
  IUserDeleteSuccessAction,
  IUserEditAction,
  IUserEditFailedAction,
  IUserEditSuccessAction,
  IUserCreateSuccessAction,
  IUserCreateAction,
  IUserCreateFailedAction,
  IUsersFetchAllAction,
  IUsersFetchAllFinishedAction,
  IUsersFetchAllSuccessAction,
  IUsersFetchAllFailedAction,
  IFetchListCompanyAction,
  IFetchListCompanySuccessAction,
  IFetchListCompanyFailedAction,
  ISetUserFilterAction,
  UsersManagementActions,
  ISetCompanyId,
} from "./types"

export const { funcs: BaseFunctions, types: BaseActions } = genActions("vessels_management")

export function fetchUsersAll(payload: any): IUsersFetchAllAction {
  return {
    type: UsersManagementActions.FETCH_USERS_ALL,
    payload,
  }
}

export function fetchUsersAllSuccess(payload: any): IUsersFetchAllSuccessAction {
  return {
    type: UsersManagementActions.FETCH_USERS_ALL_SUCCESS,
    payload,
  }
}

export function fetchUsersAllFinished(payload: any): IUsersFetchAllFinishedAction {
  return {
    type: UsersManagementActions.FETCH_USERS_ALL_FINISHED,
    payload,
  }
}

export function fetchUsersAllFailed(payload: { error: string }): IUsersFetchAllFailedAction {
  return {
    type: UsersManagementActions.FETCH_USERS_ALL_FAILED,
    payload,
  }
}

export function setUserFilters(payload: any): ISetUserFilterAction {
  return {
    type: UsersManagementActions.SET_USER_FILTERS,
    payload,
  }
}

export function setCompanyId(payload: any): ISetCompanyId {
  return {
    type: UsersManagementActions.SET_COMPANY_ID,
    payload,
  }
}

export function createUser(payload: any): IUserCreateAction {
  return { type: UsersManagementActions.CREATE_USER, payload }
}

export function createUserSuccess(): IUserCreateSuccessAction {
  return {
    type: UsersManagementActions.CREATE_USER_SUCCESS,
  }
}

export function createUserFailed(payload: { error: string }): IUserCreateFailedAction {
  return {
    type: UsersManagementActions.CREATE_USER_FAILED,
    payload,
  }
}

export function editUser(payload: any): IUserEditAction {
  return { type: UsersManagementActions.EDIT_USER, payload }
}

export function editUserSuccess(): IUserEditSuccessAction {
  return {
    type: UsersManagementActions.EDIT_USER_SUCCESS,
  }
}

export function editUserFailed(payload: { error: string }): IUserEditFailedAction {
  return {
    type: UsersManagementActions.EDIT_USER_FAILED,
    payload,
  }
}

export function deleteUser(payload: any): IUserDeleteAction {
  return { type: UsersManagementActions.DELETE_USER, payload }
}

export function deleteUserSuccess(): IUserDeleteSuccessAction {
  return {
    type: UsersManagementActions.DELETE_USER_SUCCESS,
  }
}

export function deleteUserFailed(payload: { error: string }): IUserDeleteFailedAction {
  return {
    type: UsersManagementActions.DELETE_USER_FAILED,
    payload,
  }
}

export function fetchListCompanies(payload: any): IFetchListCompanyAction {
  return {
    type: UsersManagementActions.FETCH_LIST_COMPANIES,
    payload,
  }
}

export function fetchListCompaniesSuccess(payload: any): IFetchListCompanySuccessAction {
  return {
    type: UsersManagementActions.FETCH_LIST_COMPANIES_SUCCESS,
    payload,
  }
}

export function fetchListCompaniesFailed(payload: any): IFetchListCompanyFailedAction {
  return {
    type: UsersManagementActions.FETCH_LIST_COMPANIES_FAILED,
    payload,
  }
}
