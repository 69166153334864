import { produce } from "immer"
import { stringify } from "qs"
import Axios from "services/_base/api"
import { API_NAME } from "../../core/model/Api"
import { ATATimeParams, ChartDataParams } from "./types"

class DashboardServices {
  public async fetchAll(params: Record<"page", number>) {
    const response = await Axios.get(
      `${API_NAME.dashboard.get.ais_v1_latestPositions}?${stringify(params)}`
    )
    const data = {
      data: {
        data: response.data.data.map((record) => {
          const attributes = produce(record.attributes, (draft) => {
            draft.properties = { ...draft, ...draft.properties }
            delete draft.properties.geometry
            delete draft.properties.properties
          })
          return attributes
        }),
      },
      headers: response.headers,
    }
    return data
  }

  public async fetchTargetVessels(params) {
    const response = await Axios.get(
      `${API_NAME.dashboard.get.ais_v1_vessels}?${stringify(params)}`
    )
    const data = {
      data: {
        data: response.data.data.map((record) => {
          const attributes = produce(record.attributes, (draft) => {
            draft.properties = { ...draft, ...draft.properties }
          })
          return attributes
        }),
      },
      headers: response.headers,
    }
    return data
  }

  public async fetchActualRoutes(params: any) {
    const data = await Axios.get(
      `${API_NAME.dashboard.get.ais_v1_trackings}?${stringify(params, {
        arrayFormat: "brackets",
        encode: false,
      })}`
    )
    return data
  }

  public async fetchClosestDestination(params: any) {
    const data = await Axios.get(
      `${API_NAME.dashboard.get.ais_v1_closestDestination}?${stringify(params, {
        arrayFormat: "brackets",
        encode: false,
      })}`
    )
    return data
  }

  public async fetchPlanRoutes(params: any) {
    const response = await Axios.get(
      `${API_NAME.dashboard.get.ais_v1_planRoutes}?${stringify(params, {
        arrayFormat: "brackets",
        encode: false,
      })}`
    )
    const planRouteData = []
    let lastGetFailedAt = null
    let lastModifiedAt = null
    response.data.data.map((record) => {
      const recordAttr = record.attributes
      lastGetFailedAt = recordAttr.last_get_failed_at
      lastModifiedAt = recordAttr.last_modified_at
      const points = []
      recordAttr.poins.map((point) => {
        const coordinates = point.geometry.coordinates.map((coord) => parseFloat(coord))
        const formatedPoint = produce(point, (draft) => {
          draft.attributes = point
          draft.attributes.last_ais_updated_at = point.datetime
          draft.attributes.geometry.coordinates = coordinates
        })
        return points.push(formatedPoint)
      })
      return planRouteData.push(points)
    })
    const data = {
      data: {
        data: planRouteData,
        lastGetFailedAt,
        lastModifiedAt,
      },
    }

    return data
  }

  public async fetchEcdisRoutes(params: any) {
    const response = await Axios.get(
      `${API_NAME.dashboard.get.ais_v1_ecdis_routes}?${stringify(params, {
        arrayFormat: "brackets",
        encode: false,
      })}`
    )
    const ecdisRouteData = []
    response.data.data.map((record) => {
      return ecdisRouteData.push({
        ...record,
        ...record.attributes,
      })
    })
    const data = {
      data: {
        data: ecdisRouteData,
      },
    }
    return data
  }

  public async fetchEcdisPoints(params: any) {
    const response = await Axios.get(
      `${API_NAME.dashboard.get.ais_v1_ecdis_routes_point_routes}?${stringify(params, {
        arrayFormat: "brackets",
        encode: false,
      })}`
    )
    const ecdisPointData = []
    response.data.data.map((record) => {
      const points = []
      record.attributes.ecdis_points.map((point) => {
        const coordinates = point.geometry.coordinates.map((coord) => parseFloat(coord))
        const formatedPoint = produce(point, (draft) => {
          draft.attributes = point
          draft.attributes.type = "Feature"
          draft.attributes.last_ais_updated_at = point.datetime
          draft.attributes.geometry.coordinates = coordinates
        })
        return points.push(formatedPoint)
      })
      return ecdisPointData.push(points)
    })
    return ecdisPointData
  }

  public async fetchInformations(params: any) {
    const data = await Axios.get(
      `${API_NAME.dashboard.get.ais_v1_vesselsInfo}?${stringify(params)}`
    )
    return data
  }

  public async fetchStageInformations(params: any) {
    const data = Axios.get(
      `${API_NAME.dashboard.get.analytic_v1_vessel_stage_spec}?${stringify(params)}`
    )
    return data
  }

  public async fetchXdfInformations(params: any) {
    const data = await Axios.get(
      `${API_NAME.dashboard.get.analytic_v1_vessel_xdf_spec}?${stringify(params)}`
    )
    return data
  }

  public async fetchDfdeInformations(params: any) {
    const data = await Axios.get(
      `${API_NAME.dashboard.get.analytic_v1_vessel_dfde_spec}?${stringify(params)}`
    )
    return data
  }

  public async fetchPortAll(params: Record<"page", number>) {
    const response = await Axios.get(`ais/v1/ports/cargo_related_ports?${stringify(params)}`)

    const data = {
      data: {
        data: response.data.data.map((record: any) => {
          const attributes = produce(record.attributes, (draft: any) => {
            draft.properties = { ...draft, ...draft.properties }
            delete draft.properties.geometry
          })
          return attributes
        }),
        meta: response.data.meta,
      },
    }

    return data
  }

  public async updateATATime(params: { ecdis_points: ATATimeParams[] }) {
    const response = await Axios.post(
      `${API_NAME.dashboard.post.ais_v1_ecdis_update_original_eta}`,
      params
    )
    const data = {
      data: response.data.attributes,
      headers: response.headers,
    }
    return data
  }

  public async fetchStageCargoChart(params: ChartDataParams) {
    const response = await Axios.get(
      `${API_NAME.dashboard.get.analytic_v1_charts_stage_cargo}?from_time=${params.from_time}&to_time=${params.to_time}&vessel_id=${params.vessel_id}`
    )
    return response
  }

  public async fetchXDFCargoChart(params: ChartDataParams) {
    const response = await Axios.get(
      `${API_NAME.dashboard.get.analytic_v1_charts_xdf_cargo}?from_time=${params.from_time}&to_time=${params.to_time}&vessel_id=${params.vessel_id}`
    )

    return response
  }

  public async fetchStageEngineChart(params: ChartDataParams) {
    const response = await Axios.get(
      `${API_NAME.dashboard.get.analytic_v1_charts_stage_engine}?from_time=${params.from_time}&to_time=${params.to_time}&vessel_id=${params.vessel_id}`
    )

    return response
  }

  public async fetchXDFEngineChart(params: ChartDataParams) {
    const response = await Axios.get(
      `${API_NAME.dashboard.get.analytic_v1_charts_xdf_engine}?from_time=${params.from_time}&to_time=${params.to_time}&vessel_id=${params.vessel_id}`
    )

    return response
  }

  public async fetchDFDEEngineChart(params: ChartDataParams) {
    const response = await Axios.get(
      `${API_NAME.dashboard.get.analytic_v1_charts_dfde_engine}?from_time=${params.from_time}&to_time=${params.to_time}&vessel_id=${params.vessel_id}`
    )

    return response
  }

  public async fetchDFDECargoChart(params: ChartDataParams) {
    const response = await Axios.get(
      `${API_NAME.dashboard.get.analytic_v1_charts_dfde_cargo}?from_time=${params.from_time}&to_time=${params.to_time}&vessel_id=${params.vessel_id}`
    )

    return response
  }
}

export default new DashboardServices()
