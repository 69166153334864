import genActions from "services/_base/actions"
import {
  DataSettingsLngConsInPanamaActions,
  IPanamaLngConsFetch,
  IPanamaLngConsFetchFailed,
  IPanamaLngConsFetchSuccess,
  IUpdatePanamaLngCons,
  IUpdatePanamaLngConsFailed,
  IUpdatePanamaLngConsSuccess,
} from "./types"

export const { funcs: BaseFunctions, types: BaseActions } = genActions(
  "data_settings_panama_lng_cons"
)

export function fetchPanamaLngCons(): IPanamaLngConsFetch {
  return {
    type: DataSettingsLngConsInPanamaActions.FETCH_PANAMA_LNG_CONS,
  }
}

export function fetchPanamaLngConsSuccess(payload: any): IPanamaLngConsFetchSuccess {
  return {
    type: DataSettingsLngConsInPanamaActions.FETCH_PANAMA_LNG_CONS_SUCCESS,
    payload,
  }
}

export function fetchPanamaLngConsFailed(payload: any): IPanamaLngConsFetchFailed {
  return {
    type: DataSettingsLngConsInPanamaActions.FETCH_PANAMA_LNG_CONS_FAILED,
    payload,
  }
}

export function updatePanamaLngCons(payload: any): IUpdatePanamaLngCons {
  return {
    type: DataSettingsLngConsInPanamaActions.UPDATE_FETCH_PANAMA_LNG_CONS,
    payload,
  }
}

export function updatePanamaLngConsSuccess(): IUpdatePanamaLngConsSuccess {
  return {
    type: DataSettingsLngConsInPanamaActions.UPDATE_FETCH_PANAMA_LNG_CONS_SUCCESS,
  }
}

export function updatePanamaLngConsFailed(payload: any): IUpdatePanamaLngConsFailed {
  return {
    type: DataSettingsLngConsInPanamaActions.UPDATE_FETCH_PANAMA_LNG_CONS_FAILED,
    payload,
  }
}
