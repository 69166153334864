import { HEEL_CALC_PAYLOAD, HEEL_EDQ_ITEM, HEEL_EDQ_RESULT_RESPONSE } from "core/constants/heelEdq"

interface IHeelEdqForm {
  port_dept: { loading: boolean; error: string; data: string[] }
  port_arrival: { loading: boolean; error: string; data: string[] }
  pacific_route: { loading: boolean; error: string; data: string[] }
  payload: {
    port_dept_id: string
    port_arrival_id: string
    master_route_id: string
    etd: string
    etd_utc: string
    etd_time_zone: string
    etd_label: string
    eta: string
    eta_utc: string
    eta_time_zone: string
    eta_label: string
    sea_margin: number
  }
  calculate_voyage: {
    loading: boolean
    error: string
    data: HEEL_EDQ_RESULT_RESPONSE
  }
}
interface IHeelEdqCalculation {
  init_lng_volume: number | string
  unpumpable: number | string
  cosuming_lng_of_laden_voyage: number | string
  cosuming_lng_of_laden_voyage_leg1: number | string
  cosuming_lng_of_laden_voyage_leg2: number | string
  cosuming_lng_of_ballast_voyage: number | string
  cosuming_lng_of_ballast_voyage_leg1: number | string
  cosuming_lng_of_ballast_voyage_leg2: number | string
  edq: number | string
}

interface IHeelEdqGeneral {
  imo: string | number
  foe: number
  name: string
  laden_voyage_no: string
  ballast_voyage_no: string
}

export interface IHeelEdqFilter {
  imo: string
  ballast_voyage_port_dept_id: number
  ballast_voyage_port_arrival_id: number
  laden_voyage_port_dept_id: number
  laden_voyage_port_arrival_id: number
  master_route_id: number
  master_route_type: string
  voyage_no: string
  voyage_no_type: string
  sort_by: string
  sort_order: string
  page: number
}

export interface IHeelEdqState {
  vessels: { loading: boolean; error: string; data: [] }
  lng_consumption_panama: { loading: boolean; error: string; data: Record<string, string | number> }
  calculate: {
    loading: boolean
    isReset: boolean
    validation: {
      fields: string[]
    }
    general: IHeelEdqGeneral
    laden_voyage_leg1: IHeelEdqForm
    ballast_voyage_leg1: IHeelEdqForm
    laden_voyage_leg2: IHeelEdqForm
    ballast_voyage_leg2: IHeelEdqForm
    edq_calculation: IHeelEdqCalculation
    current_voyage: string
    panama_transit: {
      laden_pa_transit: boolean
      ballast_pa_transit: boolean
      landen_fuel_consumption_in_pa: number
      ballast_fuel_consumption_in_pa: number
      cosuming_lng_of_laden_voyage: number | null
      cosuming_lng_of_ballast_voyage: number | null
      estimated_heel_leg1: number | null
      estimated_heel_leg2: number | null
    }
  }
  list: {
    loading: boolean
    error: string
    data: HEEL_EDQ_ITEM[]
    total_records: number
    current_page: number
    per_page: number
    total_pages: number
    isDeleteItem: boolean
  }
  item: {
    loading: boolean
    error: string
    data: HEEL_EDQ_ITEM
    finalize: {
      loading: boolean
      error: string
    }
  }
  filters: {
    port_dept: { loading: boolean; error: string; data: string[] }
    pacific_route: { loading: boolean; error: string; data: string[] }
    voyage_no: { loading: boolean; error: string; data: string[] }
    payload: IHeelEdqFilter
  }
}

export enum HeelEdqActions {
  FETCH_TARGET_VESSELS = "[heel calc] fetch target vessel",
  FETCH_TARGET_VESSELS_SUCCESS = "[heel calc] fetch target vessel success",
  FETCH_TARGET_VESSELS_FAILED = "[heel calc] fetch target vessel failed",
  FETCH_TARGET_VESSELS_FINISHED = "[heel calc] fetch target vessel finished",

  SET_HEEL_EDQ_IMO = "[heel calc] set heel edq imo",
  SET_HEEL_EDQ_NAME = "[heel calc] set heel edq name",
  SET_HEEL_EDQ_FOE = "[heel calc] set heel edq foe",
  SET_HEEL_EDQ_LADEN_NO = "[heel calc] set heel edq laden no",
  SET_HEEL_EDQ_BALLAST_NO = "[heel calc] set heel edq ballast no",
  RESET_HEEL_EDQ_GENERAL = "[heel calc] reset heel edq general form",

  SET_EDQ_CALCULATION = "[heel calc] set EDQ calculation",
  RESET_EDQ_CALCULATION = "[heel calc] reset EDQ calculation",

  FETCH_DEPT_PORT = "[heel calc] fetch dept port",
  FETCH_DEPT_PORT_SUCCESS = "[heel calc] fetch dept port success",
  FETCH_DEPT_PORT_FAILED = "[heel calc] fetch dept port failed",

  // Laden Leg 1
  FETCH_LADEN_ARRIVAL_PORT = "[heel calc] fetch laden arrival port",
  FETCH_LADEN_ARRIVAL_PORT_SUCCESS = "[heel calc] fetch laden arrival port success",
  FETCH_LADEN_ARRIVAL_PORT_FAILED = "[heel calc] fetch laden arrival port failed",

  FETCH_LADEN_PACIFIC_ROUTE = "[heel calc] fetch laden pacific route",
  FETCH_LADEN_PACIFIC_ROUTE_SUCCESS = "[heel calc] fetch laden pacific route success",
  FETCH_LADEN_PACIFIC_ROUTE_FAILED = "[heel calc] fetch laden pacific route failed",

  CALCULATE_VOYAGE_LADEN = "[heel calc] calculate voyage laden",
  CALCULATE_VOYAGE_LADEN_SUCCESS = "[heel calc] calculate voyage laden success",
  CALCULATE_VOYAGE_LADEN_FAILED = "[heel calc] calculate voyage laden failed",

  FETCH_LADEN_TIMEZONE_LABEL = "[heel calc] fetch laden timezone label",
  FETCH_LADEN_TIMEZONE_LABEL_SUCCESS = "[heel calc] fetch laden timezone label success",
  FETCH_LADEN_TIMEZONE_LABEL_FAILED = "[heel calc] fetch laden timezone label failed",

  // Laden Leg 2
  FETCH_LADEN_LEG2_ARRIVAL_PORT = "[heel calc] fetch laden leg 2 arrival port",
  FETCH_LADEN_LEG2_ARRIVAL_PORT_SUCCESS = "[heel calc] fetch laden leg 2 arrival port success",
  FETCH_LADEN_LEG2_ARRIVAL_PORT_FAILED = "[heel calc] fetch laden leg 2 arrival port failed",

  FETCH_LADEN_LEG2_PACIFIC_ROUTE = "[heel calc] fetch laden leg 2 pacific route",
  FETCH_LADEN_LEG2_PACIFIC_ROUTE_SUCCESS = "[heel calc] fetch leg 2 laden pacific route success",
  FETCH_LADEN_LEG2_PACIFIC_ROUTE_FAILED = "[heel calc] fetch leg 2 laden pacific route failed",

  CALCULATE_VOYAGE_LADEN_LEG2 = "[heel calc] calculate voyage laden leg 2",
  CALCULATE_VOYAGE_LADEN_LEG2_SUCCESS = "[heel calc] calculate voyage laden leg 2 success",
  CALCULATE_VOYAGE_LADEN_LEG2_FAILED = "[heel calc] calculate voyage laden leg 2 failed",

  FETCH_LADEN_LEG2_TIMEZONE_LABEL = "[heel calc] fetch laden leg2 timezone label",
  FETCH_LADEN_LEG2_TIMEZONE_LABEL_SUCCESS = "[heel calc] fetch laden leg2 timezone label success",
  FETCH_LADEN_LEG2_TIMEZONE_LABEL_FAILED = "[heel calc] fetch laden leg2 timezone label failed",

  // Ballast Leg 1
  FETCH_BALLAST_ARRIVAL_PORT = "[heel calc] fetch ballast arrival port",
  FETCH_BALLAST_ARRIVAL_PORT_SUCCESS = "[heel calc] fetch ballast arrival port success",
  FETCH_BALLAST_ARRIVAL_PORT_FAILED = "[heel calc] fetch ballast arrival port failed",

  FETCH_BALLAST_PACIFIC_ROUTE = "[heel calc] fetch pacific route",
  FETCH_BALLAST_PACIFIC_ROUTE_SUCCESS = "[heel calc] fetch pacific route success",
  FETCH_BALLAST_PACIFIC_ROUTE_FAILED = "[heel calc] fetch pacific route failed",

  CALCULATE_VOYAGE_BALLAST = "[heel calc] calculate voyage ballast",
  CALCULATE_VOYAGE_BALLAST_SUCCESS = "[heel calc] calculate voyage ballast success",
  CALCULATE_VOYAGE_BALLAST_FAILED = "[heel calc] calculate voyage ballast failed",

  FETCH_BALLAST_TIMEZONE_LABEL = "[heel calc] fetch ballast timezone label",
  FETCH_BALLAST_TIMEZONE_LABEL_SUCCESS = "[heel calc] fetch ballast timezone label success",
  FETCH_BALLAST_TIMEZONE_LABEL_FAILED = "[heel calc] fetch ballast timezone label failed",

  // Ballast Leg 2
  FETCH_BALLAST_LEG2_ARRIVAL_PORT = "[heel calc] fetch ballast leg 2 arrival port",
  FETCH_BALLAST_LEG2_ARRIVAL_PORT_SUCCESS = "[heel calc] fetch ballast leg 2 arrival port success",
  FETCH_BALLAST_LEG2_ARRIVAL_PORT_FAILED = "[heel calc] fetch ballast leg 2 arrival port failed",

  FETCH_BALLAST_LEG2_PACIFIC_ROUTE = "[heel calc] fetch ballast leg 2 pacific route",
  FETCH_BALLAST_LEG2_PACIFIC_ROUTE_SUCCESS = "[heel calc] fetch ballast leg 2 pacific route success",
  FETCH_BALLAST_LEG2_PACIFIC_ROUTE_FAILED = "[heel calc] fetch ballast leg 2 pacific route failed",

  CALCULATE_VOYAGE_BALLAST_LEG2 = "[heel calc] calculate voyage ballast leg 2",
  CALCULATE_VOYAGE_BALLAST_LEG2_SUCCESS = "[heel calc] calculate voyage ballast leg 2 success",
  CALCULATE_VOYAGE_BALLAST_LEG2_FAILED = "[heel calc] calculate voyage ballast leg 2 failed",

  FETCH_BALLAST_LEG2_TIMEZONE_LABEL = "[heel calc] fetch ballast leg 2 timezone label",
  FETCH_BALLAST_LEG2_TIMEZONE_LABEL_SUCCESS = "[heel calc] fetch ballast leg 2 timezone label success",
  FETCH_BALLAST_LEG2_TIMEZONE_LABEL_FAILED = "[heel calc] fetch ballast leg 2 timezone label failed",

  // END

  CALCULATE_VOYAGE_RESET = "[heel calc] calculate voyage reset",
  CALCULATE_VOYAGE_RESULT_RESET = "[heel calc] calculate voyage result reset",

  FETCH_LNG_CONSUMPTION_PANAMA = "[heel item] fetch lng consumption in panama",
  FETCH_LNG_CONSUMPTION_PANAMA_SUCCESS = "[heel item] fetch lng consumption in panama success",
  FETCH_LNG_CONSUMPTION_PANAMA_FAILED = "[heel result] fetch lng consumption in panama failed",

  FETCH_HEEL_EDQ_ITEM = "[heel result] fetch heel edq item",
  FETCH_HEEL_EDQ_ITEM_SUCCESS = "[heel result] heel edq item success",
  FETCH_HEEL_EDQ_ITEM_FAILED = "[heel result] heel edq item failed",
  RESET_HEEL_EDQ_ITEM = "[heel result] heel edq item reset",

  CREATE_RESULT = "[heel calc] create result",
  CREATE_RESULT_SUCCESS = "[heel calc] create result success",
  CREATE_RESULT_FAILED = "[heel calc] create result failed",

  UPDATE_RESULT = "[heel calc] update result",
  UPDATE_RESULT_SUCCESS = "[heel calc] update result success",
  UPDATE_RESULT_FAILED = "[heel calc] update result failed",

  DELETE_RESULT = "[heel calc] delete result",
  DELETE_RESULT_SUCCESS = "[heel calc] delete result success",
  DELETE_RESULT_FAILED = "[heel calc] delete result failed",

  FINALIZE_RESULT = "[heel calc] finalize result",
  FINALIZE_RESULT_SUCCESS = "[heel calc] finalize result success",
  FINALIZE_RESULT_FAILED = "[heel calc] finalize result failed",

  FETCH_CALCULATION_RESULT_ALL = "[heel list] fetch all",
  FETCH_CALCULATION_RESULT_ALL_SUCCESS = "[heel list] fetch all success",
  FETCH_CALCULATION_RESULT_ALL_FINISHED = "[heel list] fetch all finished",
  FETCH_CALCULATION_RESULT_ALL_FAILED = "[heel list] fetch all failed",

  SET_VALIDATION_FIELD = "[heel calc] set validation field",
  SET_CURRENT_VOYAGE = "[heel calc] set current voyage",

  SET_FILTERS = "[heel filter] set filters",

  FETCH_FILTER_PACIFIC_ROUTE = "[heel filter] fetch pacific route",
  FETCH_FILTER_PACIFIC_ROUTE_SUCCESS = "[heel filter] fetch pacific route success",
  FETCH_FILTER_PACIFIC_ROUTE_FAILED = "[heel filter] fetch pacific route failed",

  SET_LADEN_BALLAST_PANAMA_TRANSIT = "[heel calc] set laden or ballast panama transit",
  SET_LADEN_BALLAST_FUEL_PANAMA_TRANSIT = "[heel calc] set laden or ballast fuel panama transit",
  SET_LADEN_BALLAST_SUMMARY_PANAMA_TRANSIT = "[heel calc] set laden or ballast summary panama transit",

  SET_ESTIMATED_HEEL = "[heel calc] set estimated heel",
}

export interface ITargetVesselsFetch {
  type: HeelEdqActions.FETCH_TARGET_VESSELS
}

export interface ITargetVesselsFetchSuccess {
  type: HeelEdqActions.FETCH_TARGET_VESSELS_SUCCESS
  payload: {
    data: []
  }
}

export interface ITargetVesselsFetchFinished {
  type: HeelEdqActions.FETCH_TARGET_VESSELS_FINISHED
  payload: {
    allData: []
    headers: any
  }
}

export interface ITargetVesselsFetchFailed {
  type: HeelEdqActions.FETCH_TARGET_VESSELS_FAILED
  payload: {
    error: string
  }
}

export interface ISetHeelEdqImo {
  type: HeelEdqActions.SET_HEEL_EDQ_IMO
  payload: { imo: string | number }
}
export interface ISetHeelEdqName {
  type: HeelEdqActions.SET_HEEL_EDQ_NAME
  payload: { name: string }
}
export interface ISetHeelEdqFOE {
  type: HeelEdqActions.SET_HEEL_EDQ_FOE
  payload: { foe: number }
}
export interface ISetHeelEdqLadenNo {
  type: HeelEdqActions.SET_HEEL_EDQ_LADEN_NO
  payload: { laden_voyage_no: string }
}
export interface ISetHeelEdqBallastNo {
  type: HeelEdqActions.SET_HEEL_EDQ_BALLAST_NO
  payload: { ballast_voyage_no: string }
}

export interface IResetHeelEdqGeneral {
  type: HeelEdqActions.RESET_HEEL_EDQ_GENERAL
}

export interface ISetEDQCalculation {
  type: HeelEdqActions.SET_EDQ_CALCULATION
  payload: IHeelEdqCalculation
}

export interface IResetEDQCalculation {
  type: HeelEdqActions.RESET_EDQ_CALCULATION
  payload: IHeelEdqCalculation
}

export interface IDeptPortFetch {
  type: HeelEdqActions.FETCH_DEPT_PORT
}

export interface IDeptPortFetchSuccess {
  type: HeelEdqActions.FETCH_DEPT_PORT_SUCCESS
  payload: {
    data: []
  }
}

export interface IDeptPortFetchFailed {
  type: HeelEdqActions.FETCH_DEPT_PORT_FAILED
  payload: {
    error: string
  }
}

/* Laden Leg 1 */
export interface ILadenArrivalPortFetch {
  type: HeelEdqActions.FETCH_LADEN_ARRIVAL_PORT
  payload: {
    port_dept: string
  }
}

export interface ILadenArrivalPortFetchSuccess {
  type: HeelEdqActions.FETCH_LADEN_ARRIVAL_PORT_SUCCESS
  payload: {
    data: []
  }
}

export interface ILadenArrivalPortFetchFailed {
  type: HeelEdqActions.FETCH_LADEN_ARRIVAL_PORT_FAILED
  payload: {
    error: string
  }
}

export interface ILadenPacificRouteFetch {
  type: HeelEdqActions.FETCH_LADEN_PACIFIC_ROUTE
  payload: {
    port_dept: string
    port_arrival: string
  }
}

export interface ILadenPacificRouteFetchSuccess {
  type: HeelEdqActions.FETCH_LADEN_PACIFIC_ROUTE_SUCCESS
  payload: {
    data: []
  }
}

export interface ILadenPacificRouteFetchFailed {
  type: HeelEdqActions.FETCH_LADEN_PACIFIC_ROUTE_FAILED
  payload: {
    error: string
  }
}

export interface ILadenCalculateVoyage {
  type: HeelEdqActions.CALCULATE_VOYAGE_LADEN
  payload: {
    data: HEEL_CALC_PAYLOAD
  }
}

export interface ILadenCalculateVoyageSuccess {
  type: HeelEdqActions.CALCULATE_VOYAGE_LADEN_SUCCESS
  payload: {
    data: HEEL_EDQ_RESULT_RESPONSE
  }
}

export interface ILadenCalculateVoyageFailed {
  type: HeelEdqActions.CALCULATE_VOYAGE_LADEN_FAILED
  payload: {
    error: string
  }
}

export interface ILadenTimeZoneLabelFetch {
  type: HeelEdqActions.FETCH_LADEN_TIMEZONE_LABEL
  payload: {
    timezone_type: string
    port_id: string
    time: string
    voyage_type: string
  }
}

export interface ILadenTimeZoneLabelFetchSuccess {
  type: HeelEdqActions.FETCH_LADEN_TIMEZONE_LABEL_SUCCESS
  payload: {
    label: string
    time_zone: string
    timezone_type: string
    time_utc: string
  }
}

export interface ILadenTimeZoneLabelFetchFailed {
  type: HeelEdqActions.FETCH_LADEN_TIMEZONE_LABEL_FAILED
  payload: {
    error: string
    timezone_type: string
  }
}

// Laden Leg 2
export interface ILadenLeg2ArrivalPortFetch {
  type: HeelEdqActions.FETCH_LADEN_LEG2_ARRIVAL_PORT
  payload: {
    port_dept: string
  }
}

export interface ILadenLeg2ArrivalPortFetchSuccess {
  type: HeelEdqActions.FETCH_LADEN_LEG2_ARRIVAL_PORT_SUCCESS
  payload: {
    data: []
  }
}

export interface ILadenLeg2ArrivalPortFetchFailed {
  type: HeelEdqActions.FETCH_LADEN_LEG2_ARRIVAL_PORT_FAILED
  payload: {
    error: string
  }
}

export interface ILadenLeg2PacificRouteFetch {
  type: HeelEdqActions.FETCH_LADEN_LEG2_PACIFIC_ROUTE
  payload: {
    port_dept: string
    port_arrival: string
  }
}

export interface ILadenLeg2PacificRouteFetchSuccess {
  type: HeelEdqActions.FETCH_LADEN_LEG2_PACIFIC_ROUTE_SUCCESS
  payload: {
    data: []
  }
}

export interface ILadenLeg2PacificRouteFetchFailed {
  type: HeelEdqActions.FETCH_LADEN_LEG2_PACIFIC_ROUTE_FAILED
  payload: {
    error: string
  }
}

export interface ILadenLeg2CalculateVoyage {
  type: HeelEdqActions.CALCULATE_VOYAGE_LADEN_LEG2
  payload: {
    data: HEEL_CALC_PAYLOAD
  }
}

export interface ILadenLeg2CalculateVoyageSuccess {
  type: HeelEdqActions.CALCULATE_VOYAGE_LADEN_LEG2_SUCCESS
  payload: {
    data: HEEL_EDQ_RESULT_RESPONSE
  }
}

export interface ILadenLeg2CalculateVoyageFailed {
  type: HeelEdqActions.CALCULATE_VOYAGE_LADEN_LEG2_FAILED
  payload: {
    error: string
  }
}

export interface ILadenLeg2TimeZoneLabelFetch {
  type: HeelEdqActions.FETCH_LADEN_LEG2_TIMEZONE_LABEL
  payload: {
    timezone_type: string
    port_id: string
    time: string
    voyage_type: string
  }
}

export interface ILadenLeg2TimeZoneLabelFetchSuccess {
  type: HeelEdqActions.FETCH_LADEN_LEG2_TIMEZONE_LABEL_SUCCESS
  payload: {
    label: string
    time_zone: string
    timezone_type: string
    time_utc: string
  }
}

export interface ILadenLeg2TimeZoneLabelFetchFailed {
  type: HeelEdqActions.FETCH_LADEN_LEG2_TIMEZONE_LABEL_FAILED
  payload: {
    error: string
    timezone_type: string
  }
}
// Ballast Leg 1
export interface IBallastArrivalPortFetch {
  type: HeelEdqActions.FETCH_BALLAST_ARRIVAL_PORT
  payload: {
    port_dept: string
  }
}

export interface IBallastArrivalPortFetchSuccess {
  type: HeelEdqActions.FETCH_BALLAST_ARRIVAL_PORT_SUCCESS
  payload: {
    data: []
  }
}

export interface IBallastArrivalPortFetchFailed {
  type: HeelEdqActions.FETCH_BALLAST_ARRIVAL_PORT_FAILED
  payload: {
    error: string
  }
}

export interface IBallastPacificRouteFetch {
  type: HeelEdqActions.FETCH_BALLAST_PACIFIC_ROUTE
  payload: {
    port_dept: string
    port_arrival: string
  }
}

export interface IBallastPacificRouteFetchSuccess {
  type: HeelEdqActions.FETCH_BALLAST_PACIFIC_ROUTE_SUCCESS
  payload: {
    data: []
  }
}

export interface IBallastPacificRouteFetchFailed {
  type: HeelEdqActions.FETCH_BALLAST_PACIFIC_ROUTE_FAILED
  payload: {
    error: string
  }
}

export interface IBallastCalculateVoyage {
  type: HeelEdqActions.CALCULATE_VOYAGE_BALLAST
  payload: {
    data: HEEL_CALC_PAYLOAD
  }
}

export interface IBallastCalculateVoyageSuccess {
  type: HeelEdqActions.CALCULATE_VOYAGE_BALLAST_SUCCESS
  payload: {
    data: HEEL_EDQ_RESULT_RESPONSE
  }
}

export interface IBallastCalculateVoyageFailed {
  type: HeelEdqActions.CALCULATE_VOYAGE_BALLAST_FAILED
  payload: {
    error: string
  }
}

export interface IBallastTimeZoneLabelFetch {
  type: HeelEdqActions.FETCH_BALLAST_TIMEZONE_LABEL
  payload: {
    timezone_type: string
    port_id: string
    time: string
    voyage_type: string
  }
}

export interface IBallastTimeZoneLabelFetchSuccess {
  type: HeelEdqActions.FETCH_BALLAST_TIMEZONE_LABEL_SUCCESS
  payload: {
    label: string
    time_zone: string
    timezone_type: string
    time_utc: string
  }
}

export interface IBallastTimeZoneLabelFetchFailed {
  type: HeelEdqActions.FETCH_BALLAST_TIMEZONE_LABEL_FAILED
  payload: {
    error: string
    timezone_type: string
  }
}

// Ballast Leg 2
export interface IBallastLeg2ArrivalPortFetch {
  type: HeelEdqActions.FETCH_BALLAST_LEG2_ARRIVAL_PORT
  payload: {
    port_dept: string
  }
}

export interface IBallastLeg2ArrivalPortFetchSuccess {
  type: HeelEdqActions.FETCH_BALLAST_LEG2_ARRIVAL_PORT_SUCCESS
  payload: {
    data: []
  }
}

export interface IBallastLeg2ArrivalPortFetchFailed {
  type: HeelEdqActions.FETCH_BALLAST_LEG2_ARRIVAL_PORT_FAILED
  payload: {
    error: string
  }
}

export interface IBallastLeg2PacificRouteFetch {
  type: HeelEdqActions.FETCH_BALLAST_LEG2_PACIFIC_ROUTE
  payload: {
    port_dept: string
    port_arrival: string
  }
}

export interface IBallastLeg2PacificRouteFetchSuccess {
  type: HeelEdqActions.FETCH_BALLAST_LEG2_PACIFIC_ROUTE_SUCCESS
  payload: {
    data: []
  }
}

export interface IBallastLeg2PacificRouteFetchFailed {
  type: HeelEdqActions.FETCH_BALLAST_LEG2_PACIFIC_ROUTE_FAILED
  payload: {
    error: string
  }
}

export interface IBallastLeg2CalculateVoyage {
  type: HeelEdqActions.CALCULATE_VOYAGE_BALLAST_LEG2
  payload: {
    data: HEEL_CALC_PAYLOAD
  }
}

export interface IBallastLeg2CalculateVoyageSuccess {
  type: HeelEdqActions.CALCULATE_VOYAGE_BALLAST_LEG2_SUCCESS
  payload: {
    data: HEEL_EDQ_RESULT_RESPONSE
  }
}

export interface IBallastLeg2CalculateVoyageFailed {
  type: HeelEdqActions.CALCULATE_VOYAGE_BALLAST_LEG2_FAILED
  payload: {
    error: string
  }
}

export interface IBallastLeg2TimeZoneLabelFetch {
  type: HeelEdqActions.FETCH_BALLAST_LEG2_TIMEZONE_LABEL
  payload: {
    timezone_type: string
    port_id: string
    time: string
    voyage_type: string
  }
}

export interface IBallastLeg2TimeZoneLabelFetchSuccess {
  type: HeelEdqActions.FETCH_BALLAST_LEG2_TIMEZONE_LABEL_SUCCESS
  payload: {
    label: string
    time_zone: string
    timezone_type: string
    time_utc: string
  }
}

export interface IBallastLeg2TimeZoneLabelFetchFailed {
  type: HeelEdqActions.FETCH_BALLAST_LEG2_TIMEZONE_LABEL_FAILED
  payload: {
    error: string
    timezone_type: string
  }
}

// END

export interface ICalculateVoyageReset {
  type: HeelEdqActions.CALCULATE_VOYAGE_RESET
  payload: { voyage_type: string }
}

export interface ICalculateVoyageResultReset {
  type: HeelEdqActions.CALCULATE_VOYAGE_RESULT_RESET
  payload: { voyage_type: string }
}

export interface ILngConsumptionPanamaFetch {
  type: HeelEdqActions.FETCH_LNG_CONSUMPTION_PANAMA
}

export interface ILngConsumptionPanamaFetchSuccess {
  type: HeelEdqActions.FETCH_LNG_CONSUMPTION_PANAMA_SUCCESS
  payload: {
    data: { code: string; value: number }
  }
}

export interface ILngConsumptionPanamaFetchFailed {
  type: HeelEdqActions.FETCH_LNG_CONSUMPTION_PANAMA_FAILED
  payload: {
    error: string
  }
}

export interface IHeelEdqItemFetch {
  type: HeelEdqActions.FETCH_HEEL_EDQ_ITEM
  payload: {
    id: string | number
  }
}

export interface IHeelEdqItemFetchSuccess {
  type: HeelEdqActions.FETCH_HEEL_EDQ_ITEM_SUCCESS
  payload: {
    data: HEEL_EDQ_ITEM
  }
}

export interface IHeelEdqItemFetchFailed {
  type: HeelEdqActions.FETCH_HEEL_EDQ_ITEM_FAILED
  payload: {
    error: string
  }
}

export interface IHeelEdqItemReset {
  type: HeelEdqActions.RESET_HEEL_EDQ_ITEM
}

export interface ICreateResult {
  type: HeelEdqActions.CREATE_RESULT
  payload: {
    data: any
  }
}

export interface ICreateResultSuccess {
  type: HeelEdqActions.CREATE_RESULT_SUCCESS
}

export interface ICreateResultFailed {
  type: HeelEdqActions.CREATE_RESULT_FAILED
  payload: { error: string }
}

export interface IUpdateResult {
  type: HeelEdqActions.UPDATE_RESULT
  payload: {
    id: string
    data: any
  }
}

export interface IUpdateResultSuccess {
  type: HeelEdqActions.UPDATE_RESULT_SUCCESS
}

export interface IUpdateResultFailed {
  type: HeelEdqActions.UPDATE_RESULT_FAILED
  payload: { error: string }
}

export interface IDeleteResult {
  type: HeelEdqActions.DELETE_RESULT
  payload: {
    id: string
  }
}

export interface IDeleteResultSuccess {
  type: HeelEdqActions.DELETE_RESULT_SUCCESS
}

export interface IDeleteResultFailed {
  type: HeelEdqActions.DELETE_RESULT_FAILED
  payload: { error: string }
}

export interface IFinalizeResult {
  type: HeelEdqActions.FINALIZE_RESULT
  payload: {
    id: string
  }
}

export interface IFinalizeResultSuccess {
  type: HeelEdqActions.FINALIZE_RESULT_SUCCESS
}

export interface IFinalizeResultFailed {
  type: HeelEdqActions.FINALIZE_RESULT_FAILED
  payload: { error: string }
}

export interface ICalculationResultFetchAll {
  type: HeelEdqActions.FETCH_CALCULATION_RESULT_ALL
  payload: IHeelEdqFilter
}

export interface ICalculationResultFetchAllSuccess {
  type: HeelEdqActions.FETCH_CALCULATION_RESULT_ALL_SUCCESS
  payload: {
    data: []
    headers: any
  }
}

export interface ICalculationResultFetchAllFinished {
  type: HeelEdqActions.FETCH_CALCULATION_RESULT_ALL_FINISHED
  payload: {
    allData: []
    headers: any
  }
}

export interface ICalculationResultFetchAllFailed {
  type: HeelEdqActions.FETCH_CALCULATION_RESULT_ALL_FAILED
  payload: { error: string }
}

export interface ISetValidationField {
  type: HeelEdqActions.SET_VALIDATION_FIELD
  payload: { fields: string[] }
}

export interface ISetFilters {
  type: HeelEdqActions.SET_FILTERS
  payload: IHeelEdqFilter
}

export interface IFilterPacificRouteFetch {
  type: HeelEdqActions.FETCH_FILTER_PACIFIC_ROUTE
}

export interface IFilterPacificRouteFetchSuccess {
  type: HeelEdqActions.FETCH_FILTER_PACIFIC_ROUTE_SUCCESS
  payload: {
    data: []
  }
}

export interface IFilterPacificRouteFetchFailed {
  type: HeelEdqActions.FETCH_FILTER_PACIFIC_ROUTE_FAILED
  payload: {
    error: string
  }
}

export interface ISetCurrentVoyage {
  type: HeelEdqActions.SET_CURRENT_VOYAGE
  payload: { current_voyage: string }
}

export interface ISetLadenBallastPanamaTransit {
  type: HeelEdqActions.SET_LADEN_BALLAST_PANAMA_TRANSIT
  payload: {
    checked: boolean
    voyage_field: string
  }
}

export interface ISetLadenBallastFuelPanamaTransit {
  type: HeelEdqActions.SET_LADEN_BALLAST_FUEL_PANAMA_TRANSIT
  payload: {
    value: number
    voyage_field: string
  }
}

export interface ISetLadenBallastSummaryPanamaTransit {
  type: HeelEdqActions.SET_LADEN_BALLAST_SUMMARY_PANAMA_TRANSIT
  payload: {
    value: number
    voyage_field: string
  }
}

export interface ISetEstimatedHeel {
  type: HeelEdqActions.SET_ESTIMATED_HEEL
  payload: {
    value: number
    field: string
  }
}

export type HeelEdqTypes =
  | ITargetVesselsFetch
  | ITargetVesselsFetchSuccess
  | ITargetVesselsFetchFinished
  | ITargetVesselsFetchFailed
  | ISetHeelEdqImo
  | ISetHeelEdqName
  | ISetHeelEdqFOE
  | ISetHeelEdqLadenNo
  | ISetHeelEdqBallastNo
  | IResetHeelEdqGeneral
  | ISetEDQCalculation
  | IResetEDQCalculation
  | IDeptPortFetch
  | IDeptPortFetchSuccess
  | IDeptPortFetchFailed
  // Laden Leg 1
  | ILadenArrivalPortFetch
  | ILadenArrivalPortFetchSuccess
  | ILadenArrivalPortFetchFailed
  | ILadenPacificRouteFetch
  | ILadenPacificRouteFetchSuccess
  | ILadenPacificRouteFetchFailed
  | ILadenCalculateVoyage
  | ILadenCalculateVoyageSuccess
  | ILadenCalculateVoyageFailed
  | ILadenTimeZoneLabelFetch
  | ILadenTimeZoneLabelFetchSuccess
  | ILadenTimeZoneLabelFetchFailed
  // Laden Leg 2
  | ILadenLeg2ArrivalPortFetch
  | ILadenLeg2ArrivalPortFetchSuccess
  | ILadenLeg2ArrivalPortFetchFailed
  | ILadenLeg2PacificRouteFetch
  | ILadenLeg2PacificRouteFetchSuccess
  | ILadenLeg2PacificRouteFetchFailed
  | ILadenLeg2CalculateVoyage
  | ILadenLeg2CalculateVoyageSuccess
  | ILadenLeg2CalculateVoyageFailed
  | ILadenLeg2TimeZoneLabelFetch
  | ILadenLeg2TimeZoneLabelFetchSuccess
  | ILadenLeg2TimeZoneLabelFetchFailed
  // Ballast Leg 1
  | IBallastArrivalPortFetch
  | IBallastArrivalPortFetchSuccess
  | IBallastArrivalPortFetchFailed
  | IBallastPacificRouteFetch
  | IBallastPacificRouteFetchSuccess
  | IBallastPacificRouteFetchFailed
  | IBallastCalculateVoyage
  | IBallastCalculateVoyageSuccess
  | IBallastCalculateVoyageFailed
  | IBallastTimeZoneLabelFetch
  | IBallastTimeZoneLabelFetchSuccess
  | IBallastTimeZoneLabelFetchFailed
  // Ballast Leg 2
  | IBallastLeg2ArrivalPortFetch
  | IBallastLeg2ArrivalPortFetchSuccess
  | IBallastLeg2ArrivalPortFetchFailed
  | IBallastLeg2PacificRouteFetch
  | IBallastLeg2PacificRouteFetchSuccess
  | IBallastLeg2PacificRouteFetchFailed
  | IBallastLeg2CalculateVoyage
  | IBallastLeg2CalculateVoyageSuccess
  | IBallastLeg2CalculateVoyageFailed
  | IBallastLeg2TimeZoneLabelFetch
  | IBallastLeg2TimeZoneLabelFetchSuccess
  | IBallastLeg2TimeZoneLabelFetchFailed
  // END
  | ICalculateVoyageReset
  | ICalculateVoyageResultReset
  | ILngConsumptionPanamaFetch
  | ILngConsumptionPanamaFetchSuccess
  | ILngConsumptionPanamaFetchFailed
  | IHeelEdqItemFetch
  | IHeelEdqItemFetchSuccess
  | IHeelEdqItemFetchFailed
  | IHeelEdqItemReset
  | ICreateResult
  | ICreateResultSuccess
  | ICreateResultFailed
  | IUpdateResult
  | IUpdateResultSuccess
  | IUpdateResultFailed
  | IDeleteResult
  | IDeleteResultSuccess
  | IDeleteResultFailed
  | IFinalizeResult
  | IFinalizeResultSuccess
  | IFinalizeResultFailed
  | ICalculationResultFetchAll
  | ICalculationResultFetchAllSuccess
  | ICalculationResultFetchAllFinished
  | ICalculationResultFetchAllFailed
  | ISetValidationField
  | ISetCurrentVoyage
  | ISetFilters
  | IFilterPacificRouteFetch
  | IFilterPacificRouteFetchSuccess
  | IFilterPacificRouteFetchFailed
  | ISetLadenBallastPanamaTransit
  | ISetLadenBallastFuelPanamaTransit
  | ISetLadenBallastSummaryPanamaTransit
  | ISetEstimatedHeel
