import { Row, Col } from "antd"
import { filterParams } from "services/shipData/types"
import { DEFAULT_DATETIME } from "core/model/DateTime"

interface FlowBannerProps {
  filterParams: filterParams
}

const Index = ({ filterParams }: FlowBannerProps) => {
  return (
    <>
      <Row className="shipDataHeader">
        <Col span={9} push={0}>
          <Row>
            <Col span={6} push={0} className="shipDataTitle">
              <Col span={24} push={0} className="shipDataStep">
                STEP 1
              </Col>
              <Col span={24} push={0}>
                {filterParams ? filterParams.imos.length : 0} Vessels
              </Col>
            </Col>
            <Col span={16} push={0}>
              <hr className="horizontalLine" />
            </Col>
          </Row>
        </Col>
        <Col span={9} pull={0} push={0}>
          <Row>
            <Col span={6} push={0} className="shipDataTitle">
              <Col span={24} pull={0} className="shipDataStep">
                STEP 2
              </Col>
              <Col span={24} push={0} pull={0}>
                {`${filterParams?.channels.length} Channels`}
              </Col>
            </Col>
            <Col span={16} pull={0}>
              <hr className="horizontalLine" />
            </Col>
          </Row>
        </Col>
        <Col span={6} pull={0}>
          <Row>
            <Col span={24} push={0} className="shipDataTitle">
              <Col span={24} push={0} className="shipDataStep">
                STEP 3
              </Col>
              <Col span={24} push={0}>
                {filterParams?.timestamp_from_at || DEFAULT_DATETIME} - {"   "}
                {filterParams?.timestamp_to_at || DEFAULT_DATETIME} Time Range
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default Index
