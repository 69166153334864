import { unifyValue } from "core/utils/unifyValueChartData"
import { format } from "date-fns"
import { MMM_dd_yyyy, createDateWihoutTimeZone } from "../../DateTime"

export default class ForcingVaporVolume {
  name: string

  timestamp: string

  selected_voyage_no: string

  vessel_name: string

  keys: any

  props: any

  constructor(data) {
    this.name = data.view_day
    this.selected_voyage_no = data.selected_voyage_no
    this.vessel_name = data.vessel_name
    this.keys = data.all_voyages
    this.props = data.voyage_props
    data.avg_forcing_vapor_volumn.map((item) => {
      this[item.voyage_no] = unifyValue(item.value, 1)
      this[`${item.voyage_no}_timestamp`] = format(createDateWihoutTimeZone(item.date), MMM_dd_yyyy)
      return null
    })
  }
}
