import { forEach } from "core/utils/enum"
import {
  ActionFunctionName,
  ACTIONS,
  GenActionReturn,
  IActions,
  ReturnCommonFunction,
} from "./types"

const genActions = <T extends keyof IActions>(prefix: string): GenActionReturn<T> => {
  const funcs: Partial<ReturnCommonFunction> = {}
  const types: any = {}

  forEach(ActionFunctionName, ({ key, value }) => {
    const hasKey = Reflect.has(funcs, value)
    if (!hasKey) {
      const dispatchType = `${prefix} ${ACTIONS[key]}`

      types[key] = dispatchType

      funcs[value] = <Payload>(payload?: Payload) => ({
        type: dispatchType,
        payload,
      })
    }
  })

  return { funcs, types } as GenActionReturn<T>
}

export default genActions
