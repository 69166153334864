/* eslint-disable react/require-default-props */
import { DatePicker } from "antd"

const { RangePicker } = DatePicker

interface GuardianTimePickerProps {
  className: string
  onDateChange: (value) => void
  size: "large" | "middle" | "small"
  showTime?: any
  disabledDate: any
  onCalendarChange?: any
  onOpenChange?: any
  value?: any
  hackValue?: any
  allowClear?: boolean
}

const Index = ({
  className,
  onDateChange,
  size,
  showTime = true,
  disabledDate,
  onCalendarChange,
  onOpenChange,
  value,
  hackValue,
  allowClear = true,
}: GuardianTimePickerProps) => {
  return (
    <RangePicker
      value={hackValue || value}
      showTime={showTime}
      className={className}
      size={size}
      allowClear={allowClear}
      disabledDate={disabledDate}
      onCalendarChange={onCalendarChange}
      onChange={(_, stringDate) => onDateChange(stringDate)}
      onOpenChange={onOpenChange}
    />
  )
}

export default Index
