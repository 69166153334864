/* eslint-disable no-empty-pattern */
import { all, call, put, select, takeLatest } from "redux-saga/effects"
import { get, omit } from "lodash"
import moment from "moment"
import DashboardServices from "services/dashboard/api"
import VoyagesComparisonServices from "./api"
import {
  fetchTargetVesselsSuccess,
  fetchTargetVesselsFinished,
  fetchTargetVesselsFailed,
  fetchVoyagesComparisonSuccess,
  fetchVoyagesComparisonFailed,
  fetchVoyagesHistoryRoutesSuccess,
  fetchVoyagesHistoryRoutesFailed,
  fetchVoyageNumberSuccess,
  fetchVoyageNumberFailed,
  fetchArrivalPortsSuccess,
  fetchArrivalPortsFailed,
  fetchDeparturePortsFailed,
  fetchDeparturePortsSuccess,
} from "./actions"
import {
  IVoyageNumberFetch,
  IVoyagesComparisonFetch,
  IVoyagesComparisonState,
  VoyagesComparisonAction,
} from "./types"
import { VoyagesComparisonSelector } from "./selectors"

export function* fetchTargetVesselsSaga() {
  let allData: any[] = []
  const filter = { page: 1, target: true, sort_by: "name", sort_order: "asc" }
  try {
    while (true) {
      const {
        data: { data },
        headers,
      } = yield call(VoyagesComparisonServices.fetchTargetVessels, filter)

      allData = [...allData, ...data]
      yield put(fetchTargetVesselsSuccess({ data: allData }))

      if (!get(headers, "link").includes("next")) {
        yield put(fetchTargetVesselsFinished({ allData, headers }))
        break
      }
      filter.page += 1
    }
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        fetchTargetVesselsFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export function* fetchDeparturePortsSaga() {
  try {
    const data = yield call(VoyagesComparisonServices.fetchDeparturePorts)

    yield put(fetchDeparturePortsSuccess({ data }))
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        fetchDeparturePortsFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export function* fetchArrivalPortsSaga() {
  try {
    const data = yield call(VoyagesComparisonServices.fetchArrivalPorts)

    yield put(fetchArrivalPortsSuccess({ data }))
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        fetchArrivalPortsFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export function* fetchVoyagesComparisonSaga({ payload }: IVoyagesComparisonFetch) {
  const { color } = payload
  try {
    const data = yield call(VoyagesComparisonServices.fetchVoyagesComparison, payload)

    yield put(fetchVoyagesComparisonSuccess({ data, color }))
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        fetchVoyagesComparisonFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
          color,
        })
      )
    }
  }
}

export function* fetchVoyagesHistoryRoutesSaga() {
  try {
    const {
      voyages_comparison,
      routes,
      vessels: { data: vesselsData },
    }: IVoyagesComparisonState = yield select(VoyagesComparisonSelector)
    let allData = routes.data
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(voyages_comparison)) {
      const filterVoyagesData = value.voyages.filter_voyages
      const routes = []
      if (filterVoyagesData.length > 0 && value.isChanged) {
        // eslint-disable-next-line
        for (let i = 0; i < filterVoyagesData.length; i++) {
          const from_time =
            filterVoyagesData[i].attributes.closest_atd ?? filterVoyagesData[i].attributes.atd_utc
          const to_time =
            filterVoyagesData[i].attributes.closest_ata ?? filterVoyagesData[i].attributes.ata_utc

          const vessel: any = vesselsData.filter(
            (vessel: any) => filterVoyagesData[i].attributes.imo === vessel.imo
          )[0]

          let payload = {
            from_time: moment.utc(from_time).startOf("d").format(),
            time: moment.utc(from_time).format(),
            to_time: moment.utc(to_time).format(),
            vessel_id: vessel.id,
          }

          const {
            data: { data: closestDestinationData },
          } = yield call(DashboardServices.fetchClosestDestination, payload)
          if (closestDestinationData) {
            const { last_position_updated_at } = closestDestinationData.attributes
            payload = last_position_updated_at
              ? { ...payload, from_time: moment.utc(last_position_updated_at).format() }
              : payload
          }
          const {
            data: { data: historyRoutes },
          } = yield call(
            VoyagesComparisonServices.fetchVoyagesHistoryRoutes,
            omit(payload, ["time"])
          )
          routes.push(historyRoutes)
        }
        allData = { ...allData, [key]: routes }
      }
    }

    yield put(fetchVoyagesHistoryRoutesSuccess({ historyRoutes: allData }))
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        fetchVoyagesHistoryRoutesFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export function* fetchVoyageNumberSaga({ payload }: IVoyageNumberFetch) {
  const { color } = payload
  try {
    const {
      data: { data },
    } = yield call(VoyagesComparisonServices.fetchVoyageNumber, payload)
    yield put(fetchVoyageNumberSuccess({ data, color }))
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        fetchVoyageNumberFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export default function* rootSaga() {
  yield all([takeLatest(VoyagesComparisonAction.FETCH_TARGET_VESSELS, fetchTargetVesselsSaga)])
  yield all([takeLatest(VoyagesComparisonAction.FETCH_DEPARTURE_PORTS, fetchDeparturePortsSaga)])
  yield all([takeLatest(VoyagesComparisonAction.FETCH_ARRIVAL_PORTS, fetchArrivalPortsSaga)])
  yield all([
    takeLatest(VoyagesComparisonAction.FETCH_VOYAGES_COMPARISON, fetchVoyagesComparisonSaga),
  ])
  yield all([
    takeLatest(VoyagesComparisonAction.FETCH_VOYAGES_HISTORY_ROUTES, fetchVoyagesHistoryRoutesSaga),
  ])
  yield all([takeLatest(VoyagesComparisonAction.FETCH_VOYAGE_NUMBER, fetchVoyageNumberSaga)])
}
