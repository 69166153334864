export const WeatherElements = {
  OceanCurrent: "Ocean Current",
  TropicalStorm: "Tropical Storm",
  TropicalStormForecast: "Tropical Storm (Forecast)",
  TropicalStormTrack: "Tropical Storm (Track)",
  WaveHeight: "Wave Height",
  SurfacePressure: "Surface Pressure",
  Wind: "Wind",
}

export const elementConfigs: Record<string, any> = {
  TropicalStormForecast: {
    layers: [
      { name: "TropicalStormTrack", idx: 1, element: "TropicalStormTrack", ref: undefined },
      { name: "TropicalStormForecast", idx: 1, element: "TropicalStormForecast", ref: undefined },
    ],
    timecontrolled: false,
    data: undefined,
  },
  TSStrikeUncertainty: {
    layers: [
      { name: "TSStrikeUncertainty", idx: 1, element: "TSStrikeUncertainty", ref: undefined },
    ],
    timecontrolled: false,
    data: undefined,
  },
  WaveHeight: {
    layers: [
      {
        name: "WaveHeight",
        idx: 10,
        element: "HTSGW",
        ref: undefined,
        extraContents: { filter: "missing 1" },
      },
      {
        name: "WaveHeightContour",
        idx: 9,
        element: "HTSGW",
        ref: undefined,
        extraContents: { filter: "missing 1" },
      },
      // { name: "WaveHeightDirection", idx: 9, element: "WNIPRDIR", ref: undefined, extraContents: { filter: "missing 1" } },
      {
        name: "WaveHeightStream",
        idx: 9,
        element: ["HTSGW", "WNIPRDIR"],
        ref: undefined,
        extraContents: { filter: "missing 1" },
      },
    ],
    timecontrolled: true,
    data: undefined,
    mask: { name: "LandMask", ref: undefined, data: undefined },
  },
  Current: {
    layers: [
      {
        name: "CurrentStream",
        idx: 7,
        element: ["UOGRD", "VOGRD"],
        ref: undefined,
        extraContents: { level: "000" },
        extraAttributes: {
          min: 0.1,
          scale: 0.05,
          times: 200,
          duration: 10,
          separation: 8,
          display: 1,
          lines: 2500,
        },
      },
    ],
    timecontrolled: true,
    data: undefined,
  },
  SurfacePressure: {
    layers: [{ name: "SurfacePressure", idx: 5, element: "PRMSL", ref: undefined }],
    timecontrolled: true,
    data: undefined,
  },
  Front: {
    layers: [{ name: "Front", idx: 5, element: "PRMSL", ref: undefined }],
    timecontrolled: true,
    data: undefined,
  },
  Iceberg: {
    layers: [
      {
        name: "Iceberg",
        idx: 5,
        element: "ICEBRG",
        ref: undefined,
        extraContents: { level: "000" },
      },
    ],
    timecontrolled: true,
    data: undefined,
  },
  Wind: {
    layers: [
      { name: "WindBarb", idx: 5, element: ["UGRD", "VGRD"], extraContents: { level: "000" } },
      // {
      //   name: "WindStream",
      //   idx: 7,
      //   element: ["UGRD", "VGRD"],
      //   ref: undefined,
      //   extraContents: { level: "000" },
      //   extraAttributes: {
      //     min: 5,
      //     scale: 0.005,
      //     times: 200,
      //     duration: 10,
      //     separation: 5,
      //     display: 1,
      //     lines: 1200,
      //   },
      // },
    ],
    timecontrolled: true,
    data: undefined,
  },
}
