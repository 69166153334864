import { unifyValue } from "core/utils/unifyValueChartData"
import { setFillColor } from "core/constants/barCharts"

export default class VoyageSummary {
  name: string

  vessel_name: string

  selected: boolean

  is_average: boolean

  duration: number

  distance: number

  "MGO_Cons.": number

  "avg._speed": number

  "LNG_Cons.": number

  "avg._BOR": number

  ADQ: number

  EDQ: number

  estimated_heel: number

  actual_heel: number

  color: string

  constructor(data) {
    this.name = data.voyage_no
    this.vessel_name = data.vessel_name
    this.selected = data.selected
    this.is_average = data.is_average
    this.duration = unifyValue(data.duration, 0)
    this.distance = unifyValue(data.distance, 0)
    this["LNG_Cons."] = unifyValue(data.lng_consumption, 1)
    this["MGO_Cons."] = unifyValue(data.mgo_consumption, 1)
    this["avg._speed"] = unifyValue(data.average_speed, 1)
    this["avg._BOR"] = unifyValue(data.avg_boil_off_rate, 2)
    this.ADQ = unifyValue(data.adq, 0)
    this.EDQ = unifyValue(data.edq, 0)
    this.estimated_heel = unifyValue(data.estimated_heel, 0)
    this.actual_heel = unifyValue(data.actual_heel, 0)
    this.color = setFillColor(data)
  }
}
