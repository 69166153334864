const DEG_TO_RAD = Math.PI / 180
const MIN_ANGLE = 0
const MAX_ANGLE = 359.9999

export const drawCircleRadius = (coordinates, marker) => {
  const lat = coordinates[1]
  const lng = coordinates[0]
  const R = 6371000
  const d = DEG_TO_RAD
  const latR = marker.radius / R / d
  const top = project(convertPoint([lat + latR, lng]), marker.zoom)
  const bottom = project(convertPoint([lat - latR, lng]), marker.zoom)
  const p = divideBy(plusTopBottom(top, bottom), 2)
  const lat2 = unproject(p, marker.zoom).x
  let lngR =
    Math.acos(
      (Math.cos(latR * d) - Math.sin(lat * d) * Math.sin(lat2 * d)) /
        (Math.cos(lat * d) * Math.cos(lat2 * d))
    ) / d

  if (Number.isNaN(lngR) || lngR === 0) {
    lngR = latR / Math.cos(DEG_TO_RAD * lat)
  }

  const radius = Number.isNaN(lngR)
    ? 0
    : Math.max(Math.round(p.x - project(convertPoint([lat2, lng - lngR]), marker.zoom).x), 1)

  return { radius }
}

const convertPoint = (point) => {
  return Array.isArray(point) ? { x: point[0], y: point[1] } : point
}

const projectedPoint = (point) => {
  const R = 6378137
  const MAX_LATITUDE = 85.0511287798
  const d = DEG_TO_RAD
  const max = MAX_LATITUDE
  const lat = Math.max(Math.min(max, point.x), -max)
  const sin = Math.sin(lat * d)
  return {
    x: R * point.y * d,
    y: (R * Math.log((1 + sin) / (1 - sin))) / 2,
  }
}

const unProjectedPoint = (point) => {
  const R = 6378137
  const d = 180 / Math.PI
  return {
    x: (2 * Math.atan(Math.exp(point.y / R)) - Math.PI / 2) * d,
    y: (point.x * d) / R,
  }
}

const scaleFnc = (zoom: number) => {
  const pow = 2 ** zoom
  return 256 * pow
}

const crsTransformation = () => {
  const R = 6378137
  const scale = 0.5 / (Math.PI * R)
  return { _a: scale, _b: 0.5, _c: -scale, _d: 0.5 }
  // return new L.Transformation(scale, 0.5, -scale, 0.5);
}

const transformPoint = (point, scale = 1) => {
  const { _a, _b, _c, _d } = crsTransformation()
  return {
    x: scale * (_a * point.x + _b),
    y: scale * (_c * point.y + _d),
  }
}

const latLngToPoint = (latlng, zoom = 2) => {
  const point = projectedPoint(latlng)
  const scale = scaleFnc(zoom)

  return transformPoint(point, scale)
}

const untransformPoint = (point, scale = 1) => {
  const { _a, _b, _c, _d } = crsTransformation()
  return convertPoint([(point.x / scale - _b) / _a, (point.y / scale - _d) / _c])
}

const pointToLatLng = (point, zoom) => {
  const scale = scaleFnc(zoom)
  const untransformedPoint = untransformPoint(point, scale)

  return unProjectedPoint(untransformedPoint)
}

const project = (latlng, zoom = 3) => {
  return latLngToPoint(latlng, zoom)
}

const unproject = (point, zoom = 3) => {
  // zoom = zoom === undefined ? this._zoom : zoom;
  return pointToLatLng(convertPoint(point), zoom)
}

const plusTopBottom = (top, bottom) => {
  return {
    x: top.x + bottom.x,
    y: top.y + bottom.y,
  }
}

export const multiplyBy = (point, r) => {
  return { x: point.x * r, y: point.y * r }
}

export const divideBy = (point: { x: number; y: number }, r) => {
  return { x: point.x / r, y: point.y / r }
}

export const rotatedPoint = (angle, r) => {
  return multiplyBy(convertPoint([Math.cos(angle), Math.sin(angle)]), r)
}

export const startAngle = (marker) => {
  return fixAngle(marker.startAngle < marker.stopAngle ? marker.startAngle : marker.stopAngle)
}
export const stopAngle = (marker) => {
  if (marker.startAngle < marker.stopAngle) {
    return fixAngle(marker.stopAngle)
  }
  return fixAngle(marker.startAngle < marker.stopAngle ? marker.stopAngle : marker.startAngle)
}
export const angleDegrees = (marker) => {
  return marker.angleDegrees < MIN_ANGLE && marker.angleDegrees > MAX_ANGLE
    ? MIN_ANGLE
    : marker.angleDegrees
}

export const fixAngle = (angle) => {
  return (angle - 90) * DEG_TO_RAD
}
