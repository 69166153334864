const MAP_ZOOM = 2

export const makeWindArea = (value, radii_type, zoom = MAP_ZOOM) => {
  const windAreaColor = radii_type === "gale_force" ? "#fff1ad" : "#da5858"
  const mile = value.length > 0 ? Number(value.split(":")[0]) : 0
  const radius = nauticalMileToMeter(mile)
  const direction = parseFloat(value.split(":")[1]) + 45
  const marker = {
    shapeType: "semicircle",
    angleDegrees: direction,
    fill: true,
    fillOpacity: 0.5,
    fillColor: windAreaColor,
    opacity: 0.00001,
    color: windAreaColor,
    radius,
    circleAngle: 90,
    originRadius: mile,
    zoom,
  }
  return marker
}

const nauticalMileToMeter = (nm: number) => {
  return nm / 0.000539957
}

export const makeDirectionMarker = (item, zoom = MAP_ZOOM) => {
  const height = getHeight(item.height, item.name)
  const markerColor = getColor(item.height, item.name)
  const borderMarkerColor = markerColor
  const shapeType = item.shapeType !== "semicircle" ? getShapeType(zoom, item.name) : item.shapeType
  const [markerSize, borderWidth] = getSizeAndBorder(zoom, shapeType, height, item.name)
  const shapeSpeed = item.height

  return {
    shapeType,
    angleDegrees: item.direction,
    shapeSize: markerSize,
    shapeColor: markerColor,
    borderColor: borderMarkerColor,
    borderWith: borderWidth,
    clickable: false,
    drawToBack: true,
    shapeSpeed,
    zoom,
  }
}

const getColorOthers = (height) => {
  if (height >= 9) return "#f3002e"
  if (height >= 8) return "#f37700"
  if (height >= 7) return "#f3da00"
  if (height >= 6) return "#43f300"
  if (height >= 5) return "#0052f3"
  if (height >= 4) return "#2b6df3"
  if (height >= 3) return "#4873f3"
  if (height >= 2) return "#547cf3"
  if (height >= 1) return "#678ff3"
  return "#b0caf3"
}

const getColor = (height, name) => {
  let color = ""
  switch (name) {
    case "o_c":
      color = getOceanCurrentColor(height)
      break
    case "w_s":
      color = getWindSeaColor(height)
      break
    case "w_b":
      color = "#000000"
      break
    default:
      color = getColorOthers(height)
      break
  }
  return color
}

const getWindSeaColor = (height) => {
  if (height >= 10) return "#f3002e"
  if (height >= 9) return "#f37700"
  if (height >= 8) return "#f3da00"
  if (height >= 7) return "#8c00b0"
  if (height >= 6) return "#9a16b0"
  if (height >= 5) return "#9c2db0"
  if (height >= 4) return "#9c41b0"
  if (height >= 3) return "#9e55b0"
  if (height >= 2) return "#9e6db0"
  if (height >= 1) return "#a084b0"
  return "#a88eb0"
}

const getOceanCurrentColor = (height) => {
  const oceanHeight = parseFloat(height)
  if (oceanHeight >= 3.0) return "#f3002e"
  if (oceanHeight >= 2.5) return "#f34a81"
  if (oceanHeight >= 2.0) return "#f37700"
  if (oceanHeight >= 1.5) return "#d6c100"
  if (oceanHeight >= 1.0) return "#50b300"
  if (oceanHeight >= 0.5) return "#0052f3"
  return "#5fb4ec"
}
const getHeight = (height, name) => {
  if (name === "o_c") return height

  if (height >= 9) return 10
  if (height >= 8) return 9
  if (height >= 7) return 8
  if (height >= 6) return 7
  if (height >= 5) return 6
  if (height >= 4) return 5
  if (height >= 3) return 4
  if (height >= 2) return 3
  if (height >= 1) return 2
  return 0
}
// different map zoom is different size
// different height is different size
const getSizeAndBorder = (map_zoom, icon_class, height, name) => {
  let size = null
  let data = []
  switch (map_zoom) {
    case 1:
    case 2:
    case 3:
      size = 0
      break
    case 4:
    case 5:
    case 6:
      size = 1
      break
    case 7:
      size = 2
      break
    default:
      size = 3
      break
  }
  if (name === "o_c") {
    let size_temp = 1
    if (height >= 3.0) size_temp = 5
    else if (height >= 2.5) size_temp = 4
    else if (height >= 2.0) size_temp = 3
    else if (height >= 1.5) size_temp = 3
    else if (height >= 0.5 || height >= 1.0) size_temp = 1
    else size_temp = 1
    size += size_temp
  } else {
    if (height === 8) size += 1
    if (height === 9 || height === 10) size += 2
  }
  // this took major time to test for every icon, please careful if any changes:
  if (icon_class === "thin_arrow") {
    switch (size) {
      case 2:
        data = [5, 1]
        break
      case 3:
        data = [10, 1]
        break
      case 4:
        data = [15, 1]
        break
      case 5:
        data = [20, 1]
        break
      case 6:
        data = [25, 1]
        break
      case 7:
        data = [30, 1]
        break
      case 8:
        data = [35, 1]
        break
      case 9:
        data = [40, 1]
        break
      case 10:
        data = [45, 1]
        break
      default:
        data = [30, 1]
        break
    }
  } else if (icon_class === "line") data = [5, 1]
  else if (icon_class === "fat_arrow") {
    switch (size) {
      case 0:
        data = [7, 1]
        break
      case 1:
        data = [10, 1]
        break
      case 2:
        data = [12, 1]
        break
      case 3:
        data = [17, 1]
        break
      case 4:
        data = [20, 1]
        break
      case 5:
        data = [25, 1]
        break
      default:
        data = [30, 1]
        break
    }
  } else if (name === "w_b") {
    if (map_zoom <= 3) data = [12, 0.5]
    else if (map_zoom <= 5) data = [18, 1]
    else if (map_zoom <= 6) data = [28, 1]
    else if (map_zoom <= 7) data = [45, 1]
    else data = [45, 1]
  }
  return data
}

const getShapeType = (map_zoom, name) => {
  let icon_class = name === "o_c" ? "thin_arrow" : "fat_arrow"
  icon_class = name === "w_b" ? "wind_barb" : icon_class
  if (name === "o_c" && [1, 2, 3, 4, 5].indexOf(map_zoom) > -1) icon_class = "line"

  return icon_class
}
