export interface IDistanceRouteItem {
  id: number | string
  port_1_name: string
  port_2_name: string
  pacific_route: string
  distance: number
  detail: string
  updated_at: string
  updated_by: string
}

export interface IDistanceRouteFilter {
  port_id: number
  route_id: number
  sort_by: string
  sort_order: string
  page: number
}

export interface IDataSettingsDistanceRouteState {
  ports: { loading: boolean; error: string; data: [] }
  routes: { loading: boolean; error: string; data: [] }
  filter_routes: { loading: boolean; error: string; data: [] }
  list: {
    distance_routes: {
      loading: boolean
      error: string
      data: IDistanceRouteItem[]
      total_records: number
      current_page: number
      per_page: number
      total_pages: number
      isDeleteItem: boolean
      isEditItem: boolean
    }
  }
  item: {
    loading: boolean
    error: string
    data: IDistanceRouteItem
  }
  filters: {
    distance_routes: {
      payload: IDistanceRouteFilter
    }
  }
  export_error_log: {
    distanceRoute: {
      loading: boolean
      error: string | null
      url: string
    }
  }
  import_file: {
    distanceRoute: {
      loading: boolean
      error: string | null
      data: string
    }
  }
  export_file: {
    loading: boolean
    error: string | null
  }
}

export enum DataSettingsDistanceRouteActions {
  FETCH_PORTS = "[data settings] fetch ports",
  FETCH_PORTS_SUCCESS = "[data settings] fetch ports success",
  FETCH_PORTS_FAILED = "[data settings] fetch ports failed",

  FETCH_ROUTES = "[data settings] fetch routes",
  FETCH_ROUTES_SUCCESS = "[data settings] fetch routes success",
  FETCH_ROUTES_FAILED = "[data settings] fetch routes failed",

  // Distance route
  CREATE_DISTANCE_ROUTE = "[data settings distance route] create distance route",
  CREATE_DISTANCE_ROUTE_SUCCESS = "[data settings distance route] create distance route success",
  CREATE_DISTANCE_ROUTE_FAILED = "[data settings distance route] create distance route failed",

  UPDATE_DISTANCE_ROUTE = "[data settings distance route] update distance route",
  UPDATE_DISTANCE_ROUTE_SUCCESS = "[data settings distance route] update distance route success",
  UPDATE_DISTANCE_ROUTE_FAILED = "[data settings distance route] update distance route failed",

  DELETE_DISTANCE_ROUTE = "[data settings distance route] delete distance route",
  DELETE_DISTANCE_ROUTE_SUCCESS = "[data settings distance route] delete distance route success",
  DELETE_DISTANCE_ROUTE_FAILED = "[data settings distance route] delete distance route failed",

  FETCH_LIST_DISTANCE_ROUTE_ALL = "[data settings distance route] fetch all",
  FETCH_LIST_DISTANCE_ROUTE_ALL_SUCCESS = "[data settings distance route] fetch all success",
  FETCH_LIST_DISTANCE_ROUTE_ALL_FINISHED = "[data settings distance route] fetch all finished",
  FETCH_LIST_DISTANCE_ROUTE_ALL_FAILED = "[data settings distance route] fetch all failed",

  FETCH_FILTER_ROUTES = "[data settings distance route] fetch filter routes",
  FETCH_FILTER_ROUTES_SUCCESS = "[data settings distance route] fetch filter routes success",
  FETCH_FILTER_ROUTES_FAILED = "[data settings distance route] fetch filter routes failed",

  IMPORT_DISTANCE_ROUTE = "[importDistanceRoute] import",
  IMPORT_DISTANCE_ROUTE_SUCCESS = "[importDistanceRoute] import success",
  IMPORT_DISTANCE_ROUTE_FAILED = "[importDistanceRoute] import failed",

  EXPORT_DISTANCE_ROUTE = "[exportDistanceRoute] export",
  EXPORT_DISTANCE_ROUTE_SUCCESS = "[exportDistanceRoute] export success",
  EXPORT_DISTANCE_ROUTE_FAILED = "[exportDistanceRoute] export failed",

  EXPORT_DISTANCE_ROUTE_ERROR = "[exportDistanceRouteError] export",
  EXPORT_DISTANCE_ROUTE_ERROR_FAILED = "[exportDistanceRouteError] export failed",
  EXPORT_DISTANCE_ROUTE_ERROR_SUCCESS = "[exportDistanceRouteError] export success",
}

export interface IPortsFetch {
  type: DataSettingsDistanceRouteActions.FETCH_PORTS
}

export interface IPortsFetchSuccess {
  type: DataSettingsDistanceRouteActions.FETCH_PORTS_SUCCESS
  payload: {
    data: []
  }
}

export interface IPortsFetchFailed {
  type: DataSettingsDistanceRouteActions.FETCH_PORTS_FAILED
  payload: {
    error: string
  }
}

export interface IRoutesFetch {
  type: DataSettingsDistanceRouteActions.FETCH_ROUTES
}

export interface IRoutesFetchSuccess {
  type: DataSettingsDistanceRouteActions.FETCH_ROUTES_SUCCESS
  payload: {
    data: []
  }
}

export interface IRoutesFetchFailed {
  type: DataSettingsDistanceRouteActions.FETCH_ROUTES_FAILED
  payload: {
    error: string
  }
}

export interface IFilterRoutesFetch {
  type: DataSettingsDistanceRouteActions.FETCH_FILTER_ROUTES
}

export interface IFilterRoutesFetchSuccess {
  type: DataSettingsDistanceRouteActions.FETCH_FILTER_ROUTES_SUCCESS
  payload: {
    data: []
  }
}

export interface IFilterRoutesFetchFailed {
  type: DataSettingsDistanceRouteActions.FETCH_FILTER_ROUTES_FAILED
  payload: { error: string }
}

// Distance route
export interface ICreateDistanceRoute {
  type: DataSettingsDistanceRouteActions.CREATE_DISTANCE_ROUTE
  payload: {
    data: any
  }
}

export interface ICreateDistanceRouteSuccess {
  type: DataSettingsDistanceRouteActions.CREATE_DISTANCE_ROUTE_SUCCESS
}

export interface ICreateDistanceRouteFailed {
  type: DataSettingsDistanceRouteActions.CREATE_DISTANCE_ROUTE_FAILED
  payload: { error: string }
}

export interface IUpdateDistanceRoute {
  type: DataSettingsDistanceRouteActions.UPDATE_DISTANCE_ROUTE
  payload: {
    id: string
    data: any
  }
}

export interface IUpdateDistanceRouteSuccess {
  type: DataSettingsDistanceRouteActions.UPDATE_DISTANCE_ROUTE_SUCCESS
}

export interface IUpdateDistanceRouteFailed {
  type: DataSettingsDistanceRouteActions.UPDATE_DISTANCE_ROUTE_FAILED
  payload: { error: string }
}

export interface IDeleteDistanceRoute {
  type: DataSettingsDistanceRouteActions.DELETE_DISTANCE_ROUTE
  payload: {
    id: string
  }
}

export interface IDeleteDistanceRouteSuccess {
  type: DataSettingsDistanceRouteActions.DELETE_DISTANCE_ROUTE_SUCCESS
}

export interface IDeleteDistanceRouteFailed {
  type: DataSettingsDistanceRouteActions.DELETE_DISTANCE_ROUTE_FAILED
  payload: { error: string }
}

export interface IListDistanceRouteFetchAll {
  type: DataSettingsDistanceRouteActions.FETCH_LIST_DISTANCE_ROUTE_ALL
  payload: IDistanceRouteFilter
}

export interface IListDistanceRouteFetchAllSuccess {
  type: DataSettingsDistanceRouteActions.FETCH_LIST_DISTANCE_ROUTE_ALL_SUCCESS
  payload: {
    data: []
    headers: any
  }
}

export interface IListDistanceRouteFetchAllFinished {
  type: DataSettingsDistanceRouteActions.FETCH_LIST_DISTANCE_ROUTE_ALL_FINISHED
  payload: {
    allData: []
    headers: any
  }
}

export interface IListDistanceRouteFetchAllFailed {
  type: DataSettingsDistanceRouteActions.FETCH_LIST_DISTANCE_ROUTE_ALL_FAILED
  payload: { error: string }
}

export interface IImportDistanceRouteFileSuccessAction {
  type: DataSettingsDistanceRouteActions.IMPORT_DISTANCE_ROUTE_SUCCESS
}

export interface IImportDistanceRouteFileFailedAction {
  type: DataSettingsDistanceRouteActions.IMPORT_DISTANCE_ROUTE_FAILED
  payload: { error: string }
}

export interface IImportDistanceRouteFileAction {
  type: DataSettingsDistanceRouteActions.IMPORT_DISTANCE_ROUTE
  payload: {
    data: []
  }
}

export interface IExportDistanceRouteSuccessAction {
  type: DataSettingsDistanceRouteActions.EXPORT_DISTANCE_ROUTE_SUCCESS
}

export interface IExportDistanceRouteFailedAction {
  type: DataSettingsDistanceRouteActions.EXPORT_DISTANCE_ROUTE_FAILED
  payload: { error: string }
}

export interface IExportDistanceRouteAction {
  type: DataSettingsDistanceRouteActions.EXPORT_DISTANCE_ROUTE
  payload: {
    imo: number
  }
}

export interface IExportDistanceRouteErrorFailedAction {
  type: DataSettingsDistanceRouteActions.EXPORT_DISTANCE_ROUTE_ERROR_FAILED
  payload: { error: string }
}

export interface IExportDistanceRouteErrorAction {
  type: DataSettingsDistanceRouteActions.EXPORT_DISTANCE_ROUTE_ERROR
}

export interface IExportDistanceRouteErrorSuccessAction {
  type: DataSettingsDistanceRouteActions.EXPORT_DISTANCE_ROUTE_ERROR_SUCCESS
  payload
}

export type DataSettingsDistanceRouteTypes =
  | IPortsFetch
  | IPortsFetchSuccess
  | IPortsFetchFailed
  | IRoutesFetch
  | IRoutesFetchSuccess
  | IRoutesFetchFailed
  | IFilterRoutesFetch
  | IFilterRoutesFetchSuccess
  | IFilterRoutesFetchFailed
  // Distance Route
  | ICreateDistanceRoute
  | ICreateDistanceRouteSuccess
  | ICreateDistanceRouteFailed
  | IUpdateDistanceRoute
  | IUpdateDistanceRouteSuccess
  | IUpdateDistanceRouteFailed
  | IDeleteDistanceRoute
  | IDeleteDistanceRouteSuccess
  | IDeleteDistanceRouteFailed
  | IListDistanceRouteFetchAll
  | IListDistanceRouteFetchAllSuccess
  | IListDistanceRouteFetchAllFinished
  | IListDistanceRouteFetchAllFailed
  | IImportDistanceRouteFileSuccessAction
  | IImportDistanceRouteFileAction
  | IImportDistanceRouteFileFailedAction
  | IExportDistanceRouteFailedAction
  | IExportDistanceRouteSuccessAction
  | IExportDistanceRouteAction
  | IExportDistanceRouteErrorAction
  | IExportDistanceRouteErrorFailedAction
  | IExportDistanceRouteErrorSuccessAction
