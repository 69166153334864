/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useMemo } from "react"
import { Select } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { AuthSelector } from "services/auth/selectors"
import Axios from "services/_base/api"
import { setAuthCompanyId } from "services/auth/actions"
import { useLocation } from "react-router-dom"
import {
  COMPANIES_MANAGEMENT,
  USERS_MANAGEMENT,
  USER_MANUAL,
  VESSEL_MANAGEMENT,
  VOYAGES_MAP,
  VOYAGES_SUMMARY,
} from "core/constants/routes"

const Index = () => {
  const dispatch = useDispatch()
  const {
    current_user: { isSuperAdmin },
    companies: { data, company_id },
  } = useSelector(AuthSelector)
  const { setCompanyId } = Axios
  const { pathname, search } = useLocation()

  useEffect(() => {
    if (data && data.length > 0) {
      const record = Object.entries(data[0])[0]
      const company_id = record[0]
      const queryCompanyId = search.substr(12)
      setCompanyId(queryCompanyId === "" ? company_id : queryCompanyId)
      dispatch(
        setAuthCompanyId({ company_id: queryCompanyId === "" ? company_id : queryCompanyId })
      )
    }
  }, [data])

  const onFilterOption = (input, option: any) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  const onFilterSort = (optionA: any, optionB: any) => {
    return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
  }

  const renderOpt = (opt, keyName) => {
    const record = Object.entries(opt)[0]
    const id = record[0]
    const name = record[1]

    return (
      <Select.Option key={`vessel-${keyName}-${name}`} value={id}>
        {name}
      </Select.Option>
    )
  }

  const onSelectCompany = (company_id) => {
    const itemDetail = pathname.split("/")[2] ?? null
    if (pathname.includes(VOYAGES_MAP))
      window.location.replace(`${VOYAGES_SUMMARY}?company_id=${company_id}`)
    else if (itemDetail)
      window.location.replace(`/${pathname.split("/")[1] ?? pathname}?company_id=${company_id}`)
    else window.location.replace(`${pathname}?company_id=${company_id}`)
  }

  const visible = useMemo(() => {
    return (
      !pathname.includes(COMPANIES_MANAGEMENT) &&
      !pathname.includes(VESSEL_MANAGEMENT) &&
      !pathname.includes(USERS_MANAGEMENT) &&
      !pathname.includes(USER_MANUAL)
    )
  }, [pathname])

  return (
    <>
      {isSuperAdmin && company_id && (
        <div className="mx-2 px-1 py-2">
          <Select
            style={{ width: "100%", visibility: visible ? "visible" : "hidden" }}
            filterOption={(input, option) => onFilterOption(input, option)}
            filterSort={(optionA: any, optionB: any) => onFilterSort(optionA, optionB)}
            onChange={(company_id) => onSelectCompany(company_id)}
            defaultValue={company_id}
          >
            {data && data.map((el: any) => renderOpt(el, "auth"))}
          </Select>
        </div>
      )}
    </>
  )
}

export default memo(Index)
