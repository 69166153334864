export enum ActionFunctionName {
  SET_STATE = "setState",
  RESET_STATE = "resetState",
}

export interface IActions {
  SET_STATE: string
  RESET_STATE: string
}

export const ACTIONS: IActions = {
  SET_STATE: "set state",
  RESET_STATE: "reset state",
}

export type ReturnCommonFunction = Record<
  ActionFunctionName,
  <Payload>(payload?: Payload) => { type: string; payload: Payload | undefined }
>

export type GenActionReturn<T extends keyof IActions> = {
  funcs: ReturnCommonFunction
  types: { [key in T]: string }
}
