interface SvgProps {
  name?: string
  fill?: string
  width?: string
  height?: string
}

const Index: React.FC<SvgProps> = () => {
  return (
    <span role="img" aria-label="team" className="anticon anticon-team ant-menu-item-icon">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1_8)">
          <path
            d="M-0.0411932 3.27273H1.55682L4.33523 10.0568H4.4375L7.21591 3.27273H8.81392V12H7.56108V5.68466H7.48011L4.90625 11.9872H3.86648L1.29261 5.6804H1.21165V12H-0.0411932V3.27273Z"
            fill="currentColor"
          />
          <path
            d="M11.6136 12.1321C10.9801 12.1321 10.4347 11.9886 9.97727 11.7017C9.52273 11.4119 9.1733 11.0128 8.92898 10.5043C8.68466 9.99574 8.5625 9.41335 8.5625 8.7571C8.5625 8.09233 8.6875 7.50568 8.9375 6.99716C9.1875 6.4858 9.53977 6.08665 9.99432 5.79972C10.4489 5.51278 10.9844 5.36932 11.6009 5.36932C12.098 5.36932 12.5412 5.46165 12.9304 5.64631C13.3196 5.82812 13.6335 6.08381 13.8722 6.41335C14.1136 6.7429 14.2571 7.12784 14.3026 7.56818H13.0625C12.9943 7.26136 12.8381 6.99716 12.5938 6.77557C12.3523 6.55398 12.0284 6.44318 11.6222 6.44318C11.267 6.44318 10.956 6.53693 10.6889 6.72443C10.4247 6.90909 10.2188 7.1733 10.071 7.51705C9.9233 7.85795 9.84943 8.26136 9.84943 8.72727C9.84943 9.20455 9.92188 9.61648 10.0668 9.96307C10.2116 10.3097 10.4162 10.5781 10.6804 10.7685C10.9474 10.9588 11.2614 11.054 11.6222 11.054C11.8636 11.054 12.0824 11.0099 12.2784 10.9219C12.4773 10.831 12.6435 10.7017 12.777 10.5341C12.9134 10.3665 13.0085 10.1648 13.0625 9.92898H14.3026C14.2571 10.3523 14.1193 10.7301 13.8892 11.0625C13.6591 11.3949 13.3509 11.6562 12.9645 11.8466C12.581 12.0369 12.1307 12.1321 11.6136 12.1321Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_8">
            <rect width="14" height="14" fill="currentColor" />
          </clipPath>
        </defs>
      </svg>
    </span>
  )
}

export default Index
