import { createBrowserHistory } from "history"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import App from "app"
import configureStore from "services/store"

// Import style
import "assets/styles/style.scss"

// Config
import "core/lib/i18n"

import { ActionCableProvider } from "react-actioncable-provider"

// Create redux store
const history = createBrowserHistory()
const store = configureStore({}, history)

ReactDOM.render(
  <Provider store={store}>
    <ActionCableProvider url={process.env.REACT_APP_CABLE_URL}>
      <App history={history} />
    </ActionCableProvider>
  </Provider>,
  document.getElementById("root")
)

export { history }
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
