import { IRoute } from "core/constants/routes"

export const mergeParentChild = (route: IRoute[]) => {
  let mergeRoutes = []
  route.forEach((record) => {
    mergeRoutes = record.children
      ? [...mergeRoutes, ...record.children]
      : [...mergeRoutes, ...[record]]
  })
  return mergeRoutes
}

export const onRouterClick = (e, path, history, isExternal = false, search) => {
  if (isExternal) {
    window.open(path, "_blank")
    return
  }
  if (e.ctrlKey || e.metaKey) {
    window.open(path, "_blank")
  } else history.push(`${path}${search}`)
}
