import genActions from "services/_base/actions"
import {
  HeelEdqActions,
  IDeptPortFetch,
  IDeptPortFetchSuccess,
  IDeptPortFetchFailed,
  ILadenArrivalPortFetch,
  ILadenArrivalPortFetchSuccess,
  ILadenArrivalPortFetchFailed,
  ILadenPacificRouteFetch,
  ILadenPacificRouteFetchSuccess,
  ILadenPacificRouteFetchFailed,
  IBallastArrivalPortFetch,
  IBallastArrivalPortFetchSuccess,
  IBallastArrivalPortFetchFailed,
  IBallastPacificRouteFetch,
  IBallastPacificRouteFetchSuccess,
  IBallastPacificRouteFetchFailed,
  ICalculateVoyageReset,
  IHeelEdqItemFetch,
  IHeelEdqItemFetchSuccess,
  IHeelEdqItemFetchFailed,
  ISetEDQCalculation,
  IResetEDQCalculation,
  ICreateResult,
  ICreateResultSuccess,
  ICreateResultFailed,
  IUpdateResult,
  IUpdateResultSuccess,
  IUpdateResultFailed,
  ICalculationResultFetchAll,
  ICalculationResultFetchAllSuccess,
  ICalculationResultFetchAllFinished,
  ICalculationResultFetchAllFailed,
  ISetValidationField,
  ISetHeelEdqImo,
  ISetHeelEdqName,
  ISetHeelEdqFOE,
  ISetHeelEdqLadenNo,
  ISetHeelEdqBallastNo,
  IResetHeelEdqGeneral,
  IHeelEdqItemReset,
  IDeleteResult,
  IDeleteResultSuccess,
  IDeleteResultFailed,
  IFilterPacificRouteFetch,
  IFilterPacificRouteFetchSuccess,
  IFilterPacificRouteFetchFailed,
  ITargetVesselsFetch,
  ITargetVesselsFetchSuccess,
  ITargetVesselsFetchFailed,
  ITargetVesselsFetchFinished,
  ISetFilters,
  ICalculateVoyageResultReset,
  ILadenCalculateVoyage,
  ILadenCalculateVoyageSuccess,
  ILadenCalculateVoyageFailed,
  IBallastCalculateVoyage,
  IBallastCalculateVoyageSuccess,
  IBallastCalculateVoyageFailed,
  ILadenTimeZoneLabelFetch,
  ILadenTimeZoneLabelFetchSuccess,
  ILadenTimeZoneLabelFetchFailed,
  IBallastTimeZoneLabelFetch,
  IBallastTimeZoneLabelFetchSuccess,
  IBallastTimeZoneLabelFetchFailed,
  IFinalizeResult,
  IFinalizeResultSuccess,
  IFinalizeResultFailed,
  ILadenLeg2TimeZoneLabelFetchFailed,
  ILadenLeg2TimeZoneLabelFetchSuccess,
  ILadenLeg2TimeZoneLabelFetch,
  ILadenLeg2PacificRouteFetchFailed,
  ILadenLeg2PacificRouteFetchSuccess,
  ILadenLeg2PacificRouteFetch,
  ILadenLeg2ArrivalPortFetchFailed,
  ILadenLeg2ArrivalPortFetchSuccess,
  ILadenLeg2ArrivalPortFetch,
  IBallastLeg2ArrivalPortFetch,
  IBallastLeg2ArrivalPortFetchSuccess,
  IBallastLeg2ArrivalPortFetchFailed,
  IBallastLeg2PacificRouteFetch,
  IBallastLeg2PacificRouteFetchSuccess,
  IBallastLeg2PacificRouteFetchFailed,
  IBallastLeg2CalculateVoyage,
  IBallastLeg2CalculateVoyageSuccess,
  IBallastLeg2CalculateVoyageFailed,
  IBallastLeg2TimeZoneLabelFetch,
  IBallastLeg2TimeZoneLabelFetchSuccess,
  IBallastLeg2TimeZoneLabelFetchFailed,
  ISetCurrentVoyage,
  ILadenLeg2CalculateVoyageSuccess,
  ILadenLeg2CalculateVoyage,
  ILadenLeg2CalculateVoyageFailed,
  ILngConsumptionPanamaFetch,
  ILngConsumptionPanamaFetchSuccess,
  ILngConsumptionPanamaFetchFailed,
  ISetLadenBallastPanamaTransit,
  ISetLadenBallastFuelPanamaTransit,
  ISetLadenBallastSummaryPanamaTransit,
  ISetEstimatedHeel,
} from "./types"

export const { funcs: BaseFunctions, types: BaseActions } = genActions("heel_edq")

export function fetchTargetVessels(): ITargetVesselsFetch {
  return {
    type: HeelEdqActions.FETCH_TARGET_VESSELS,
  }
}

export function fetchTargetVesselsSuccess(payload: any): ITargetVesselsFetchSuccess {
  return {
    type: HeelEdqActions.FETCH_TARGET_VESSELS_SUCCESS,
    payload,
  }
}

export function fetchTargetVesselsFinished(payload: any): ITargetVesselsFetchFinished {
  return {
    type: HeelEdqActions.FETCH_TARGET_VESSELS_FINISHED,
    payload,
  }
}

export function fetchTargetVesselsFailed(payload: any): ITargetVesselsFetchFailed {
  return {
    type: HeelEdqActions.FETCH_TARGET_VESSELS_FAILED,
    payload,
  }
}

export function setHeelEdqImo(payload: any): ISetHeelEdqImo {
  return {
    type: HeelEdqActions.SET_HEEL_EDQ_IMO,
    payload,
  }
}

export function setHeelEdqName(payload: any): ISetHeelEdqName {
  return {
    type: HeelEdqActions.SET_HEEL_EDQ_NAME,
    payload,
  }
}
export function setHeelEdqFOE(payload: any): ISetHeelEdqFOE {
  return {
    type: HeelEdqActions.SET_HEEL_EDQ_FOE,
    payload,
  }
}
export function setHeelEdqLadenNo(payload: any): ISetHeelEdqLadenNo {
  return {
    type: HeelEdqActions.SET_HEEL_EDQ_LADEN_NO,
    payload,
  }
}

export function resetHeelEdqGeneral(): IResetHeelEdqGeneral {
  return {
    type: HeelEdqActions.RESET_HEEL_EDQ_GENERAL,
  }
}

export function setHeelEdqBallastNo(payload: any): ISetHeelEdqBallastNo {
  return {
    type: HeelEdqActions.SET_HEEL_EDQ_BALLAST_NO,
    payload,
  }
}

export function setEDQCalculation(payload: any): ISetEDQCalculation {
  return {
    type: HeelEdqActions.SET_EDQ_CALCULATION,
    payload,
  }
}

export function resetEDQCalculation(payload: any): IResetEDQCalculation {
  return {
    type: HeelEdqActions.RESET_EDQ_CALCULATION,
    payload,
  }
}

export function fetchDeptPort(): IDeptPortFetch {
  return {
    type: HeelEdqActions.FETCH_DEPT_PORT,
  }
}

export function fetchDeptPortSuccess(payload: any): IDeptPortFetchSuccess {
  return {
    type: HeelEdqActions.FETCH_DEPT_PORT_SUCCESS,
    payload,
  }
}

export function fetchDeptPortFailed(payload: any): IDeptPortFetchFailed {
  return {
    type: HeelEdqActions.FETCH_DEPT_PORT_FAILED,
    payload,
  }
}

// Laden Leg 1

export function fetchLadenArrivalPort(payload: any): ILadenArrivalPortFetch {
  return {
    type: HeelEdqActions.FETCH_LADEN_ARRIVAL_PORT,
    payload,
  }
}

export function fetchLadenArrivalPortSuccess(payload: any): ILadenArrivalPortFetchSuccess {
  return {
    type: HeelEdqActions.FETCH_LADEN_ARRIVAL_PORT_SUCCESS,
    payload,
  }
}

export function fetchLadenArrivalPortFailed(payload: any): ILadenArrivalPortFetchFailed {
  return {
    type: HeelEdqActions.FETCH_LADEN_ARRIVAL_PORT_FAILED,
    payload,
  }
}

export function fetchLadenPacificRoute(payload: any): ILadenPacificRouteFetch {
  return {
    type: HeelEdqActions.FETCH_LADEN_PACIFIC_ROUTE,
    payload,
  }
}

export function fetchLadenPacificRouteSuccess(payload: any): ILadenPacificRouteFetchSuccess {
  return {
    type: HeelEdqActions.FETCH_LADEN_PACIFIC_ROUTE_SUCCESS,
    payload,
  }
}

export function fetchLadenPacificRouteFailed(payload: any): ILadenPacificRouteFetchFailed {
  return {
    type: HeelEdqActions.FETCH_LADEN_PACIFIC_ROUTE_FAILED,
    payload,
  }
}

export function fetchLadenTimeZoneLabel(payload: any): ILadenTimeZoneLabelFetch {
  return {
    type: HeelEdqActions.FETCH_LADEN_TIMEZONE_LABEL,
    payload,
  }
}

export function fetchLadenTimeZoneLabelSuccess(payload: any): ILadenTimeZoneLabelFetchSuccess {
  return {
    type: HeelEdqActions.FETCH_LADEN_TIMEZONE_LABEL_SUCCESS,
    payload,
  }
}

export function fetchLadenTimeZoneLabelFailed(payload: any): ILadenTimeZoneLabelFetchFailed {
  return {
    type: HeelEdqActions.FETCH_LADEN_TIMEZONE_LABEL_FAILED,
    payload,
  }
}

export function ladenCalculateVoyage(payload: any): ILadenCalculateVoyage {
  return {
    type: HeelEdqActions.CALCULATE_VOYAGE_LADEN,
    payload,
  }
}

export function ladenCalculateVoyageSuccess(payload: any): ILadenCalculateVoyageSuccess {
  return {
    type: HeelEdqActions.CALCULATE_VOYAGE_LADEN_SUCCESS,
    payload,
  }
}

export function ladenCalculateVoyageFailed(payload: any): ILadenCalculateVoyageFailed {
  return {
    type: HeelEdqActions.CALCULATE_VOYAGE_LADEN_FAILED,
    payload,
  }
}

// Laden Leg 2
export function fetchLadenLeg2ArrivalPort(payload: any): ILadenLeg2ArrivalPortFetch {
  return {
    type: HeelEdqActions.FETCH_LADEN_LEG2_ARRIVAL_PORT,
    payload,
  }
}

export function fetchLadenLeg2ArrivalPortSuccess(payload: any): ILadenLeg2ArrivalPortFetchSuccess {
  return {
    type: HeelEdqActions.FETCH_LADEN_LEG2_ARRIVAL_PORT_SUCCESS,
    payload,
  }
}

export function fetchLadenLeg2ArrivalPortFailed(payload: any): ILadenLeg2ArrivalPortFetchFailed {
  return {
    type: HeelEdqActions.FETCH_LADEN_LEG2_ARRIVAL_PORT_FAILED,
    payload,
  }
}

export function fetchLadenLeg2PacificRoute(payload: any): ILadenLeg2PacificRouteFetch {
  return {
    type: HeelEdqActions.FETCH_LADEN_LEG2_PACIFIC_ROUTE,
    payload,
  }
}

export function fetchLadenLeg2PacificRouteSuccess(
  payload: any
): ILadenLeg2PacificRouteFetchSuccess {
  return {
    type: HeelEdqActions.FETCH_LADEN_LEG2_PACIFIC_ROUTE_SUCCESS,
    payload,
  }
}

export function fetchLadenLeg2PacificRouteFailed(payload: any): ILadenLeg2PacificRouteFetchFailed {
  return {
    type: HeelEdqActions.FETCH_LADEN_LEG2_PACIFIC_ROUTE_FAILED,
    payload,
  }
}

export function fetchLadenLeg2TimeZoneLabel(payload: any): ILadenLeg2TimeZoneLabelFetch {
  return {
    type: HeelEdqActions.FETCH_LADEN_LEG2_TIMEZONE_LABEL,
    payload,
  }
}

export function fetchLadenLeg2TimeZoneLabelSuccess(
  payload: any
): ILadenLeg2TimeZoneLabelFetchSuccess {
  return {
    type: HeelEdqActions.FETCH_LADEN_LEG2_TIMEZONE_LABEL_SUCCESS,
    payload,
  }
}

export function fetchLadenLeg2TimeZoneLabelFailed(
  payload: any
): ILadenLeg2TimeZoneLabelFetchFailed {
  return {
    type: HeelEdqActions.FETCH_LADEN_LEG2_TIMEZONE_LABEL_FAILED,
    payload,
  }
}

export function ladenLeg2CalculateVoyage(payload: any): ILadenLeg2CalculateVoyage {
  return {
    type: HeelEdqActions.CALCULATE_VOYAGE_LADEN_LEG2,
    payload,
  }
}

export function ladenLeg2CalculateVoyageSuccess(payload: any): ILadenLeg2CalculateVoyageSuccess {
  return {
    type: HeelEdqActions.CALCULATE_VOYAGE_LADEN_LEG2_SUCCESS,
    payload,
  }
}

export function ladenLeg2CalculateVoyageFailed(payload: any): ILadenLeg2CalculateVoyageFailed {
  return {
    type: HeelEdqActions.CALCULATE_VOYAGE_LADEN_LEG2_FAILED,
    payload,
  }
}
// Ballast Leg 1
export function fetchBallastArrivalPort(payload: any): IBallastArrivalPortFetch {
  return {
    type: HeelEdqActions.FETCH_BALLAST_ARRIVAL_PORT,
    payload,
  }
}

export function fetchBallastArrivalPortSuccess(payload: any): IBallastArrivalPortFetchSuccess {
  return {
    type: HeelEdqActions.FETCH_BALLAST_ARRIVAL_PORT_SUCCESS,
    payload,
  }
}

export function fetchBallastArrivalPortFailed(payload: any): IBallastArrivalPortFetchFailed {
  return {
    type: HeelEdqActions.FETCH_BALLAST_ARRIVAL_PORT_FAILED,
    payload,
  }
}

export function fetchBallastPacificRoute(payload: any): IBallastPacificRouteFetch {
  return {
    type: HeelEdqActions.FETCH_BALLAST_PACIFIC_ROUTE,
    payload,
  }
}

export function fetchBallastPacificRouteSuccess(payload: any): IBallastPacificRouteFetchSuccess {
  return {
    type: HeelEdqActions.FETCH_BALLAST_PACIFIC_ROUTE_SUCCESS,
    payload,
  }
}

export function fetchBallastPacificRouteFailed(payload: any): IBallastPacificRouteFetchFailed {
  return {
    type: HeelEdqActions.FETCH_BALLAST_PACIFIC_ROUTE_FAILED,
    payload,
  }
}

export function ballastCalculateVoyage(payload: any): IBallastCalculateVoyage {
  return {
    type: HeelEdqActions.CALCULATE_VOYAGE_BALLAST,
    payload,
  }
}

export function ballastCalculateVoyageSuccess(payload: any): IBallastCalculateVoyageSuccess {
  return {
    type: HeelEdqActions.CALCULATE_VOYAGE_BALLAST_SUCCESS,
    payload,
  }
}

export function ballastCalculateVoyageFailed(payload: any): IBallastCalculateVoyageFailed {
  return {
    type: HeelEdqActions.CALCULATE_VOYAGE_BALLAST_FAILED,
    payload,
  }
}

export function fetchBallastTimeZoneLabel(payload: any): IBallastTimeZoneLabelFetch {
  return {
    type: HeelEdqActions.FETCH_BALLAST_TIMEZONE_LABEL,
    payload,
  }
}

export function fetchBallastTimeZoneLabelSuccess(payload: any): IBallastTimeZoneLabelFetchSuccess {
  return {
    type: HeelEdqActions.FETCH_BALLAST_TIMEZONE_LABEL_SUCCESS,
    payload,
  }
}

export function fetchBallastTimeZoneLabelFailed(payload: any): IBallastTimeZoneLabelFetchFailed {
  return {
    type: HeelEdqActions.FETCH_BALLAST_TIMEZONE_LABEL_FAILED,
    payload,
  }
}

// BallastLeg2 Leg 2
export function fetchBallastLeg2ArrivalPort(payload: any): IBallastLeg2ArrivalPortFetch {
  return {
    type: HeelEdqActions.FETCH_BALLAST_LEG2_ARRIVAL_PORT,
    payload,
  }
}

export function fetchBallastLeg2ArrivalPortSuccess(
  payload: any
): IBallastLeg2ArrivalPortFetchSuccess {
  return {
    type: HeelEdqActions.FETCH_BALLAST_LEG2_ARRIVAL_PORT_SUCCESS,
    payload,
  }
}

export function fetchBallastLeg2ArrivalPortFailed(
  payload: any
): IBallastLeg2ArrivalPortFetchFailed {
  return {
    type: HeelEdqActions.FETCH_BALLAST_LEG2_ARRIVAL_PORT_FAILED,
    payload,
  }
}

export function fetchBallastLeg2PacificRoute(payload: any): IBallastLeg2PacificRouteFetch {
  return {
    type: HeelEdqActions.FETCH_BALLAST_LEG2_PACIFIC_ROUTE,
    payload,
  }
}

export function fetchBallastLeg2PacificRouteSuccess(
  payload: any
): IBallastLeg2PacificRouteFetchSuccess {
  return {
    type: HeelEdqActions.FETCH_BALLAST_LEG2_PACIFIC_ROUTE_SUCCESS,
    payload,
  }
}

export function fetchBallastLeg2PacificRouteFailed(
  payload: any
): IBallastLeg2PacificRouteFetchFailed {
  return {
    type: HeelEdqActions.FETCH_BALLAST_LEG2_PACIFIC_ROUTE_FAILED,
    payload,
  }
}

export function ballastLeg2CalculateVoyage(payload: any): IBallastLeg2CalculateVoyage {
  return {
    type: HeelEdqActions.CALCULATE_VOYAGE_BALLAST_LEG2,
    payload,
  }
}

export function ballastLeg2CalculateVoyageSuccess(
  payload: any
): IBallastLeg2CalculateVoyageSuccess {
  return {
    type: HeelEdqActions.CALCULATE_VOYAGE_BALLAST_LEG2_SUCCESS,
    payload,
  }
}

export function ballastLeg2CalculateVoyageFailed(payload: any): IBallastLeg2CalculateVoyageFailed {
  return {
    type: HeelEdqActions.CALCULATE_VOYAGE_BALLAST_LEG2_FAILED,
    payload,
  }
}

export function fetchBallastLeg2TimeZoneLabel(payload: any): IBallastLeg2TimeZoneLabelFetch {
  return {
    type: HeelEdqActions.FETCH_BALLAST_LEG2_TIMEZONE_LABEL,
    payload,
  }
}

export function fetchBallastLeg2TimeZoneLabelSuccess(
  payload: any
): IBallastLeg2TimeZoneLabelFetchSuccess {
  return {
    type: HeelEdqActions.FETCH_BALLAST_LEG2_TIMEZONE_LABEL_SUCCESS,
    payload,
  }
}

export function fetchBallastLeg2TimeZoneLabelFailed(
  payload: any
): IBallastLeg2TimeZoneLabelFetchFailed {
  return {
    type: HeelEdqActions.FETCH_BALLAST_LEG2_TIMEZONE_LABEL_FAILED,
    payload,
  }
}
// END

export function calculateVoyageReset(payload: any): ICalculateVoyageReset {
  return {
    type: HeelEdqActions.CALCULATE_VOYAGE_RESET,
    payload,
  }
}

export function calculateVoyageResultReset(payload: any): ICalculateVoyageResultReset {
  return {
    type: HeelEdqActions.CALCULATE_VOYAGE_RESULT_RESET,
    payload,
  }
}

export function fetchLngConsumptionPanama(): ILngConsumptionPanamaFetch {
  return {
    type: HeelEdqActions.FETCH_LNG_CONSUMPTION_PANAMA,
  }
}

export function fetchLngConsumptionPanamaSuccess(payload: any): ILngConsumptionPanamaFetchSuccess {
  return {
    type: HeelEdqActions.FETCH_LNG_CONSUMPTION_PANAMA_SUCCESS,
    payload,
  }
}

export function fetchLngConsumptionPanamaFailed(payload: any): ILngConsumptionPanamaFetchFailed {
  return {
    type: HeelEdqActions.FETCH_LNG_CONSUMPTION_PANAMA_FAILED,
    payload,
  }
}

export function fetchHeelEdqItem(payload: any): IHeelEdqItemFetch {
  return {
    type: HeelEdqActions.FETCH_HEEL_EDQ_ITEM,
    payload,
  }
}

export function fetchHeelEdqItemSuccess(payload: any): IHeelEdqItemFetchSuccess {
  return {
    type: HeelEdqActions.FETCH_HEEL_EDQ_ITEM_SUCCESS,
    payload,
  }
}

export function fetchHeelEdqItemFailed(payload: any): IHeelEdqItemFetchFailed {
  return {
    type: HeelEdqActions.FETCH_HEEL_EDQ_ITEM_FAILED,
    payload,
  }
}

export function resetHeelEdqItem(): IHeelEdqItemReset {
  return {
    type: HeelEdqActions.RESET_HEEL_EDQ_ITEM,
  }
}

export function createResult(payload: any): ICreateResult {
  return {
    type: HeelEdqActions.CREATE_RESULT,
    payload,
  }
}

export function createResultSuccess(): ICreateResultSuccess {
  return {
    type: HeelEdqActions.CREATE_RESULT_SUCCESS,
  }
}

export function createResultFailed(payload: any): ICreateResultFailed {
  return {
    type: HeelEdqActions.CREATE_RESULT_FAILED,
    payload,
  }
}

export function updateResult(payload: any): IUpdateResult {
  return {
    type: HeelEdqActions.UPDATE_RESULT,
    payload,
  }
}

export function updateResultSuccess(): IUpdateResultSuccess {
  return {
    type: HeelEdqActions.UPDATE_RESULT_SUCCESS,
  }
}

export function updateResultFailed(payload: any): IUpdateResultFailed {
  return {
    type: HeelEdqActions.UPDATE_RESULT_FAILED,
    payload,
  }
}

export function deleteResult(payload: any): IDeleteResult {
  return {
    type: HeelEdqActions.DELETE_RESULT,
    payload,
  }
}

export function deleteResultSuccess(): IDeleteResultSuccess {
  return {
    type: HeelEdqActions.DELETE_RESULT_SUCCESS,
  }
}

export function deleteResultFailed(payload: any): IDeleteResultFailed {
  return {
    type: HeelEdqActions.DELETE_RESULT_FAILED,
    payload,
  }
}

export function finalizeResult(payload: any): IFinalizeResult {
  return {
    type: HeelEdqActions.FINALIZE_RESULT,
    payload,
  }
}

export function finalizeResultSuccess(): IFinalizeResultSuccess {
  return {
    type: HeelEdqActions.FINALIZE_RESULT_SUCCESS,
  }
}

export function finalizeResultFailed(payload: any): IFinalizeResultFailed {
  return {
    type: HeelEdqActions.FINALIZE_RESULT_FAILED,
    payload,
  }
}

export function fetchCalculationResult(payload: any): ICalculationResultFetchAll {
  return {
    type: HeelEdqActions.FETCH_CALCULATION_RESULT_ALL,
    payload,
  }
}

export function fetchCalculationResultSuccess(payload: any): ICalculationResultFetchAllSuccess {
  return {
    type: HeelEdqActions.FETCH_CALCULATION_RESULT_ALL_SUCCESS,
    payload,
  }
}

export function fetchCalculationResultFinished(payload: any): ICalculationResultFetchAllFinished {
  return {
    type: HeelEdqActions.FETCH_CALCULATION_RESULT_ALL_FINISHED,
    payload,
  }
}

export function fetchCalculationResultFailed(payload: {
  error: string
}): ICalculationResultFetchAllFailed {
  return {
    type: HeelEdqActions.FETCH_CALCULATION_RESULT_ALL_FAILED,
    payload,
  }
}

export function setValidationField(payload: any): ISetValidationField {
  return {
    type: HeelEdqActions.SET_VALIDATION_FIELD,
    payload,
  }
}

export function setHeelEdqFilters(payload: any): ISetFilters {
  return {
    type: HeelEdqActions.SET_FILTERS,
    payload,
  }
}

export function fetchFilterPacificRoute(): IFilterPacificRouteFetch {
  return {
    type: HeelEdqActions.FETCH_FILTER_PACIFIC_ROUTE,
  }
}

export function fetchFilterPacificRouteSuccess(payload: any): IFilterPacificRouteFetchSuccess {
  return {
    type: HeelEdqActions.FETCH_FILTER_PACIFIC_ROUTE_SUCCESS,
    payload,
  }
}

export function fetchFilterPacificRouteFailed(payload: any): IFilterPacificRouteFetchFailed {
  return {
    type: HeelEdqActions.FETCH_FILTER_PACIFIC_ROUTE_FAILED,
    payload,
  }
}

export function setCurrentVoyage(payload: any): ISetCurrentVoyage {
  return {
    type: HeelEdqActions.SET_CURRENT_VOYAGE,
    payload,
  }
}

export function setLadenBallastPanamaTransit(payload: any): ISetLadenBallastPanamaTransit {
  return {
    type: HeelEdqActions.SET_LADEN_BALLAST_PANAMA_TRANSIT,
    payload,
  }
}

export function setLadenBallastFuelPanamaTransit(payload: any): ISetLadenBallastFuelPanamaTransit {
  return {
    type: HeelEdqActions.SET_LADEN_BALLAST_FUEL_PANAMA_TRANSIT,
    payload,
  }
}

export function setLadenBallastSummaryPanamaTransit(
  payload: any
): ISetLadenBallastSummaryPanamaTransit {
  return {
    type: HeelEdqActions.SET_LADEN_BALLAST_SUMMARY_PANAMA_TRANSIT,
    payload,
  }
}

export function setEstimatedHeel(payload: any): ISetEstimatedHeel {
  return {
    type: HeelEdqActions.SET_ESTIMATED_HEEL,
    payload,
  }
}
