import { Layout } from "antd"
import React, { useCallback, useMemo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setMenuCollapsed } from "services/layout/actions"
import { LayoutSelector } from "services/layout/selectors"
import { AuthSelector } from "services/auth/selectors"
import { useInjectReducer } from "core/hooks/useInjectReducer"
import { SIGNIN } from "core/constants/routes"
import { useLocation } from "react-router-dom"
import layoutReducer from "services/layout/reducers"
import Loader from "components/Common/Loader"
import Header from "../Header"
import Sider from "../Sider"
import Footer from "../Footer"
import { getCurrentUser, refreshToken, fetchListCompany } from "../../services/auth/actions"

interface MainLayoutProps {
  children: React.ReactNode
}

function Main(props: MainLayoutProps) {
  useInjectReducer({ key: "layout", reducer: layoutReducer })
  const dispatch = useDispatch()
  const { children } = props
  const { isCollapsed } = useSelector(LayoutSelector)
  const {
    authorized,
    current_user: { isSuperAdmin },
    companies: { data, company_id },
  } = useSelector(AuthSelector)
  const { pathname } = useLocation()
  const setCollapsed = () => {
    dispatch(setMenuCollapsed({ isCollapsed: !isCollapsed }))
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toggle = useCallback(() => setCollapsed(), [isCollapsed])

  const collapsedClassName = useMemo(() => (isCollapsed ? "collapsed" : ""), [isCollapsed])

  const REFRESH_TOKEN_INTERVAL = 2 * 60 * 1000
  const onFailedRefresh = () => {
    if (pathname !== SIGNIN) return window.location.replace(SIGNIN)
    return null
  }

  const onFailedGetCurrentUser = () => {
    if (pathname !== SIGNIN) return window.location.replace(SIGNIN)
    return null
  }

  useEffect(() => {
    if (!authorized) {
      dispatch(getCurrentUser({ onFailed: () => onFailedGetCurrentUser() }))
    } else {
      setInterval(() => {
        dispatch(
          refreshToken({
            setIsWaiting: () => {}, // eslint-disable-line
            onFailed: () => onFailedRefresh(),
          })
        )
      }, REFRESH_TOKEN_INTERVAL)
    }
  }, [authorized])

  useEffect(() => {
    if (isSuperAdmin) dispatch(fetchListCompany())
  }, [isSuperAdmin])

  return (
    <Layout>
      {authorized && (!isSuperAdmin || (isSuperAdmin && data?.length > 0)) && (
        <>
          <Sider collapsed={isCollapsed} onBreakPoint={() => setCollapsed} />
          <Layout className="site-layout">
            {!isSuperAdmin && <Header collapsed={isCollapsed} onToggle={toggle} />}
            {isSuperAdmin && company_id && <Header collapsed={isCollapsed} onToggle={toggle} />}
            <Layout.Content
              className={`site-layout-background ${collapsedClassName}`}
              id="layoutContent"
            >
              {!isSuperAdmin && children}
              {isSuperAdmin && company_id && children}
            </Layout.Content>
            <Footer />
          </Layout>
        </>
      )}
      {!authorized && <Loader size="large" />}
    </Layout>
  )
}

export default Main
