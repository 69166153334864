import genActions from "services/_base/actions"
import {
  DataSettingsRouteActions,
  IListRouteFetchAll,
  IListRouteFetchAllSuccess,
  IListRouteFetchAllFailed,
  ICreateRoute,
  ICreateRouteSuccess,
  ICreateRouteFailed,
  IDeleteRoute,
  IDeleteRouteSuccess,
  IDeleteRouteFailed,
  IUpdateRoute,
  IUpdateRouteSuccess,
  IUpdateRouteFailed,
  IListRouteFetchAllFinished,
} from "./types"

export const { funcs: BaseFunctions, types: BaseActions } = genActions("data_settings_route")

export function fetchListRoute(): IListRouteFetchAll {
  return {
    type: DataSettingsRouteActions.FETCH_LIST_ROUTE_ALL,
  }
}

export function fetchListRouteSuccess(payload: any): IListRouteFetchAllSuccess {
  return {
    type: DataSettingsRouteActions.FETCH_LIST_ROUTE_ALL_SUCCESS,
    payload,
  }
}

export function fetchListRouteFinished(payload: any): IListRouteFetchAllFinished {
  return {
    type: DataSettingsRouteActions.FETCH_LIST_ROUTE_ALL_FINISHED,
    payload,
  }
}

export function fetchListRouteFailed(payload: { error: string }): IListRouteFetchAllFailed {
  return {
    type: DataSettingsRouteActions.FETCH_LIST_ROUTE_ALL_FAILED,
    payload,
  }
}

export function createRoute(payload: any): ICreateRoute {
  return {
    type: DataSettingsRouteActions.CREATE_ROUTE,
    payload,
  }
}

export function createRouteSuccess(): ICreateRouteSuccess {
  return {
    type: DataSettingsRouteActions.CREATE_ROUTE_SUCCESS,
  }
}

export function createRouteFailed(payload: any): ICreateRouteFailed {
  return {
    type: DataSettingsRouteActions.CREATE_ROUTE_FAILED,
    payload,
  }
}

export function updateRoute(payload: any): IUpdateRoute {
  return {
    type: DataSettingsRouteActions.UPDATE_ROUTE,
    payload,
  }
}

export function updateRouteSuccess(): IUpdateRouteSuccess {
  return {
    type: DataSettingsRouteActions.UPDATE_ROUTE_SUCCESS,
  }
}

export function updateRouteFailed(payload: any): IUpdateRouteFailed {
  return {
    type: DataSettingsRouteActions.UPDATE_ROUTE_FAILED,
    payload,
  }
}

export function deleteRoute(payload: any): IDeleteRoute {
  return {
    type: DataSettingsRouteActions.DELETE_ROUTE,
    payload,
  }
}

export function deleteRouteSuccess(): IDeleteRouteSuccess {
  return {
    type: DataSettingsRouteActions.DELETE_ROUTE_SUCCESS,
  }
}

export function deleteRouteFailed(payload: any): IDeleteRouteFailed {
  return {
    type: DataSettingsRouteActions.DELETE_ROUTE_FAILED,
    payload,
  }
}
