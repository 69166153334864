import { WEATHER_KEY } from "core/constants/weather"
import genActions from "services/_base/actions"
import {
  IDurationSetCurrentDateAction,
  HistoricalActions,
  ISetSelectedImosAction,
  ISetDurationSettingAction,
  WeatherParams,
  IWeatherWaveHeight,
  IWeatherWaveHeightSuccess,
  IWeatherWaveHeightFailed,
  IWeatherSurfacePressure,
  IWeatherSurfacePressureSuccess,
  IWeatherSurfacePressureFailed,
  IWeatherTropicalStorm,
  IWeatherTropicalStormSuccess,
  IWeatherTropicalStormFailed,
  IWeatherOceanCurrent,
  IWeatherOceanCurrentSuccess,
  IWeatherOceanCurrentFailed,
  IWeatherWindBarbs,
  IWeatherWindBarbsSuccess,
  IWeatherWindBarbsFailed,
  IShowWeatherWaveHeight,
  IShowWeatherSurfacePressure,
  IShowWeatherTropicalStorm,
  IShowWeatherOceanCurrent,
  IShowWeatherWindBarbs,
  IShowWeathers,
  IHideWeathers,
  ICancelWeatherWindBarbs,
  ICancelWeatherOceanCurrent,
  ISetSelectedVesselIdAction,
} from "./types"

export function setDurationCurrentDate(payload: { date: Date }): IDurationSetCurrentDateAction {
  return { type: HistoricalActions.SET_DURATION_CURRENT_DATE, payload }
}

export function setSelectedImos(payload: { imos: any }): ISetSelectedImosAction {
  return { type: HistoricalActions.SET_SELECTED_IMO, payload }
}

export function setSelectedVesselId(payload: { vessel_id: any }): ISetSelectedVesselIdAction {
  return { type: HistoricalActions.SET_SELECTED_VESSEL_ID, payload }
}

export function setDurationSetting(payload: {
  fromDate: Date
  toDate: Date
  currentDate: Date
}): ISetDurationSettingAction {
  return { type: HistoricalActions.SET_DURATION_SETTING, payload }
}

export function fetchWeathers(payload: WeatherParams) {
  switch (payload.element) {
    case WEATHER_KEY.WAVE_HEIGHT:
      return fetchWeatherWaveHeight(payload)
    case WEATHER_KEY.SURFACE_PRESSURE:
      return fetchWeatherSurfacePressure(payload)
    case WEATHER_KEY.GLOBAL_STORM:
      return fetchWeatherTropicalStorm(payload)
    case WEATHER_KEY.OCEAN_CURRENT:
      return fetchWeatherOceanCurrent(payload)
    case WEATHER_KEY.WIND_BARB:
      return fetchWeatherWindBarbs(payload)
    default:
      break
  }
}

export function showWeatherWaveHeight(payload: { checked: boolean }): IShowWeatherWaveHeight {
  return { type: HistoricalActions.SHOW_WEATHER_WAVE_HEIGHT, payload }
}

export function fetchWeatherWaveHeight(payload: WeatherParams): IWeatherWaveHeight {
  return { type: HistoricalActions.FETCH_WEATHER_WAVE_HEIGHT, payload }
}

export function fetchWeatherWaveHeightSuccess(data: any): IWeatherWaveHeightSuccess {
  return { type: HistoricalActions.FETCH_WEATHER_WAVE_HEIGHT_SUCCESS, data }
}

export function fetchWeatherWaveHeightFailed(payload: { error: string }): IWeatherWaveHeightFailed {
  return { type: HistoricalActions.FETCH_WEATHER_WAVE_HEIGHT_FAILED, payload }
}

export function showWeatherSurfacePressure(payload: {
  checked: boolean
}): IShowWeatherSurfacePressure {
  return { type: HistoricalActions.SHOW_WEATHER_SURFACE_PRESSURE, payload }
}

export function fetchWeatherSurfacePressure(payload: WeatherParams): IWeatherSurfacePressure {
  return { type: HistoricalActions.FETCH_WEATHER_SURFACE_PRESSURE, payload }
}

export function fetchWeatherSurfacePressureSuccess(data: any): IWeatherSurfacePressureSuccess {
  return { type: HistoricalActions.FETCH_WEATHER_SURFACE_PRESSURE_SUCCESS, data }
}

export function fetchWeatherSurfacePressureFailed(payload: {
  error: string
}): IWeatherSurfacePressureFailed {
  return { type: HistoricalActions.FETCH_WEATHER_SURFACE_PRESSURE_FAILED, payload }
}

export function showWeatherGlobalStorm(payload: { checked: boolean }): IShowWeatherTropicalStorm {
  return { type: HistoricalActions.SHOW_WEATHER_GLOBAL_STORM, payload }
}

export function fetchWeatherTropicalStorm(payload: WeatherParams): IWeatherTropicalStorm {
  return { type: HistoricalActions.FETCH_WEATHER_GLOBAL_STORM, payload }
}

export function fetchWeatherTropicalStormSuccess(data: any): IWeatherTropicalStormSuccess {
  return { type: HistoricalActions.FETCH_WEATHER_GLOBAL_STORM_SUCCESS, data }
}

export function fetchWeatherTropicalStormFailed(payload: {
  error: string
}): IWeatherTropicalStormFailed {
  return { type: HistoricalActions.FETCH_WEATHER_GLOBAL_STORM_FAILED, payload }
}

export function showWeatherOceanCurrent(payload: { checked: boolean }): IShowWeatherOceanCurrent {
  return { type: HistoricalActions.SHOW_WEATHER_OCEAN_CURRENT, payload }
}

export function fetchWeatherOceanCurrent(payload: WeatherParams): IWeatherOceanCurrent {
  return { type: HistoricalActions.FETCH_WEATHER_OCEAN_CURRENT, payload }
}

export function fetchWeatherOceanCurrentSuccess(data: any): IWeatherOceanCurrentSuccess {
  return { type: HistoricalActions.FETCH_WEATHER_OCEAN_CURRENT_SUCCESS, data }
}

export function fetchWeatherOceanCurrentFailed(payload: {
  error: string
}): IWeatherOceanCurrentFailed {
  return { type: HistoricalActions.FETCH_WEATHER_OCEAN_CURRENT_FAILED, payload }
}

export function cancelWeatherOceanCurrent(): ICancelWeatherOceanCurrent {
  return { type: HistoricalActions.CANCEL_WEATHER_OCEAN_CURRENT }
}

export function showWeatherWindBarbs(payload: { checked: boolean }): IShowWeatherWindBarbs {
  return { type: HistoricalActions.SHOW_WEATHER_WIND_BARBS, payload }
}

export function fetchWeatherWindBarbs(payload: WeatherParams): IWeatherWindBarbs {
  return { type: HistoricalActions.FETCH_WEATHER_WIND_BARBS, payload }
}

export function fetchWeatherWindBarbsSuccess(data: any): IWeatherWindBarbsSuccess {
  return { type: HistoricalActions.FETCH_WEATHER_WIND_BARBS_SUCCESS, data }
}

export function fetchWeatherWindBarbsFailed(payload: { error: string }): IWeatherWindBarbsFailed {
  return { type: HistoricalActions.FETCH_WEATHER_WIND_BARBS_FAILED, payload }
}

export function cancelWeatherWindBarbs(): ICancelWeatherWindBarbs {
  return { type: HistoricalActions.CANCEL_WEATHER_WIND_BARBS }
}

export function showWeathers(data: []): IShowWeathers {
  return { type: HistoricalActions.SHOW_WEATHERS, data }
}

export function hideWeathers(): IHideWeathers {
  return { type: HistoricalActions.HIDE_WEATHERS }
}

export const { funcs: BaseFunctions, types: BaseActions } = genActions("historical")
