import { formatFeatures } from "core/model/Map"
import { makeWindArea } from "./makeDirection"

const STORM_FORECAST_POINT = "storm_forecast_point"
const GALE_FORCE_WIND_AREA = "gale_force_wind_area"
const STORM_FORCE_WIND_AREA = "storm_force_wind_area"

export const renderStormIconFeatures = (data) => {
  const markers = []
  data.tropInfo.forEach((tropInfo) => {
    const tropName = tropInfo?.tropName || "-"
    if (tropInfo?.trackInfolist?.trackInfo?.length > 0)
      tropInfo.trackInfolist.trackInfo.forEach((trackInfo) => {
        markers.push(stormForecastCircleFeature(trackInfo, tropName))
      })
    if (tropInfo?.forecastInfolist?.forecastInfo?.length > 0)
      tropInfo.forecastInfolist.forecastInfo.forEach((item) => {
        markers.push(stormForecastFeature(item, tropName))
        const stormWindAreas = stormWindAreaFeatures(item, tropName)
        if (stormWindAreas.length > 0) {
          markers.push(...stormWindAreas)
        }
      })
  })
  return formatFeatures(markers)
}

export const renderStormLinesFeatures = (data) => {
  const lines = []
  data.tropInfo.forEach((tropInfo) => {
    let latlngs = []
    const trackInfolist = tropInfo?.trackInfolist
    if (trackInfolist && trackInfolist.trackInfo) {
      trackInfolist.trackInfo.forEach((item) => {
        latlngs.push({
          lat: item.point.lat,
          lon: item.point.lon,
        })
      })
    }

    const forecastInfolist = tropInfo?.forecastInfolist
    if (forecastInfolist && forecastInfolist.forecastInfo) {
      forecastInfolist.forecastInfo.forEach((item) => {
        latlngs.push({
          lat: item.point.lat,
          lon: item.point.lon,
        })
      })
    }
    if (latlngs.length === 0) {
      return []
    }
    latlngs = correctTrackingPoints(latlngs)
    const coordinates = buildLatLongArray(latlngs)
    const geometry = { type: "LineString", coordinates }
    const properties = {
      name: "storm_lines_features",
    }
    const feature = { type: "Feature", geometry, properties }
    lines.push(feature)
  })

  return formatFeatures(lines)
}

const stormForecastCircleFeature = (item, tropName) => {
  const coordinates = [item.point.lon, item.point.lat]
  const geometry = { type: "Point", coordinates }
  const itemWithTropName = { ...item, tropName, coordinates }
  const properties = { name: STORM_FORECAST_POINT, data: itemWithTropName }
  const feature = { type: "Feature", geometry, properties }
  return feature
}

function stormForecastFeature(item, tropName) {
  const coordinates = [item.point.lon, item.point.lat]
  const geometry = { type: "Point", coordinates }
  const itemWithTropName = { ...item, tropName, coordinates }
  const properties = { name: "storm_circle_icon", data: itemWithTropName }
  const feature = { type: "Feature", geometry, properties }
  return feature
}

const stormWindAreaFeatures = (item, tropName) => {
  const markers = []
  if (Object.keys(item.stormForceWindArea).length > 0) {
    // eslint-disable-next-line
    for (const [_, value] of Object.entries(item.stormForceWindArea)) {
      const coordinates = [item.point.lon, item.point.lat]
      const geometry = { type: "Point", coordinates }
      const properties = {
        name: STORM_FORCE_WIND_AREA,
        ...makeWindArea(value, "storm_force"),
        coordinates,
        tropName,
      }
      const feature = { type: "Feature", geometry, properties }
      markers.push(feature)
    }
  }
  if (Object.keys(item.galeForceWindArea).length > 0) {
    // eslint-disable-next-line
    for (const [_, value] of Object.entries(item.galeForceWindArea)) {
      const coordinates = [item.point.lon, item.point.lat]
      const geometry = { type: "Point", coordinates }
      const properties = {
        name: GALE_FORCE_WIND_AREA,
        ...makeWindArea(value, "gale_force"),
        coordinates,
        tropName,
      }
      const feature = { type: "Feature", geometry, properties }
      markers.push(feature)
    }
  }
  return markers
}

const correctTrackingPoints = (trackings) => {
  const points = []
  const area = getRouteArea(trackings)
  const offsetAdjustment = calculateOffset(area)
  trackings.forEach((tracking) => {
    let offset = 0
    if (tracking.lon >= 0) {
      offset = offsetAdjustment.positive
    } else {
      offset = offsetAdjustment.negative
    }
    points.push({ lat: tracking.lat, lon: tracking.lon + offset })
  })
  return points
}

const calculateOffset = (area) => {
  const default_adjustment = {
    positive: 0,
    negative: 0,
  }
  // eslint-disable-next-line
  if (area._0_to_180) {
    if (area.negative_180_to_90) {
      return {
        positive: 0,
        negative: 360,
      }
    }
  }
  return default_adjustment
}

const getRouteArea = (trackings) => {
  const area = {
    _0_to_180: false,
    negative_180_to_90: false,
  }
  trackings.forEach((tracking) => {
    if (tracking.lon >= 0 && tracking.lon <= 180) {
      area._0_to_180 = true
    }
    if (tracking.lon > -180 && tracking.lon <= -90) {
      area.negative_180_to_90 = true
    }
  })

  return area
}

const buildLatLongArray = (latlngs) => {
  return latlngs.map((item) => {
    return [item.lon, item.lat]
  })
}
