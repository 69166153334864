import { WEATHER_FIELD, WEATHER_KEY } from "core/constants/weather"
import { Style } from "ol/style"
import { WeatherParams } from "services/historical/types"
import { formatFeatureExtend } from "../Map"
import { setLocalStorageData } from "./commonWeather"
import { drawCanvas } from "./drawCanvas"
import { makeDirectionMarker } from "./weatherStorm/makeDirection"

export const formatWeatherData = (params: WeatherParams, weatherData: any) => {
  if (!weatherData) return
  const type = params.element
  switch (type) {
    case WEATHER_KEY.WIND_BARB:
    case WEATHER_KEY.OCEAN_CURRENT:
      setLocalStorageData({ element: type, data: weatherData })
      return parseJsonData(weatherData, type, params)
    case WEATHER_KEY.SURFACE_PRESSURE:
    case WEATHER_KEY.WAVE_HEIGHT:
    case WEATHER_KEY.GLOBAL_STORM:
      return weatherData[WEATHER_FIELD[type]]
    default:
      return null
  }
}

export const parseJsonData = (weatherData: any, weatherType: string, params: any) => {
  const resData = []
  if (weatherData || weatherData.coor) {
    // eslint-disable-next-line
    for (const [key, data] of Object.entries(weatherData.coor)) {
      if (!data[weatherType]) return
      const direction = data[weatherType][0]
      let height = data[weatherType][1]
      if (direction && height) {
        const lat_lng = key.split("|")

        const lat = parseFloat(lat_lng[1])
        const lon = parseFloat(lat_lng[0])
        const coordinates = [lat, lon]

        height = parseFloat(height).toFixed(1)
        const geometry = { type: "Point", coordinates }
        const item_detail = { direction, height, name: weatherType }
        const properties = { ...item_detail, coordinates }
        const feature = formatFeatureExtend({ type: "Feature", geometry, properties })
        const featureProperties = {
          ...feature.getProperties(),
          ...makeDirectionMarker(feature.getProperties(), params.zoomForDraw),
        }
        const newWeatherJsonStyle = new Style({
          renderer: (coordinates, state) => drawCanvas(coordinates, featureProperties, state),
        })
        feature.setStyle(newWeatherJsonStyle)

        resData.push(feature)
      }
    }
  }
  return resData
}
