/* eslint-disable react/require-default-props */
import { Tooltip } from "antd"

interface TooltipProps {
  title: string
  className?: string
  color: string
  children: JSX.Element
}

export default ({ title, className, color, children }: TooltipProps) => {
  return (
    <Tooltip title={title} className={className} color={color}>
      {children}
    </Tooltip>
  )
}
