import genActions from "services/_base/actions"
import {
  IVesselFetchAllSuccessAction,
  IVesselFetchAllFinishedAction,
  IVesselFetchAllFailedAction,
  IVesselFetchAllAction,
  IVesselFetchActualRoutes,
  IVesselFetchActualRoutesSuccess,
  IVesselFetchActualRoutesFailed,
  IVesselFetchPlanRoutes,
  IVesselFetchPlanRoutesSuccess,
  IVesselFetchPlanRoutesFailed,
  IVesselFetchEcdisRoutes,
  IVesselFetchEcdisRoutesSuccess,
  IVesselFetchEcdisRoutesFailed,
  IVesselFetchEcdisPoints,
  IVesselFetchEcdisPointsSuccess,
  IVesselFetchEcdisPointsFailed,
  IVesselFetchInformations,
  IVesselFetchInformationsSuccess,
  IVesselFetchInformationsFailed,
  IVesselStageFetchInformations,
  IVesselStageFetchInformationsSuccess,
  IVesselStageFetchInformationsFailed,
  IVesselXdfFetchInformations,
  IVesselXdfFetchInformationsSuccess,
  IVesselXdfFetchInformationsFailed,
  IVesselsetSelected,
  DashboardActions,
  IWeatherShowWaveHeightAction,
  IWeatherShowPressurePatternAction,
  IWeatherShowTropicalStormAction,
  IWeatherShowTropicalStormTrackAction,
  IWeatherShowOcceanCurrentAction,
  IWeatherShowWindAction,
  IWeatherSetDateAction,
  IPortFetchAllSuccessAction,
  IPortFetchAllFinishedAction,
  IPortFetchAllFailedAction,
  IPortFetchAllAction,
  ISetShowCargoMonitorChart,
  ISetShowEngineMonitorChart,
  IFetchStageBoilOffRateChartDataSuccess,
  IFetchStageTrend1ChartDataSuccess,
  IFetchStageTrend21ChartDataSuccess,
  IFetchStageTrend33ChartDataSuccess,
  IFetchStageTankEquatorChartDataSuccess,
  IFetchStageTankVolumeChartDataSuccess,
  IFetchStageTankLiquidTempChartDataSuccess,
  IFetchStagePressChartDataSuccess,
  IFetchXdfTrend1ChartDataSuccess,
  IFetchXdfTrend315ChartDataSuccess,
  IFetchXdfTrend316ChartDataSuccess,
  IFetchXdfTrend317ChartDataSuccess,
  IFetchXdfTrend21ChartDataSuccess,
  IFetchXdfBoilOffRateChartDataSuccess,
  IFetchXdfInnerSurfaceTempChartDataSuccess,
  IFetchXdfTankVolumeChartDataSuccess,
  IFetchXdfTankLiquidTempChartDataSuccess,
  IFetchXdfPressChartDataSuccess,
  ChartDataParams,
  IWeatherSetToDateAction,
  IWeatherSetFromDateAction,
  ATATimeParams,
  IUpdateATATime,
  IUpdateATATimeSuccess,
  IUpdateATATimeFailed,
  ISetSelectedPoint,
  SelectedPoint,
  IUpdateManualETAPeriod,
  IUpdateCalculatedEtaPoints,
  IVesselResetEcdisRoutesAndPoints,
  IVesselShowNoneTargetVessel,
  IVesselShowLabelNoneTargetVessel,
  ISetIsChartPointClick,
  IHistoryRoutePointFiltered,
  IHistoryRoutePointFilteredSuccess,
  IHistoryRoutePointFilteredFailed,
  IHistoryPointSelected,
  IHideWeathers,
  IFetchOgSpeedChartDataSuccess,
  IFetchStageEmBlrChartDataSuccess,
  IFetchStageEmFdumpVOpenChartDataSuccess,
  IFetchXdfCmTotalTankVolumeChartDataSuccess,
  IFetchStageCmPressHoldChartDataSuccess,
  IFetchStageCmTotalTankVolumeChartDataSuccess,
  IFetchStageCmBlrFlowChartDataSuccess,
  IFetchStageTrend1CargoChartDataSuccess,
  IFetchXdfFoc1ChartDataSuccess,
  IFetchStageFoc1ChartDataSuccess,
  IFetchStageTotalFocChartDataSuccess,
  IFetchXdfMEChartDataSuccess,
  IVesselResetList,
  ISetShowChart,
  IResetMonitoringInformation,
  IFetchChartDFDEEngine,
  IFetchChartDFDEEngineSuccess,
  IFetchChartDFDEEngineFailed,
  IFetchChartDFDECargo,
  IFetchChartDFDECargoSuccess,
  IFetchChartDFDECargoFailed,
  ITargetVesselFetchAllAction,
  ITargetVesselFetchAllSuccessAction,
  ITargetVesselFetchAllFinishedAction,
  ITargetVesselFetchAllFailedAction,
  IFetchChartStageCargo,
  IFetchChartStageCargoSuccess,
  IFetchChartStageCargoFailed,
  IFetchChartStageEngine,
  IFetchChartStageEngineSuccess,
  IFetchChartStageEngineFailed,
  IFetchChartXDFCargo,
  IFetchChartXDFCargoSuccess,
  IFetchChartXDFCargoFailed,
  IFetchChartXDFEngine,
  IFetchChartXDFEngineSuccess,
  IFetchChartXDFEngineFailed,
  IVesselDfdeFetchInformations,
  IVesselDfdeFetchInformationsSuccess,
  IVesselDfdeFetchInformationsFailed,
} from "./types"

export const { funcs: BaseFunctions, types: BaseActions } = genActions("dashboard")

export function fetchVesselAll(payload?: any): IVesselFetchAllAction {
  return { type: DashboardActions.FETCH_VESSEL_ALL, payload }
}
export function fetchVesselAllSuccess(payload: any): IVesselFetchAllSuccessAction {
  return {
    type: DashboardActions.FETCH_VESSEL_ALL_SUCCESS,
    payload,
  }
}
export function fetchVesselAllFinished(payload: any): IVesselFetchAllFinishedAction {
  return {
    type: DashboardActions.FETCH_VESSEL_ALL_FINISHED,
    payload,
  }
}
export function fetchVesselAllFailed(payload: { error: string }): IVesselFetchAllFailedAction {
  return {
    type: DashboardActions.FETCH_VESSEL_ALL_FAILED,
    payload,
  }
}

export function fetchTargetVesselAll(): ITargetVesselFetchAllAction {
  return { type: DashboardActions.FETCH_TARGET_VESSEL_ALL }
}
export function fetchTargetVesselAllSuccess(payload: any): ITargetVesselFetchAllSuccessAction {
  return {
    type: DashboardActions.FETCH_TARGET_VESSEL_ALL_SUCCESS,
    payload,
  }
}
export function fetchTargetVesselAllFinished(payload: any): ITargetVesselFetchAllFinishedAction {
  return {
    type: DashboardActions.FETCH_TARGET_VESSEL_ALL_FINISHED,
    payload,
  }
}
export function fetchTargetVesselAllFailed(payload: {
  error: string
}): ITargetVesselFetchAllFailedAction {
  return {
    type: DashboardActions.FETCH_TARGET_VESSEL_ALL_FAILED,
    payload,
  }
}

export function resetVesselList(): IVesselResetList {
  return { type: DashboardActions.RESET_VESSEL_LIST }
}

export function setVesselSelectedImo(payload: any): IVesselsetSelected {
  return { type: DashboardActions.SET_VESSEL_SELECTED, payload }
}

export function fetchVesselActualRoutes(payload: any): IVesselFetchActualRoutes {
  return { type: DashboardActions.FETCH_VESSEL_ACTUAL_ROUTES, payload }
}

export function fetchVesselActualRoutesSuccess(payload: any): IVesselFetchActualRoutesSuccess {
  return { type: DashboardActions.FETCH_VESSEL_ACTUAL_ROUTES_SUCCESS, payload }
}
export function fetchVesselActualRoutesFailed(payload: any): IVesselFetchActualRoutesFailed {
  return { type: DashboardActions.FETCH_VESSEL_ACTUAL_ROUTES_FAILED, payload }
}

export function fetchVesselPlanRoutes(payload: any): IVesselFetchPlanRoutes {
  return { type: DashboardActions.FETCH_VESSEL_PLAN_ROUTES, payload }
}

export function fetchVesselPlanRoutesSuccess(payload: any): IVesselFetchPlanRoutesSuccess {
  return { type: DashboardActions.FETCH_VESSEL_PLAN_ROUTES_SUCCESS, payload }
}
export function fetchVesselPlanRoutesFailed(payload: any): IVesselFetchPlanRoutesFailed {
  return { type: DashboardActions.FETCH_VESSEL_PLAN_ROUTES_FAILED, payload }
}

export function fetchVesselEcdisRoutes(payload: any): IVesselFetchEcdisRoutes {
  return { type: DashboardActions.FETCH_VESSEL_ECDIS_ROUTES, payload }
}

export function fetchVesselEcdisRoutesSuccess(payload: any): IVesselFetchEcdisRoutesSuccess {
  return { type: DashboardActions.FETCH_VESSEL_ECDIS_ROUTES_SUCCESS, payload }
}

export function fetchVesselEcdisRoutesFailed(payload: any): IVesselFetchEcdisRoutesFailed {
  return { type: DashboardActions.FETCH_VESSEL_ECDIS_ROUTES_FAILED, payload }
}

export function fetchVesselEcdisPoints(payload: any): IVesselFetchEcdisPoints {
  return { type: DashboardActions.FETCH_VESSEL_ECDIS_POINTS, payload }
}

export function fetchVesselEcdisPointsSuccess(payload: any): IVesselFetchEcdisPointsSuccess {
  return { type: DashboardActions.FETCH_VESSEL_ECDIS_POINTS_SUCCESS, payload }
}

export function fetchVesselEcdisPointsFailed(payload: any): IVesselFetchEcdisPointsFailed {
  return { type: DashboardActions.FETCH_VESSEL_ECDIS_POINTS_FAILED, payload }
}

export function resetVesselEcdisRoutesAndPoints(): IVesselResetEcdisRoutesAndPoints {
  return { type: DashboardActions.RESET_FETCH_VESSEL_ECDIS_ROUTES_AND_POINTS }
}

export function fetchVesselInformations(payload: any): IVesselFetchInformations {
  return { type: DashboardActions.FETCH_VESSEL_INFORMATIONS, payload }
}

export function setSelectedPoint(selectedPoint: SelectedPoint): ISetSelectedPoint {
  return { type: DashboardActions.SET_SELECTED_POINT, payload: { selectedPoint } }
}

export function setIsChartPointClick(isChartPointClick: boolean): ISetIsChartPointClick {
  return { type: DashboardActions.SET_IS_POINT_CHART_CLICK, payload: { isChartPointClick } }
}

export function fetchHistoryRoutePointFiltered(payload: any): IHistoryRoutePointFiltered {
  return { type: DashboardActions.FETCH_HISTORY_ROUTE_POINT_FILTERED, payload }
}

export function fetchHistoryRoutePointFilteredSuccess(
  payload: any
): IHistoryRoutePointFilteredSuccess {
  return { type: DashboardActions.FETCH_HISTORY_ROUTE_POINT_FILTERED_SUCCESS, payload }
}

export function fetchHistoryRoutePointFilteredFailed(
  payload: any
): IHistoryRoutePointFilteredFailed {
  return { type: DashboardActions.FETCH_HISTORY_ROUTE_POINT_FILTERED_FAILED, payload }
}

export function fetchVesselInformationsSuccess(payload: any): IVesselFetchInformationsSuccess {
  return { type: DashboardActions.FETCH_VESSEL_INFORMATIONS_SUCCESS, payload }
}
export function fetchVesselInformationsFailed(payload: any): IVesselFetchInformationsFailed {
  return { type: DashboardActions.FETCH_VESSEL_INFORMATIONS_FAILED, payload }
}

export function fetchStageVesselInformations(payload: any): IVesselStageFetchInformations {
  return { type: DashboardActions.FETCH_VESSEL_STAGE_INFORMATIONS, payload }
}

export function fetchStageVesselInformationsSuccess(
  payload: any
): IVesselStageFetchInformationsSuccess {
  return { type: DashboardActions.FETCH_VESSEL_STAGE_INFORMATIONS_SUCCESS, payload }
}
export function fetchStageVesselInformationsFailed(
  payload: any
): IVesselStageFetchInformationsFailed {
  return { type: DashboardActions.FETCH_VESSEL_STAGE_INFORMATIONS_FAILED, payload }
}

export function fetchXdfVesselInformations(payload: any): IVesselXdfFetchInformations {
  return { type: DashboardActions.FETCH_VESSEL_XDF_INFORMATIONS, payload }
}

export function fetchXdfVesselInformationsSuccess(
  payload: any
): IVesselXdfFetchInformationsSuccess {
  return { type: DashboardActions.FETCH_VESSEL_XDF_INFORMATIONS_SUCCESS, payload }
}
export function fetchXdfVesselInformationsFailed(payload: any): IVesselXdfFetchInformationsFailed {
  return { type: DashboardActions.FETCH_VESSEL_XDF_INFORMATIONS_FAILED, payload }
}

export function fetchDfdeVesselInformations(payload: any): IVesselDfdeFetchInformations {
  return { type: DashboardActions.FETCH_VESSEL_DFDE_INFORMATIONS, payload }
}

export function fetchDfdeVesselInformationsSuccess(
  payload: any
): IVesselDfdeFetchInformationsSuccess {
  return { type: DashboardActions.FETCH_VESSEL_DFDE_INFORMATIONS_SUCCESS, payload }
}
export function fetchDfdeVesselInformationsFailed(
  payload: any
): IVesselDfdeFetchInformationsFailed {
  return { type: DashboardActions.FETCH_VESSEL_DFDE_INFORMATIONS_FAILED, payload }
}

export function updateManualEtaPeriod(payload: { manualEtaPeriod: any[] }): IUpdateManualETAPeriod {
  return { type: DashboardActions.UPDATE_MANUAL_ETA_PERIOD, payload }
}

export function updateCalculatedEtaPoints(payload: {
  calculatedEtaPoints: any[]
}): IUpdateCalculatedEtaPoints {
  return { type: DashboardActions.UPDATE_CALCULATED_ETA_POINTS, payload }
}

export function updateATATime(payload: {
  ecdis_points: ATATimeParams[]
  onSuccess?: () => void
}): IUpdateATATime {
  return { type: DashboardActions.UPDATE_ATA_TIME, payload }
}

export function updateATATimeSuccess(payload: any): IUpdateATATimeSuccess {
  return {
    type: DashboardActions.UPDATE_ATA_TIME_SUCCESS,
    payload,
  }
}

export function updateATATimeFailed(payload: any): IUpdateATATimeFailed {
  return {
    type: DashboardActions.UPDATE_ATA_TIME_FAILED,
    payload,
  }
}

export function showWeatherWaveHeightAction(payload: {
  checked: boolean
}): IWeatherShowWaveHeightAction {
  return { type: DashboardActions.SHOW_WEATHER_WAVE_HEIGHT, payload }
}

export function showWeatherPressurePatternAction(payload: {
  checked: boolean
}): IWeatherShowPressurePatternAction {
  return { type: DashboardActions.SHOW_WEATHER_PRESSURE_PATTERN, payload }
}

export function showWeatherTropicalStormAction(payload: {
  checked: boolean
}): IWeatherShowTropicalStormAction {
  return { type: DashboardActions.SHOW_WEATHER_TROPICAL_STORM, payload }
}

export function showWeatherTropicalStormTrackAction(payload: {
  checked: boolean
}): IWeatherShowTropicalStormTrackAction {
  return { type: DashboardActions.SHOW_WEATHER_TROPICAL_STORM_TRACK, payload }
}

export function showWeatherOcceanCurrentAction(payload: {
  checked: boolean
}): IWeatherShowOcceanCurrentAction {
  return { type: DashboardActions.SHOW_WEATHER_OCEAN_CURRENT, payload }
}

export function showWeatherWindAction(payload: { checked: boolean }): IWeatherShowWindAction {
  return { type: DashboardActions.SHOW_WEATHER_WIND, payload }
}

export function hideWeathers(): IHideWeathers {
  return { type: DashboardActions.HIDE_WEATHERS }
}

export function setWeatherDate(payload: { date: Date }): IWeatherSetDateAction {
  return { type: DashboardActions.SET_WEATHER_DATE, payload }
}

export function fetchPortAll(): IPortFetchAllAction {
  return { type: DashboardActions.FETCH_PORT_ALL }
}

export function fetchPortAllSuccess(payload: any): IPortFetchAllSuccessAction {
  return {
    type: DashboardActions.FETCH_PORT_ALL_SUCCESS,
    payload,
  }
}

export function fetchPortAllFinished(): IPortFetchAllFinishedAction {
  return {
    type: DashboardActions.FETCH_PORT_ALL_FINISHED,
  }
}

export function fetchPortAllFailed(payload: { error: string }): IPortFetchAllFailedAction {
  return {
    type: DashboardActions.FETCH_PORT_ALL_FAILED,
    payload,
  }
}

export function setShowChart(payload: { isShowChart: boolean }): ISetShowChart {
  return { type: DashboardActions.SHOW_CHART, payload }
}
export function setShowCargoMonitorChart(payload: {
  isShowCargoMonitorChart: boolean
}): ISetShowCargoMonitorChart {
  return { type: DashboardActions.SHOW_CHART_CARGO_MONITOR, payload }
}
export function setShowEngineMonitorChart(payload: {
  isShowEngineMonitorChart: boolean
}): ISetShowEngineMonitorChart {
  return { type: DashboardActions.SHOW_CHART_ENGINE_MONITOR, payload }
}

export function fetchStageBoilOffRateChartDataSuccess(
  payload: any
): IFetchStageBoilOffRateChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_STAGE_BOIL_OFF_RATE_DATA_SUCCESS, payload }
}

export function fetchStageTrend1ChartDataSuccess(payload: any): IFetchStageTrend1ChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_STAGE_TREND1_DATA_SUCCESS, payload }
}

export function fetchStageTrend1CargoChartDataSuccess(
  payload: any
): IFetchStageTrend1CargoChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_STAGE_TREND1_CARGO_DATA_SUCCESS, payload }
}

export function fetchStageTrend21ChartDataSuccess(
  payload: any
): IFetchStageTrend21ChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_STAGE_TREND21_DATA_SUCCESS, payload }
}

export function fetchStageTrend33ChartDataSuccess(
  payload: any
): IFetchStageTrend33ChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_STAGE_TREND33_DATA_SUCCESS, payload }
}

export function fetchStageEmBlrChartDataSuccess(payload: any): IFetchStageEmBlrChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_STAGE_EM_BLR_SUCCESS, payload }
}

export function fetchStageEmFdumpVOpenChartDataSuccess(
  payload: any
): IFetchStageEmFdumpVOpenChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_STAGE_EM_FDUMP_V_OPEN_SUCCESS, payload }
}

export function fetchStageTankEquatorChartDataSuccess(
  payload: any
): IFetchStageTankEquatorChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_STAGE_TANK_EQUATOR_DATA_SUCCESS, payload }
}

export function fetchStageTankVolumeChartDataSuccess(
  payload: any
): IFetchStageTankVolumeChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_STAGE_TANK_VOLUME_DATA_SUCCESS, payload }
}

export function fetchStageTankLiquidTempChartDataSuccess(
  payload: any
): IFetchStageTankLiquidTempChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_STAGE_TANK_LIQUID_TEMP_DATA_SUCCESS, payload }
}

export function fetchStagePressChartDataSuccess(payload: any): IFetchStagePressChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_STAGE_PRESS_DATA_SUCCESS, payload }
}

export function fetchXdfTrend1ChartDataSuccess(payload: any): IFetchXdfTrend1ChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_XDF_TREND1_DATA_SUCCESS, payload }
}

export function fetchXdfTrend315ChartDataSuccess(payload: any): IFetchXdfTrend315ChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_XDF_TREND315_DATA_SUCCESS, payload }
}

export function fetchOgSpeedChartDataSuccess(payload: any): IFetchOgSpeedChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_XDF_EM_OG_SPEED_DATA_SUCCESS, payload }
}

export function fetchXdfTrend316ChartDataSuccess(payload: any): IFetchXdfTrend316ChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_XDF_TREND316_DATA_SUCCESS, payload }
}
export function fetchXdfTrend317ChartDataSuccess(payload: any): IFetchXdfTrend317ChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_XDF_TREND317_DATA_SUCCESS, payload }
}

export function fetchXdfTrend21ChartDataSuccess(payload: any): IFetchXdfTrend21ChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_XDF_TREND21_DATA_SUCCESS, payload }
}

export function fetchXdfBoilOffRateChartDataSuccess(
  payload: any
): IFetchXdfBoilOffRateChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_XDF_BOIL_OFF_RATE_DATA_SUCCESS, payload }
}

export function fetchXdfInnerSurfaceTempChartDataSuccess(
  payload: any
): IFetchXdfInnerSurfaceTempChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_XDF_INNER_SURFACE_TEMP_DATA_SUCCESS, payload }
}

export function fetchXdfTankVolumeChartDataSuccess(
  payload: any
): IFetchXdfTankVolumeChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_XDF_TANK_VOLUME_DATA_SUCCESS, payload }
}

export function fetchXdfTankLiquidTempChartDataSuccess(
  payload: any
): IFetchXdfTankLiquidTempChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_XDF_TANK_LIQUID_TEMP_DATA_SUCCESS, payload }
}

export function fetchXdfPressChartDataSuccess(payload: any): IFetchXdfPressChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_XDF_PRESS_DATA_SUCCESS, payload }
}

export function fetchXdfCmTotalTankVolumeChartDataSuccess(
  payload: any
): IFetchXdfCmTotalTankVolumeChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_XDF_CM_TOTAL_TANK_VOLUME_SUCCESS, payload }
}

export function fetchStageCmTotalTankVolumeChartDataSuccess(
  payload: any
): IFetchStageCmTotalTankVolumeChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_STAGE_CM_TOTAL_TANK_VOLUME_SUCCESS, payload }
}

export function fetchStageCmPressHoldChartDataSuccess(
  payload: any
): IFetchStageCmPressHoldChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_STAGE_CM_PRESS_HOLD_SUCCESS, payload }
}

export function fetchStageCmBlrFlowChartDataSuccess(
  payload: any
): IFetchStageCmBlrFlowChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_STAGE_CM_BLR_FLOW_SUCCESS, payload }
}

export function fetchXdfFoc1ChartDataSuccess(payload: any): IFetchXdfFoc1ChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_XDF_FOC1_DATA_SUCCESS, payload }
}

export function fetchStageFoc1ChartDataSuccess(payload: any): IFetchStageFoc1ChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_STAGE_FOC1_DATA_SUCCESS, payload }
}

export function fetchStageTotalFocChartDataSuccess(
  payload: any
): IFetchStageTotalFocChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_STAGE_TOTAL_FOC_DATA_SUCCESS, payload }
}

export function fetchXdfMEChartDataSuccess(payload: any): IFetchXdfMEChartDataSuccess {
  return { type: DashboardActions.FETCH_CHART_XDF_ME_DATA_SUCCESS, payload }
}

export function setFromDate(payload: { fromDate: Date }): IWeatherSetFromDateAction {
  return { type: DashboardActions.SET_FROM_DATE, payload }
}

export function setToDate(payload: { toDate: Date }): IWeatherSetToDateAction {
  return { type: DashboardActions.SET_TO_DATE, payload }
}

export function showNoneTargetVessel(payload: { checked: boolean }): IVesselShowNoneTargetVessel {
  return { type: DashboardActions.SHOW_NONE_TARGET_VESSEL, payload }
}

export function showLabelNoneTargetVessel(payload: {
  checked: boolean
}): IVesselShowLabelNoneTargetVessel {
  return { type: DashboardActions.SHOW_LABEL_NONE_TARGET_VESSEL, payload }
}

export function setHistoryPointSelected(payload): IHistoryPointSelected {
  return { type: DashboardActions.SET_HISTORY_SELECTED, payload }
}

export function resetMonitoringInformation(): IResetMonitoringInformation {
  return { type: DashboardActions.RESET_MONITORING_INFORMATION }
}
export function fetchChartDFDEEngine(payload: ChartDataParams): IFetchChartDFDEEngine {
  return { type: DashboardActions.FETCH_CHART_DFDE_ENGINE, payload }
}

export function fetchChartDFDEEngineSuccess(payload: any): IFetchChartDFDEEngineSuccess {
  return { type: DashboardActions.FETCH_CHART_DFDE_ENGINE_SUCCESS, payload }
}

export function fetchChartDFDEEngineFailed(payload: {
  error: string
}): IFetchChartDFDEEngineFailed {
  return { type: DashboardActions.FETCH_CHART_DFDE_ENGINE_FAILED, payload }
}

export function fetchChartDFDECargo(payload: ChartDataParams): IFetchChartDFDECargo {
  return { type: DashboardActions.FETCH_CHART_DFDE_CARGO, payload }
}

export function fetchChartDFDECargoSuccess(payload: any): IFetchChartDFDECargoSuccess {
  return { type: DashboardActions.FETCH_CHART_DFDE_CARGO_SUCCESS, payload }
}

export function fetchChartDFDECargoFailed(payload: { error: string }): IFetchChartDFDECargoFailed {
  return { type: DashboardActions.FETCH_CHART_DFDE_CARGO_FAILED, payload }
}
export function fetchChartStageCargo(payload: ChartDataParams): IFetchChartStageCargo {
  return { type: DashboardActions.FETCH_CHART_STAGE_CARGO, payload }
}

export function fetchChartStageCargoSuccess(payload: any): IFetchChartStageCargoSuccess {
  return { type: DashboardActions.FETCH_CHART_STAGE_CARGO_SUCCESS, payload }
}

export function fetchChartStageCargoFailed(payload: {
  error: string
}): IFetchChartStageCargoFailed {
  return { type: DashboardActions.FETCH_CHART_STAGE_CARGO_FAILED, payload }
}

export function fetchChartStageEngine(payload: ChartDataParams): IFetchChartStageEngine {
  return { type: DashboardActions.FETCH_CHART_STAGE_ENGINE, payload }
}

export function fetchChartStageEngineSuccess(payload: any): IFetchChartStageEngineSuccess {
  return { type: DashboardActions.FETCH_CHART_STAGE_ENGINE_SUCCESS, payload }
}

export function fetchChartStageEngineFailed(payload: {
  error: string
}): IFetchChartStageEngineFailed {
  return { type: DashboardActions.FETCH_CHART_STAGE_ENGINE_FAILED, payload }
}

export function fetchChartXDFCargo(payload: ChartDataParams): IFetchChartXDFCargo {
  return { type: DashboardActions.FETCH_CHART_XDF_CARGO, payload }
}

export function fetchChartXDFCargoSuccess(payload: any): IFetchChartXDFCargoSuccess {
  return { type: DashboardActions.FETCH_CHART_XDF_CARGO_SUCCESS, payload }
}

export function fetchChartXDFCargoFailed(payload: { error: string }): IFetchChartXDFCargoFailed {
  return { type: DashboardActions.FETCH_CHART_XDF_CARGO_FAILED, payload }
}

export function fetchChartXDFEngine(payload: ChartDataParams): IFetchChartXDFEngine {
  return { type: DashboardActions.FETCH_CHART_XDF_ENGINE, payload }
}

export function fetchChartXDFEngineSuccess(payload: any): IFetchChartXDFEngineSuccess {
  return { type: DashboardActions.FETCH_CHART_XDF_ENGINE_SUCCESS, payload }
}

export function fetchChartXDFEngineFailed(payload: { error: string }): IFetchChartXDFEngineFailed {
  return { type: DashboardActions.FETCH_CHART_XDF_ENGINE_FAILED, payload }
}
