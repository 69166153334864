import { produce } from "immer"
import { stringify } from "qs"
import Axios from "services/_base/api"
import { API_NAME } from "core/model/Api"
import VoyageComparisonChart from "core/model/Chart/Bar/comparison"
import { IFilterVoyagesComparison, IVoyagesNo } from "./types"

class VoyagesComparisonServices {
  public async fetchTargetVessels(params) {
    const response = await Axios.get(`${API_NAME.heel_edq.get.ais_v1_vessels}?${stringify(params)}`)
    const data = {
      data: {
        data: response.data.data.map((record) => {
          const attributes = produce(record.attributes, (draft) => {
            draft.properties = { ...draft, ...draft.properties }
          })
          return attributes
        }),
      },
      headers: response.headers,
    }
    return data
  }

  public async fetchDeparturePorts() {
    const response = await Axios.get(
      `${API_NAME.voyage_comparison.get.analytic_v1_voyages_seasonal_voyage_summaries_fetch_departure_ports}`
    )
    return response.data
  }

  public async fetchArrivalPorts() {
    const response = await Axios.get(
      `${API_NAME.voyage_comparison.get.analytic_v1_voyages_seasonal_voyage_summaries_fetch_arrival_ports}`
    )
    return response.data
  }

  public async fetchVoyagesComparison(params) {
    const response = await Axios.get(
      `${
        API_NAME.voyage_comparison.get.analytic_v1_voyages_seasonal_voyage_summaries
      }?${stringify(params, { arrayFormat: "comma", encode: false })}`,
      params
    )

    const filter_voyages: IFilterVoyagesComparison[] = response.data.filter_voyages?.data
      ? response.data.filter_voyages.data
      : []

    const voyages_chart = response.data.voyages_chart?.data
      ? new VoyageComparisonChart(response.data.voyages_chart.data.attributes, params.color)
      : null

    const voyages_no: IVoyagesNo[] = []
    filter_voyages.forEach((record) => {
      const { attributes } = record
      // if (voyage.imo === attributes.imo) voyage.voyages_no.push(attributes.voyage_no)
      const hasImo = voyages_no.filter((record) => record.imo === attributes.imo)[0]
      if (voyages_no.length > 0 && hasImo) {
        hasImo.data.push(attributes.voyage_no)
      } else
        voyages_no.push({
          imo: attributes.imo,
          vessel_name: attributes.vessel_name,
          data: [attributes.voyage_no],
        })
    })

    return { filter_voyages, voyages_chart, voyages_no }
  }

  public async fetchVoyageNumber(params) {
    const response = await Axios.get(
      `${
        API_NAME.voyage_comparison.get.analytic_v1_voyages_seasonal_voyage_summaries
      }/fetch_voyage_numbers?${stringify(params, { arrayFormat: "comma", encode: false })}`,
      params
    )
    const data = {
      data: {
        data: response.data.data.map((record) => {
          return record.attributes
        }),
      },
    }

    return data
  }

  public async fetchVoyagesHistoryRoutes(params: any) {
    const data = await Axios.get(
      `${API_NAME.dashboard.get.ais_v1_trackings}?${stringify(params, {
        arrayFormat: "brackets",
        encode: false,
      })}`
    )
    return data
  }
}

export default new VoyagesComparisonServices()
