import genActions from "services/_base/actions"
import {
  ISignInAction,
  ISignInActionSuccess,
  ISignInActionFailed,
  ISignOutAction,
  ISignOutActionSuccess,
  ISignOutActionFailed,
  IGetCurrentUserAction,
  IGetCurrentUserActionSuccess,
  IGetCurrentUserActionFailed,
  IRefreshToken,
  IRefreshTokenSuccess,
  IRefreshTokenFailed,
  IRefreshTokenInvalided,
  AuthActions,
  IListCompanyFetchAll,
  IListCompanyFetchAllSuccess,
  IListCompanyFetchAllFinished,
  IListCompanyFetchAllFailed,
  ISetAuthCompanyId,
} from "./types"

export const { funcs: BaseFunctions, types: BaseActions } = genActions("auth")

export function signIn(payload: any): ISignInAction {
  return { type: AuthActions.SIGNIN, payload }
}

export function signInSuccess(): ISignInActionSuccess {
  return { type: AuthActions.SIGNIN_SUCCESS }
}

export function signInFailed(payload: any): ISignInActionFailed {
  return { type: AuthActions.SIGNIN_FAILED, payload }
}

export function signOut(payload: any): ISignOutAction {
  return { type: AuthActions.SIGNOUT, payload }
}

export function signOutSuccess(): ISignOutActionSuccess {
  return { type: AuthActions.SIGNOUT_SUCCESS }
}

export function signOutFailed(payload: any): ISignOutActionFailed {
  return { type: AuthActions.SIGNOUT_FAILED, payload }
}

export function getCurrentUser(payload: any): IGetCurrentUserAction {
  return { type: AuthActions.GET_CURRENT_USER, payload }
}

export function getCurrentUserSuccess(payload: any): IGetCurrentUserActionSuccess {
  return { type: AuthActions.GET_CURRENT_USER_SUCCESS, payload }
}

export function getCurrentUserFailed(payload: any): IGetCurrentUserActionFailed {
  return { type: AuthActions.GET_CURRENT_USER_FAILED, payload }
}

export function refreshToken(payload: any): IRefreshToken {
  return { type: AuthActions.REFRESH_TOKEN, payload }
}

export function refreshTokenSuccess(): IRefreshTokenSuccess {
  return { type: AuthActions.REFRESH_TOKEN_SUCCESSS }
}

export function refreshTokenFailed(payload: any): IRefreshTokenFailed {
  return { type: AuthActions.REFRESH_TOKEN_FAILED, payload }
}
export function refreshTokenInvalided(): IRefreshTokenInvalided {
  return { type: AuthActions.REFRESH_TOKEN_INVALIDED }
}

export function fetchListCompany(): IListCompanyFetchAll {
  return {
    type: AuthActions.FETCH_LIST_COMPANY_ALL,
  }
}

export function fetchListCompanySuccess(payload: any): IListCompanyFetchAllSuccess {
  return {
    type: AuthActions.FETCH_LIST_COMPANY_ALL_SUCCESS,
    payload,
  }
}

export function fetchListCompanyFinished(): IListCompanyFetchAllFinished {
  return {
    type: AuthActions.FETCH_LIST_COMPANY_ALL_FINISHED,
  }
}

export function fetchListCompanyFailed(payload: { error: string }): IListCompanyFetchAllFailed {
  return {
    type: AuthActions.FETCH_LIST_COMPANY_ALL_FAILED,
    payload,
  }
}

export function setAuthCompanyId(payload: any): ISetAuthCompanyId {
  return {
    type: AuthActions.SET_AUTH_COMPANY_ID,
    payload,
  }
}
