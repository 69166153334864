import { Popover } from "antd"
import { TooltipPlacement } from "antd/lib/tooltip"
import { useState } from "react"

interface GuardianPopoverTooltip {
  title: React.ReactNode | string
  content: any
  trigger: string
  children: any
  placement?: TooltipPlacement
  hoverContent?: string
  hoverTitle?: string
  hoverClassName?: string
  visibleClassName?: string
  isVisible: boolean
  setIsVisible: (value) => void
  onClosed?: () => void
}

const Index = ({
  title,
  content,
  trigger,
  children,
  placement,
  hoverContent,
  hoverTitle,
  hoverClassName,
  visibleClassName,
  isVisible,
  setIsVisible,
  onClosed,
}: GuardianPopoverTooltip) => {
  const [isHover, setIsHover] = useState(false)

  const onChangeHover = () => !isVisible && setIsHover(!isHover)

  return (
    <>
      <Popover
        content={hoverContent}
        title={hoverTitle}
        trigger="hover"
        visible={isHover}
        onVisibleChange={onChangeHover}
        placement={placement}
        overlayClassName={hoverClassName}
        arrowPointAtCenter
      >
        <Popover
          placement={placement}
          content={content}
          title={title}
          trigger={trigger}
          visible={isVisible}
          overlayClassName={visibleClassName}
          onVisibleChange={(visible) => {
            setIsHover(false)
            setIsVisible(!isVisible)
            if (!isVisible) {
              onClosed()
            }
          }}
          arrowPointAtCenter
        >
          {children}
        </Popover>
      </Popover>
    </>
  )
}
Index.defaultProps = {
  placement: "top",
  hoverTitle: "",
  hoverContent: "",
  hoverClassName: "tooltip-primary",
  visibleClassName: "",
  onClosed: () => null,
}
export default Index
