import { produce } from "immer"
import { stringify } from "qs"
import Axios from "services/_base/api"
import { API_NAME } from "core/model/Api"
import { clone } from "lodash"

class VesselsManagementServices {
  public async fetchAll(params: any) {
    const { company_id } = params
    const newParams = clone(params)
    delete newParams.company_id
    const response = await Axios.get(
      `${API_NAME.vesselManagement.get.ais_v1_vessels}?${stringify(newParams)}`,
      null,
      null,
      company_id
    )
    const data = {
      data: {
        data: response.data.data.map((record) => {
          const attributes = produce(record.attributes, (draft) => {
            draft.properties = { ...draft, ...draft.properties }
          })
          return attributes
        }),
      },
      headers: response.headers,
    }
    return data
  }

  public async deleteVessel(params: any) {
    await Axios.delete(
      `${API_NAME.vesselManagement.delete.ais_v1_vessels}/${params.id}?job_id=${params.job_id}`,
      {
        headers: params.headers,
      }
    )
  }

  public async editVessel(params: any) {
    const response = await Axios.put(
      `${API_NAME.vesselManagement.put.ais_v1_vessels}/${params.id}`,
      params
    )
    const data = {
      data: response.data.attributes,
      headers: response.headers,
    }
    return data
  }

  public async createVessel(params: any) {
    const { company_id } = params
    const response = await Axios.post(
      `${API_NAME.vesselManagement.post.ais_v1_vessels}`,
      params,
      company_id
    )
    const data = {
      data: response.data.data.attributes,
      headers: response.headers,
    }
    return data
  }

  public async createNonTargetVessel(params: any) {
    const { company_id } = params
    await Axios.post(
      `${API_NAME.vesselManagement.post.ais_v1_vessels_non_targets}`,
      params,
      company_id
    )
  }

  public async deleteNonTargetVessel(params: any) {
    await Axios.delete(`${API_NAME.vesselManagement.delete.ais_v1_vessels}/${params.vessel_id}`, {
      headers: params.headers,
    })
  }

  public async exportGenreMappingApi(params: any) {
    const url = `${API_NAME.vesselManagement.get.analytic_v1_export_genre_maping}`
    const response = await Axios.get(url.replace("id", params.vessel_id))
    const data = {
      data: {
        data: response.data.url,
      },
    }
    return data
  }

  public async exportGenreErrorApi(params: any) {
    const url = `${API_NAME.vesselManagement.get.analytic_v1_export_genre_error}`
    const response = await Axios.get(url.replace("id", params.vessel_id))
    const data = {
      data: {
        data: response.data.url,
      },
    }
    return data
  }

  public async importGenreApi(params: any) {
    const url = `${API_NAME.vesselManagement.post.ais_v1_vessels_import_genre}`
    const response = await Axios.post(url.replace("id", params.vessel_id), params.data)
    const data = {
      data: response.data.id,
      headers: response.headers,
    }
    return data
  }

  public async uploadFile(params: any) {
    const formData = new FormData()
    formData.append("file", params.file)
    const response = await Axios.post(`${API_NAME.file.post.analytic_v1_upload_file}`, formData)
    const data = {
      data: response.data,
      headers: response.headers,
    }
    return data
  }

  public async fetchSimsDataType(params: any) {
    const response = await Axios.get(
      `${API_NAME.vesselManagement.get.ais_v1_sims_data_type}?${stringify(params)}`
    )
    const data = {
      data: {
        data: response.data,
      },
    }
    return data
  }

  public async fetchCompanies() {
    const response = await Axios.get(
      `${API_NAME.vesselManagement.get.identity_v1_fetch_list_companies}`
    )
    return response
  }
}

export default new VesselsManagementServices()
