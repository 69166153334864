import { includes, round } from "lodash"

const EMPTY_VALUES = ["", null, undefined]

export const isEmptyValue = (val) => {
  let newValue = val
  if (typeof newValue === "string" || newValue instanceof String) {
    newValue = newValue.trim()
  }

  if (includes(EMPTY_VALUES, newValue)) return true
  return false
}

export const unifyValue = (value, roundOption = 1) => {
  if (isEmptyValue(value)) return null

  return round(value, roundOption)
}

export const unifyValueData = (value, roundOption = 1) => {
  if (isEmptyValue(value)) return "-"

  return round(value, roundOption)
}
