import { Slider } from "antd"
import { SliderMarks } from "antd/lib/slider"

const maxShow = 20

interface SliderProps {
  className: string
  onSelectWeatherSlider: (e: number) => void
  max: number
  defaultValue: number
  step: number
  marks: SliderMarks
  value: number
  tipFormatter: (e: number | undefined) => string
}

const Index = ({
  className,
  onSelectWeatherSlider,
  max,
  defaultValue,
  step,
  marks,
  value,
  tipFormatter,
}: SliderProps) => {
  const isOver = max / 24 > maxShow
  const style = { width: isOver ? `${(max / 24 / maxShow) * 100}%` : "100%" }

  return (
    <div className={`slider-wrp ${isOver && "scroll-x"}`}>
      <Slider
        style={style}
        range={false}
        marks={marks}
        step={step}
        defaultValue={defaultValue}
        max={max}
        className={className}
        value={value}
        onChange={onSelectWeatherSlider}
        tipFormatter={tipFormatter}
      />
    </div>
  )
}

export default Index
