import { Space, Row, Col, DatePicker, Radio, Button, Checkbox } from "antd"

import GuardianPopoverTooltip from "components/GuardianPopoverTooltip"
import GuardianSlider from "components/GuardianSlider"
import SliderSettings from "components/SliderSettings"
import GuardianTooltip from "components/GuardianTooltip"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { HistoricalSelector } from "services/historical/selectors"
import { DashboardSelector } from "services/dashboard/selectors"
import { useInjectReducer } from "core/hooks/useInjectReducer"
import dashboardReducer from "services/dashboard/reducers"
import { createMarks } from "core/model/DateTime"
import moment from "moment"
import {
  setDurationCurrentDate,
  setDurationSetting,
  showWeatherWaveHeight,
  showWeatherOceanCurrent,
  showWeatherSurfacePressure,
  showWeatherGlobalStorm,
  showWeatherWindBarbs,
  setSelectedVesselId,
} from "services/historical/actions"
import { WEATHER_KEY } from "core/constants/weather"
import { setSelectedPoint, fetchVesselAll, resetVesselList } from "services/dashboard/actions"
import { debounce } from "lodash"
import VesselsManagementServices from "services/vesselsManagement/api"
import { WeatherElements } from "core/model/Weather"
import { HistoricalActionTypes } from "services/historical/types"
import { CloudOutlined } from "@ant-design/icons"
import CargoShip from "components/Icons/CargoShip"
import historicalReducer from "services/historical/reducers"
import { setPinHeaderAction } from "services/layout/actions"

const DEFAULT_WEEK_HOURS = 168 // hours
const MAX_WEEK_HOURS = 360 // hours
const MAX_START_END_DATE = 90 // days
const defaultFromDate = moment.utc().subtract(14, "d").startOf("d")

const Index = () => {
  useInjectReducer({ key: "dashboard", reducer: dashboardReducer })
  useInjectReducer({ key: "historical", reducer: historicalReducer })

  const [sliderMarks, setSliderMarks] = useState(createMarks(360 + 24, defaultFromDate.toDate()))
  const [sliderValue, setSliderValue] = useState(0)
  const [currentHourValue, setCurrentHourValue] = useState(0)
  const [maximumHoursValue, setMaximumHoursValue] = useState(MAX_WEEK_HOURS)
  const [mounted, setMounted] = useState(false)
  const [isCustomSlider, setIsCustomSlider] = useState(false)
  const [fromDateValue, setFromDateValue] = useState(defaultFromDate)
  const [vesselVisible, setVesselVisible] = useState(false)
  const [sliderVisible, setSliderVisible] = useState(false)
  const [popupVisible, setPopupVisible] = useState(false)
  const [vesselIdSelectValue, setVesselIdSelectValue] = useState(null)
  const [isShowSlider, setIsShowSlider] = useState(false)
  const [vessels, setVessels] = useState([])
  const [disabledFutureSlider, setDisabledFutureSlider] = useState(true)
  const [disabledWeathers, setDisabledWeathers] = useState(true)
  const {
    vessel: {
      selectedVessel: { selectedPoint },
    },
  } = useSelector(DashboardSelector)
  const {
    durationSettings,
    weathers: { weathersChecked },
  } = useSelector(HistoricalSelector)
  const onSelectWeatherHistoryType = (action: HistoricalActionTypes) => {
    dispatch(action)
  }
  const dispatch = useDispatch()

  useEffect(() => {
    setMounted(true)
    fetchVessels()
    return () => {
      setMounted(false)
      dispatch(resetVesselList())
    }
  }, [])

  useEffect(() => {
    setDisabledWeathers(weathersChecked.length > 2)
  }, [weathersChecked])

  useEffect(() => {
    if (selectedPoint && mounted) {
      const selectedTime = moment.utc(selectedPoint.dateTime)
      const selectedPointDate = moment.utc(selectedPoint.dateTime)
      const duration = moment.duration(fromDateValue.diff(selectedTime))
      const durationHours = Math.abs(duration.asHours())
      setCurrentHourValue(durationHours)
      setSliderValue(durationHours)
      if (moment.utc(durationSettings.currentDate).diff(moment.utc(selectedPointDate))) {
        dispatch(setDurationCurrentDate({ date: selectedPointDate.toDate() }))
      }
    }
  }, [selectedPoint])

  const fetchVessels = () => {
    VesselsManagementServices.fetchAll({
      page: 1,
      target: true,
      sort_by: "name",
      sort_order: "asc",
    }).then((res) => {
      setVessels(res.data.data)
    })
  }
  const onSelectVessel = (e) => {
    setVesselIdSelectValue(e.target.value)
  }

  const vesselOptions = () => (
    <>
      <Radio.Group onChange={onSelectVessel} value={vesselIdSelectValue}>
        <Space direction="vertical">
          {vessels.map((el) => (
            <Radio value={el.id}>{el.name}</Radio>
          ))}
        </Space>
      </Radio.Group>
    </>
  )

  const onSelectDate = (startDate, endDate) => {
    const fromDate = moment.utc(startDate).startOf("d")
    const toDate = moment.utc(endDate).endOf("d")
    const duration = moment.duration(fromDate.diff(toDate))
    const durationHours = Math.abs(duration.asHours())

    const markHoursValue = durationHours + 24

    setFromDateValue(fromDate)
    setSliderValue(0)
    setCurrentHourValue(0)
    setMaximumHoursValue(durationHours)
    setSliderMarks(createMarks(markHoursValue, moment.utc(startDate).toDate()))
    setIsCustomSlider(true)

    dispatch(
      setDurationSetting({
        fromDate: fromDate.toDate(),
        toDate: toDate.toDate(),
        currentDate: fromDate.toDate(),
      })
    )

    const selectedValue = {
      dateTime: fromDate.format(),
      imo: selectedPoint?.imo,
      vessel_id: selectedPoint?.vessel_id,
    }
    dispatch(fetchVesselAll())
    dispatch(setSelectedPoint(selectedValue))
  }

  const onSelectSlider = (hours: number) => {
    setSliderValue(hours)
    const date = moment.utc(fromDateValue).add(hours, "hours")
    dispatch(setDurationCurrentDate({ date: date.toDate() }))
    setIsCustomSlider(true)
  }

  const tipFormatter = (value: number | undefined) => {
    if (value !== undefined) {
      return `${value % 24}:00`
    }
    return ""
  }

  const onClickShow = () => {
    dispatch(setSelectedVesselId({ vessel_id: vesselIdSelectValue }))
    setIsShowSlider(true)
  }

  const weatherCheckbox = () => (
    <>
      <Checkbox
        className="marginLeft0"
        onChange={(e) => {
          return onSelectWeatherHistoryType(showWeatherOceanCurrent({ checked: e.target.checked }))
        }}
        disabled={disabledWeathers && weathersChecked.indexOf(WEATHER_KEY.OCEAN_CURRENT) < 0}
      >
        {WeatherElements.OceanCurrent}
      </Checkbox>
      <Checkbox
        className="marginLeft0"
        onChange={(e) => {
          return onSelectWeatherHistoryType(
            showWeatherSurfacePressure({ checked: e.target.checked })
          )
        }}
        disabled={disabledWeathers && weathersChecked.indexOf(WEATHER_KEY.SURFACE_PRESSURE) < 0}
      >
        {WeatherElements.SurfacePressure}
      </Checkbox>
      <Checkbox
        className="marginLeft0"
        onChange={(e) => {
          return onSelectWeatherHistoryType(showWeatherGlobalStorm({ checked: e.target.checked }))
        }}
        disabled={disabledWeathers && weathersChecked.indexOf(WEATHER_KEY.GLOBAL_STORM) < 0}
      >
        {WeatherElements.TropicalStorm}
      </Checkbox>
      <Checkbox
        className="marginLeft0"
        onChange={(e) => {
          return onSelectWeatherHistoryType(showWeatherWaveHeight({ checked: e.target.checked }))
        }}
        disabled={disabledWeathers && weathersChecked.indexOf(WEATHER_KEY.WAVE_HEIGHT) < 0}
      >
        {WeatherElements.WaveHeight}
      </Checkbox>
      <Checkbox
        className="marginLeft0"
        onChange={(e) => {
          return onSelectWeatherHistoryType(showWeatherWindBarbs({ checked: e.target.checked }))
        }}
        disabled={disabledWeathers && weathersChecked.indexOf(WEATHER_KEY.WIND_BARB) < 0}
      >
        {WeatherElements.Wind}
      </Checkbox>
    </>
  )

  useEffect(() => {
    dispatch(setPinHeaderAction({ isPinHeader: popupVisible || vesselVisible || sliderVisible }))
  }, [popupVisible, vesselVisible, sliderVisible])

  return (
    <>
      <Row gutter={24}>
        <Col flex="231px">
          <Row gutter={16}>
            <Col>
              {!isShowSlider ? (
                <GuardianTooltip
                  title="Weather Types"
                  className="header-icon__location line-height-32"
                  color="#14639f"
                >
                  <Button
                    shape="circle"
                    disabled={!isShowSlider}
                    className="get-current"
                    icon={<CloudOutlined />}
                  />
                </GuardianTooltip>
              ) : (
                <GuardianPopoverTooltip
                  title=""
                  placement="topLeft"
                  hoverContent="Weather Types"
                  content={weatherCheckbox}
                  trigger="click"
                  isVisible={popupVisible}
                  setIsVisible={setPopupVisible}
                  visibleClassName="weather-type__popover"
                >
                  <Button
                    shape="circle"
                    disabled={!isShowSlider}
                    className="get-current"
                    icon={<CloudOutlined />}
                  />
                </GuardianPopoverTooltip>
              )}
            </Col>
            <Col>
              <GuardianPopoverTooltip
                title=""
                placement="topLeft"
                hoverContent="Target LNG vessels"
                content={vesselOptions}
                trigger="click"
                isVisible={vesselVisible}
                setIsVisible={setVesselVisible}
              >
                <Button
                  shape="circle"
                  className="get-current"
                  icon={<CargoShip name="cargo-ship-header" />}
                />
              </GuardianPopoverTooltip>
            </Col>
            <Col>
              <SliderSettings
                onSelectDate={onSelectDate}
                hoverContent="Slide bar duration"
                maxDate={MAX_START_END_DATE}
                minDate={0}
                disabledFuture={disabledFutureSlider}
                setPopupVisibleProps={setSliderVisible}
              />
            </Col>
            <Col style={{ maxWidth: "77px" }}>
              <Button
                type="primary"
                onClick={onClickShow}
                disabled={!vesselIdSelectValue || !isCustomSlider}
              >
                Show
              </Button>
            </Col>
          </Row>
        </Col>
        <Col flex="auto">
          {isShowSlider && (
            <GuardianSlider
              marks={sliderMarks}
              onSelectWeatherSlider={debounce(onSelectSlider, 150)}
              value={sliderValue}
              step={1}
              defaultValue={currentHourValue}
              max={maximumHoursValue}
              className="WeatherSettingSlider"
              tipFormatter={tipFormatter}
            />
          )}
        </Col>
      </Row>
    </>
  )
}

Index.defaultProps = {
  hasBack: false,
}

export default Index
