import { unifyValue } from "core/utils/unifyValueChartData"
import { format } from "date-fns"
import { MMM_dd_yyyy, createDateWihoutTimeZone } from "../../DateTime"

const TANK_TEMP_KEY = "no."
const TANK_TEMP_LABEL = "No."

export default class StageTankTemp {
  name: string

  timestamp: string

  selected_voyage_no: string

  vessel_name: string

  keys: any

  props: any

  constructor(data) {
    this.name = data.view_day
    this.selected_voyage_no = data.selected_voyage_no
    this.vessel_name = data.vessel_name
    const keys_tmp = {}
    Object.keys(data.all_voyages).map((voyage) => {
      keys_tmp[`${voyage}__${TANK_TEMP_KEY}1`] = `${voyage}__${TANK_TEMP_KEY}1`
      keys_tmp[`${voyage}__${TANK_TEMP_KEY}2`] = `${voyage}__${TANK_TEMP_KEY}2`
      keys_tmp[`${voyage}__${TANK_TEMP_KEY}3`] = `${voyage}__${TANK_TEMP_KEY}3`
      keys_tmp[`${voyage}__${TANK_TEMP_KEY}4`] = `${voyage}__${TANK_TEMP_KEY}4`
      return null
    })
    this.keys = keys_tmp
    const props_temp = []
    data.voyage_props.map((item) => {
      props_temp.push({
        key: `${item.key}__${TANK_TEMP_KEY}1`,
        label: `${item.key} ${TANK_TEMP_LABEL}1`,
      })
      props_temp.push({
        key: `${item.key}__${TANK_TEMP_KEY}2`,
        label: `${item.key} ${TANK_TEMP_LABEL}2`,
      })
      props_temp.push({
        key: `${item.key}__${TANK_TEMP_KEY}3`,
        label: `${item.key} ${TANK_TEMP_LABEL}3`,
      })
      props_temp.push({
        key: `${item.key}__${TANK_TEMP_KEY}4`,
        label: `${item.key} ${TANK_TEMP_LABEL}4`,
      })
      return null
    })
    this.props = props_temp

    data.avg_cargotk1.map((item) => {
      this[`${item.voyage_no}__${TANK_TEMP_KEY}1`] = unifyValue(item.value, 1)
      this[`${item.voyage_no}__${TANK_TEMP_KEY}1_timestamp`] = format(
        createDateWihoutTimeZone(item.date),
        MMM_dd_yyyy
      )
      return null
    })
    data.avg_cargotk2.map((item) => {
      this[`${item.voyage_no}__${TANK_TEMP_KEY}2`] = unifyValue(item.value, 1)
      this[`${item.voyage_no}__${TANK_TEMP_KEY}2_timestamp`] = format(
        createDateWihoutTimeZone(item.date),
        MMM_dd_yyyy
      )
      return null
    })
    data.avg_cargotk3.map((item) => {
      this[`${item.voyage_no}__${TANK_TEMP_KEY}3`] = unifyValue(item.value, 1)
      this[`${item.voyage_no}__${TANK_TEMP_KEY}3_timestamp`] = format(
        createDateWihoutTimeZone(item.date),
        MMM_dd_yyyy
      )
      return null
    })
    data.avg_cargotk4.map((item) => {
      this[`${item.voyage_no}__${TANK_TEMP_KEY}4`] = unifyValue(item.value, 1)
      this[`${item.voyage_no}__${TANK_TEMP_KEY}4_timestamp`] = format(
        createDateWihoutTimeZone(item.date),
        MMM_dd_yyyy
      )
      return null
    })
  }
}
