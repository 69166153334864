import { Button } from "antd"
import GuardianTimePicker from "components/GuardianTimePicker"
import GuardianPopoverTooltip from "components/GuardianPopoverTooltip"
import { CalendarOutlined } from "@ant-design/icons"
import { useState, useEffect } from "react"
import moment from "moment"

const MAX_START_END_DATE = 120 // 120 days

const text = <span>Select Start Date and End Date</span>
const content = (
  onPickerChange,
  onConfirmed,
  onCancel,
  maxStartDate,
  onCalendarChange,
  onOpenChange,
  value,
  hackValue
) => (
  <div className="slider-time-picker-wrp">
    <GuardianTimePicker
      allowClear={false}
      onDateChange={onPickerChange}
      className="slider-time-picker"
      size="middle"
      showTime={false}
      disabledDate={maxStartDate}
      onCalendarChange={onCalendarChange}
      onOpenChange={onOpenChange}
      value={value}
      hackValue={hackValue}
    />
    <div>
      <Button onClick={onConfirmed} disabled={value.length === 0} type="primary">
        OK
      </Button>
      <Button onClick={onCancel}>Cancel</Button>
    </div>
  </div>
)

const Index = ({
  onSelectDate,
  placement,
  hoverContent,
  maxDate,
  minDate,
  disabledFuture,
  setPopupVisibleProps,
}) => {
  const [popupVisible, setPopupVisible] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [dates, setDates] = useState([])
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [latestConfirmedValue, setLatestConfirmedValue] = useState([])
  const [hackValue, setHackValue] = useState(undefined)
  const [value, setValue] = useState([])
  const maxStartDate = (current) => {
    if (disabledFuture && current.toDate() >= new Date()) {
      return true
    }

    if (!dates || dates.length === 0) {
      return false
    }

    const tooLate = dates[0] && current.diff(dates[0], "days") > maxDate
    const tooEarly = dates[1] && dates[1].diff(current, "days") > maxDate
    return tooEarly || tooLate
  }

  const onPickerChange = (dateStrings) => {
    setIsConfirmed(false)
    if (dateStrings[0] === "") {
      setStartDate(null)
      setEndDate(null)
      setValue([])
    } else {
      setStartDate(dateStrings[0])
      setEndDate(dateStrings[1])
      setValue([moment.utc(dateStrings[0]), moment.utc(dateStrings[1])])
    }
  }

  const onCalendar = (val) => {
    setDates(val)
  }

  const onConfirmed = () => {
    setIsConfirmed(true)
    setLatestConfirmedValue(value)
    onSelectDate(startDate, endDate)
    setPopupVisible(false)
  }

  const onCancel = () => {
    resetValueWhenUnconfirmed()
    setPopupVisible(false)
  }

  const resetValueWhenUnconfirmed = () => {
    if (!isConfirmed) {
      setValue(latestConfirmedValue)
      setStartDate(latestConfirmedValue[0])
      setEndDate(latestConfirmedValue[1])
    }
  }

  const onOpenChange = (open) => {
    if (open) {
      setDates([])
      setHackValue([])
    } else {
      setHackValue(undefined)
    }
  }

  useEffect(() => {
    setPopupVisibleProps(popupVisible)
  }, [popupVisible])

  return (
    <>
      <GuardianPopoverTooltip
        title={text}
        placement={placement}
        hoverContent={hoverContent}
        trigger="click"
        onClosed={resetValueWhenUnconfirmed}
        content={() => {
          return content(
            onPickerChange,
            onConfirmed,
            onCancel,
            maxStartDate,
            onCalendar,
            onOpenChange,
            value,
            hackValue
          )
        }}
        isVisible={popupVisible}
        setIsVisible={setPopupVisible}
      >
        <Button shape="circle" icon={<CalendarOutlined />} />
      </GuardianPopoverTooltip>
    </>
  )
}
Index.defaultProps = {
  placement: "bottom",
  hoverContent: "",
  maxDate: MAX_START_END_DATE + 1,
  minDate: MAX_START_END_DATE + 1,
  disabledFuture: false,
}
export default Index
