import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import translationEN from "core/locales/en.json"
import translationJP from "core/locales/jp.json"

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEN },
    jp: { translation: translationJP },
  },
  lng: "en",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
})

export default i18n
