import { Spin } from "antd"

export interface LoaderProps {
  classCustom?: string
  size?: "default" | "small" | "large"
}

const Loader = ({ classCustom = null, size = "default" }: LoaderProps) => {
  return (
    <div className={`loader-container ${classCustom}`}>
      <Spin size={size} />
    </div>
  )
}

export default Loader
