import axios from "services/_base/_axios"
import Axios from "services/_base/api"
import { API_NAME } from "../../core/model/Api"

class AuthServices {
  private path = "auth"

  public async getCurrentUser() {
    const { data } = await Axios.get(`${API_NAME.auth.get.identity_v1_me}`)

    return data
  }

  public async signIn(params: Record<"email" | "password", string>) {
    const { email, password } = params
    return axios.post(
      `${API_NAME.auth.post.identity_v1_auth}`,
      {
        email,
        password,
      },
      { withCredentials: true }
    )
  }

  public async refreshToken() {
    const response = await axios.post(`${API_NAME.auth.post.identity_v1_refresh}`)
    return response
  }

  public async signOut() {
    return axios.post(`${API_NAME.auth.post.identity_v1_logout}`)
  }

  public async fetchCompanies(params) {
    const response = await Axios.get(
      `${API_NAME.auth.get.identity_v1_fetch_list_companies}`,
      params
    )
    return response
  }
}

export default new AuthServices()
