import { notification } from "antd"
import { ArgsProps as AntdArgsProps, ConfigProps } from "antd/es/notification"
import { cloneDeep } from "lodash"
import i18n from "core/lib/i18n"

type ArgsProps = Omit<AntdArgsProps, "message"> & {
  message?: React.ReactNode
}

interface NotificationInstance {
  success(args: ArgsProps): void
  error(args: ArgsProps): void
  info(args: ArgsProps): void
  warning(args: ArgsProps): void
  open(args: ArgsProps): void
}

interface NotificationApi extends NotificationInstance {
  warn(args: ArgsProps): void
  close(key: string): void
  config(options: ConfigProps): void
  destroy(): void
  useNotification: () => [NotificationInstance, React.ReactElement]
}

// Default config
const DefaultConfig = { duration: 10 }

export const FacenNotification: NotificationApi = cloneDeep(notification)

FacenNotification.success = (args: ArgsProps) => {
  notification.destroy()

  return notification.success({
    message: i18n.t("notification.title.success"),
    ...DefaultConfig,
    ...args,
  })
}

FacenNotification.info = (args: ArgsProps) => {
  notification.destroy()

  return notification.info({
    message: i18n.t("notification.title.info"),
    ...DefaultConfig,
    ...args,
  })
}

FacenNotification.error = (args: ArgsProps) => {
  notification.destroy()

  return notification.error({
    message: i18n.t("notification.title.error"),
    ...DefaultConfig,
    ...args,
  })
}

FacenNotification.warning = (args: ArgsProps) => {
  notification.destroy()

  return notification.warning({
    message: i18n.t("notification.title.warning"),
    ...DefaultConfig,
    ...args,
  })
}

FacenNotification.destroy = () => {
  return notification.destroy()
}

FacenNotification.warn = FacenNotification.warning

export default FacenNotification
