export interface MonitorType {
  loading: boolean
  error: string
}
export interface IDashboardState {
  vessel: {
    loading: boolean
    error: string | null
    data: []
    isFinished: boolean
    total: string
    next_page: number
    per_page: string
    selectedVessel: {
      informations: Record<string, unknown>
      vesselSpec: {
        stageSpec: Record<string, unknown>
        xdfSpec: Record<string, unknown>
        dfdeSpec: Record<string, unknown>
      }
      imo: string
      vessel_id: number
      name: string
      historyRoutes: []
      planRoutes: PlanRoutes
      ecdis: {
        routes: {
          loading: boolean
          error: string | null
          data: []
        }
        points: {
          loading: boolean
          error: string | null
          data: []
        }
      }
      updatedATATime: updatedATATime
      selectedPoint: SelectedPoint
      historyPointSelected: any
      manualEtaPeriod: any[]
      calculatedEtaPoints: any[]
      isChartPointClick: boolean
      historyRoutePointFiltered: []
    }
    showNoneTargetVessel: boolean
    showLabelNoneTargetVessel: boolean
  }
  weather: {
    loading: boolean
    error: string | null
    data: {
      showWaveHeight: boolean
      showPressurePattern: boolean
      showTropicalStorm: boolean
      showTropicalStormTrack: boolean
      showOcceanCurrent: boolean
      showWind: boolean
      currentDate: Date
      fromDate: Date
      toDate: Date
    }
  }
  port: {
    loading: boolean
    error: string | null
    data: []
    meta: Record<string, unknown>
    total: string
    next_page: number
    per_page: string
    selectedPort: Record<string, unknown>
  }
  chart: {
    isShowChart: boolean
    isShowCargoMonitorChart: boolean
    isShowEngineMonitorChart: boolean
    engineMonitor: {
      stageTrend33: ChartDataProps
      xdfTrend315: ChartDataProps
      xdfTrend316: ChartDataProps
      xdfTrend317: ChartDataProps
      stageEmBlr: ChartDataProps
      stageEmFdumpVOpen: ChartDataProps
      xdfFoc1: ChartDataProps
      stageFoc1: ChartDataProps
      stageTotalFoc: ChartDataProps
      dfdeFoc1: ChartProps
      dfdeTotalFoc: ChartProps
      dfdeDgFuelMode: ChartProps
    }
    cargoMonitor: {
      stageTrend1Cargo: ChartDataProps
      stageTrend21: ChartDataProps
      stageTankEquator: ChartDataProps
      stageTankVolume: ChartDataProps
      stageBoilOffRate: ChartDataProps
      stagePress: ChartDataProps
      stageTankLiquidTemp: ChartDataProps
      stageCmPressHold: ChartDataProps
      stageCmBlrFlow: ChartDataProps
      stageCmTotalTankVolume: ChartDataProps
      xdfTrend21: ChartDataProps
      xdfInnerSurfaceTemp: ChartDataProps
      xdfTankVolume: ChartDataProps
      xdfBoilOffRate: ChartDataProps
      xdfPress: ChartDataProps
      xdfTankLiquidTemp: ChartDataProps
      xdfCmTotalTankVolume: ChartDataProps
      xdfME: ChartDataProps
      dfdeTrend2: ChartProps
      dfdeBlrFlow: ChartProps
      dfdeTankVolume: ChartProps
    }
    common: {
      stageTrend1: ChartDataProps
      xdfTrend1: ChartDataProps
      ogSpeed: ChartDataProps
      dfdeTrend1: ChartProps
    }
    dfde: {
      cargo: MonitorType
      engine: MonitorType
    }
    stageCargo: ChartCommonProps
    stageEngine: ChartCommonProps
    xdfCargo: ChartCommonProps
    xdfEngine: ChartCommonProps
  }
  targetVessel: {
    loading: boolean
    error: string | null
    data: []
    isFinished: boolean
  }
}

export interface ChartDataParams {
  closest_at: string
  from_time: string
  to_time: string
  vessel_id: number
}

export interface SelectedPoint {
  dateTime: string
  imo: string
  vessel_id: number
}

export interface ChartDataProps {
  data: any[]
}

export type ChartProps = Pick<ChartDataProps, "data">

export interface updatedATATime {
  success: boolean
  loading: boolean
  error: string
}

export interface ATATimeParams {
  id: string
  original_eta: string
  ecdis_route_id: string
}

export interface PlanRoutes {
  data: []
  last_modified_at: string
  last_get_failed_at: string
}

export interface ChartCommonProps {
  loading: boolean
  error: string
}

export enum DashboardActions {
  FETCH_VESSEL_ALL = "[vessel] fetch all",
  FETCH_VESSEL_ALL_SUCCESS = "[vessel] fetch all success",
  FETCH_VESSEL_ALL_FINISHED = "[vessel] fetch all finished",
  FETCH_VESSEL_ALL_FAILED = "[vessel] fetch all failed",

  FETCH_TARGET_VESSEL_ALL = "[target vessel] fetch all target",
  FETCH_TARGET_VESSEL_ALL_SUCCESS = "[target vessel] fetch all target success",
  FETCH_TARGET_VESSEL_ALL_FINISHED = "[target vessel] fetch all target finished",
  FETCH_TARGET_VESSEL_ALL_FAILED = "[target vessel] fetch all target failed",

  RESET_VESSEL_LIST = "[vessel] reset vessel list",
  SET_VESSEL_SELECTED = "[vessel] set selected",
  FETCH_VESSEL_ACTUAL_ROUTES = "[vessel] fetch actual routes",
  FETCH_VESSEL_ACTUAL_ROUTES_SUCCESS = "[vessel] fetch actual routes success",
  FETCH_VESSEL_ACTUAL_ROUTES_FAILED = "[vessel] fetch actual routes failed",
  FETCH_VESSEL_PLAN_ROUTES = "[vessel] fetch plan routes",
  FETCH_VESSEL_PLAN_ROUTES_SUCCESS = "[vessel] fetch plan routes success",
  FETCH_VESSEL_PLAN_ROUTES_FAILED = "[vessel] fetch plan routes failed",

  FETCH_VESSEL_ECDIS_ROUTES = "[vessel] fetch ecdis routes",
  FETCH_VESSEL_ECDIS_ROUTES_SUCCESS = "[vessel] fetch ecdis routes success",
  FETCH_VESSEL_ECDIS_ROUTES_FAILED = "[vessel] fetch ecdis routes failed",

  FETCH_VESSEL_ECDIS_POINTS = "[vessel] fetch ecdis points",
  FETCH_VESSEL_ECDIS_POINTS_SUCCESS = "[vessel] fetch ecdis points success",
  FETCH_VESSEL_ECDIS_POINTS_FAILED = "[vessel] fetch ecdis points failed",

  RESET_FETCH_VESSEL_ECDIS_ROUTES_AND_POINTS = "[vessel] reset ecdis routes and points",

  FETCH_VESSEL_INFORMATIONS = "[vessel] fetch informations",
  FETCH_VESSEL_INFORMATIONS_SUCCESS = "[vessel] fetch informations success",
  FETCH_VESSEL_INFORMATIONS_FAILED = "[vessel] fetch informations failed",

  FETCH_VESSEL_STAGE_INFORMATIONS = "[vessel] fetch stage informations",
  FETCH_VESSEL_STAGE_INFORMATIONS_SUCCESS = "[vessel] fetch stage informations success",
  FETCH_VESSEL_STAGE_INFORMATIONS_FAILED = "[vessel] fetch stage informations failed",
  FETCH_VESSEL_XDF_INFORMATIONS = "[vessel] fetch xdf informations",
  FETCH_VESSEL_XDF_INFORMATIONS_SUCCESS = "[vessel] fetch xdf informations success",
  FETCH_VESSEL_XDF_INFORMATIONS_FAILED = "[vessel] fetch xdf informations failed",
  FETCH_VESSEL_DFDE_INFORMATIONS = "[vessel] fetch dfde informations",
  FETCH_VESSEL_DFDE_INFORMATIONS_SUCCESS = "[vessel] fetch dfde informations success",
  FETCH_VESSEL_DFDE_INFORMATIONS_FAILED = "[vessel] fetch dfde informations failed",

  UPDATE_ATA_TIME = "[vessel] update ATA Time",
  UPDATE_ATA_TIME_SUCCESS = "[vessel] update ATA Time success",
  UPDATE_ATA_TIME_FAILED = "[vessel] update ATA Time failed",

  SET_SELECTED_POINT = "[vessel] set selected point",
  UPDATE_MANUAL_ETA_PERIOD = "[vessel] update Manual ETA period",
  UPDATE_CALCULATED_ETA_POINTS = "[vessel] update Calculated ETA Points",
  SET_IS_POINT_CHART_CLICK = "[vessel] set is point click",

  SHOW_WEATHER_WAVE_HEIGHT = "[weather] show wave height",
  SHOW_WEATHER_PRESSURE_PATTERN = "[weather] show pressure pattern",
  SHOW_WEATHER_TROPICAL_STORM = "[weather] show tropical storm",
  SHOW_WEATHER_TROPICAL_STORM_TRACK = "[weather] show tropical storm track",
  SHOW_WEATHER_OCEAN_CURRENT = "[weather] show ocean current",
  SHOW_WEATHER_WIND = "[weather] show wind",
  HIDE_WEATHERS = "[weather] dashboard hide",
  SET_WEATHER_DATE = "[weather] set date",
  SET_FROM_DATE = "[weather] set from date",
  SET_TO_DATE = "[weather] set to date",
  FETCH_PORT_ALL = "[port] fetch all",
  FETCH_PORT_ALL_SUCCESS = "[port] fetch all success",
  FETCH_PORT_ALL_FINISHED = "[port] fetch all finished",
  FETCH_PORT_ALL_FAILED = "[port] fetch all failed",
  SHOW_CHART = "[dashboard] show Chart",
  SHOW_CHART_CARGO_MONITOR = "[dashboard] show Cargo Monitor Chart",
  SHOW_CHART_ENGINE_MONITOR = "[dashboard] show Engine Monitor Chart",

  FETCH_CHART_STAGE_BOIL_OFF_RATE_DATA_SUCCESS = "[dashboard] fetch Cargo Monitor Chart Stage Boil Off Rate Data success",

  FETCH_CHART_STAGE_TREND1_DATA_SUCCESS = "[dashboard] fetch Cargo Monitor Chart Stage Trend1 Data success",

  FETCH_CHART_STAGE_TREND1_CARGO_DATA_SUCCESS = "[dashboard] fetch Cargo Monitor Chart Stage Trend1 cargo Data success",

  FETCH_CHART_STAGE_TREND21_DATA_SUCCESS = "[dashboard] fetch Cargo Monitor Chart Stage Trend21 Data success",

  FETCH_CHART_STAGE_EM_BLR_SUCCESS = "[dashboard] fetch Cargo Monitor EMStageBlr Chart success",

  FETCH_CHART_STAGE_EM_FDUMP_V_OPEN_SUCCESS = "[dashboard] fetch Cargo Monitor EMStageFDumpVOpen Chart success",

  FETCH_CHART_STAGE_TREND33_DATA_SUCCESS = "[dashboard] fetch Cargo Monitor Chart Stage Trend33 Data success",

  FETCH_CHART_STAGE_TANK_EQUATOR_DATA_SUCCESS = "[dashboard] fetch Cargo Monitor Chart Stage Tank Equator Data success",

  FETCH_CHART_STAGE_TANK_VOLUME_DATA_SUCCESS = "[dashboard] fetch Cargo Monitor Chart Stage Tank Volume Data success",

  FETCH_CHART_STAGE_TANK_LIQUID_TEMP_DATA_SUCCESS = "[dashboard] fetch Cargo Monitor Chart Stage Tank Liquid Temp Data success",

  FETCH_CHART_STAGE_PRESS_DATA_SUCCESS = "[dashboard] fetch Cargo Monitor Chart Stage Press Data success",

  FETCH_CHART_XDF_TREND1_DATA_SUCCESS = "[dashboard] fetch Cargo Monitor Chart Xdf Trend1 Data success",

  FETCH_CHART_XDF_TREND315_DATA_SUCCESS = "[dashboard] fetch Cargo Monitor Chart Xdf Trend315 Data success",

  FETCH_CHART_XDF_EM_OG_SPEED_DATA_SUCCESS = "[dashboard] fetch Chart Xdf OgEmSpeed Data success",

  FETCH_CHART_XDF_TREND316_DATA_SUCCESS = "[dashboard] fetch Cargo Monitor Chart Xdf Trend316 Data success",

  FETCH_CHART_XDF_TREND317_DATA_SUCCESS = "[dashboard] fetch Cargo Monitor Chart Xdf Trend317 Data success",

  FETCH_CHART_XDF_TREND21_DATA_SUCCESS = "[dashboard] fetch Cargo Monitor Chart Xdf Trend21 Data success",

  FETCH_CHART_XDF_BOIL_OFF_RATE_DATA_SUCCESS = "[dashboard] fetch Cargo Monitor Chart XDF Boil Off Rate Data success",

  FETCH_CHART_XDF_INNER_SURFACE_TEMP_DATA_SUCCESS = "[dashboard] fetch Cargo Monitor Chart XDF Inner Surface Temp Data success",

  FETCH_CHART_XDF_TANK_VOLUME_DATA_SUCCESS = "[dashboard] fetch Cargo Monitor Chart XDF Tank Volume Data success",

  FETCH_CHART_XDF_TANK_LIQUID_TEMP_DATA_SUCCESS = "[dashboard] fetch Cargo Monitor Chart XDF Tank Liquid Temp Data success",

  FETCH_CHART_XDF_PRESS_DATA_SUCCESS = "[dashboard] fetch Cargo Monitor Chart XDF Press Data success",

  FETCH_CHART_STAGE_CM_PRESS_HOLD_SUCCESS = "[dashboard] fetch Cargo Monitor Chart STAGE CM PRESS_HOLD Data success",

  FETCH_CHART_STAGE_CM_TOTAL_TANK_VOLUME_SUCCESS = "[dashboard] fetch Cargo Monitor Chart STAGE CM TOTAL_TANK_VOLUME Data success",

  FETCH_CHART_STAGE_CM_BLR_FLOW_SUCCESS = "[dashboard] fetch Cargo Monitor Chart STAGE CM BLR_FLOW Data success",

  FETCH_CHART_XDF_CM_TOTAL_TANK_VOLUME_SUCCESS = "[dashboard] fetch Cargo Monitor Chart XDF CM TotalTankVolume Data success",

  FETCH_CHART_XDF_FOC1_DATA_SUCCESS = "[dashboard] fetch Cargo Monitor Chart Xdf FOC1 Data success",

  FETCH_CHART_STAGE_FOC1_DATA_SUCCESS = "[dashboard] fetch Cargo Monitor Chart STAGE FOC1 Data success",

  FETCH_CHART_STAGE_TOTAL_FOC_DATA_SUCCESS = "[dashboard] fetch Cargo Monitor Chart StageTotalFoc Data success",

  FETCH_CHART_XDF_ME_DATA_SUCCESS = "[dashboard] fetch Cargo Monitor Chart Xdf ME Data success",

  SHOW_NONE_TARGET_VESSEL = "[vessel] show none target vessel",
  SHOW_LABEL_NONE_TARGET_VESSEL = "[vessel] show label none target vessel",
  FETCH_HISTORY_ROUTE_POINT_FILTERED = "[vessel] fetch History Route Point Filtered",
  FETCH_HISTORY_ROUTE_POINT_FILTERED_SUCCESS = "[vessel] fetch History Route Point Filtered success",
  FETCH_HISTORY_ROUTE_POINT_FILTERED_FAILED = "[vessel] fetch History Route Point Filtered failed",
  SET_HISTORY_SELECTED = "[history] set history selected",

  RESET_MONITORING_INFORMATION = "[history] reset monitoring information",

  FETCH_CHART_DFDE_ENGINE = "[dashboard] fetch DFDE Engine Chart",
  FETCH_CHART_DFDE_ENGINE_SUCCESS = "[dashboard] fetch DFDE Engine Chart success",
  FETCH_CHART_DFDE_ENGINE_FAILED = "[dashboard] fetch DFDE Engine Chart failed",

  FETCH_CHART_DFDE_CARGO = "[dashboard] fetch DFDE Cargo Chart",
  FETCH_CHART_DFDE_CARGO_SUCCESS = "[dashboard] fetch DFDE Cargo Chart success",
  FETCH_CHART_DFDE_CARGO_FAILED = "[dashboard] fetch DFDE Cargo Chart failed",

  FETCH_CHART_STAGE_CARGO = "[dashboard chart] fetch Stage Cargo Chart all data",
  FETCH_CHART_STAGE_CARGO_SUCCESS = "[dashboard chart] fetch Stage Cargo Chart all data success",
  FETCH_CHART_STAGE_CARGO_FAILED = "[dashboard chart] fetch Stage Cargo Chart all data failed",

  FETCH_CHART_STAGE_ENGINE = "[dashboard chart] fetch Stage Engine Chart all data",
  FETCH_CHART_STAGE_ENGINE_SUCCESS = "[dashboard chart] fetch Stage Engine Chart all data success",
  FETCH_CHART_STAGE_ENGINE_FAILED = "[dashboard chart] fetch Stage Engine Chart all data failed",

  FETCH_CHART_XDF_CARGO = "[dashboard chart] fetch XDF Cargo Chart all data",
  FETCH_CHART_XDF_CARGO_SUCCESS = "[dashboard chart] fetch XDF Cargo Chart all data success",
  FETCH_CHART_XDF_CARGO_FAILED = "[dashboard chart] fetch XDF Cargo Chart all data failed",

  FETCH_CHART_XDF_ENGINE = "[dashboard chart] fetch XDF Engine Chart all data",
  FETCH_CHART_XDF_ENGINE_SUCCESS = "[dashboard chart] fetch XDF Engine Chart all data success",
  FETCH_CHART_XDF_ENGINE_FAILED = "[dashboard chart] fetch XDF Engine Chart all data failed",
}

export interface IVesselFetchAllAction {
  type: DashboardActions.FETCH_VESSEL_ALL
  payload?: {
    imo: number | string
  }
}
export interface IVesselFetchAllSuccessAction {
  type: DashboardActions.FETCH_VESSEL_ALL_SUCCESS
  payload: {
    data: []
  }
}
export interface IVesselFetchAllFinishedAction {
  type: DashboardActions.FETCH_VESSEL_ALL_FINISHED
  payload: {
    data: []
  }
}
export interface IVesselFetchAllFailedAction {
  type: DashboardActions.FETCH_VESSEL_ALL_FAILED
  payload: { error: string }
}

export interface ITargetVesselFetchAllAction {
  type: DashboardActions.FETCH_TARGET_VESSEL_ALL
}
export interface ITargetVesselFetchAllSuccessAction {
  type: DashboardActions.FETCH_TARGET_VESSEL_ALL_SUCCESS
  payload: {
    data: []
  }
}
export interface ITargetVesselFetchAllFinishedAction {
  type: DashboardActions.FETCH_TARGET_VESSEL_ALL_FINISHED
  payload: {
    allData: []
    headers: any
  }
}

export interface ITargetVesselFetchAllFailedAction {
  type: DashboardActions.FETCH_TARGET_VESSEL_ALL_FAILED
  payload: { error: string }
}

export interface IVesselResetList {
  type: DashboardActions.RESET_VESSEL_LIST
}

export interface IVesselsetSelected {
  type: DashboardActions.SET_VESSEL_SELECTED
  payload: {
    imo: string
    name: string
    vessel_id: number
  }
}

export interface IVesselFetchActualRoutes {
  type: DashboardActions.FETCH_VESSEL_ACTUAL_ROUTES
  payload: {
    from_time: string
    to_time: string
    vessel_id: number
  }
}

export interface IVesselFetchActualRoutesSuccess {
  type: DashboardActions.FETCH_VESSEL_ACTUAL_ROUTES_SUCCESS
  payload: {
    historyRoutes: []
  }
}
export interface IVesselFetchActualRoutesFailed {
  type: DashboardActions.FETCH_VESSEL_ACTUAL_ROUTES_FAILED
  payload: { error: string }
}

export interface IVesselFetchPlanRoutes {
  type: DashboardActions.FETCH_VESSEL_PLAN_ROUTES
  payload: {
    from_time: string
    to_time: string
    vessel_id: number
  }
}

export interface IVesselFetchPlanRoutesSuccess {
  type: DashboardActions.FETCH_VESSEL_PLAN_ROUTES_SUCCESS
  payload: {
    planRoutes: []
    lastGetFailedAt: string
    lastModifiedAt: string
  }
}

export interface IHistoryRoutePointFiltered {
  type: DashboardActions.FETCH_HISTORY_ROUTE_POINT_FILTERED
  payload: {
    from_time: string
    to_time: string
    imos: []
  }
}

export interface IHistoryRoutePointFilteredSuccess {
  type: DashboardActions.FETCH_HISTORY_ROUTE_POINT_FILTERED_SUCCESS
  payload: {
    historyRoutePointFiltered: []
  }
}

export interface IHistoryRoutePointFilteredFailed {
  type: DashboardActions.FETCH_HISTORY_ROUTE_POINT_FILTERED_FAILED
  payload: { error: string }
}

export interface IVesselFetchPlanRoutesFailed {
  type: DashboardActions.FETCH_VESSEL_PLAN_ROUTES_FAILED
  payload: { error: string }
}

export interface IVesselFetchInformations {
  type: DashboardActions.FETCH_VESSEL_INFORMATIONS
  payload: {
    vessel_id: number
  }
}
export interface IVesselFetchInformationsSuccess {
  type: DashboardActions.FETCH_VESSEL_INFORMATIONS_SUCCESS
  payload: {
    informations: {
      imo: string
      name: string
      mmsi: string
      callsign: string
      date_of_build: string
      engine_type: string
    }
  }
}
export interface IVesselFetchInformationsFailed {
  type: DashboardActions.FETCH_VESSEL_INFORMATIONS_FAILED
  payload: { error: string }
}

export interface IVesselStageFetchInformations {
  type: DashboardActions.FETCH_VESSEL_STAGE_INFORMATIONS
  payload: {
    time: Date
    vessel_id: number
  }
}
export interface IVesselStageFetchInformationsSuccess {
  type: DashboardActions.FETCH_VESSEL_STAGE_INFORMATIONS_SUCCESS
  payload: {
    stageSpec: {
      jsmea_nav_gnss_sog: number
      jsmea_nav_speedlog_log: number
      jsmea_mac_engineroom_air_temp: string
      jsmea_mac_sw_temp: string
      jsmea_mac_boiler_fuelmode: string
      jsmea_mac_dieselgeneratorset1_fuelmode: string
      jsmea_mac_dieselgeneratorset2_fuelmode: string
      jsmea_mac_dieselgeneratorset3_fuelmode: string
      jsmea_nav_gnss_eca: number
      jsmea_nav_trackcontrolsystem_course_direction: number
      jsmea_nav_windindicator_wind_true_direction: number
      jsmea_nav_windindicator_wind_true_speed: number
      jsmea_mac_boiler_mgoline_mgo_flowcounter_foc: string
      jsmea_mac_dieselgeneratorset1_mainline_mgo_flowcounter_foc: number
      jsmea_mac_dieselgeneratorset2_mainline_mgo_flowcounter_foc: number
      jsmea_mac_dieselgeneratorset3_mainline_mgo_flowcounter_foc: number
      jsmea_mac_dieselgeneratorset1_pilotline_mgo_flowcounter_foc: number
      jsmea_mac_dieselgeneratorset2_pilotline_mgo_flowcounter_foc: number
      jsmea_mac_dieselgeneratorset3_pilotline_mgo_flowcounter_foc: number
      jsmea_mac_boiler_fgline_fg_flowcounter_fgc: number
      jsmea_mac_dieselgeneratorset_fg_total_flowcounter_fgc: number
      jsmea_mac_boiler_total_flowcounter_foc: number
      jsmea_mac_dieselgeneratorset_total_flowcounter_foc: number
    }
  }
}
export interface IVesselStageFetchInformationsFailed {
  type: DashboardActions.FETCH_VESSEL_STAGE_INFORMATIONS_FAILED
  payload: { error: string }
}

export interface IVesselXdfFetchInformations {
  type: DashboardActions.FETCH_VESSEL_XDF_INFORMATIONS
  payload: {
    time: Date
    vessel_id: number
  }
}
export interface IVesselXdfFetchInformationsSuccess {
  type: DashboardActions.FETCH_VESSEL_XDF_INFORMATIONS_SUCCESS
  payload: {
    xdfSpec: {
      jsmea_nav_gnss_sog: number
      jsmea_nav_speedlog_log: number
      jsmea_mac_engineroom_air_temp: string
      jsmea_mac_sw_temp: string
      jsmea_mac_mainengine_fuelmode: string
      jsmea_mac_dieselgeneratorset1_fuelmode: string
      jsmea_mac_dieselgeneratorset2_fuelmode: string
      jsmea_mac_dieselgeneratorset3_fuelmode: string
      jsmea_nav_gnss_eca: number
      jsmea_nav_trackcontrolsystem_course_direction: number
      jsmea_nav_windindicator_wind_true_direction: number
      jsmea_nav_windindicator_wind_true_speed: number
      jsmea_mac_ship_mgo_total_flowcounter_foc: number
      jsmea_mac_ship_fg_flowcounter_fgc: number
      jsmea_mac_ship_total_include_gcu_fc: number
      jsmea_mac_mainengine2_fuelmode: string
    }
  }
}
export interface IVesselXdfFetchInformationsFailed {
  type: DashboardActions.FETCH_VESSEL_XDF_INFORMATIONS_FAILED
  payload: { error: string }
}

export interface IVesselDfdeFetchInformations {
  type: DashboardActions.FETCH_VESSEL_DFDE_INFORMATIONS
  payload: {
    time: Date
    vessel_id: number
  }
}
export interface IVesselDfdeFetchInformationsSuccess {
  type: DashboardActions.FETCH_VESSEL_DFDE_INFORMATIONS_SUCCESS
  payload: {
    dfdeSpec: {
      jsmea_nav_gnss_sog: number
      jsmea_nav_speedlog_log: number
      jsmea_mac_engineroom_air_temp: string
      jsmea_mac_dieselgeneratorset1_fuelmode: string
      jsmea_mac_dieselgeneratorset2_fuelmode: string
      jsmea_mac_dieselgeneratorset3_fuelmode: string
      jsmea_mac_dieselgeneratorset4_fuelmode: string
      jsmea_mac_dieselgeneratorset5_fuelmode: string
      jsmea_nav_gnss_eca: number
      jsmea_mac_dieselgeneratorset_total_flowcounter_foc: number
      jsmea_mac_boiler_fgline_fg_flowcounter_fgc: number
      jsmea_nav_trackcontrolsystem_course_direction: number
      jsmea_nav_windindicator_wind_true_direction: number
      jsmea_nav_windindicator_wind_true_speed: number
      jsmea_mac_mainengine_fuelmode: string
      timestamp: string
    }
  }
}
export interface IVesselDfdeFetchInformationsFailed {
  type: DashboardActions.FETCH_VESSEL_DFDE_INFORMATIONS_FAILED
  payload: { error: string }
}

export interface IUpdateATATime {
  type: DashboardActions.UPDATE_ATA_TIME
  payload: {
    ecdis_points: ATATimeParams[]
    onSuccess?: () => void
  }
}

export interface IUpdateATATimeSuccess {
  type: DashboardActions.UPDATE_ATA_TIME_SUCCESS
  payload: { data: [] }
}

export interface IUpdateATATimeFailed {
  type: DashboardActions.UPDATE_ATA_TIME_FAILED
  payload: { error: string }
}

export interface ISetSelectedPoint {
  type: DashboardActions.SET_SELECTED_POINT
  payload: {
    selectedPoint: SelectedPoint
  }
}
export interface IUpdateManualETAPeriod {
  type: DashboardActions.UPDATE_MANUAL_ETA_PERIOD
  payload: {
    manualEtaPeriod: any[]
  }
}

export interface IUpdateCalculatedEtaPoints {
  type: DashboardActions.UPDATE_CALCULATED_ETA_POINTS
  payload: {
    calculatedEtaPoints: any[]
  }
}

export interface ISetIsChartPointClick {
  type: DashboardActions.SET_IS_POINT_CHART_CLICK
  payload: {
    isChartPointClick: boolean
  }
}

export interface IWeatherShowWaveHeightAction {
  type: DashboardActions.SHOW_WEATHER_WAVE_HEIGHT
  payload: { checked: boolean }
}

export interface IWeatherShowPressurePatternAction {
  type: DashboardActions.SHOW_WEATHER_PRESSURE_PATTERN
  payload: { checked: boolean }
}

export interface IWeatherShowTropicalStormAction {
  type: DashboardActions.SHOW_WEATHER_TROPICAL_STORM
  payload: { checked: boolean }
}

export interface IWeatherShowTropicalStormTrackAction {
  type: DashboardActions.SHOW_WEATHER_TROPICAL_STORM_TRACK
  payload: { checked: boolean }
}

export interface IWeatherShowOcceanCurrentAction {
  type: DashboardActions.SHOW_WEATHER_OCEAN_CURRENT
  payload: { checked: boolean }
}

export interface IWeatherShowWindAction {
  type: DashboardActions.SHOW_WEATHER_WIND
  payload: { checked: boolean }
}

export interface IHideWeathers {
  type: DashboardActions.HIDE_WEATHERS
}

export interface IWeatherSetDateAction {
  type: DashboardActions.SET_WEATHER_DATE
  payload: { date: Date }
}

export interface IWeatherSetFromDateAction {
  type: DashboardActions.SET_FROM_DATE
  payload: { fromDate: Date }
}

export interface IWeatherSetToDateAction {
  type: DashboardActions.SET_TO_DATE
  payload: { toDate: Date }
}

export interface IPortFetchAllSuccessAction {
  type: DashboardActions.FETCH_PORT_ALL_SUCCESS
  payload: {
    data: []
  }
}

export interface IPortFetchAllFinishedAction {
  type: DashboardActions.FETCH_PORT_ALL_FINISHED
}

export interface IPortFetchAllFailedAction {
  type: DashboardActions.FETCH_PORT_ALL_FAILED
  payload: { error: string }
}

export interface IPortFetchAllAction {
  type: DashboardActions.FETCH_PORT_ALL
}

export interface ISetShowChart {
  type: DashboardActions.SHOW_CHART
  payload: {
    isShowChart: boolean
  }
}

export interface ISetShowCargoMonitorChart {
  type: DashboardActions.SHOW_CHART_CARGO_MONITOR
  payload: {
    isShowCargoMonitorChart: boolean
  }
}

export interface ISetShowEngineMonitorChart {
  type: DashboardActions.SHOW_CHART_ENGINE_MONITOR
  payload: {
    isShowEngineMonitorChart: boolean
  }
}

export interface IFetchStageBoilOffRateChartDataSuccess {
  type: DashboardActions.FETCH_CHART_STAGE_BOIL_OFF_RATE_DATA_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchStageTrend1ChartDataSuccess {
  type: DashboardActions.FETCH_CHART_STAGE_TREND1_DATA_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchStageTrend1CargoChartDataSuccess {
  type: DashboardActions.FETCH_CHART_STAGE_TREND1_CARGO_DATA_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchStageTrend21ChartDataSuccess {
  type: DashboardActions.FETCH_CHART_STAGE_TREND21_DATA_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchStageTrend33ChartDataSuccess {
  type: DashboardActions.FETCH_CHART_STAGE_TREND33_DATA_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchStageEmBlrChartDataSuccess {
  type: DashboardActions.FETCH_CHART_STAGE_EM_BLR_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchStageEmFdumpVOpenChartDataSuccess {
  type: DashboardActions.FETCH_CHART_STAGE_EM_FDUMP_V_OPEN_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchStageTankEquatorChartDataSuccess {
  type: DashboardActions.FETCH_CHART_STAGE_TANK_EQUATOR_DATA_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchStageTankVolumeChartDataSuccess {
  type: DashboardActions.FETCH_CHART_STAGE_TANK_VOLUME_DATA_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchStageTankLiquidTempChartDataSuccess {
  type: DashboardActions.FETCH_CHART_STAGE_TANK_LIQUID_TEMP_DATA_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchStagePressChartDataSuccess {
  type: DashboardActions.FETCH_CHART_STAGE_PRESS_DATA_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchXdfTrend1ChartDataSuccess {
  type: DashboardActions.FETCH_CHART_XDF_TREND1_DATA_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchXdfTrend315ChartDataSuccess {
  type: DashboardActions.FETCH_CHART_XDF_TREND315_DATA_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchOgSpeedChartDataSuccess {
  type: DashboardActions.FETCH_CHART_XDF_EM_OG_SPEED_DATA_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchXdfTrend316ChartDataSuccess {
  type: DashboardActions.FETCH_CHART_XDF_TREND316_DATA_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchXdfTrend317ChartDataSuccess {
  type: DashboardActions.FETCH_CHART_XDF_TREND317_DATA_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchXdfTrend21ChartDataSuccess {
  type: DashboardActions.FETCH_CHART_XDF_TREND21_DATA_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchXdfBoilOffRateChartDataSuccess {
  type: DashboardActions.FETCH_CHART_XDF_BOIL_OFF_RATE_DATA_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchXdfInnerSurfaceTempChartDataSuccess {
  type: DashboardActions.FETCH_CHART_XDF_INNER_SURFACE_TEMP_DATA_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchXdfTankVolumeChartDataSuccess {
  type: DashboardActions.FETCH_CHART_XDF_TANK_VOLUME_DATA_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchXdfTankLiquidTempChartDataSuccess {
  type: DashboardActions.FETCH_CHART_XDF_TANK_LIQUID_TEMP_DATA_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchXdfPressChartDataSuccess {
  type: DashboardActions.FETCH_CHART_XDF_PRESS_DATA_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchXdfCmTotalTankVolumeChartDataSuccess {
  type: DashboardActions.FETCH_CHART_XDF_CM_TOTAL_TANK_VOLUME_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchStageCmTotalTankVolumeChartDataSuccess {
  type: DashboardActions.FETCH_CHART_STAGE_CM_TOTAL_TANK_VOLUME_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchStageCmBlrFlowChartDataSuccess {
  type: DashboardActions.FETCH_CHART_STAGE_CM_BLR_FLOW_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchStageCmPressHoldChartDataSuccess {
  type: DashboardActions.FETCH_CHART_STAGE_CM_PRESS_HOLD_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchXdfFoc1ChartDataSuccess {
  type: DashboardActions.FETCH_CHART_XDF_FOC1_DATA_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchStageFoc1ChartDataSuccess {
  type: DashboardActions.FETCH_CHART_STAGE_FOC1_DATA_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchStageTotalFocChartDataSuccess {
  type: DashboardActions.FETCH_CHART_STAGE_TOTAL_FOC_DATA_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchXdfMEChartDataSuccess {
  type: DashboardActions.FETCH_CHART_XDF_ME_DATA_SUCCESS
  payload: {
    data: []
  }
}

export interface IVesselFetchEcdisRoutes {
  type: DashboardActions.FETCH_VESSEL_ECDIS_ROUTES
  payload: {
    vessel_id: number
    form_time: string
    to_time: string
  }
}

export interface IVesselFetchEcdisRoutesSuccess {
  type: DashboardActions.FETCH_VESSEL_ECDIS_ROUTES_SUCCESS
  payload: {
    ecdisRoutes: []
  }
}

export interface IVesselFetchEcdisRoutesFailed {
  type: DashboardActions.FETCH_VESSEL_ECDIS_ROUTES_FAILED
  payload: { error: string }
}

export interface IVesselFetchEcdisPoints {
  type: DashboardActions.FETCH_VESSEL_ECDIS_POINTS
  payload: {
    ecdis_route_ids: []
  }
}

export interface IVesselFetchEcdisPointsSuccess {
  type: DashboardActions.FETCH_VESSEL_ECDIS_POINTS_SUCCESS
  payload: {
    ecdisPoints: []
  }
}

export interface IVesselFetchEcdisPointsFailed {
  type: DashboardActions.FETCH_VESSEL_ECDIS_POINTS_FAILED
  payload: { error: string }
}

export interface IVesselResetEcdisRoutesAndPoints {
  type: DashboardActions.RESET_FETCH_VESSEL_ECDIS_ROUTES_AND_POINTS
}

export interface IVesselShowNoneTargetVessel {
  type: DashboardActions.SHOW_NONE_TARGET_VESSEL
  payload: { checked: boolean }
}
export interface IVesselShowLabelNoneTargetVessel {
  type: DashboardActions.SHOW_LABEL_NONE_TARGET_VESSEL
  payload: { checked: boolean }
}
export interface IHistoryPointSelected {
  type: DashboardActions.SET_HISTORY_SELECTED
  payload: { selectedPoint: any }
}
export interface IResetMonitoringInformation {
  type: DashboardActions.RESET_MONITORING_INFORMATION
}

export interface IFetchChartStageCargo {
  type: DashboardActions.FETCH_CHART_STAGE_CARGO
  payload: ChartDataParams
}

export interface IFetchChartStageCargoSuccess {
  type: DashboardActions.FETCH_CHART_STAGE_CARGO_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchChartStageCargoFailed {
  type: DashboardActions.FETCH_CHART_STAGE_CARGO_FAILED
  payload: {
    error: string
  }
}

export interface IFetchChartStageEngine {
  type: DashboardActions.FETCH_CHART_STAGE_ENGINE
  payload: ChartDataParams
}

export interface IFetchChartStageEngineSuccess {
  type: DashboardActions.FETCH_CHART_STAGE_ENGINE_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchChartStageEngineFailed {
  type: DashboardActions.FETCH_CHART_STAGE_ENGINE_FAILED
  payload: {
    error: string
  }
}

export interface IFetchChartXDFCargo {
  type: DashboardActions.FETCH_CHART_XDF_CARGO
  payload: ChartDataParams
}

export interface IFetchChartXDFCargoSuccess {
  type: DashboardActions.FETCH_CHART_XDF_CARGO_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchChartXDFCargoFailed {
  type: DashboardActions.FETCH_CHART_XDF_CARGO_FAILED
  payload: {
    error: string
  }
}

export interface IFetchChartXDFEngine {
  type: DashboardActions.FETCH_CHART_XDF_ENGINE
  payload: ChartDataParams
}

export interface IFetchChartXDFEngineSuccess {
  type: DashboardActions.FETCH_CHART_XDF_ENGINE_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchChartXDFEngineFailed {
  type: DashboardActions.FETCH_CHART_XDF_ENGINE_FAILED
  payload: {
    error: string
  }
}

export interface IFetchChartDFDEEngine {
  type: DashboardActions.FETCH_CHART_DFDE_ENGINE
  payload: ChartDataParams
}

export interface IFetchChartDFDEEngineSuccess {
  type: DashboardActions.FETCH_CHART_DFDE_ENGINE_SUCCESS
  payload: {
    dfdeTrend1: []
    dfdeFoc1: []
    dfdeTotalFoc: []
    ogSpeed: []
    dfdeDgFuelMode: []
  }
}

export interface IFetchChartDFDEEngineFailed {
  type: DashboardActions.FETCH_CHART_DFDE_ENGINE_FAILED
  payload: {
    error: string
  }
}

export interface IFetchChartDFDECargo {
  type: DashboardActions.FETCH_CHART_DFDE_CARGO
  payload: ChartDataParams
}

export interface IFetchChartDFDECargoSuccess {
  type: DashboardActions.FETCH_CHART_DFDE_CARGO_SUCCESS
  payload: {
    dfdeTrend1: []
    dfdeTrend2: []
    dfdeBlrFlow: []
    stageBoilOffRate: []
    stageTankLiquidTemp: []
    stagePress: []
    dfdeTankVolume: []
    stageCmPressHold: []
    stageCmTotalTankVolume: []
    xdfInnerSurfaceTemp: []
  }
}

export interface IFetchChartDFDECargoFailed {
  type: DashboardActions.FETCH_CHART_DFDE_CARGO_FAILED
  payload: {
    error: string
  }
}

export type DashboardActionTypes =
  | IWeatherShowWaveHeightAction
  | IWeatherShowPressurePatternAction
  | IWeatherShowTropicalStormAction
  | IWeatherShowTropicalStormTrackAction
  | IWeatherShowOcceanCurrentAction
  | IWeatherShowWindAction
  | IWeatherSetDateAction
  | IWeatherSetFromDateAction
  | IWeatherSetToDateAction
  | IVesselFetchAllAction
  | IVesselFetchAllSuccessAction
  | IVesselFetchAllFinishedAction
  | IVesselFetchAllFailedAction
  | ITargetVesselFetchAllAction
  | ITargetVesselFetchAllSuccessAction
  | ITargetVesselFetchAllFinishedAction
  | ITargetVesselFetchAllFailedAction
  | IVesselResetList
  | IVesselsetSelected
  | IVesselFetchActualRoutes
  | IVesselFetchActualRoutesSuccess
  | IVesselFetchActualRoutesFailed
  | IVesselFetchPlanRoutes
  | IVesselFetchPlanRoutesSuccess
  | IVesselFetchPlanRoutesFailed
  | IVesselFetchInformations
  | IVesselFetchInformationsSuccess
  | IVesselFetchInformationsFailed
  | IVesselStageFetchInformations
  | IVesselStageFetchInformationsSuccess
  | IVesselStageFetchInformationsFailed
  | IVesselXdfFetchInformations
  | IVesselXdfFetchInformationsSuccess
  | IVesselXdfFetchInformationsFailed
  | IVesselDfdeFetchInformations
  | IVesselDfdeFetchInformationsSuccess
  | IVesselDfdeFetchInformationsFailed
  | IUpdateATATime
  | IUpdateATATimeSuccess
  | IUpdateATATimeFailed
  | IUpdateManualETAPeriod
  | ISetSelectedPoint
  | ISetIsChartPointClick
  | IPortFetchAllSuccessAction
  | IPortFetchAllFinishedAction
  | IPortFetchAllFailedAction
  | IPortFetchAllAction
  | ISetShowChart
  | ISetShowCargoMonitorChart
  | ISetShowEngineMonitorChart
  | IFetchStageBoilOffRateChartDataSuccess
  | IFetchStageTrend1ChartDataSuccess
  | IFetchStageTrend21ChartDataSuccess
  | IFetchStageTrend33ChartDataSuccess
  | IFetchStageTankEquatorChartDataSuccess
  | IFetchStageTankVolumeChartDataSuccess
  | IFetchStageTankLiquidTempChartDataSuccess
  | IFetchStagePressChartDataSuccess
  | IFetchXdfTrend1ChartDataSuccess
  | IFetchXdfTrend315ChartDataSuccess
  | IFetchXdfTrend316ChartDataSuccess
  | IFetchXdfTrend317ChartDataSuccess
  | IFetchXdfTrend21ChartDataSuccess
  | IFetchXdfBoilOffRateChartDataSuccess
  | IFetchXdfInnerSurfaceTempChartDataSuccess
  | IFetchXdfTankVolumeChartDataSuccess
  | IFetchXdfTankLiquidTempChartDataSuccess
  | IFetchXdfPressChartDataSuccess
  | IVesselFetchEcdisRoutes
  | IVesselFetchEcdisRoutesSuccess
  | IVesselFetchEcdisRoutesFailed
  | IVesselFetchEcdisPoints
  | IVesselFetchEcdisPointsSuccess
  | IVesselFetchEcdisPointsFailed
  | IVesselResetEcdisRoutesAndPoints
  | IUpdateCalculatedEtaPoints
  | ISetSelectedPoint
  | IVesselShowNoneTargetVessel
  | IVesselShowLabelNoneTargetVessel
  | IHistoryRoutePointFiltered
  | IHistoryRoutePointFilteredSuccess
  | IHistoryRoutePointFilteredFailed
  | IHistoryPointSelected
  | IHideWeathers
  | IFetchOgSpeedChartDataSuccess
  | IFetchStageEmBlrChartDataSuccess
  | IFetchStageEmFdumpVOpenChartDataSuccess
  | IFetchXdfCmTotalTankVolumeChartDataSuccess
  | IFetchStageCmPressHoldChartDataSuccess
  | IFetchStageCmTotalTankVolumeChartDataSuccess
  | IFetchStageCmBlrFlowChartDataSuccess
  | IFetchStageTrend1CargoChartDataSuccess
  | IFetchXdfFoc1ChartDataSuccess
  | IFetchStageFoc1ChartDataSuccess
  | IFetchStageTotalFocChartDataSuccess
  | IFetchXdfMEChartDataSuccess
  | IResetMonitoringInformation
  | IFetchChartDFDEEngine
  | IFetchChartDFDEEngineSuccess
  | IFetchChartDFDEEngineFailed
  | IFetchChartDFDECargo
  | IFetchChartDFDECargoSuccess
  | IFetchChartDFDECargoFailed
  | IFetchChartStageCargo
  | IFetchChartStageCargoSuccess
  | IFetchChartStageCargoFailed
  | IFetchChartStageEngine
  | IFetchChartStageEngineSuccess
  | IFetchChartStageEngineFailed
  | IFetchChartXDFCargo
  | IFetchChartXDFCargoSuccess
  | IFetchChartXDFCargoFailed
  | IFetchChartXDFEngine
  | IFetchChartXDFEngineSuccess
  | IFetchChartXDFEngineFailed
