export const LADEN_VOYAGE = "Laden Voyage"
export const BALLAST_VOYAGE = "Ballast Voyage"
export const LADEN_BALLAST = [LADEN_VOYAGE, BALLAST_VOYAGE]
export const LADEN_TYPE_LEG1 = "laden_voyage_leg1"
export const BALLAST_TYPE_LEG1 = "ballast_voyage_leg1"
export const LADEN_TYPE_LEG2 = "laden_voyage_leg2"
export const BALLAST_TYPE_LEG2 = "ballast_voyage_leg2"
export const LADEN_BALLAST_TYPE = [
  LADEN_TYPE_LEG1,
  BALLAST_TYPE_LEG1,
  LADEN_TYPE_LEG2,
  BALLAST_TYPE_LEG2,
]
export const LADEN_FIELD = "laden"
export const BALLAST_FIELD = "ballast"
export const LADEN_BALLAST_FIELDS = [LADEN_FIELD, BALLAST_FIELD]
export const LADEN_BALLAST_RESULT_TITLE = {
  [LADEN_TYPE_LEG1]: "Leg ID 1",
  [LADEN_TYPE_LEG2]: "Leg ID 2",
  [BALLAST_TYPE_LEG1]: "Leg ID 1",
  [BALLAST_TYPE_LEG2]: "Leg ID 2",
}
export const WINTER_MONTHS = ["Oct", "Nov", "Dec", "Jan", "Feb", "Mar"]

export interface HEEL_EDQ_FORM {
  name: string
  label: string
  required?: boolean
  max?: any
  error?: boolean
  help?: string
  control: any
  suffixControl?: any
}

export interface HEEL_EDQ_RESULT {
  name: string
  value: string | number
  unit: string
}

export interface HEEL_CALC_PAYLOAD {
  port_dept_id: string
  port_arrival_id: string
  master_route_id: string
  etd: string
  etd_utc: string
  etd_time_zone: string
  etd_label: string
  eta: string
  eta_utc: string
  eta_time_zone: string
  eta_label: string
  foe: number
  imo: number
  voyage_type: string
  sea_margin: number
}

export interface HEEL_EDQ_RESULT_RESPONSE {
  id: number | string
  estimated_distance: number | string
  voyage_duration: number | string
  required_speed: number | string
  estimated_daily_foc: number | string
  estimated_daily_foc_season_effect: number | string
  estimated_total_foc: number | string
  consuming_lng: number | string
  eta?: string
  eta_utc?: string
  eta_label?: string
  eta_time_zone?: string
  etd?: string
  etd_utc?: string
  etd_label?: string
  etd_time_zone?: string
  sea_margin?: number
}

export interface LADEN_BALLAST_DATA {
  id: string | number
  type: string
  attributes: LADEN_BALLAST_ATTRIBUTES
}

export interface LADEN_BALLAST_ATTRIBUTES {
  etd: string
  etd_label: string
  etd_utc: string
  eta: string
  eta_label: string
  eta_utc: string
  estimated_distance: number
  voyage_duration: number
  required_speed: number
  estimated_daily_foc: number
  estimated_daily_foc_season_effect: number
  estimated_total_foc: number
  consuming_lng: number
  master_route_id: number
  master_route_name: string
  port_arrival_id: number
  port_arrival_name: string
  port_dept_id: number
  port_dept_name: string
}

export interface HEEL_EDQ_ITEM {
  imo: number
  name: string
  foe: number
  init_lng_volume: number
  laden_voyage_no: string
  ballast_voyage_no: string
  unpumpable: number
  edq: number
  author_id: number
  updated_by_id: number
  updated_by_name: string
  created_at: string
  updated_at: string
  vessel_name: string
  ballast_fuel_consumption_in_pa: number
  ballast_pa_transit: boolean
  ballast_voyage_leg1_port_arrival: string
  ballast_voyage_leg1_port_dept: string
  ballast_voyage_leg1: {
    data?: LADEN_BALLAST_DATA
  }
  ballast_voyage_leg2_port_arrival: string
  ballast_voyage_leg2_port_dept: string
  ballast_voyage_leg2: {
    data?: LADEN_BALLAST_DATA
  }
  cosuming_lng_of_laden_voyage: number
  cosuming_lng_of_laden_voyage_leg1: number
  cosuming_lng_of_laden_voyage_leg2: number
  finalized: false
  cosuming_lng_of_ballast_voyage: number
  cosuming_lng_of_ballast_voyage_leg1: number
  cosuming_lng_of_ballast_voyage_leg2: number
  estimated_heel_leg1: number
  estimated_heel_leg2: number
  laden_pa_transit: boolean
  laden_voyage_leg1_port_arrival: string
  laden_voyage_leg1_port_dept: string
  laden_voyage_leg1: {
    data?: LADEN_BALLAST_DATA
  }
  laden_voyage_leg2_port_arrival: string
  laden_voyage_leg2_port_dept: string
  laden_voyage_leg2: {
    data?: LADEN_BALLAST_DATA
  }
  landen_fuel_consumption_in_pa: number
  sea_margin: number
}
