import { unifyValue } from "core/utils/unifyValueChartData"
import { setFillComparisonColor } from "core/constants/barCharts"

const DEFAULT_NAME = "Avg. of Data Set"

export default class VoyageComparison {
  name: string

  duration: number

  distance: number

  "MGO_Cons.": number

  "avg._speed": number

  tooltip_duration: Array<{ vessel_name: string; voyage_no: string; value: number }>

  tooltip_distance: Array<{ vessel_name: string; voyage_no: string; value: number }>

  "tooltip_MGO_Cons.": Array<{ vessel_name: string; voyage_no: string; value: number }>

  "tooltip_avg._speed": Array<{ vessel_name: string; voyage_no: string; value: number }>

  color: string

  constructor(data, color) {
    this.name = DEFAULT_NAME
    this.duration = unifyValue(data.duration, 0)
    this.distance = unifyValue(data.distance, 0)
    this["MGO_Cons."] = unifyValue(data.mgo_consumption, 1)
    this["avg._speed"] = unifyValue(data.average_speed, 1)
    this.tooltip_duration = data.tooltip_duration
    this.tooltip_distance = data.tooltip_distance
    this["tooltip_MGO_Cons."] = data.tooltip_mgo_consumption
    this["tooltip_avg._speed"] = data.tooltip_average_speed
    this.color = setFillComparisonColor(color)
  }
}
