export interface IHistoricalState {
  durationSettings: {
    currentDate: Date
    fromDate: Date
    toDate: Date
  }
  selectedImo: any
  selectedVesselId: number
  weathers: {
    weathersChecked: string[]
    waveHeight: {
      isShow: boolean
      loading: boolean
      error: string
      data: []
    }
    surfacePressure: {
      isShow: boolean
      loading: boolean
      error: string
      data: []
    }
    tropicalStorm: {
      isShow: boolean
      loading: boolean
      error: string
      data: []
    }
    oceanCurrent: {
      isShow: boolean
      loading: boolean
      error: string
      data: []
    }
    windBarbs: {
      isShow: boolean
      loading: boolean
      error: string
      data: []
    }
  }
}

export enum HistoricalActions {
  SET_DURATION_CURRENT_DATE = "[historical] currentDate",
  SET_SELECTED_IMO = "[historical] selectedImos",
  SET_SELECTED_VESSEL_ID = "[historical] selected vessel id",
  SET_DURATION_SETTING = "[historical] duration Settings",
  SHOW_WEATHERS = "[weathers] show",
  HIDE_WEATHERS = "[weathers] hide",

  SHOW_WEATHER_WAVE_HEIGHT = "[weather] show wave height historical",
  FETCH_WEATHER_WAVE_HEIGHT = "[weather] fetch wave height historical",
  FETCH_WEATHER_WAVE_HEIGHT_SUCCESS = "[weather] fetch wave height historical success",
  FETCH_WEATHER_WAVE_HEIGHT_FAILED = "[weather] fetch wave height historical failed",

  SHOW_WEATHER_SURFACE_PRESSURE = "[weather] show surface pressure historical",
  FETCH_WEATHER_SURFACE_PRESSURE = "[weather] fetch surface pressure historical",
  FETCH_WEATHER_SURFACE_PRESSURE_SUCCESS = "[weather] fetch surface pressure historical success",
  FETCH_WEATHER_SURFACE_PRESSURE_FAILED = "[weather] fetch surface pressure historical failed",

  SHOW_WEATHER_GLOBAL_STORM = "[weather] show tropical storm historical",
  FETCH_WEATHER_GLOBAL_STORM = "[weather] fetch tropical storm historical",
  FETCH_WEATHER_GLOBAL_STORM_SUCCESS = "[weather] fetch tropical storm historical success",
  FETCH_WEATHER_GLOBAL_STORM_FAILED = "[weather] fetch tropical storm historical failed",

  SHOW_WEATHER_OCEAN_CURRENT = "[weather] show ocean current historical",
  FETCH_WEATHER_OCEAN_CURRENT = "[weather] fetch ocean current historical",
  FETCH_WEATHER_OCEAN_CURRENT_SUCCESS = "[weather] fetch ocean current historical success",
  FETCH_WEATHER_OCEAN_CURRENT_FAILED = "[weather] fetch ocean current historical failed",
  CANCEL_WEATHER_OCEAN_CURRENT = "[weather] cancel fetch ocean current historical",

  SHOW_WEATHER_WIND_BARBS = "[weather] show wind barbs historical",
  FETCH_WEATHER_WIND_BARBS = "[weather] fetch wind barbs historical",
  FETCH_WEATHER_WIND_BARBS_SUCCESS = "[weather] fetch wind barbs historical success",
  FETCH_WEATHER_WIND_BARBS_FAILED = "[weather] fetch wind barbs historical failed",
  CANCEL_WEATHER_WIND_BARBS = "[weather] cancel fetch wind barbs historical",
}

export interface IDurationSetCurrentDateAction {
  type: HistoricalActions.SET_DURATION_CURRENT_DATE
  payload: { date: Date }
}

export interface ISetSelectedImosAction {
  type: HistoricalActions.SET_SELECTED_IMO
  payload: { imos: any }
}

export interface ISetSelectedVesselIdAction {
  type: HistoricalActions.SET_SELECTED_VESSEL_ID
  payload: { vessel_id: number }
}

export interface ISetDurationSettingAction {
  type: HistoricalActions.SET_DURATION_SETTING
  payload: { fromDate: Date; toDate: Date; currentDate: Date }
}

export interface WeatherParams {
  date: string
  bounds: number[]
  element: string
  zoom: number
  zoomForDraw?: number
  loading?: boolean
  signal?: any
  cancelSource?: any
}

export interface IShowWeatherWaveHeight {
  type: HistoricalActions.SHOW_WEATHER_WAVE_HEIGHT
  payload: { checked: boolean }
}

export interface IWeatherWaveHeight {
  type: HistoricalActions.FETCH_WEATHER_WAVE_HEIGHT
  payload: WeatherParams
}

export interface IWeatherWaveHeightSuccess {
  type: HistoricalActions.FETCH_WEATHER_WAVE_HEIGHT_SUCCESS

  data: []
}

export interface IWeatherWaveHeightFailed {
  type: HistoricalActions.FETCH_WEATHER_WAVE_HEIGHT_FAILED
  payload: { error: string }
}

export interface IShowWeatherSurfacePressure {
  type: HistoricalActions.SHOW_WEATHER_SURFACE_PRESSURE
  payload: { checked: boolean }
}

export interface IWeatherSurfacePressure {
  type: HistoricalActions.FETCH_WEATHER_SURFACE_PRESSURE
  payload: WeatherParams
}

export interface IWeatherSurfacePressureSuccess {
  type: HistoricalActions.FETCH_WEATHER_SURFACE_PRESSURE_SUCCESS

  data: []
}

export interface IWeatherSurfacePressureFailed {
  type: HistoricalActions.FETCH_WEATHER_SURFACE_PRESSURE_FAILED
  payload: { error: string }
}

export interface IShowWeatherTropicalStorm {
  type: HistoricalActions.SHOW_WEATHER_GLOBAL_STORM
  payload: { checked: boolean }
}

export interface IWeatherTropicalStorm {
  type: HistoricalActions.FETCH_WEATHER_GLOBAL_STORM
  payload: WeatherParams
}

export interface IWeatherTropicalStormSuccess {
  type: HistoricalActions.FETCH_WEATHER_GLOBAL_STORM_SUCCESS

  data: []
}

export interface IWeatherTropicalStormFailed {
  type: HistoricalActions.FETCH_WEATHER_GLOBAL_STORM_FAILED
  payload: { error: string }
}

export interface IShowWeatherOceanCurrent {
  type: HistoricalActions.SHOW_WEATHER_OCEAN_CURRENT
  payload: { checked: boolean }
}

export interface IWeatherOceanCurrent {
  type: HistoricalActions.FETCH_WEATHER_OCEAN_CURRENT
  payload: WeatherParams
}

export interface IWeatherOceanCurrentSuccess {
  type: HistoricalActions.FETCH_WEATHER_OCEAN_CURRENT_SUCCESS

  data: []
}

export interface IWeatherOceanCurrentFailed {
  type: HistoricalActions.FETCH_WEATHER_OCEAN_CURRENT_FAILED
  payload: { error: string }
}

export interface ICancelWeatherOceanCurrent {
  type: HistoricalActions.CANCEL_WEATHER_OCEAN_CURRENT
}

export interface IShowWeatherWindBarbs {
  type: HistoricalActions.SHOW_WEATHER_WIND_BARBS
  payload: { checked: boolean }
}

export interface IWeatherWindBarbs {
  type: HistoricalActions.FETCH_WEATHER_WIND_BARBS
  payload: WeatherParams
}

export interface IWeatherWindBarbsSuccess {
  type: HistoricalActions.FETCH_WEATHER_WIND_BARBS_SUCCESS
  data: []
}

export interface IWeatherWindBarbsFailed {
  type: HistoricalActions.FETCH_WEATHER_WIND_BARBS_FAILED
  payload: { error: string }
}

export interface IShowWeathers {
  type: HistoricalActions.SHOW_WEATHERS
  data: []
}

export interface IHideWeathers {
  type: HistoricalActions.HIDE_WEATHERS
}

export interface ICancelWeatherWindBarbs {
  type: HistoricalActions.CANCEL_WEATHER_WIND_BARBS
}

export type HistoricalActionTypes =
  | IDurationSetCurrentDateAction
  | ISetSelectedImosAction
  | ISetSelectedVesselIdAction
  | ISetDurationSettingAction
  | IShowWeatherWaveHeight
  | IWeatherWaveHeight
  | IWeatherWaveHeightSuccess
  | IWeatherWaveHeightFailed
  | IShowWeatherSurfacePressure
  | IWeatherSurfacePressure
  | IWeatherSurfacePressureSuccess
  | IWeatherSurfacePressureFailed
  | IShowWeatherTropicalStorm
  | IWeatherTropicalStorm
  | IWeatherTropicalStormSuccess
  | IWeatherTropicalStormFailed
  | IShowWeatherOceanCurrent
  | IWeatherOceanCurrent
  | IWeatherOceanCurrentSuccess
  | IWeatherOceanCurrentFailed
  | IShowWeatherWindBarbs
  | IWeatherWindBarbs
  | IWeatherWindBarbsSuccess
  | IWeatherWindBarbsFailed
  | IShowWeathers
  | IHideWeathers
  | ICancelWeatherWindBarbs
  | ICancelWeatherOceanCurrent
