import genActions from "services/_base/actions"
import {
  VoyagesAction,
  IVoyagesFetch,
  IVoyagesFetchSuccess,
  IVoyagesFetchFailed,
  ITargetVesselsFetch,
  ITargetVesselsFetchSuccess,
  ITargetVesselsFetchFinished,
  ITargetVesselsFetchFailed,
  ISetVoyageFilters,
  IFetchVoyageArrivalPorts,
  IFetchVoyageArrivalPortsSuccess,
  IFetchVoyageArrivalPortsFailed,
  IFetchVoyageDeptPortsSuccess,
  IFetchVoyageDeptPorts,
  IFetchVoyageDeptPortsFailed,
  ISetSelectedVoyage,
  IExportVoyages,
  IExportVoyagesFailed,
  IVoyagesItemFetch,
  IVoyagesItemFetchSuccess,
  IVoyagesItemFetchFailed,
  IFetchVoyageSummaryCharts,
  IFetchVoyageSummaryChartsSuccess,
  IFetchVoyageSummaryChartsFailed,
  ISetShowChart,
  IFetchSpeedFuelAvgSpeedChart,
  IFetchSpeedFuelAvgSpeedChartSuccess,
  IFetchSpeedFuelAvgSpeedChartFailed,
  IFetchSpeedFuelLngConsChart,
  IFetchSpeedFuelLngConsChartSuccess,
  IFetchSpeedFuelLngConsChartFailed,
  IFetchSpeedFuelMgoConsChart,
  IFetchSpeedFuelMgoConsChartSuccess,
  IFetchSpeedFuelMgoConsChartFailed,
  IFetchPowerCurveChart,
  IFetchPowerCurveChartSuccess,
  IFetchPowerCurveChartFailed,
  IFetchTankCondCargoVolumeChart,
  IFetchTankCondCargoVolumeChartSuccess,
  IFetchTankCondCargoVolumeChartFailed,
  IFetchTankCondTankTempChart,
  IFetchTankCondTankTempChartSuccess,
  IFetchTankCondTankTempChartFailed,
  IFetchTankCondForcingVaporVolumeChart,
  IFetchTankCondForcingVaporVolumeChartSuccess,
  IFetchTankCondForcingVaporVolumeChartFailed,
  IFetchTankCondAvgBoilOffRateChart,
  IFetchTankCondAvgBoilOffRateChartSuccess,
  IFetchTankCondAvgBoilOffRateChartFailed,
  IExportVoyagesSuccess,
  IVoyagesUpdateManualFields,
  IVoyagesUpdateManualFieldsSuccess,
  IVoyagesUpdateManualFieldsFailed,
  IFetchManualPortsSuccess,
  IFetchManualPortsFailed,
  IFetchManualPorts,
  IVoyagesGetTz,
  IVoyagesManualImport,
  IVoyagesManualImportFailed,
  IVoyagesCreateSummaryVoyage,
  IVoyagesCreateSummaryVoyageSuccess,
  IVoyagesCreateSummaryVoyageFailed,
  IDeleteVoyage,
  IDeleteVoyageFailed,
  IRestoreVoyage,
  IRestoreVoyageFailed,
  IDeleteVoyageSuccess,
  IRestoreVoyageSuccess,
} from "./types"

export const { funcs: BaseFunctions, types: BaseActions } = genActions("voyages")

export function fetchTargetVessels(): ITargetVesselsFetch {
  return {
    type: VoyagesAction.FETCH_TARGET_VESSELS,
  }
}

export function fetchTargetVesselsSuccess(payload: any): ITargetVesselsFetchSuccess {
  return {
    type: VoyagesAction.FETCH_TARGET_VESSELS_SUCCESS,
    payload,
  }
}

export function fetchTargetVesselsFinished(payload: any): ITargetVesselsFetchFinished {
  return {
    type: VoyagesAction.FETCH_TARGET_VESSELS_FINISHED,
    payload,
  }
}

export function fetchTargetVesselsFailed(payload: any): ITargetVesselsFetchFailed {
  return {
    type: VoyagesAction.FETCH_TARGET_VESSELS_FAILED,
    payload,
  }
}

export function setVoyagesFilters(payload: any): ISetVoyageFilters {
  return {
    type: VoyagesAction.SET_VOYAGES_FILTERS,
    payload,
  }
}

export function fetchVoyages(payload: any): IVoyagesFetch {
  return {
    type: VoyagesAction.FETCH_VOYAGES,
    payload,
  }
}

export function fetchVoyagesSuccess(payload: any): IVoyagesFetchSuccess {
  return {
    type: VoyagesAction.FETCH_VOYAGES_SUCCESS,
    payload,
  }
}

export function fetchVoyagesFailed(payload: any): IVoyagesFetchFailed {
  return {
    type: VoyagesAction.FETCH_VOYAGES_FAILED,
    payload,
  }
}

export function fetchVoyagesItem(payload: any): IVoyagesItemFetch {
  return {
    type: VoyagesAction.FETCH_VOYAGES_ITEM,
    payload,
  }
}

export function fetchVoyagesItemSuccess(payload: any): IVoyagesItemFetchSuccess {
  return {
    type: VoyagesAction.FETCH_VOYAGES_ITEM_SUCCESS,
    payload,
  }
}

export function fetchVoyagesItemFailed(payload: any): IVoyagesItemFetchFailed {
  return {
    type: VoyagesAction.FETCH_VOYAGES_ITEM_FAILED,
    payload,
  }
}

export function fetchArrivalPorts(): IFetchVoyageArrivalPorts {
  return {
    type: VoyagesAction.FETCH_ARRIVAL_PORTS,
  }
}

export function fetchArrivalPortsSuccess(payload: any): IFetchVoyageArrivalPortsSuccess {
  return {
    type: VoyagesAction.FETCH_ARRIVAL_PORTS_SUCCESS,
    payload,
  }
}

export function fetchArrivalPortsFailed(payload: any): IFetchVoyageArrivalPortsFailed {
  return {
    type: VoyagesAction.FETCH_ARRIVAL_PORTS_FAILED,
    payload,
  }
}

export function fetchDeptPorts(): IFetchVoyageDeptPorts {
  return {
    type: VoyagesAction.FETCH_DEPT_PORTS,
  }
}

export function fetchDeptPortsSuccess(payload: any): IFetchVoyageDeptPortsSuccess {
  return {
    type: VoyagesAction.FETCH_DEPT_PORTS_SUCCESS,
    payload,
  }
}

export function fetchDeptPortsFailed(payload: any): IFetchVoyageDeptPortsFailed {
  return {
    type: VoyagesAction.FETCH_DEPT_PORTS_FAILED,
    payload,
  }
}

export function setSelectedVoyage(payload: any): ISetSelectedVoyage {
  return {
    type: VoyagesAction.SET_SELECTED_VOYAGE,
    payload,
  }
}

export function exportVoyages(payload: any): IExportVoyages {
  return {
    type: VoyagesAction.EXPORT_VOYAGES_SUMMARY,
    payload,
  }
}

export function exportVoyagesSuccess(): IExportVoyagesSuccess {
  return {
    type: VoyagesAction.EXPORT_VOYAGES_SUMMARY_SUCCESS,
  }
}

export function exportVoyagesFailed(payload: any): IExportVoyagesFailed {
  return {
    type: VoyagesAction.EXPORT_VOYAGES_SUMMARY_FAILED,
    payload,
  }
}

export function setShowChart(payload: { isShowChart: boolean }): ISetShowChart {
  return { type: VoyagesAction.SHOW_CHART, payload }
}

export function fetchVoyageSummaryCharts(payload: any): IFetchVoyageSummaryCharts {
  return {
    type: VoyagesAction.FETCH_SUMMARY_CHARTS,
    payload,
  }
}

export function fetchVoyageSummaryChartsSuccess(payload: any): IFetchVoyageSummaryChartsSuccess {
  return {
    type: VoyagesAction.FETCH_SUMMARY_CHARTS_SUCCESS,
    payload,
  }
}

export function fetchVoyageSummaryChartsFailed(payload: any): IFetchVoyageSummaryChartsFailed {
  return {
    type: VoyagesAction.FETCH_SUMMARY_CHARTS_FAILED,
    payload,
  }
}

export function fetchSpeedFuelAvgSpeedChart(payload: any): IFetchSpeedFuelAvgSpeedChart {
  return {
    type: VoyagesAction.FETCH_SPEED_FUEL_AVG_SPEED_CHART,
    payload,
  }
}

export function fetchSpeedFuelAvgSpeedChartSuccess(
  payload: any
): IFetchSpeedFuelAvgSpeedChartSuccess {
  return {
    type: VoyagesAction.FETCH_SPEED_FUEL_AVG_SPEED_CHART_SUCCESS,
    payload,
  }
}

export function fetchSpeedFuelAvgSpeedChartFailed(
  payload: any
): IFetchSpeedFuelAvgSpeedChartFailed {
  return {
    type: VoyagesAction.FETCH_SPEED_FUEL_AVG_SPEED_CHART_FAILED,
    payload,
  }
}

export function fetchSpeedFuelLngConsChart(payload: any): IFetchSpeedFuelLngConsChart {
  return {
    type: VoyagesAction.FETCH_SPEED_FUEL_LNG_CONS_CHART,
    payload,
  }
}

export function fetchSpeedFuelLngConsChartSuccess(
  payload: any
): IFetchSpeedFuelLngConsChartSuccess {
  return {
    type: VoyagesAction.FETCH_SPEED_FUEL_LNG_CONS_CHART_SUCCESS,
    payload,
  }
}

export function fetchSpeedFuelLngConsChartFailed(payload: any): IFetchSpeedFuelLngConsChartFailed {
  return {
    type: VoyagesAction.FETCH_SPEED_FUEL_LNG_CONS_CHART_FAILED,
    payload,
  }
}

export function fetchSpeedFuelMgoConsChart(payload: any): IFetchSpeedFuelMgoConsChart {
  return {
    type: VoyagesAction.FETCH_SPEED_FUEL_MGO_CONS_CHART,
    payload,
  }
}

export function fetchSpeedFuelMgoConsChartSuccess(
  payload: any
): IFetchSpeedFuelMgoConsChartSuccess {
  return {
    type: VoyagesAction.FETCH_SPEED_FUEL_MGO_CONS_CHART_SUCCESS,
    payload,
  }
}

export function fetchSpeedFuelMgoConsChartFailed(payload: any): IFetchSpeedFuelMgoConsChartFailed {
  return {
    type: VoyagesAction.FETCH_SPEED_FUEL_MGO_CONS_CHART_FAILED,
    payload,
  }
}

export function fetchPowerCurveChart(payload: any): IFetchPowerCurveChart {
  return {
    type: VoyagesAction.FETCH_POWER_CURVE_CHART,
    payload,
  }
}

export function fetchPowerCurveChartSuccess(payload: any): IFetchPowerCurveChartSuccess {
  return {
    type: VoyagesAction.FETCH_POWER_CURVE_CHART_SUCCESS,
    payload,
  }
}

export function fetchPowerCurveChartFailed(payload: any): IFetchPowerCurveChartFailed {
  return {
    type: VoyagesAction.FETCH_POWER_CURVE_CHART_FAILED,
    payload,
  }
}

export function fetchTankCondCargoVolumeChart(payload: any): IFetchTankCondCargoVolumeChart {
  return {
    type: VoyagesAction.FETCH_TANK_COND_CARGO_VOLUME_CHART,
    payload,
  }
}

export function fetchTankCondCargoVolumeChartSuccess(
  payload: any
): IFetchTankCondCargoVolumeChartSuccess {
  return {
    type: VoyagesAction.FETCH_TANK_COND_CARGO_VOLUME_CHART_SUCCESS,
    payload,
  }
}

export function fetchTankCondCargoVolumeChartFailed(
  payload: any
): IFetchTankCondCargoVolumeChartFailed {
  return {
    type: VoyagesAction.FETCH_TANK_COND_CARGO_VOLUME_CHART_FAILED,
    payload,
  }
}

export function fetchTankCondTankTempChart(payload: any): IFetchTankCondTankTempChart {
  return {
    type: VoyagesAction.FETCH_TANK_COND_TANK_TEMP_CHART,
    payload,
  }
}

export function fetchTankCondTankTempChartSuccess(
  payload: any
): IFetchTankCondTankTempChartSuccess {
  return {
    type: VoyagesAction.FETCH_TANK_COND_TANK_TEMP_CHART_SUCCESS,
    payload,
  }
}

export function fetchTankCondTankTempChartFailed(payload: any): IFetchTankCondTankTempChartFailed {
  return {
    type: VoyagesAction.FETCH_TANK_COND_TANK_TEMP_CHART_FAILED,
    payload,
  }
}

export function fetchTankCondForcingVaporVolumeChart(
  payload: any
): IFetchTankCondForcingVaporVolumeChart {
  return {
    type: VoyagesAction.FETCH_TANK_COND_FORCING_VAPOR_VOLUME_CHART,
    payload,
  }
}

export function fetchTankCondForcingVaporVolumeChartSuccess(
  payload: any
): IFetchTankCondForcingVaporVolumeChartSuccess {
  return {
    type: VoyagesAction.FETCH_TANK_COND_FORCING_VAPOR_VOLUME_CHART_SUCCESS,
    payload,
  }
}

export function fetchTankCondForcingVaporVolumeChartFailed(
  payload: any
): IFetchTankCondForcingVaporVolumeChartFailed {
  return {
    type: VoyagesAction.FETCH_TANK_COND_FORCING_VAPOR_VOLUME_CHART_FAILED,
    payload,
  }
}

export function fetchTankCondAvgBoilOffRateChart(payload: any): IFetchTankCondAvgBoilOffRateChart {
  return {
    type: VoyagesAction.FETCH_TANK_COND_AVG_BOIL_OFF_RATE_CHART,
    payload,
  }
}

export function fetchTankCondAvgBoilOffRateChartSuccess(
  payload: any
): IFetchTankCondAvgBoilOffRateChartSuccess {
  return {
    type: VoyagesAction.FETCH_TANK_COND_AVG_BOIL_OFF_RATE_CHART_SUCCESS,
    payload,
  }
}

export function fetchTankCondAvgBoilOffRateChartFailed(
  payload: any
): IFetchTankCondAvgBoilOffRateChartFailed {
  return {
    type: VoyagesAction.FETCH_TANK_COND_AVG_BOIL_OFF_RATE_CHART_FAILED,
    payload,
  }
}

export function updateManualFields(payload: any): IVoyagesUpdateManualFields {
  return {
    type: VoyagesAction.UPDATE_MANUAL_FIELDS,
    payload,
  }
}

export function updateManualFieldsSuccess(payload: any): IVoyagesUpdateManualFieldsSuccess {
  return {
    type: VoyagesAction.UPDATE_MANUAL_FIELDS_SUCCESS,
    payload,
  }
}

export function updateManualFieldsFailed(payload: any): IVoyagesUpdateManualFieldsFailed {
  return {
    type: VoyagesAction.UPDATE_MANUAL_FIELDS_FAILED,
    payload,
  }
}

export function fetchManualPorts(): IFetchManualPorts {
  return {
    type: VoyagesAction.FETCH_MANUAL_PORTS,
  }
}

export function fetchManualPortsSuccess(payload: any): IFetchManualPortsSuccess {
  return {
    type: VoyagesAction.FETCH_MANUAL_PORTS_SUCCESS,
    payload,
  }
}

export function fetchManualPortsFailed(payload: any): IFetchManualPortsFailed {
  return {
    type: VoyagesAction.FETCH_MANUAL_PORTS_FAILED,
    payload,
  }
}

export function getManualTz(payload: any): IVoyagesGetTz {
  return {
    type: VoyagesAction.GET_TZ,
    payload,
  }
}

export function manualImportVoyage(payload: any): IVoyagesManualImport {
  return {
    type: VoyagesAction.MANUAL_IMPORT,
    payload,
  }
}

export function manualImportVoyageFailed(payload: any): IVoyagesManualImportFailed {
  return {
    type: VoyagesAction.MANUAL_IMPORT_FAILED,
    payload,
  }
}

export function createSummaryVoyage(payload: any): IVoyagesCreateSummaryVoyage {
  return {
    type: VoyagesAction.CREATE_SUMMARY_VOYAGE,
    payload,
  }
}

export function createSummaryVoyageSuccess(payload: any): IVoyagesCreateSummaryVoyageSuccess {
  return {
    type: VoyagesAction.CREATE_SUMMARY_VOYAGE_SUCCESS,
    payload,
  }
}

export function createSummaryVoyageFailed(payload: any): IVoyagesCreateSummaryVoyageFailed {
  return {
    type: VoyagesAction.CREATE_SUMMARY_VOYAGE_FAILED,
    payload,
  }
}

export function deleteVoyage(payload: any): IDeleteVoyage {
  return {
    type: VoyagesAction.DELETE_VOYAGE,
    payload,
  }
}

export function deleteVoyageSuccess(): IDeleteVoyageSuccess {
  return {
    type: VoyagesAction.DELETE_VOYAGE_SUCCESS,
  }
}

export function deleteVoyageFailed(payload: any): IDeleteVoyageFailed {
  return {
    type: VoyagesAction.DELETE_VOYAGE_FAILED,
    payload,
  }
}

export function restoreVoyage(payload: any): IRestoreVoyage {
  return {
    type: VoyagesAction.RESTORE_VOYAGE,
    payload,
  }
}

export function restoreVoyageSuccess(): IRestoreVoyageSuccess {
  return {
    type: VoyagesAction.RESTORE_VOYAGE_SUCCESS,
  }
}

export function restoreVoyageFailed(payload: any): IRestoreVoyageFailed {
  return {
    type: VoyagesAction.RESTORE_VOYAGE_FAILED,
    payload,
  }
}
