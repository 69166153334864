import genActions from "services/_base/actions"
import {
  VoyagesComparisonAction,
  ITargetVesselsFetch,
  ITargetVesselsFetchSuccess,
  ITargetVesselsFetchFinished,
  ITargetVesselsFetchFailed,
  IVoyagesComparisonFetch,
  IVoyagesComparisonFetchSuccess,
  IVoyagesComparisonFetchFailed,
  IVoyagesHistoryRoutesFetch,
  IVoyagesHistoryRoutesFetchSuccess,
  IVoyagesHistoryRoutesFetchFailed,
  IVoyageNumberFetch,
  IVoyageNumberFetchSuccess,
  IVoyageNumberFetchFailed,
  IDeleteCondition,
  IDeparturePortsFetch,
  IDeparturePortsFetchSuccess,
  IDeparturePortsFetchFailed,
  IArrivalPortsFetch,
  IArrivalPortsFetchSuccess,
  IArrivalPortsFetchFailed,
  IDuplicateCondition,
  ISaveConditions,
  IResetVoyageComparison,
} from "./types"

export const { funcs: BaseFunctions, types: BaseActions } = genActions("voyages_comparison")

export function fetchTargetVessels(): ITargetVesselsFetch {
  return {
    type: VoyagesComparisonAction.FETCH_TARGET_VESSELS,
  }
}

export function fetchTargetVesselsSuccess(payload: any): ITargetVesselsFetchSuccess {
  return {
    type: VoyagesComparisonAction.FETCH_TARGET_VESSELS_SUCCESS,
    payload,
  }
}

export function fetchTargetVesselsFinished(payload: any): ITargetVesselsFetchFinished {
  return {
    type: VoyagesComparisonAction.FETCH_TARGET_VESSELS_FINISHED,
    payload,
  }
}

export function fetchTargetVesselsFailed(payload: any): ITargetVesselsFetchFailed {
  return {
    type: VoyagesComparisonAction.FETCH_TARGET_VESSELS_FAILED,
    payload,
  }
}

export function fetchDeparturePorts(): IDeparturePortsFetch {
  return {
    type: VoyagesComparisonAction.FETCH_DEPARTURE_PORTS,
  }
}

export function fetchDeparturePortsSuccess(payload: any): IDeparturePortsFetchSuccess {
  return {
    type: VoyagesComparisonAction.FETCH_DEPARTURE_PORTS_SUCCESS,
    payload,
  }
}

export function fetchDeparturePortsFailed(payload: any): IDeparturePortsFetchFailed {
  return {
    type: VoyagesComparisonAction.FETCH_DEPARTURE_PORTS_FAILED,
    payload,
  }
}

export function fetchArrivalPorts(): IArrivalPortsFetch {
  return {
    type: VoyagesComparisonAction.FETCH_ARRIVAL_PORTS,
  }
}

export function fetchArrivalPortsSuccess(payload: any): IArrivalPortsFetchSuccess {
  return {
    type: VoyagesComparisonAction.FETCH_ARRIVAL_PORTS_SUCCESS,
    payload,
  }
}

export function fetchArrivalPortsFailed(payload: any): IArrivalPortsFetchFailed {
  return {
    type: VoyagesComparisonAction.FETCH_ARRIVAL_PORTS_FAILED,
    payload,
  }
}

export function fetchVoyagesComparison(payload: any): IVoyagesComparisonFetch {
  return {
    type: VoyagesComparisonAction.FETCH_VOYAGES_COMPARISON,
    payload,
  }
}

export function fetchVoyagesComparisonSuccess(payload: any): IVoyagesComparisonFetchSuccess {
  return {
    type: VoyagesComparisonAction.FETCH_VOYAGES_COMPARISON_SUCCESS,
    payload,
  }
}

export function fetchVoyagesComparisonFailed(payload: any): IVoyagesComparisonFetchFailed {
  return {
    type: VoyagesComparisonAction.FETCH_VOYAGES_COMPARISON_FAILED,
    payload,
  }
}

export function fetchVoyagesHistoryRoutes(): IVoyagesHistoryRoutesFetch {
  return { type: VoyagesComparisonAction.FETCH_VOYAGES_HISTORY_ROUTES }
}

export function fetchVoyagesHistoryRoutesSuccess(payload: any): IVoyagesHistoryRoutesFetchSuccess {
  return { type: VoyagesComparisonAction.FETCH_VOYAGES_HISTORY_ROUTES_SUCCESS, payload }
}

export function fetchVoyagesHistoryRoutesFailed(payload: any): IVoyagesHistoryRoutesFetchFailed {
  return { type: VoyagesComparisonAction.FETCH_VOYAGES_HISTORY_ROUTES_FAILED, payload }
}

export function fetchVoyageNumber(payload: any): IVoyageNumberFetch {
  return { type: VoyagesComparisonAction.FETCH_VOYAGE_NUMBER, payload }
}

export function fetchVoyageNumberSuccess(payload: any): IVoyageNumberFetchSuccess {
  return { type: VoyagesComparisonAction.FETCH_VOYAGE_NUMBER_SUCCESS, payload }
}

export function fetchVoyageNumberFailed(payload: any): IVoyageNumberFetchFailed {
  return { type: VoyagesComparisonAction.FETCH_VOYAGE_NUMBER_FAILED, payload }
}

export function duplicateCondition(payload: any): IDuplicateCondition {
  return { type: VoyagesComparisonAction.DUPLICATE_CONDITION, payload }
}

export function deleteCondition(payload: any): IDeleteCondition {
  return { type: VoyagesComparisonAction.DELETE_CONDITION, payload }
}

export function saveConditions(payload: any): ISaveConditions {
  return { type: VoyagesComparisonAction.SAVE_CONDITIONS, payload }
}

export function resetVoyageComparison(): IResetVoyageComparison {
  return { type: VoyagesComparisonAction.RESET_VOYAGES_COMPARISON }
}
