export interface IFOCItem {
  id: number | string
  imo: number
  speed: number
  laden: number
  ballast: number
  updated_at: string
  vessel_name: string
  updated_by: string
}

export interface IPortItem {
  id: number | string
  name: string
  time_zone: string
  time_zone_code: string
  country: string
  country_code: string
  can_delete: boolean
  updated_at: string
  updated_by: string
}

export interface IDataSettingsFilter {
  imo: string
  sort_by: string
  sort_order: string
  page: number
}

export interface IBasicFilter {
  sort_by: string
  sort_order: string
  page: number
}

export interface IDataSettingsPortState {
  time_zones: { loading: boolean; error: string; data: [] }
  countries: { loading: boolean; error: string; data: [] }
  list: {
    port: {
      loading: boolean
      error: string
      data: IPortItem[]
      total_records: number
      current_page: number
      per_page: number
      total_pages: number
      isDeleteItem: boolean
      isEditItem: boolean
    }
  }
  item: {
    loading: boolean
    error: string
    data: IPortItem
  }
  filters: {
    port: {
      payload: IBasicFilter
    }
  }
}

export enum DataSettingsPortActions {
  FETCH_TIME_ZONES = "[data settings port] fetch time zones",
  FETCH_TIME_ZONES_SUCCESS = "[data settings port] fetch time zones success",
  FETCH_TIME_ZONES_FAILED = "[data settings port] fetch time zones failed",

  FETCH_COUNTRIES = "[data settings port] fetch countries",
  FETCH_COUNTRIES_SUCCESS = "[data settings port] fetch countries success",
  FETCH_COUNTRIES_FAILED = "[data settings port] fetch countries failed",

  // Port List
  CREATE_PORT = "[data settings port] create port",
  CREATE_PORT_SUCCESS = "[data settings port] create port success",
  CREATE_PORT_FAILED = "[data settings port] create port failed",

  UPDATE_PORT = "[data settings port] update port",
  UPDATE_PORT_SUCCESS = "[data settings port] update port success",
  UPDATE_PORT_FAILED = "[data settings port] update port failed",

  DELETE_PORT = "[data settings port] delete port",
  DELETE_PORT_SUCCESS = "[data settings port] delete port success",
  DELETE_PORT_FAILED = "[data settings port] delete port failed",

  FETCH_LIST_PORT_ALL = "[data settings port] fetch all",
  FETCH_LIST_PORT_ALL_SUCCESS = "[data settings port] fetch all success",
  FETCH_LIST_PORT_ALL_FINISHED = "[data settings port] fetch all finished",
  FETCH_LIST_PORT_ALL_FAILED = "[data settings port] fetch all failed",
}

export interface ITimeZonesFetch {
  type: DataSettingsPortActions.FETCH_TIME_ZONES
  payload: { countries: string }
}

export interface ITimeZonesFetchSuccess {
  type: DataSettingsPortActions.FETCH_TIME_ZONES_SUCCESS
  payload: {
    data: []
  }
}

export interface ITimeZonesFetchFailed {
  type: DataSettingsPortActions.FETCH_TIME_ZONES_FAILED
  payload: { error: string }
}

export interface ICountriesFetch {
  type: DataSettingsPortActions.FETCH_COUNTRIES
}

export interface ICountriesFetchSuccess {
  type: DataSettingsPortActions.FETCH_COUNTRIES_SUCCESS
  payload: {
    data: []
  }
}

export interface ICountriesFetchFailed {
  type: DataSettingsPortActions.FETCH_COUNTRIES_FAILED
  payload: { error: string }
}

// Port List
export interface ICreatePort {
  type: DataSettingsPortActions.CREATE_PORT
  payload: {
    data: any
  }
}

export interface ICreatePortSuccess {
  type: DataSettingsPortActions.CREATE_PORT_SUCCESS
}

export interface ICreatePortFailed {
  type: DataSettingsPortActions.CREATE_PORT_FAILED
  payload: { error: string }
}

export interface IUpdatePort {
  type: DataSettingsPortActions.UPDATE_PORT
  payload: {
    id: string
    data: any
  }
}

export interface IUpdatePortSuccess {
  type: DataSettingsPortActions.UPDATE_PORT_SUCCESS
}

export interface IUpdatePortFailed {
  type: DataSettingsPortActions.UPDATE_PORT_FAILED
  payload: { error: string }
}

export interface IDeletePort {
  type: DataSettingsPortActions.DELETE_PORT
  payload: {
    id: string
  }
}

export interface IDeletePortSuccess {
  type: DataSettingsPortActions.DELETE_PORT_SUCCESS
}

export interface IDeletePortFailed {
  type: DataSettingsPortActions.DELETE_PORT_FAILED
  payload: { error: string }
}

export interface IListPortFetchAll {
  type: DataSettingsPortActions.FETCH_LIST_PORT_ALL
  payload: IBasicFilter
}

export interface IListPortFetchAllSuccess {
  type: DataSettingsPortActions.FETCH_LIST_PORT_ALL_SUCCESS
  payload: {
    data: []
    headers: any
  }
}

export interface IListPortFetchAllFinished {
  type: DataSettingsPortActions.FETCH_LIST_PORT_ALL_FINISHED
  payload: {
    allData: []
    headers: any
  }
}

export interface IListPortFetchAllFailed {
  type: DataSettingsPortActions.FETCH_LIST_PORT_ALL_FAILED
  payload: { error: string }
}

export type DataSettingsPortTypes =
  | ITimeZonesFetch
  | ITimeZonesFetchSuccess
  | ITimeZonesFetchFailed
  | ICountriesFetch
  | ICountriesFetchSuccess
  | ICountriesFetchFailed
  // Port List
  | ICreatePort
  | ICreatePortSuccess
  | ICreatePortFailed
  | IUpdatePort
  | IUpdatePortSuccess
  | IUpdatePortFailed
  | IDeletePort
  | IDeletePortSuccess
  | IDeletePortFailed
  | IListPortFetchAll
  | IListPortFetchAllSuccess
  | IListPortFetchAllFinished
  | IListPortFetchAllFailed
