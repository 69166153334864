import { Row, Col, Button, Checkbox } from "antd"

import GuardianPopoverTooltip from "components/GuardianPopoverTooltip"
import GuardianSlider from "components/GuardianSlider"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { HistoricalSelector } from "services/historical/selectors"
import { DashboardSelector } from "services/dashboard/selectors"
import { useInjectReducer } from "core/hooks/useInjectReducer"
import dashboardReducer from "services/dashboard/reducers"
import voyagesReducer from "services/voyages/reducers"
import { createMarks } from "core/model/DateTime"
import moment from "moment"
import {
  setDurationCurrentDate,
  setSelectedImos,
  setDurationSetting,
  showWeatherWaveHeight,
  showWeatherOceanCurrent,
  showWeatherSurfacePressure,
  showWeatherGlobalStorm,
  showWeatherWindBarbs,
  setSelectedVesselId,
} from "services/historical/actions"
import { WEATHER_KEY } from "core/constants/weather"
import { setSelectedPoint, resetVesselList } from "services/dashboard/actions"
import { debounce, get } from "lodash"
import { WeatherElements } from "core/model/Weather"
import { HistoricalActionTypes } from "services/historical/types"
import { ArrowLeftOutlined, CloudOutlined } from "@ant-design/icons"
import historicalReducer from "services/historical/reducers"
import { setPinHeaderAction } from "services/layout/actions"
import { VoyagesSelector } from "services/voyages/selectors"
import { fetchVoyagesItem, setSelectedVoyage } from "services/voyages/actions"
import { useHistory } from "react-router-dom"
import { VOYAGES_SUMMARY } from "core/constants/routes"
import { isEmptyValue } from "core/utils/unifyValueChartData"
import voyagesSaga from "services/voyages/sagas"
import useInjectSaga from "core/hooks/useInjectSaga"
import { SagaInjectionModes } from "core/constants/common"

const MAX_WEEK_HOURS = 360 // hours
const defaultFromDate = moment.utc().subtract(14, "d").startOf("d")

const Index = () => {
  useInjectReducer({ key: "dashboard", reducer: dashboardReducer })
  useInjectReducer({ key: "historical", reducer: historicalReducer })
  useInjectReducer({ key: "voyages", reducer: voyagesReducer })
  useInjectSaga({
    key: "voyages",
    saga: voyagesSaga,
    mode: SagaInjectionModes.DAEMON,
  })

  const [sliderMarks, setSliderMarks] = useState(createMarks(360 + 24, defaultFromDate.toDate()))
  const [sliderValue, setSliderValue] = useState(0)
  const [currentHourValue, setCurrentHourValue] = useState(0)
  const [maximumHoursValue, setMaximumHoursValue] = useState(MAX_WEEK_HOURS)
  const [mounted, setMounted] = useState(false)
  const [isCustomSlider, setIsCustomSlider] = useState(false)
  const [fromDateValue, setFromDateValue] = useState(defaultFromDate)
  const [popupVisible, setPopupVisible] = useState(false)
  const [disabledWeathers, setDisabledWeathers] = useState(true)
  const {
    vessel: {
      isFinished,
      selectedVessel: { selectedPoint },
    },
  } = useSelector(DashboardSelector)
  const {
    durationSettings,
    weathers: { weathersChecked },
  } = useSelector(HistoricalSelector)
  const {
    selected_voyage: { data, error },
  } = useSelector(VoyagesSelector)
  const onSelectWeatherHistoryType = (action: HistoricalActionTypes) => {
    dispatch(action)
  }
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname, search } = history.location

  useEffect(() => {
    const id = pathname.substr(pathname.lastIndexOf("/") + 1)
    if (isEmptyValue(id) || id === "") history.push(`${VOYAGES_SUMMARY}${search}`)
    dispatch(fetchVoyagesItem({ id }))
    setMounted(true)
    return () => {
      setMounted(false)
      dispatch(resetVesselList())
      dispatch(setSelectedImos({ imos: [] }))
      dispatch(setSelectedVesselId({ vessel_id: null }))
      dispatch(setSelectedVoyage(null))
    }
  }, [])

  useEffect(() => {
    setDisabledWeathers(weathersChecked.length > 2)
  }, [weathersChecked])

  useEffect(() => {
    if (selectedPoint && mounted) {
      const selectedTime = moment.utc(selectedPoint.dateTime)
      const selectedPointDate = moment.utc(selectedPoint.dateTime)
      const duration = moment.duration(fromDateValue.diff(selectedTime))
      const durationHours = Math.abs(duration.asHours())
      setCurrentHourValue(durationHours)
      setSliderValue(durationHours)
      if (moment.utc(durationSettings.currentDate).diff(moment.utc(selectedPointDate))) {
        dispatch(setDurationCurrentDate({ date: selectedPointDate.toDate() }))
      }
    }
  }, [selectedPoint])

  const onSelectDate = (startDate, endDate, imo, vessel_id) => {
    const currentDate = startDate ? moment.utc(startDate) : moment.utc(endDate).subtract(30, "d")
    const fromDate = startDate
      ? moment.utc(startDate).startOf("d")
      : moment.utc(endDate).subtract(30, "d").startOf("d")
    const toDate = endDate
      ? moment.utc(endDate).endOf("d")
      : moment.utc(startDate).add(30, "d").startOf("d")

    const duration = moment.duration(fromDate.diff(toDate))
    const durationHours = Math.abs(duration.asHours())

    const markHoursValue = durationHours + 24

    setFromDateValue(fromDate)
    setSliderValue(0)
    setCurrentHourValue(0)
    setMaximumHoursValue(durationHours)
    setSliderMarks(createMarks(markHoursValue, moment.utc(fromDate).toDate()))
    setIsCustomSlider(true)

    dispatch(
      setDurationSetting({
        fromDate: fromDate.toDate(),
        toDate: toDate.toDate(),
        currentDate: currentDate.toDate(),
      })
    )

    const selectedValue = {
      dateTime: fromDate.format(),
      imo,
      vessel_id,
    }
    dispatch(setSelectedPoint(selectedValue))
  }

  const onSelectSlider = (hours: number) => {
    setSliderValue(hours)
    const date = moment.utc(fromDateValue).add(hours, "hours")
    dispatch(setDurationCurrentDate({ date: date.toDate() }))
    setIsCustomSlider(true)
  }

  const tipFormatter = (value: number | undefined) => {
    if (value !== undefined) {
      return `${value % 24}:00`
    }
    return ""
  }

  useEffect(() => {
    if (!isFinished) return

    if (error) history.push(VOYAGES_SUMMARY)

    if (data && Object.keys(data).length > 0) {
      onSelectDate(data.apply_atd_utc, data.apply_ata_utc, data.imo, data.vessel_id)
      dispatch(setSelectedVesselId({ vessel_id: data.vessel_id }))
      dispatch(setSelectedImos({ imos: [data.imo] }))
    }
  }, [isFinished, data, error])

  const weatherCheckbox = () => (
    <>
      <Checkbox
        className="marginLeft0"
        onChange={(e) => {
          return onSelectWeatherHistoryType(showWeatherOceanCurrent({ checked: e.target.checked }))
        }}
        disabled={disabledWeathers && weathersChecked.indexOf(WEATHER_KEY.OCEAN_CURRENT) < 0}
      >
        {WeatherElements.OceanCurrent}
      </Checkbox>
      <Checkbox
        className="marginLeft0"
        onChange={(e) => {
          return onSelectWeatherHistoryType(
            showWeatherSurfacePressure({ checked: e.target.checked })
          )
        }}
        disabled={disabledWeathers && weathersChecked.indexOf(WEATHER_KEY.SURFACE_PRESSURE) < 0}
      >
        {WeatherElements.SurfacePressure}
      </Checkbox>
      <Checkbox
        className="marginLeft0"
        onChange={(e) => {
          return onSelectWeatherHistoryType(showWeatherGlobalStorm({ checked: e.target.checked }))
        }}
        disabled={disabledWeathers && weathersChecked.indexOf(WEATHER_KEY.GLOBAL_STORM) < 0}
      >
        {WeatherElements.TropicalStorm}
      </Checkbox>
      <Checkbox
        className="marginLeft0"
        onChange={(e) => {
          return onSelectWeatherHistoryType(showWeatherWaveHeight({ checked: e.target.checked }))
        }}
        disabled={disabledWeathers && weathersChecked.indexOf(WEATHER_KEY.WAVE_HEIGHT) < 0}
      >
        {WeatherElements.WaveHeight}
      </Checkbox>
      <Checkbox
        className="marginLeft0"
        onChange={(e) => {
          return onSelectWeatherHistoryType(showWeatherWindBarbs({ checked: e.target.checked }))
        }}
        disabled={disabledWeathers && weathersChecked.indexOf(WEATHER_KEY.WIND_BARB) < 0}
      >
        {WeatherElements.Wind}
      </Checkbox>
    </>
  )

  useEffect(() => {
    dispatch(setPinHeaderAction({ isPinHeader: popupVisible }))
  }, [popupVisible])

  return (
    <>
      <Row gutter={24}>
        <Col flex="104px">
          <Row gutter={16}>
            <Col>
              <Button
                shape="circle"
                className="get-current"
                icon={<ArrowLeftOutlined />}
                onClick={() => history.push(`${VOYAGES_SUMMARY}${search}`)}
              />
            </Col>
            <Col>
              <GuardianPopoverTooltip
                title=""
                placement="topLeft"
                hoverContent="Weather Types"
                content={weatherCheckbox}
                trigger="click"
                isVisible={popupVisible}
                setIsVisible={setPopupVisible}
                visibleClassName="weather-type__popover"
              >
                <Button shape="circle" className="get-current" icon={<CloudOutlined />} />
              </GuardianPopoverTooltip>
            </Col>
          </Row>
        </Col>
        <Col flex="auto">
          {isCustomSlider && (
            <GuardianSlider
              marks={sliderMarks}
              onSelectWeatherSlider={debounce(onSelectSlider, 150)}
              value={sliderValue}
              step={1}
              defaultValue={currentHourValue}
              max={maximumHoursValue}
              className="WeatherSettingSlider"
              tipFormatter={tipFormatter}
            />
          )}
        </Col>
      </Row>
    </>
  )
}

Index.defaultProps = {
  hasBack: false,
}

export default Index
