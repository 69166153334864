export interface IVesselsManagementState {
  companies: {
    loading: boolean
    error: string
    data: { [key: string]: string | null }[]
    total_records: number
    current_page: number
    per_page: number
    total_pages: number
    company_id: number
  }
  vessels: {
    loading: boolean
    error: string | null
    data: { [key: string]: string | null }[]
    total_records: number
    current_page: number
    per_page: number
    total_pages: number
  }
  non_target_vessels: {
    loading: boolean
    error: string | null
    data: { [key: string]: string | null }[]
    total_records: number
    current_page: number
    per_page: number
    total_pages: number
  }
  vessel: {
    loading: boolean
    error: string | null
    data: any
    editSuccess: boolean
  }
  non_target_vessel: {
    loading: boolean
    error: string | null
    data: any
    editSuccess: boolean
  }
  import_genre_file: {
    loading: boolean
    error: string | null
    data: string
  }
  sims_data_type: {
    loading: boolean
    error: string | null
    data: any
  }
}

export enum VesselsManagementActions {
  FETCH_VESSELS_ALL = "[vessels] fetch all",
  FETCH_VESSELS_ALL_SUCCESS = "[vessels] fetch all success",
  FETCH_VESSELS_ALL_FINISHED = "[vessels] fetch all finished",
  FETCH_VESSELS_ALL_FAILED = "[vessels] fetch all failed",
  FETCH_NON_TARGET_VESSELS = "[non_target_vessels] fetch all",
  FETCH_NON_TARGET_VESSELS_SUCCESS = "[non_target_vessels] fetch all success",
  FETCH_NON_TARGET_VESSELS_FINISHED = "[non_target_vessels] fetch all finished",
  FETCH_NON_TARGET_VESSELS_FAILED = "[non_target_vessels] fetch all failed",
  CREATE_VESSEL = "[vessel] create",
  CREATE_VESSEL_SUCCESS = "[vessel] create success",
  CREATE_VESSEL_FAILED = "[vessel] create failed",
  CREATE_NON_TARGET_VESSEL = "[non_target_vessels] create",
  CREATE_NON_TARGET_VESSEL_SUCCESS = "[non_target_vessels] create success",
  CREATE_NON_TARGET_VESSEL_FAILED = "[non_target_vessels] create failed",
  EDIT_VESSEL = "[vessel] edit",
  EDIT_VESSEL_SUCCESS = "[vessel] edit success",
  EDIT_VESSEL_FAILED = "[vessel] edit failed",
  DELETE_VESSEL = "[vessel] delete",
  DELETE_VESSEL_SUCCESS = "[vessel] delete success",
  DELETE_VESSEL_FAILED = "[vessel] delete failed",
  DELETE_NON_TARGET_VESSEL = "[non_target_vessels] delete",
  DELETE_NON_TARGET_VESSEL_SUCCESS = "[non_target_vessels] delete success",
  DELETE_NON_TARGET_VESSEL_FAILED = "[non_target_vessels] delete failed",

  EXPORT_GENRE_MAPPING = "[export_genre_mapping] export",
  EXPORT_GENRE_MAPPING_FAILED = "[export_genre_mapping] export failed",

  EXPORT_GENRE_ERROR = "[export_genre_error] export",
  EXPORT_GENRE_ERROR_FAILED = "[export_genre_error] export failed",

  UPLOAD_FILE = "[vessel] upload file",
  UPLOAD_FILE_SUCCESS = "[vessel] upload file success",
  UPLOAD_FILE_FAILED = "[vessel] upload file failed",
  CLEAN_UPLOAD_FILE = "[vessel] clean upload genre",

  IMPORT_GENRE = "[vessel] import genre",
  IMPORT_GENRE_SUCCESS = "[vessel] import genre success",
  IMPORT_GENRE_FAILED = "[vessel] import genre failed",

  FETCH_SIMS_DATA_TYPE = "[vessels] fetch SIM DATA TYPE",
  FETCH_SIMS_DATA_TYPE_SUCCESS = "[vessels] fetch SIM DATA TYPE success",
  FETCH_SIMS_DATA_TYPE_FAILED = "[vessels] fetch SIM DATA TYPE failed",

  FETCH_LIST_COMPANY_ALL = "[vessels manage] fetch all company",
  FETCH_LIST_COMPANY_ALL_SUCCESS = "[vessels manage] fetch all company success",
  FETCH_LIST_COMPANY_ALL_FINISHED = "[vessels manage] fetch all company finished",
  FETCH_LIST_COMPANY_ALL_FAILED = "[vessels manage] fetch all company failed",

  SET_COMPANY_ID = "[vessel manage] set company id",
  RESET_ALL_VESSELS = "[vessel manage] reset all vessel list",
}

export interface IVesselsFetchAllAction {
  type: VesselsManagementActions.FETCH_VESSELS_ALL
}

export interface IVesselsFetchAllSuccessAction {
  type: VesselsManagementActions.FETCH_VESSELS_ALL_SUCCESS
  payload: {
    data: []
  }
}

export interface IVesselsFetchAllFinishedAction {
  type: VesselsManagementActions.FETCH_VESSELS_ALL_FINISHED
  payload: {
    allData: []
    headers: any
  }
}

export interface IVesselsFetchAllFailedAction {
  type: VesselsManagementActions.FETCH_VESSELS_ALL_FAILED
  payload: { error: string }
}

export interface INonTargetVesselsFetchAction {
  type: VesselsManagementActions.FETCH_NON_TARGET_VESSELS
}

export interface INonTargetVesselsFetchSuccessAction {
  type: VesselsManagementActions.FETCH_NON_TARGET_VESSELS_SUCCESS
  payload: {
    data: []
    headers: any
  }
}

export interface INonTargetVesselsFetchFinishedAction {
  type: VesselsManagementActions.FETCH_NON_TARGET_VESSELS_FINISHED
}

export interface INonTargetVesselsFetchFailedAction {
  type: VesselsManagementActions.FETCH_NON_TARGET_VESSELS_FAILED
  payload: { error: string }
}

export interface IVesselCreateSuccessAction {
  type: VesselsManagementActions.CREATE_VESSEL_SUCCESS
}

export interface IVesselCreateFailedAction {
  type: VesselsManagementActions.CREATE_VESSEL_FAILED
  payload: { error: string }
}

export interface IVesselCreateAction {
  type: VesselsManagementActions.CREATE_VESSEL
  payload: {
    data: []
  }
}

export interface INonTargetVesselCreateSuccessAction {
  type: VesselsManagementActions.CREATE_NON_TARGET_VESSEL_SUCCESS
}

export interface INonTargetVesselCreateFailedAction {
  type: VesselsManagementActions.CREATE_NON_TARGET_VESSEL_FAILED
  payload: { error: string }
}

export interface INonTargetVesselCreateAction {
  type: VesselsManagementActions.CREATE_NON_TARGET_VESSEL
  payload: {
    data: []
  }
}

export interface IVesselEditSuccessAction {
  type: VesselsManagementActions.EDIT_VESSEL_SUCCESS
}

export interface IVesselEditFailedAction {
  type: VesselsManagementActions.EDIT_VESSEL_FAILED
  payload: { error: string }
}

export interface IVesselEditAction {
  type: VesselsManagementActions.EDIT_VESSEL
  payload: {
    data: []
  }
}

export interface IVesselDeleteSuccessAction {
  type: VesselsManagementActions.DELETE_VESSEL_SUCCESS
}

export interface IVesselDeleteFailedAction {
  type: VesselsManagementActions.DELETE_VESSEL_FAILED
  payload: { error: string }
}

export interface IVesselDeleteAction {
  type: VesselsManagementActions.DELETE_VESSEL
  payload: {
    vessel_id: number
    job_id: string
  }
}

export interface INonTargetVesselDeleteSuccessAction {
  type: VesselsManagementActions.DELETE_NON_TARGET_VESSEL_SUCCESS
}

export interface INonTargetVesselDeleteFailedAction {
  type: VesselsManagementActions.DELETE_NON_TARGET_VESSEL_FAILED
  payload: { error: string }
}

export interface INonTargetVesselDeleteAction {
  type: VesselsManagementActions.DELETE_NON_TARGET_VESSEL
  payload: {
    vessel_id: number
  }
}

export interface IExportGenreMappingFailedAction {
  type: VesselsManagementActions.EXPORT_GENRE_MAPPING_FAILED
  payload: { error: string }
}

export interface IExportGenreMappingAction {
  type: VesselsManagementActions.EXPORT_GENRE_MAPPING
  payload: {
    vessel_id: number
  }
}

export interface IExportGenreErrorFailedAction {
  type: VesselsManagementActions.EXPORT_GENRE_ERROR_FAILED
  payload: { error: string }
}

export interface IExportGenreErrorAction {
  type: VesselsManagementActions.EXPORT_GENRE_ERROR
  payload: {
    vessel_id: number
  }
}

export interface IImportGenreSuccessAction {
  type: VesselsManagementActions.IMPORT_GENRE_SUCCESS
}

export interface IImportGenreFailedAction {
  type: VesselsManagementActions.IMPORT_GENRE_FAILED
  payload: { error: string }
}

export interface IImportGenreAction {
  type: VesselsManagementActions.IMPORT_GENRE
  payload: {
    vessel_id: number
  }
}

export interface IUploadGenreFileSuccessAction {
  type: VesselsManagementActions.UPLOAD_FILE_SUCCESS
  payload: {
    data: any
  }
}

export interface IUploadGenreFileFailedAction {
  type: VesselsManagementActions.UPLOAD_FILE_FAILED
  payload: { error: string }
}

export interface IUploadGenreFileAction {
  type: VesselsManagementActions.UPLOAD_FILE
  payload: {
    file: any
    vessel_id: number | string
  }
}

export interface ICleanUploadGenreFileAction {
  type: VesselsManagementActions.CLEAN_UPLOAD_FILE
}

export interface ISimsDataTypeSuccessAction {
  type: VesselsManagementActions.FETCH_SIMS_DATA_TYPE_SUCCESS
  payload: {
    data: []
  }
}

export interface ISimsDataTypeFailedAction {
  type: VesselsManagementActions.FETCH_SIMS_DATA_TYPE_FAILED
  payload: { error: string }
}

export interface ISimsDataTypeAction {
  type: VesselsManagementActions.FETCH_SIMS_DATA_TYPE
  payload: {
    imo: number | string
  }
}

export interface IListCompanyFetchAll {
  type: VesselsManagementActions.FETCH_LIST_COMPANY_ALL
  payload?: any
}

export interface IListCompanyFetchAllSuccess {
  type: VesselsManagementActions.FETCH_LIST_COMPANY_ALL_SUCCESS
  payload: {
    data: []
  }
}

export interface IListCompanyFetchAllFinished {
  type: VesselsManagementActions.FETCH_LIST_COMPANY_ALL_FINISHED
}

export interface IListCompanyFetchAllFailed {
  type: VesselsManagementActions.FETCH_LIST_COMPANY_ALL_FAILED
  payload: { error: string }
}

export interface ISetCompanyId {
  type: VesselsManagementActions.SET_COMPANY_ID
  payload: {
    company_id: number
  }
}
export interface IResetAllVessel {
  type: VesselsManagementActions.RESET_ALL_VESSELS
}

export type VesselsManagementActionTypes =
  | IVesselsFetchAllSuccessAction
  | IVesselsFetchAllFinishedAction
  | IVesselsFetchAllFailedAction
  | IVesselsFetchAllAction
  | IVesselCreateSuccessAction
  | IVesselCreateFailedAction
  | IVesselCreateAction
  | IVesselEditSuccessAction
  | IVesselEditFailedAction
  | IVesselEditAction
  | IVesselDeleteSuccessAction
  | IVesselDeleteFailedAction
  | IVesselDeleteAction
  | INonTargetVesselsFetchAction
  | INonTargetVesselsFetchSuccessAction
  | INonTargetVesselsFetchFinishedAction
  | INonTargetVesselsFetchFailedAction
  | INonTargetVesselDeleteSuccessAction
  | INonTargetVesselDeleteFailedAction
  | INonTargetVesselDeleteAction
  | INonTargetVesselCreateSuccessAction
  | INonTargetVesselCreateFailedAction
  | INonTargetVesselCreateAction
  | IExportGenreMappingAction
  | IExportGenreMappingFailedAction
  | IExportGenreErrorAction
  | IExportGenreErrorFailedAction
  | IImportGenreAction
  | IImportGenreSuccessAction
  | IImportGenreFailedAction
  | IUploadGenreFileSuccessAction
  | IUploadGenreFileAction
  | IUploadGenreFileFailedAction
  | ICleanUploadGenreFileAction
  | ISimsDataTypeAction
  | ISimsDataTypeSuccessAction
  | ISimsDataTypeFailedAction
  | IListCompanyFetchAll
  | IListCompanyFetchAllSuccess
  | IListCompanyFetchAllFailed
  | IListCompanyFetchAllFinished
  | ISetCompanyId
  | IResetAllVessel
