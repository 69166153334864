import genActions from "services/_base/actions"
import {
  IFetchUserManualAction,
  IFetchUserManualFailedAction,
  IFetchUserManualSuccessAction,
  IUploadUserManualAction,
  IUploadUserManualFailedAction,
  IUploadUserManualSuccessAction,
  UserManualActions,
} from "./types"

export const { funcs: BaseFunctions, types: BaseActions } = genActions("user_manual")

export const uploadUserManual = (payload: any): IUploadUserManualAction => ({
  type: UserManualActions.UPLOAD_USER_MANUAL_REQUEST,
  payload,
})

export const uploadUserManualSuccess = (): IUploadUserManualSuccessAction => ({
  type: UserManualActions.UPLOAD_USER_MANUAL_SUCCESS,
})

export const uploadUserManualFailure = (payload: {
  error: string
}): IUploadUserManualFailedAction => ({
  type: UserManualActions.UPLOAD_USER_MANUAL_FAILURE,
  payload,
})

export const fetchUserManual = (): IFetchUserManualAction => ({
  type: UserManualActions.FETCH_USER_MANUAL_REQUEST,
})

export const fetchUserManualSuccess = (payload: {
  file_url: string
}): IFetchUserManualSuccessAction => ({
  type: UserManualActions.FETCH_USER_MANUAL_SUCCESS,
  payload,
})

export const fetchUserManualFailure = (payload: {
  error: string
}): IFetchUserManualFailedAction => ({
  type: UserManualActions.FETCH_USER_MANUAL_FAILURE,
  payload,
})
