import { CHART_COLORS } from "core/constants/common"

export const allChartKeys = {
  duration: "duration",
  distance: "distance",
  "avg._speed": "avg._speed",
  "LNG_Cons.": "LNG_Cons.",
  "MGO_Cons.": "MGO_Cons.",
  "avg._BOR": "avg._BOR",
  ADQ: "ADQ",
  EDQ: "EDQ",
  estimated_heel: "estimated_heel",
  actual_heel: "actual_heel",
}

const commonChartKeys = {
  [allChartKeys.distance]: allChartKeys.distance,
  [allChartKeys.duration]: allChartKeys.duration,
  [allChartKeys["avg._speed"]]: allChartKeys["avg._speed"],
  [allChartKeys["LNG_Cons."]]: allChartKeys["LNG_Cons."],
  [allChartKeys["MGO_Cons."]]: allChartKeys["MGO_Cons."],
}

export const ladenChartKeys = {
  ...commonChartKeys,
  [allChartKeys["avg._BOR"]]: allChartKeys["avg._BOR"],
  [allChartKeys.ADQ]: allChartKeys.ADQ,
  [allChartKeys.EDQ]: allChartKeys.EDQ,
}

export const ballastChartKeys = {
  ...commonChartKeys,
  [allChartKeys.actual_heel]: allChartKeys.actual_heel,
  [allChartKeys.estimated_heel]: allChartKeys.estimated_heel,
}

export const comparisonChartKeys = {
  [allChartKeys.distance]: allChartKeys.distance,
  [allChartKeys.duration]: allChartKeys.duration,
  [allChartKeys["MGO_Cons."]]: allChartKeys["MGO_Cons."],
  [allChartKeys["avg._speed"]]: allChartKeys["avg._speed"],
}

export const initIsShowrange = {
  [allChartKeys.distance]: false,
  [allChartKeys.duration]: false,
  [allChartKeys["avg._speed"]]: false,
  [allChartKeys["LNG_Cons."]]: false,
  [allChartKeys["MGO_Cons."]]: false,
  [allChartKeys.distance]: false,
  [allChartKeys.duration]: false,
  [allChartKeys["LNG_Cons."]]: false,
  [allChartKeys["avg._speed"]]: false,
  [allChartKeys.actual_heel]: false,
  [allChartKeys.estimated_heel]: false,
}

export const BARCHART_COLORS = {
  selected: "#6496F9",
  eve: "#F6C026",
  default: "#667799",
}

export const POWER_CURVE_COLORS = {
  blue: "#6496F9",
  green: "#63DAAC",
  gray: "#667799",
  orange: "#F6C026",
  purple: " #7667F9",
}

export const MaxRangeCharts = {
  [allChartKeys.duration]: 800,
  [allChartKeys.distance]: 10000,
  [allChartKeys["avg._speed"]]: 20,
  [allChartKeys["LNG_Cons."]]: 2500,
  [allChartKeys["MGO_Cons."]]: 2500,
  [allChartKeys["avg._BOR"]]: 0.3,
  [allChartKeys.ADQ]: 170000,
  [allChartKeys.EDQ]: 170000,
  [allChartKeys.actual_heel]: 10000,
  [allChartKeys.estimated_heel]: 10000,
}

export const MinRangeCharts = {
  [allChartKeys.duration]: 0,
  [allChartKeys.distance]: 0,
  [allChartKeys["avg._speed"]]: 0,
  [allChartKeys["LNG_Cons."]]: 0,
  [allChartKeys["MGO_Cons."]]: 0,
  [allChartKeys["avg._BOR"]]: 0,
  [allChartKeys.ADQ]: 0,
  [allChartKeys.EDQ]: 0,
  [allChartKeys.actual_heel]: 0,
  [allChartKeys.estimated_heel]: 0,
}

export const unitBarCharts = {
  [allChartKeys.duration]: "hours",
  [allChartKeys.distance]: "miles",
  [allChartKeys["avg._speed"]]: "knot",
  [allChartKeys["LNG_Cons."]]: "MT",
  [allChartKeys["MGO_Cons."]]: "MT",
  [allChartKeys["avg._BOR"]]: "%/day",
  [allChartKeys.ADQ]: "m3",
  [allChartKeys.EDQ]: "m3",
  [allChartKeys.actual_heel]: "m3",
  [allChartKeys.estimated_heel]: "m3",
}

export const chartsName = {
  [allChartKeys.duration]: "Duration (hours)",
  [allChartKeys.distance]: "Distance (miles)",
  [allChartKeys["avg._speed"]]: "Avg. Speed (knot)",
  [allChartKeys["LNG_Cons."]]: "LNG Cons. (MT)",
  [allChartKeys["MGO_Cons."]]: "MGO Cons. (MT)",
  [allChartKeys["avg._BOR"]]: "Avg. BOR (%/day)",
  [allChartKeys.ADQ]: "ADQ (m3)",
  [allChartKeys.EDQ]: "EDQ (m3)",
  [allChartKeys.actual_heel]: "Actual Heel (m3)",
  [allChartKeys.estimated_heel]: "Estimated Heel (m3)",
}

export const setFillColor = (cell) => {
  const { selected, is_average } = cell
  if (selected) return BARCHART_COLORS.selected
  if (is_average) return BARCHART_COLORS.eve
  return BARCHART_COLORS.default
}

export const setFillComparisonColor = (colorType: string) => {
  let color = ""
  switch (colorType) {
    case "yellow":
      color = CHART_COLORS[5]
      break
    case "lime":
      color = CHART_COLORS[6]
      break
    case "green":
      color = CHART_COLORS[1]
      break
    case "red":
      color = CHART_COLORS[3]
      break
    default:
      color = CHART_COLORS[0]
      break
  }

  return color
}
