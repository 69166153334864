import { Button, Form, Select, Space } from "antd"
import { HEEL_EDQ_FORM } from "core/constants/heelEdq"
import { isArray, isEmpty, omit } from "lodash"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { dispatch } from "core/utils/dispatch"
import { ALL_ROUTE_COLOR } from "core/constants/vesselRoutes"
import { CloseOutlined, CopyOutlined } from "@ant-design/icons"
import { VoyagesComparisonSelector } from "services/voyagesComparison/selectors"
import moment from "moment"
import {
  fetchVoyageNumber,
  fetchVoyagesComparison,
  saveConditions,
} from "services/voyagesComparison/actions"
import { IVoyagesConditionsPayload } from "services/voyagesComparison/types"

interface VoyageComparisonProps {
  color: string | null
  conditionId: number | null
  isValid: boolean
  resetFields: boolean
  data: IVoyagesConditionsPayload
  setPayload: (color: string, data: IVoyagesConditionsPayload) => void
  onValidate: (isValid: boolean) => void
  onDelete: (color: string) => void
  createConditions: (duplicateData: IVoyagesConditionsPayload, duplicateColor: string) => void
  conditionsColor: string[]
}

const Index = ({
  color,
  conditionId,
  isValid,
  resetFields,
  data,
  setPayload,
  onValidate,
  onDelete,
  createConditions,
  conditionsColor,
}: VoyageComparisonProps) => {
  const {
    vessels: { data: vesselsData },
    dept_ports: { data: deptPortsData },
    arrival_ports: { data: arrivalPortsData },
    voyages_comparison,
  } = useSelector(VoyagesComparisonSelector)
  const [yearsData, setYearsData] = useState([])
  const monthsData = moment.monthsShort()
  const yearsRange: number = new Date().getFullYear() - 2018

  const [form] = Form.useForm()

  const defaultValue = {
    imos: [],
    dept_years: [],
    dept_months: [],
    dept_ports: [],
    arrival_ports: [],
    voyages_no: [],
  }

  useEffect(() => {
    const allYears = []
    // eslint-disable-next-line
    for (let i = 0; i <= yearsRange; i++) {
      allYears.push(`${2018 + i}`)
    }
    setYearsData(allYears)
  }, [])

  useEffect(() => {
    if (isValid) onFinish()
  }, [isValid])

  useEffect(() => {
    form.setFieldsValue(data)
  }, [resetFields, color])

  const onFinish = async () => {
    try {
      await form.validateFields()
      dispatch(saveConditions({ color }))
      onValidate(true)
    } catch {
      onValidate(false)
      return false
    }
  }

  const renderOpt = (opt, keyName) => {
    const { dept_ports, arrival_ports } = form.getFieldsValue()
    const disabled =
      keyName === "dept-ports"
        ? isArray(dept_ports) && dept_ports.length === 5 && !dept_ports.includes(opt)
        : isArray(arrival_ports) && arrival_ports.length === 5 && !arrival_ports.includes(opt)
    return (
      <Select.Option
        key={`voyages-comparison-${keyName}-${opt}`}
        value={opt ?? "-"}
        disabled={disabled}
      >
        {opt ?? "-"}
      </Select.Option>
    )
  }

  const onFilterOption = (input, option: any) => {
    if (isArray(option.children)) return false
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  const onFilterSort = (optionA: any, optionB: any) => {
    return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
  }

  const handleDelete = () => {
    onDelete(color)
  }

  const formData: HEEL_EDQ_FORM[] = [
    {
      name: "imos",
      label: "Vessels",
      error: false,
      required: true,
      control: () => (
        <Select
          onChange={() => onFieldsChange()}
          mode="multiple"
          maxTagCount="responsive"
          showSearch
          filterOption={(input, option) => onFilterOption(input, option)}
          filterSort={(optionA: any, optionB: any) => onFilterSort(optionA, optionB)}
        >
          {vesselsData &&
            vesselsData.map((el: any) => (
              <Select.Option
                key={el.imo}
                value={el.imo}
                disabled={
                  form.getFieldValue("imos") &&
                  form.getFieldValue("imos").length === 4 &&
                  !form.getFieldValue("imos").includes(el.imo)
                }
              >
                {el.name}
              </Select.Option>
            ))}
        </Select>
      ),
    },
    {
      name: "dept_ports",
      label: "Departure Port",
      error: false,
      required: false,
      control: () => (
        <Select
          showSearch
          filterOption={(input, option) => onFilterOption(input, option)}
          filterSort={(optionA: any, optionB: any) => onFilterSort(optionA, optionB)}
          mode="multiple"
          maxTagCount="responsive"
          onChange={() => onFieldsChange()}
        >
          {deptPortsData && deptPortsData.map((opt) => renderOpt(opt, "dept-ports"))}
        </Select>
      ),
    },
    {
      name: "arrival_ports",
      label: "Arrival Port",
      error: false,
      required: false,
      control: () => (
        <Select
          showSearch
          filterOption={(input, option) => onFilterOption(input, option)}
          filterSort={(optionA: any, optionB: any) => onFilterSort(optionA, optionB)}
          mode="multiple"
          maxTagCount="responsive"
          onChange={() => onFieldsChange()}
        >
          {arrivalPortsData && arrivalPortsData.map((opt) => renderOpt(opt, "arrival-ports"))}
        </Select>
      ),
    },
    {
      name: "dept_years",
      label: "Departure Year",
      error: false,
      required: true,
      control: () => (
        <Select
          showSearch
          filterOption={(input, option) => onFilterOption(input, option)}
          filterSort={(optionA: any, optionB: any) => onFilterSort(optionA, optionB)}
          mode="multiple"
          maxTagCount="responsive"
          onChange={() => onFieldsChange()}
        >
          {yearsData &&
            yearsData.map((opt) => (
              <Select.Option
                key={`voyages-comparison-dept-years-${opt}`}
                value={opt}
                disabled={
                  form.getFieldValue("dept_years") &&
                  form.getFieldValue("dept_years").length === 5 &&
                  !form.getFieldValue("dept_years").includes(opt)
                }
              >
                {opt}
              </Select.Option>
            ))}
        </Select>
      ),
    },
    {
      name: "dept_months",
      label: "Departure Month",
      error: false,
      required: true,
      control: () => (
        <Select
          showSearch
          filterOption={(input, option) => onFilterOption(input, option)}
          filterSort={(optionA: any, optionB: any) => onFilterSort(optionA, optionB)}
          mode="multiple"
          maxTagCount="responsive"
          onChange={() => onFieldsChange()}
        >
          {monthsData &&
            monthsData.map((opt: any) => (
              <Select.Option
                key={`voyages-comparison-dept-years-${opt}`}
                value={opt}
                disabled={
                  form.getFieldValue("dept_months") &&
                  form.getFieldValue("dept_months").length === 3 &&
                  !form.getFieldValue("dept_months").includes(opt)
                }
              >
                {opt}
              </Select.Option>
            ))}
        </Select>
      ),
    },
    {
      name: "voyage_ids",
      label: "Voyage No.",
      required: false,
      control: () => (
        <Select
          showSearch
          filterOption={(input, option) => onFilterOption(input, option)}
          filterSort={(optionA: any, optionB: any) => onFilterSort(optionA, optionB)}
          mode="multiple"
          maxTagCount="responsive"
          onChange={() => onFieldsChange(true)}
          disabled={
            isEmpty(form.getFieldValue("imos")) ||
            isEmpty(form.getFieldValue("dept_years")) ||
            isEmpty(form.getFieldValue("dept_months"))
          }
          loading={voyages_comparison[color].payload_voyages.loading}
        >
          {voyages_comparison[color].filter_voyages_no.map((opt: any) => (
            <Select.OptGroup label={opt.vessel_name}>
              {opt.data.map((voyage) => {
                const record = Object.entries(voyage)[0]
                // eslint-disable-next-line
                return (
                  <Select.Option key={record[0]} value={record[0]}>
                    {record[1]}
                  </Select.Option>
                )
              })}
            </Select.OptGroup>
          ))}
        </Select>
      ),
    },
  ]

  const onFieldsChange = (isVoyageNo = false, duplicateData = null) => {
    let values = duplicateData ?? form.getFieldsValue()
    setPayload(color, values)
    if (!isEmpty(values.imos) && !isEmpty(values.dept_years) && !isEmpty(values.dept_months)) {
      if (!isVoyageNo) {
        form.resetFields(["voyage_ids"])
        values = omit(values, "voyage_ids")
        dispatch(fetchVoyageNumber({ ...values, color }))
      }
      dispatch(fetchVoyagesComparison({ ...values, color }))
    } else form.resetFields(["voyage_ids"])
  }

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: "${label} is required!",
  }
  return (
    <div className="content">
      <div className="title d-flex align-items-center">
        <div
          className="block-color"
          style={{ background: ALL_ROUTE_COLOR[color], marginRight: "4px" }}
        />
        <div className="d-inline-block">Condition Data Set {conditionId}</div>
        <div className="ml-auto">
          <Space size={[8, 0]} wrap>
            <Button
              className="duplicate"
              type="link"
              icon={<CopyOutlined />}
              size="middle"
              onClick={() => {
                createConditions(form.getFieldsValue(), color)
              }}
              disabled={conditionsColor.length === 5}
            >
              Duplicate Condition
            </Button>
            <CloseOutlined onClick={() => handleDelete()} />
          </Space>
        </div>
      </div>
      <Form
        className="heel-edq__form"
        layout="vertical"
        form={form}
        name="distance-route-form"
        onFinish={onFinish}
        validateMessages={validateMessages}
        initialValues={defaultValue}
      >
        {formData.map((item: HEEL_EDQ_FORM) => (
          <Form.Item
            key={item.name}
            name={item.name}
            label={item.label}
            rules={[{ required: item.required }]}
          >
            {item.control()}
          </Form.Item>
        ))}
      </Form>
    </div>
  )
}
export default Index
