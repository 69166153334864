import React, { forwardRef, createRef, memo, useImperativeHandle } from "react"
import { isEqual } from "lodash"
import { useDispatch } from "react-redux"
import { Action } from "redux"

interface AppDispatchType {
  dispatch: (action: Action<string>) => void
}

const dispatchRef = createRef<AppDispatchType>()

const AppDispatchComponent = forwardRef((props, ref) => {
  const dispatch = useDispatch()

  useImperativeHandle(
    ref,
    () => ({
      dispatch: (action: Action<string>) => {
        dispatch(action)
      },
    }),
    [dispatch]
  )

  return null
})

export const AppDispatch = memo(() => <AppDispatchComponent ref={dispatchRef} />, isEqual)

export const dispatch = (action: Action<string>) => {
  if (dispatchRef.current) {
    dispatchRef.current.dispatch(action)
  }
}
