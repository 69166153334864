export interface VoyagesComparisonPayload {
  color: string
  imos: number[]
  dept_years: number[]
  dept_months: string[]
  dept_ports: string[] | null
  arrival_ports: string[] | null
  voyage_no: string[] | null
}

interface TooltipChartComparison {
  vessel_name: string
  voyage_no: string
  value: number
}

export interface IFilterVoyagesComparison {
  id: string
  type: string
  attributes: {
    id: number
    imo: number
    vessel_name: string
    voyage_no: string
    atd_utc: string | null
    ata_utc: string | null
    closest_atd: string | null
    closest_ata: string | null
  }
}

export interface IVoyagesChartComparison {
  data: {
    id: string
    type: string
    attributes: {
      duration: number
      distance: number
      average_speed: number
      mgo_consumption: number
      tooltip_duration: TooltipChartComparison[]
      tooltip_distance: TooltipChartComparison[]
      tooltip_average_speed: TooltipChartComparison[]
      tooltip_mgo_consumption: TooltipChartComparison[]
    }
  }
}

export interface IVoyagesNo {
  imo: number
  vessel_name: string
  data: string[]
}

export interface IVoyagesConditionsPayload {
  imos: number[]
  dept_ports: string[]
  arrival_ports: string[]
  dept_years: number[]
  dept_months: string[]
  voyages_no: string[]
}

interface IVoyagesComparisonItem {
  isChanged: boolean
  voyages: {
    filter_voyages: IFilterVoyagesComparison[]
    voyages_chart: IVoyagesChartComparison | any
    voyages_no: IVoyagesNo[]
  }
  payload_voyages: {
    loading: boolean
    error: string | null
    filter_voyages: IFilterVoyagesComparison[]
    voyages_chart: IVoyagesChartComparison
    voyages_no: IVoyagesNo[]
  }
  filter_voyages_no: string[]
}

export interface IVoyagesComparison {
  blue: IVoyagesComparisonItem
  yellow: IVoyagesComparisonItem
  lime: IVoyagesComparisonItem
  green: IVoyagesComparisonItem
  red: IVoyagesComparisonItem
}

export interface IVoyagesComparisonRoutes {
  blue: []
  yellow: []
  lime: []
  green: []
  red: []
}

export interface IVoyagesComparisonState {
  vessels: { loading: boolean; error: string; data: [] }
  dept_ports: {
    loading: boolean
    error: string | null
    data: []
  }
  arrival_ports: {
    loading: boolean
    error: string | null
    data: []
  }
  voyages_comparison: IVoyagesComparison
  routes: {
    loading: boolean
    error: string | null
    data: IVoyagesComparisonRoutes
  }
}

export enum VoyagesComparisonAction {
  FETCH_TARGET_VESSELS = "[voyage comparison] fetch target vessel",
  FETCH_TARGET_VESSELS_SUCCESS = "[voyage comparison] fetch target vessel success",
  FETCH_TARGET_VESSELS_FAILED = "[voyage comparison] fetch target vessel failed",
  FETCH_TARGET_VESSELS_FINISHED = "[voyage comparison] fetch target vessel finished",

  FETCH_DEPARTURE_PORTS = "[voyage comparison] fetch departure port",
  FETCH_DEPARTURE_PORTS_SUCCESS = "[voyage comparison] fetch departure port success",
  FETCH_DEPARTURE_PORTS_FAILED = "[voyage comparison] fetch departure port failed",

  FETCH_ARRIVAL_PORTS = "[voyage comparison] fetch arrival port",
  FETCH_ARRIVAL_PORTS_SUCCESS = "[voyage comparison] fetch arrival port success",
  FETCH_ARRIVAL_PORTS_FAILED = "[voyage comparison] fetch arrival port failed",

  FETCH_VOYAGES_COMPARISON = "[voyage comparison] fetch voyages comparison",
  FETCH_VOYAGES_COMPARISON_SUCCESS = "[voyage comparison] fetch voyages comparison success",
  FETCH_VOYAGES_COMPARISON_FAILED = "[voyage comparison] fetch voyages comparison failed",

  FETCH_VOYAGES_HISTORY_ROUTES = "[voyage comparison] fetch history routes",
  FETCH_VOYAGES_HISTORY_ROUTES_SUCCESS = "[voyage comparison] fetch history routes success",
  FETCH_VOYAGES_HISTORY_ROUTES_FAILED = "[voyage comparison] fetch history routes failed",

  FETCH_VOYAGE_NUMBER = "[voyage comparison] fetch voyage number",
  FETCH_VOYAGE_NUMBER_SUCCESS = "[voyage comparison] fetch voyage number success",
  FETCH_VOYAGE_NUMBER_FAILED = "[voyage comparison] fetch voyage number failed",

  DUPLICATE_CONDITION = "[voyage comparison] duplicate condition",
  DELETE_CONDITION = "[voyage comparison] delete condition",
  SAVE_CONDITIONS = "[voyage comparison] save condition",
  RESET_VOYAGES_COMPARISON = "[voyage comparison] reset voyage comparison",
}

export interface ITargetVesselsFetch {
  type: VoyagesComparisonAction.FETCH_TARGET_VESSELS
}

export interface ITargetVesselsFetchSuccess {
  type: VoyagesComparisonAction.FETCH_TARGET_VESSELS_SUCCESS
  payload: {
    data: []
  }
}

export interface ITargetVesselsFetchFinished {
  type: VoyagesComparisonAction.FETCH_TARGET_VESSELS_FINISHED
  payload: {
    allData: []
    headers: any
  }
}

export interface ITargetVesselsFetchFailed {
  type: VoyagesComparisonAction.FETCH_TARGET_VESSELS_FAILED
  payload: {
    error: string
  }
}

export interface IDeparturePortsFetch {
  type: VoyagesComparisonAction.FETCH_DEPARTURE_PORTS
}

export interface IDeparturePortsFetchSuccess {
  type: VoyagesComparisonAction.FETCH_DEPARTURE_PORTS_SUCCESS
  payload: {
    data: []
  }
}

export interface IDeparturePortsFetchFailed {
  type: VoyagesComparisonAction.FETCH_DEPARTURE_PORTS_FAILED
  payload: {
    error: string
  }
}
export interface IArrivalPortsFetch {
  type: VoyagesComparisonAction.FETCH_ARRIVAL_PORTS
}

export interface IArrivalPortsFetchSuccess {
  type: VoyagesComparisonAction.FETCH_ARRIVAL_PORTS_SUCCESS
  payload: {
    data: []
  }
}

export interface IArrivalPortsFetchFailed {
  type: VoyagesComparisonAction.FETCH_ARRIVAL_PORTS_FAILED
  payload: {
    error: string
  }
}

export interface IVoyagesComparisonFetch {
  type: VoyagesComparisonAction.FETCH_VOYAGES_COMPARISON
  payload: VoyagesComparisonPayload
}

export interface IVoyagesComparisonFetchSuccess {
  type: VoyagesComparisonAction.FETCH_VOYAGES_COMPARISON_SUCCESS
  payload: any
}

export interface IVoyagesComparisonFetchFailed {
  type: VoyagesComparisonAction.FETCH_VOYAGES_COMPARISON_FAILED
  payload: {
    error: string
    color: string
  }
}

export interface IVoyagesHistoryRoutesFetch {
  type: VoyagesComparisonAction.FETCH_VOYAGES_HISTORY_ROUTES
}

export interface IVoyagesHistoryRoutesFetchSuccess {
  type: VoyagesComparisonAction.FETCH_VOYAGES_HISTORY_ROUTES_SUCCESS
  payload: {
    historyRoutes: any
    color: string
  }
}
export interface IVoyagesHistoryRoutesFetchFailed {
  type: VoyagesComparisonAction.FETCH_VOYAGES_HISTORY_ROUTES_FAILED
  payload: { error: string; color: string }
}

export interface IVoyageNumberFetch {
  type: VoyagesComparisonAction.FETCH_VOYAGE_NUMBER
  payload: { color: string }
}

export interface IVoyageNumberFetchSuccess {
  type: VoyagesComparisonAction.FETCH_VOYAGE_NUMBER_SUCCESS
  payload: {
    data: any
    color: string
  }
}

export interface IVoyageNumberFetchFailed {
  type: VoyagesComparisonAction.FETCH_VOYAGE_NUMBER_FAILED
  payload: { error: string; color: string }
}

export interface IDuplicateCondition {
  type: VoyagesComparisonAction.DUPLICATE_CONDITION
  payload: { color: string; duplicateColor: string }
}

export interface IDeleteCondition {
  type: VoyagesComparisonAction.DELETE_CONDITION
  payload: {
    color: string
  }
}

export interface ISaveConditions {
  type: VoyagesComparisonAction.SAVE_CONDITIONS
  payload: {
    color: string
  }
}

export interface IResetVoyageComparison {
  type: VoyagesComparisonAction.RESET_VOYAGES_COMPARISON
}

export type VoyagesComparisonActionTypes =
  | ITargetVesselsFetch
  | ITargetVesselsFetchSuccess
  | ITargetVesselsFetchFailed
  | ITargetVesselsFetchFinished
  | IDeparturePortsFetch
  | IDeparturePortsFetchSuccess
  | IDeparturePortsFetchFailed
  | IArrivalPortsFetch
  | IArrivalPortsFetchSuccess
  | IArrivalPortsFetchFailed
  | IVoyagesComparisonFetch
  | IVoyagesComparisonFetchSuccess
  | IVoyagesComparisonFetchFailed
  | IVoyagesHistoryRoutesFetch
  | IVoyagesHistoryRoutesFetchSuccess
  | IVoyagesHistoryRoutesFetchFailed
  | IVoyageNumberFetch
  | IVoyageNumberFetchSuccess
  | IVoyageNumberFetchFailed
  | IDeleteCondition
  | IDuplicateCondition
  | ISaveConditions
  | IResetVoyageComparison
