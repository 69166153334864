import { connectRouter } from "connected-react-router"
import { History } from "history"
import { combineReducers } from "redux"
import { withReduxStateSync } from "redux-state-sync"

import auth from "services/auth/reducers"
import layout from "./layout/reducers"

export default function createReducer(injectedReducers = {}, history: History) {
  const asyncReducer = combineReducers({
    auth,
    layout,
  })

  const rootReducer = {
    ...injectedReducers,
    asyncReducer,
    router: connectRouter(history),
  }

  return combineReducers({
    ...rootReducer,
    asyncReducer,
  })
}
