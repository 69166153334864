import genActions from "services/_base/actions"
import {
  VesselsFetchAllSuccessAction,
  VesselsFetchAllFinishedAction,
  VesselsFetchAllFailedAction,
  TemplatesFetchAllSuccessAction,
  TemplatesFetchAllFinishedAction,
  TemplatesFetchAllAction,
  TemplatesFetchAllFailedAction,
  TemplateCreateAction,
  TemplateCreateSuccessAction,
  TemplateCreateFailedAction,
  TemplateEditAction,
  TemplateEditSuccessAction,
  TemplateEditFailedAction,
  TemplateDeleteAction,
  TemplateDeleteSuccessAction,
  TemplateDeleteFailedAction,
  ChannelsFetchAllSuccessAction,
  ChannelsFetchAllFinishedAction,
  ChannelsFetchAllAction,
  ChannelsFetchAllFailedAction,
  HistoriesFetchAllSuccessAction,
  HistoriesFetchAllFinishedAction,
  HistoriesFetchAllAction,
  HistoriesFetchAllFailedAction,
  ExportProcessingAction,
  ExportProcessingSuccessAction,
  ExportProcessingFailedAction,
  ChannelsFilterParamsChangeAction,
  ShipDataActions,
  SearchChannelsAction,
  ChannelsClearAllAction,
  SearchUnitAction,
  FetchUnitsAction,
  FetchUnitsSuccessAction,
  FetchUnitsFailedAction,
  FetchGenresAction,
  FetchGenresSuccessAction,
  FetchGenresFailedAction,
} from "./types"

export const { funcs: BaseFunctions, types: BaseActions } = genActions("vessels_management")

export function fetchVesselsAll() {
  return {
    type: ShipDataActions.FETCH_VESSELS_ALL,
  }
}

export function fetchVesselsAllSuccess(payload: any): VesselsFetchAllSuccessAction {
  return {
    type: ShipDataActions.FETCH_VESSELS_ALL_SUCCESS,
    payload,
  }
}

export function fetchVesselsAllFailed(payload: any): VesselsFetchAllFailedAction {
  return {
    type: ShipDataActions.FETCH_VESSELS_ALL_FAILED,
    payload,
  }
}

export function fetchVesselsAllFinished(payload: any): VesselsFetchAllFinishedAction {
  return {
    type: ShipDataActions.FETCH_VESSELS_ALL_FINISHED,
    payload,
  }
}

export function fetchTemplatesAll(payload?: any): TemplatesFetchAllAction {
  return {
    type: ShipDataActions.FETCH_TEMPLATES_ALL,
    payload,
  }
}

export function fetchTemplatesAllSuccess(payload: any): TemplatesFetchAllSuccessAction {
  return {
    type: ShipDataActions.FETCH_TEMPLATES_ALL_SUCCESS,
    payload,
  }
}

export function fetchTemplatesAllFailed(payload: any): TemplatesFetchAllFailedAction {
  return {
    type: ShipDataActions.FETCH_TEMPLATES_ALL_FAILED,
    payload,
  }
}

export function fetchTemplatesAllFinished(payload: any): TemplatesFetchAllFinishedAction {
  return {
    type: ShipDataActions.FETCH_TEMPLATES_ALL_FINISHED,
    payload,
  }
}

export function createTemplate(payload: any): TemplateCreateAction {
  return { type: ShipDataActions.CREATE_TEMPLATE, payload }
}

export function createTemplateSuccess(): TemplateCreateSuccessAction {
  return {
    type: ShipDataActions.CREATE_TEMPLATE_SUCCESS,
  }
}

export function createTemplateFailed(payload: { error: string }): TemplateCreateFailedAction {
  return {
    type: ShipDataActions.CREATE_TEMPLATE_FAILED,
    payload,
  }
}

export function editTemplate(payload: any): TemplateEditAction {
  return { type: ShipDataActions.EDIT_TEMPLATE, payload }
}

export function editTemplateSuccess(): TemplateEditSuccessAction {
  return {
    type: ShipDataActions.EDIT_TEMPLATE_SUCCESS,
  }
}

export function editTemplateFailed(payload: { error: string }): TemplateEditFailedAction {
  return {
    type: ShipDataActions.EDIT_TEMPLATE_FAILED,
    payload,
  }
}

export function deleteTemplate(payload: any): TemplateDeleteAction {
  return { type: ShipDataActions.DELETE_TEMPLATE, payload }
}

export function deleteTemplateSuccess(): TemplateDeleteSuccessAction {
  return {
    type: ShipDataActions.DELETE_TEMPLATE_SUCCESS,
  }
}

export function deleteTemplateFailed(payload: { error: string }): TemplateDeleteFailedAction {
  return {
    type: ShipDataActions.DELETE_TEMPLATE_FAILED,
    payload,
  }
}

export function fetchChannelsAll(payload): ChannelsFetchAllAction {
  return {
    type: ShipDataActions.FETCH_CHANNELS_ALL,
    payload,
  }
}

export function clearChannelsAll(): ChannelsClearAllAction {
  return {
    type: ShipDataActions.CLEAR_CHANNELS_ALL,
  }
}

export function fetchChannelsAllSuccess(payload: any): ChannelsFetchAllSuccessAction {
  return {
    type: ShipDataActions.FETCH_CHANNELS_ALL_SUCCESS,
    payload,
  }
}

export function fetchChannelsAllFailed(payload: any): ChannelsFetchAllFailedAction {
  return {
    type: ShipDataActions.FETCH_CHANNELS_ALL_FAILED,
    payload,
  }
}

export function fetchChannelsAllFinished(payload: any): ChannelsFetchAllFinishedAction {
  return {
    type: ShipDataActions.FETCH_CHANNELS_ALL_FINISHED,
    payload,
  }
}

export function fetchHistoriesAll(payload: any): HistoriesFetchAllAction {
  return {
    type: ShipDataActions.FETCH_HISTORIES_ALL,
    payload,
  }
}

export function fetchHistoriesAllSuccess(payload: any): HistoriesFetchAllSuccessAction {
  return {
    type: ShipDataActions.FETCH_HISTORIES_ALL_SUCCESS,
    payload,
  }
}

export function fetchHistoriesAllFailed(payload: any): HistoriesFetchAllFailedAction {
  return {
    type: ShipDataActions.FETCH_HISTORIES_ALL_FAILED,
    payload,
  }
}

export function fetchHistoriesAllFinished(payload: any): HistoriesFetchAllFinishedAction {
  return {
    type: ShipDataActions.FETCH_HISTORIES_ALL_FINISHED,
    payload,
  }
}

export function exportProcessing(payload: any): ExportProcessingAction {
  return {
    type: ShipDataActions.EXPORT_PROCESSING,
    payload,
  }
}

export function exportProcessingSuccess(payload: any): ExportProcessingSuccessAction {
  return {
    type: ShipDataActions.EXPORT_PROCESSING_SUCCESS,
    payload,
  }
}

export function exportProcessingFailed(payload: any): ExportProcessingFailedAction {
  return {
    type: ShipDataActions.EXPORT_PROCESSING_FAILED,
    payload,
  }
}

export function filterParamsChange(payload: any): ChannelsFilterParamsChangeAction {
  return {
    type: ShipDataActions.FILTER_PARAMS_CHANGE,
    payload,
  }
}

export function searchChannels(payload: any): SearchChannelsAction {
  return {
    type: ShipDataActions.SEARCH_CHANNELS,
    payload,
  }
}

export function searchUnit(payload: any): SearchUnitAction {
  return {
    type: ShipDataActions.SEARCH_UNIT,
    payload,
  }
}

export function fetchUnits(): FetchUnitsAction {
  return {
    type: ShipDataActions.FETCH_UNITS,
  }
}

export function fetchUnitsSuccess(payload: any): FetchUnitsSuccessAction {
  return {
    type: ShipDataActions.FETCH_UNITS_SUCCESS,
    payload,
  }
}

export function fetchUnitsFailed(payload: any): FetchUnitsFailedAction {
  return {
    type: ShipDataActions.FETCH_UNITS_FAILED,
    payload,
  }
}

export function fetchGenres(payload: any): FetchGenresAction {
  return {
    type: ShipDataActions.FETCH_GENRES,
    payload,
  }
}

export function fetchGenresSuccess(payload: any): FetchGenresSuccessAction {
  return {
    type: ShipDataActions.FETCH_GENRES_SUCCESS,
    payload,
  }
}

export function fetchGenresFailed(payload: any): FetchGenresFailedAction {
  return {
    type: ShipDataActions.FETCH_GENRES_FAILED,
    payload,
  }
}
