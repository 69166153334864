import produce from "immer"
import { Reducer } from "redux"
import moment from "moment"
import genReducers from "services/_base/reducers"
import { WEATHER_KEY } from "core/constants/weather"
import {
  changeWeathersChecked,
  removeLocalStorageData,
  setLocalStoragePayload,
} from "core/model/WeatherHistory"
import { notification } from "antd"
import { BaseActions } from "./actions"
import { HistoricalActions, IHistoricalState, HistoricalActionTypes } from "./types"

export const initialState: IHistoricalState = {
  durationSettings: {
    currentDate: moment.utc().subtract(14, "d").startOf("d").toDate(),
    fromDate: moment.utc().subtract(14, "d").startOf("d").toDate(),
    toDate: moment.utc().endOf("d").toDate(),
  },
  selectedImo: [],
  selectedVesselId: null,
  weathers: {
    weathersChecked: [],
    waveHeight: { isShow: false, loading: false, error: null, data: [] },
    surfacePressure: { isShow: false, loading: false, error: null, data: [] },
    tropicalStorm: { isShow: false, loading: false, error: null, data: [] },
    oceanCurrent: { isShow: false, loading: false, error: null, data: [] },
    windBarbs: { isShow: false, loading: false, error: null, data: [] },
  },
}

const historicalReducer: Reducer<IHistoricalState, HistoricalActionTypes> = (
  state = initialState,
  action
) => {
  return produce<IHistoricalState>(state, (draft) => {
    switch (action.type) {
      case HistoricalActions.SET_DURATION_CURRENT_DATE: {
        const { date } = action.payload

        draft.durationSettings.currentDate = date
        return draft
      }

      case HistoricalActions.SET_SELECTED_IMO: {
        const { imos } = action.payload

        draft.selectedImo = imos
        return draft
      }

      case HistoricalActions.SET_SELECTED_VESSEL_ID: {
        const { vessel_id } = action.payload

        draft.selectedVesselId = vessel_id
        return draft
      }

      case HistoricalActions.SET_DURATION_SETTING: {
        const { fromDate, toDate, currentDate } = action.payload

        draft.durationSettings = { fromDate, toDate, currentDate }
        return draft
      }

      case HistoricalActions.SHOW_WEATHER_WAVE_HEIGHT: {
        draft.weathers.waveHeight.isShow = action.payload.checked
        draft.weathers.weathersChecked = changeWeathersChecked(
          action.payload.checked,
          draft.weathers.weathersChecked,
          WEATHER_KEY.WAVE_HEIGHT
        )
        return draft
      }

      case HistoricalActions.FETCH_WEATHER_WAVE_HEIGHT: {
        draft.weathers.waveHeight.loading = true
        draft.weathers.waveHeight.data = []

        return draft
      }

      case HistoricalActions.FETCH_WEATHER_WAVE_HEIGHT_SUCCESS: {
        draft.weathers.waveHeight.loading = false
        draft.weathers.waveHeight.data = action.data

        return draft
      }

      case HistoricalActions.FETCH_WEATHER_WAVE_HEIGHT_FAILED: {
        const { error } = action.payload

        draft.weathers.waveHeight.data = []
        draft.weathers.waveHeight.loading = false
        draft.weathers.waveHeight.error = error

        notification.error({ message: error })

        return draft
      }

      case HistoricalActions.SHOW_WEATHER_SURFACE_PRESSURE: {
        draft.weathers.surfacePressure.isShow = action.payload.checked
        draft.weathers.weathersChecked = changeWeathersChecked(
          action.payload.checked,
          draft.weathers.weathersChecked,
          WEATHER_KEY.SURFACE_PRESSURE
        )

        return draft
      }

      case HistoricalActions.FETCH_WEATHER_SURFACE_PRESSURE: {
        draft.weathers.surfacePressure.loading = true
        draft.weathers.surfacePressure.data = []

        return draft
      }

      case HistoricalActions.FETCH_WEATHER_SURFACE_PRESSURE_SUCCESS: {
        draft.weathers.surfacePressure.loading = false
        draft.weathers.surfacePressure.data = action.data

        return draft
      }

      case HistoricalActions.FETCH_WEATHER_SURFACE_PRESSURE_FAILED: {
        const { error } = action.payload
        draft.weathers.surfacePressure.data = []
        draft.weathers.surfacePressure.loading = false
        draft.weathers.surfacePressure.error = error

        notification.error({ message: error })

        return draft
      }

      case HistoricalActions.SHOW_WEATHER_GLOBAL_STORM: {
        draft.weathers.tropicalStorm.isShow = action.payload.checked
        draft.weathers.weathersChecked = changeWeathersChecked(
          action.payload.checked,
          draft.weathers.weathersChecked,
          WEATHER_KEY.GLOBAL_STORM
        )

        return draft
      }

      case HistoricalActions.FETCH_WEATHER_GLOBAL_STORM: {
        draft.weathers.tropicalStorm.loading = true
        draft.weathers.tropicalStorm.data = []

        return draft
      }

      case HistoricalActions.FETCH_WEATHER_GLOBAL_STORM_SUCCESS: {
        draft.weathers.tropicalStorm.loading = false
        draft.weathers.tropicalStorm.data = action.data

        return draft
      }

      case HistoricalActions.FETCH_WEATHER_GLOBAL_STORM_FAILED: {
        const { error } = action.payload
        draft.weathers.tropicalStorm.data = []
        draft.weathers.tropicalStorm.loading = false
        draft.weathers.tropicalStorm.error = error

        notification.error({ message: error })

        return draft
      }

      case HistoricalActions.SHOW_WEATHER_OCEAN_CURRENT: {
        draft.weathers.oceanCurrent.isShow = action.payload.checked
        draft.weathers.weathersChecked = changeWeathersChecked(
          action.payload.checked,
          draft.weathers.weathersChecked,
          WEATHER_KEY.OCEAN_CURRENT
        )

        return draft
      }

      case HistoricalActions.FETCH_WEATHER_OCEAN_CURRENT: {
        draft.weathers.oceanCurrent.loading = true
        setLocalStoragePayload(action.payload)
        return draft
      }

      case HistoricalActions.FETCH_WEATHER_OCEAN_CURRENT_SUCCESS: {
        draft.weathers.oceanCurrent.loading = false
        draft.weathers.oceanCurrent.data = action.data
        return draft
      }

      case HistoricalActions.FETCH_WEATHER_OCEAN_CURRENT_FAILED: {
        const { error } = action.payload

        draft.weathers.oceanCurrent.data = null
        draft.weathers.oceanCurrent.loading = false
        draft.weathers.oceanCurrent.error = error
        removeLocalStorageData(WEATHER_KEY.OCEAN_CURRENT)

        notification.error({ message: error })

        return draft
      }

      case HistoricalActions.CANCEL_WEATHER_OCEAN_CURRENT: {
        draft.weathers.oceanCurrent.loading = false
        return draft
      }

      case HistoricalActions.SHOW_WEATHER_WIND_BARBS: {
        draft.weathers.windBarbs.isShow = action.payload.checked
        draft.weathers.weathersChecked = changeWeathersChecked(
          action.payload.checked,
          draft.weathers.weathersChecked,
          WEATHER_KEY.WIND_BARB
        )
        return draft
      }

      case HistoricalActions.FETCH_WEATHER_WIND_BARBS: {
        draft.weathers.windBarbs.loading = true
        setLocalStoragePayload(action.payload)

        return draft
      }

      case HistoricalActions.FETCH_WEATHER_WIND_BARBS_SUCCESS: {
        draft.weathers.windBarbs.loading = false
        draft.weathers.windBarbs.data = action.data

        return draft
      }

      case HistoricalActions.FETCH_WEATHER_WIND_BARBS_FAILED: {
        const { error } = action.payload

        draft.weathers.windBarbs.data = []
        draft.weathers.windBarbs.loading = false
        draft.weathers.windBarbs.error = error
        removeLocalStorageData(WEATHER_KEY.WIND_BARB)

        notification.error({ message: error })

        return draft
      }

      case HistoricalActions.CANCEL_WEATHER_WIND_BARBS: {
        draft.weathers.windBarbs.loading = false
        return draft
      }

      case HistoricalActions.SHOW_WEATHERS: {
        draft.weathers.weathersChecked = action.data

        return draft
      }

      case HistoricalActions.HIDE_WEATHERS: {
        draft.weathers.weathersChecked = []
        draft.weathers.windBarbs.isShow = false
        draft.weathers.oceanCurrent.isShow = false
        draft.weathers.surfacePressure.isShow = false
        draft.weathers.waveHeight.isShow = false
        draft.weathers.tropicalStorm.isShow = false
        return draft
      }

      default:
        return genReducers(initialState, BaseActions)(state, action)
    }
  })
}

export default historicalReducer
