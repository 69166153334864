/* eslint-disable no-empty-pattern */
import { all, call, put, takeLatest } from "redux-saga/effects"
import { get } from "lodash"
import { API_NAME } from "core/model/Api"
import VoyagesServices from "./api"
import {
  fetchTargetVesselsSuccess,
  fetchTargetVesselsFinished,
  fetchTargetVesselsFailed,
  fetchVoyagesSuccess,
  fetchVoyagesFailed,
  fetchArrivalPortsSuccess,
  fetchArrivalPortsFailed,
  fetchDeptPortsSuccess,
  fetchDeptPortsFailed,
  exportVoyagesFailed,
  exportVoyagesSuccess,
  fetchVoyagesItemSuccess,
  fetchVoyagesItemFailed,
  fetchVoyageSummaryChartsSuccess,
  fetchVoyageSummaryChartsFailed,
  fetchSpeedFuelAvgSpeedChartSuccess,
  fetchSpeedFuelAvgSpeedChartFailed,
  fetchSpeedFuelLngConsChartSuccess,
  fetchSpeedFuelLngConsChartFailed,
  fetchSpeedFuelMgoConsChartSuccess,
  fetchSpeedFuelMgoConsChartFailed,
  fetchPowerCurveChartSuccess,
  fetchPowerCurveChartFailed,
  fetchTankCondCargoVolumeChartSuccess,
  fetchTankCondCargoVolumeChartFailed,
  fetchTankCondTankTempChartSuccess,
  fetchTankCondTankTempChartFailed,
  fetchTankCondForcingVaporVolumeChartSuccess,
  fetchTankCondForcingVaporVolumeChartFailed,
  fetchTankCondAvgBoilOffRateChartSuccess,
  fetchTankCondAvgBoilOffRateChartFailed,
  updateManualFieldsSuccess,
  updateManualFieldsFailed,
  fetchVoyages,
  fetchManualPortsSuccess,
  fetchManualPortsFailed,
  manualImportVoyageFailed,
  createSummaryVoyageSuccess,
  createSummaryVoyageFailed,
  deleteVoyageFailed,
  restoreVoyageFailed,
  restoreVoyageSuccess,
  deleteVoyageSuccess,
} from "./actions"
import {
  VoyagesAction,
  IVoyagesFetch,
  IFetchVoyageArrivalPorts,
  IFetchVoyageDeptPorts,
  IExportVoyages,
  IVoyagesItemFetch,
  IFetchVoyageSummaryCharts,
  IFetchSpeedFuelAvgSpeedChart,
  IFetchSpeedFuelLngConsChart,
  IFetchSpeedFuelMgoConsChart,
  IFetchPowerCurveChart,
  IFetchTankCondCargoVolumeChart,
  IFetchTankCondTankTempChart,
  IFetchTankCondForcingVaporVolumeChart,
  IFetchTankCondAvgBoilOffRateChart,
  IVoyagesUpdateManualFields,
  IFetchManualPorts,
  IVoyagesManualImport,
  IVoyagesCreateSummaryVoyage,
  IDeleteVoyage,
  IRestoreVoyage,
} from "./types"

export function* fetchTargetVesselsSaga() {
  let allData: any[] = []
  const filter = { page: 1, target: true, sort_by: "name", sort_order: "asc" }
  try {
    while (true) {
      const {
        data: { data },
        headers,
      } = yield call(VoyagesServices.fetchTargetVessels, filter)

      allData = [...allData, ...data]
      yield put(fetchTargetVesselsSuccess({ data: allData }))

      if (!get(headers, "link").includes("next")) {
        yield put(fetchTargetVesselsFinished({ allData, headers }))
        break
      }
      filter.page += 1
    }
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        fetchTargetVesselsFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export function* fetchVoyagesSaga({ payload }: IVoyagesFetch) {
  try {
    const {
      data: { data },
      headers,
    } = yield call(VoyagesServices.fetchVoyages, payload)
    yield put(fetchVoyagesSuccess({ data, headers }))
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        fetchVoyagesFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export function* fetchVoyageItemSaga({ payload }: IVoyagesItemFetch) {
  try {
    const data = yield call(
      VoyagesServices.getCreateItem,
      `${API_NAME.voyage_map.get.analytic_v1_voyages_voyage_summary}/${payload.id}`,
      "get",
      null,
      true
    )

    yield put(fetchVoyagesItemSuccess({ data }))
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        fetchVoyagesItemFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export function* fetchArrivalPortsSaga({}: IFetchVoyageArrivalPorts) {
  try {
    const data = yield call(VoyagesServices.fetchArrivalPorts)
    yield put(fetchArrivalPortsSuccess({ data }))
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        fetchArrivalPortsFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export function* fetchDeptPortsSaga({}: IFetchVoyageDeptPorts) {
  try {
    const data = yield call(VoyagesServices.fetchDeptPorts)

    yield put(fetchDeptPortsSuccess({ data }))
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        fetchDeptPortsFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export function* exportVoyagesSummarySaga({ payload }: IExportVoyages) {
  try {
    yield call(VoyagesServices.exportVoyagesSummary, payload)
    yield put(exportVoyagesSuccess())
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        exportVoyagesFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export function* fetchVoyageSummaryChartsSaga({ payload }: IFetchVoyageSummaryCharts) {
  try {
    const {
      data: { data },
      headers,
    } = yield call(VoyagesServices.fetchVoyageSummaryCharts, payload)
    yield put(fetchVoyageSummaryChartsSuccess({ data, headers }))
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        fetchVoyageSummaryChartsFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export function* fetchSpeedFuelAvgSpeedChartSaga({ payload }: IFetchSpeedFuelAvgSpeedChart) {
  try {
    const {
      data: { data },
      headers,
    } = yield call(VoyagesServices.fetchSpeedFuelAvgSpeedChart, payload)
    yield put(fetchSpeedFuelAvgSpeedChartSuccess({ data, headers }))
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        fetchSpeedFuelAvgSpeedChartFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export function* fetchSpeedFuelLngConsChartSaga({ payload }: IFetchSpeedFuelLngConsChart) {
  try {
    const {
      data: { data },
      headers,
    } = yield call(VoyagesServices.fetchSpeedFuelLngConsChart, payload)
    yield put(fetchSpeedFuelLngConsChartSuccess({ data, headers }))
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        fetchSpeedFuelLngConsChartFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export function* fetchSpeedFuelMgoConsChartSaga({ payload }: IFetchSpeedFuelMgoConsChart) {
  try {
    const {
      data: { data },
      headers,
    } = yield call(VoyagesServices.fetchSpeedFuelMgoConsChart, payload)
    yield put(fetchSpeedFuelMgoConsChartSuccess({ data, headers }))
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        fetchSpeedFuelMgoConsChartFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export function* fetchPowerCurveChartSaga({ payload }: IFetchPowerCurveChart) {
  try {
    const {
      data: { data },
    } = yield call(VoyagesServices.fetchPowerCurveChart, payload)
    yield put(fetchPowerCurveChartSuccess({ data }))
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        fetchPowerCurveChartFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export function* fetchTankCondCaroVolumeChartSaga({ payload }: IFetchTankCondCargoVolumeChart) {
  try {
    const {
      data: { data },
      headers,
    } = yield call(VoyagesServices.fetchTankCondCargoVolumeChart, payload)
    yield put(fetchTankCondCargoVolumeChartSuccess({ data, headers }))
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        fetchTankCondCargoVolumeChartFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export function* fetchTankCondTankTempChartSaga({ payload }: IFetchTankCondTankTempChart) {
  try {
    const {
      data: { data },
      headers,
    } = yield call(VoyagesServices.fetchTankCondTankTempChart, payload)
    yield put(fetchTankCondTankTempChartSuccess({ data, headers }))
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        fetchTankCondTankTempChartFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export function* fetchTankCondForcingVaporVolumeChartSaga({
  payload,
}: IFetchTankCondForcingVaporVolumeChart) {
  try {
    const {
      data: { data },
      headers,
    } = yield call(VoyagesServices.fetchTankCondForcingVaporVolumeChart, payload)
    yield put(fetchTankCondForcingVaporVolumeChartSuccess({ data, headers }))
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        fetchTankCondForcingVaporVolumeChartFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export function* fetchTankCondAvgBoilOffRateChartSaga({
  payload,
}: IFetchTankCondAvgBoilOffRateChart) {
  try {
    const {
      data: { data },
      headers,
    } = yield call(VoyagesServices.fetchTankCondAvgBoilOffRateChart, payload)
    yield put(fetchTankCondAvgBoilOffRateChartSuccess({ data, headers }))
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        fetchTankCondAvgBoilOffRateChartFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export function* updateManualFieldsSaga({ payload }: IVoyagesUpdateManualFields) {
  try {
    const { form, refetch } = payload
    const {
      data: { data },
      headers,
    } = yield call(VoyagesServices.updateManualFieldsApi, form)
    yield put(updateManualFieldsSuccess({ data, headers }))
    yield put(fetchVoyages(refetch))
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        updateManualFieldsFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export function* fetchManualPortsSaga() {
  try {
    const data = yield call(VoyagesServices.fetchManualPorts)

    yield put(fetchManualPortsSuccess({ data }))
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        fetchManualPortsFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export function* manualImportVoyageSaga({ payload }: IVoyagesManualImport) {
  const { refetch, form } = payload
  try {
    yield call(VoyagesServices.manualImportVoyageApi, form)
    yield put(fetchVoyages(refetch))
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        manualImportVoyageFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
      yield put(fetchVoyages(refetch))
    }
  }
}

export function* createSummaryVoyageSaga({ payload }: IVoyagesCreateSummaryVoyage) {
  try {
    const { form, refetch } = payload
    const {
      data: { data },
      headers,
    } = yield call(VoyagesServices.createSummaryVoyageApi, form)
    yield put(createSummaryVoyageSuccess({ data, headers }))
    yield put(fetchVoyages(refetch))
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        createSummaryVoyageFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export function* deleteVoyageSaga({ payload }: IDeleteVoyage) {
  try {
    yield call(VoyagesServices.deleteVoyageApi, payload)
    yield put(deleteVoyageSuccess())
    yield put(fetchVoyages(payload))
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        deleteVoyageFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export function* restoreVoyageSaga({ payload }: IRestoreVoyage) {
  try {
    yield call(VoyagesServices.restoreVoyageApi, payload)
    yield put(restoreVoyageSuccess())
    yield put(fetchVoyages(payload))
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        restoreVoyageFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(VoyagesAction.FETCH_TARGET_VESSELS, fetchTargetVesselsSaga),
    takeLatest(VoyagesAction.FETCH_VOYAGES, fetchVoyagesSaga),
    takeLatest(VoyagesAction.FETCH_VOYAGES_ITEM, fetchVoyageItemSaga),
    takeLatest(VoyagesAction.FETCH_DEPT_PORTS, fetchDeptPortsSaga),
    takeLatest(VoyagesAction.FETCH_ARRIVAL_PORTS, fetchArrivalPortsSaga),
    takeLatest(VoyagesAction.EXPORT_VOYAGES_SUMMARY, exportVoyagesSummarySaga),
    takeLatest(VoyagesAction.FETCH_SUMMARY_CHARTS, fetchVoyageSummaryChartsSaga),
    takeLatest(VoyagesAction.FETCH_SPEED_FUEL_AVG_SPEED_CHART, fetchSpeedFuelAvgSpeedChartSaga),
    takeLatest(VoyagesAction.FETCH_SPEED_FUEL_LNG_CONS_CHART, fetchSpeedFuelLngConsChartSaga),
    takeLatest(VoyagesAction.FETCH_SPEED_FUEL_MGO_CONS_CHART, fetchSpeedFuelMgoConsChartSaga),
    takeLatest(VoyagesAction.FETCH_POWER_CURVE_CHART, fetchPowerCurveChartSaga),
    takeLatest(VoyagesAction.FETCH_TANK_COND_CARGO_VOLUME_CHART, fetchTankCondCaroVolumeChartSaga),
    takeLatest(VoyagesAction.FETCH_TANK_COND_TANK_TEMP_CHART, fetchTankCondTankTempChartSaga),
    takeLatest(
      VoyagesAction.FETCH_TANK_COND_FORCING_VAPOR_VOLUME_CHART,
      fetchTankCondForcingVaporVolumeChartSaga
    ),
    takeLatest(
      VoyagesAction.FETCH_TANK_COND_AVG_BOIL_OFF_RATE_CHART,
      fetchTankCondAvgBoilOffRateChartSaga
    ),
    takeLatest(VoyagesAction.UPDATE_MANUAL_FIELDS, updateManualFieldsSaga),
    takeLatest(VoyagesAction.FETCH_MANUAL_PORTS, fetchManualPortsSaga),
    takeLatest(VoyagesAction.MANUAL_IMPORT, manualImportVoyageSaga),
    takeLatest(VoyagesAction.CREATE_SUMMARY_VOYAGE, createSummaryVoyageSaga),
    takeLatest(VoyagesAction.DELETE_VOYAGE, deleteVoyageSaga),
    takeLatest(VoyagesAction.RESTORE_VOYAGE, restoreVoyageSaga),
  ])
}
