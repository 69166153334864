import produce from "immer"
import { Reducer } from "redux"
import genReducers from "services/_base/reducers"
import { BaseActions } from "./actions"
import { ILayoutState, LayoutActions, LayoutActionTypes } from "./types"

export const initialState: ILayoutState = {
  isCollapsed: true,
  isFullscreen: false,
  isShowHeader: false,
  isPinHeader: false,
}

const layoutReducer: Reducer<ILayoutState, LayoutActionTypes> = (state = initialState, action) => {
  return produce<ILayoutState>(state, (draft) => {
    switch (action.type) {
      case LayoutActions.SET_MENU_COLLAPSED: {
        const { isCollapsed } = action.payload
        draft.isCollapsed = isCollapsed

        return draft
      }
      case LayoutActions.SET_FULLSCREEN: {
        const { isFullscreen } = action.payload
        draft.isFullscreen = isFullscreen

        return draft
      }
      case LayoutActions.SET_SHOW_HEADER: {
        const { isShowHeader } = action.payload
        draft.isShowHeader = isShowHeader

        return draft
      }
      case LayoutActions.SET_PIN_HEADER: {
        const { isPinHeader } = action.payload
        draft.isPinHeader = isPinHeader

        return draft
      }
      default:
        return genReducers(initialState, BaseActions)(state, action)
    }
  })
}

export default layoutReducer
