import { createMemoSelector } from "services/_base/selectors"
import { IAppState } from "types/redux"

export const ShipDataSelector = createMemoSelector(
  (state: IAppState) => {
    const {
      vessels,
      channels,
      histories,
      templates,
      template,
      filterParams,
      units,
      exportProcess,
      genres,
    } = state.ship_data || {}
    return {
      vessels,
      channels,
      histories,
      templates,
      template,
      filterParams,
      units,
      exportProcess,
      genres,
    }
  },
  (shipDataResult) => shipDataResult
)
