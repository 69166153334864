import produce from "immer"
import { Reducer } from "redux"
import genReducers from "services/_base/reducers"
import { FacenNotification } from "components/Common/Notification"
import { setFillComparisonColor } from "core/constants/barCharts"
import { ALL_ROUTE_COLOR_NAME } from "core/constants/vesselRoutes"
import { BaseActions } from "./actions"
import {
  VoyagesComparisonAction,
  VoyagesComparisonActionTypes,
  IVoyagesComparisonState,
  IVoyagesComparisonRoutes,
} from "./types"

export const defaultConditionPayload = {
  imos: [],
  dept_ports: [],
  arrival_ports: [],
  dept_years: [],
  dept_months: [],
  voyages_no: [],
}

const voyagesData = {
  isChanged: false,
  payload: defaultConditionPayload,
  voyages: {
    loading: false,
    error: null,
    filter_voyages: [],
    voyages_chart: null,
    voyages_no: [],
  },
  payload_voyages: {
    loading: false,
    error: null,
    filter_voyages: [],
    voyages_chart: null,
    voyages_no: [],
  },
  filter_voyages_no: [],
}

const routesData: IVoyagesComparisonRoutes = { blue: [], yellow: [], lime: [], green: [], red: [] }

export const initialState: IVoyagesComparisonState = {
  vessels: { loading: false, error: null, data: [] },
  dept_ports: {
    loading: false,
    error: "",
    data: [],
  },
  arrival_ports: {
    loading: false,
    error: "",
    data: [],
  },
  voyages_comparison: {
    blue: voyagesData,
    yellow: voyagesData,
    lime: voyagesData,
    green: voyagesData,
    red: voyagesData,
  },
  routes: {
    loading: false,
    error: null,
    data: routesData,
  },
}

const usersReducer: Reducer<IVoyagesComparisonState, VoyagesComparisonActionTypes> = (
  state = initialState,
  action
) => {
  return produce<IVoyagesComparisonState>(state, (draft) => {
    switch (action.type) {
      case VoyagesComparisonAction.FETCH_TARGET_VESSELS: {
        draft.vessels.loading = true
        draft.vessels.error = null

        return draft
      }

      case VoyagesComparisonAction.FETCH_TARGET_VESSELS_SUCCESS: {
        const { data } = action.payload
        draft.vessels.data = data

        return draft
      }

      case VoyagesComparisonAction.FETCH_TARGET_VESSELS_FAILED: {
        const { error } = action.payload
        draft.vessels.loading = false
        draft.vessels.error = error
        FacenNotification.error({ message: error })

        return draft
      }

      case VoyagesComparisonAction.FETCH_TARGET_VESSELS_FINISHED: {
        const { allData } = action.payload
        draft.vessels.data = allData
        draft.vessels.loading = false

        return draft
      }

      case VoyagesComparisonAction.FETCH_DEPARTURE_PORTS: {
        draft.dept_ports.loading = true
        return draft
      }

      case VoyagesComparisonAction.FETCH_DEPARTURE_PORTS_SUCCESS: {
        const { data } = action.payload
        draft.dept_ports.loading = false
        draft.dept_ports.data = data

        return draft
      }

      case VoyagesComparisonAction.FETCH_DEPARTURE_PORTS_FAILED: {
        const { error } = action.payload
        draft.dept_ports.loading = false
        draft.dept_ports.error = error
        FacenNotification.error({ message: error })

        return draft
      }

      case VoyagesComparisonAction.FETCH_ARRIVAL_PORTS: {
        draft.arrival_ports.loading = true
        return draft
      }

      case VoyagesComparisonAction.FETCH_ARRIVAL_PORTS_SUCCESS: {
        const { data } = action.payload
        draft.arrival_ports.loading = false
        draft.arrival_ports.data = data

        return draft
      }

      case VoyagesComparisonAction.FETCH_ARRIVAL_PORTS_FAILED: {
        const { error } = action.payload
        draft.arrival_ports.loading = false
        draft.arrival_ports.error = error
        FacenNotification.error({ message: error })

        return draft
      }

      case VoyagesComparisonAction.FETCH_VOYAGES_COMPARISON: {
        const { color } = action.payload
        draft.voyages_comparison[color].payload_voyages.error = null
        draft.voyages_comparison[color].payload_voyages.loading = true
        draft.voyages_comparison[color].isChanged = true
        return draft
      }

      case VoyagesComparisonAction.FETCH_VOYAGES_COMPARISON_SUCCESS: {
        const { data, color } = action.payload
        draft.voyages_comparison[color].payload_voyages.filter_voyages = data.filter_voyages
        draft.voyages_comparison[color].payload_voyages.voyages_chart = data.voyages_chart
        draft.voyages_comparison[color].payload_voyages.voyages_no = data.voyages_no
        draft.voyages_comparison[color].payload_voyages.loading = false
        return draft
      }

      case VoyagesComparisonAction.FETCH_VOYAGES_COMPARISON_FAILED: {
        const { error, color } = action.payload
        draft.voyages_comparison[color].payload_voyages.error = null
        draft.voyages_comparison[color].payload_voyages.loading = false
        draft.voyages_comparison[color].isChanged = false
        FacenNotification.error({ message: error })

        return draft
      }

      case VoyagesComparisonAction.FETCH_VOYAGES_HISTORY_ROUTES: {
        draft.routes.error = null
        draft.routes.loading = true
        return draft
      }

      case VoyagesComparisonAction.FETCH_VOYAGES_HISTORY_ROUTES_SUCCESS: {
        const { historyRoutes } = action.payload
        draft.routes.loading = false
        draft.routes.data = historyRoutes
        ALL_ROUTE_COLOR_NAME.forEach((color: string) => {
          draft.voyages_comparison[color].isChanged = false
        })
        return draft
      }
      case VoyagesComparisonAction.FETCH_VOYAGES_HISTORY_ROUTES_FAILED: {
        const { error } = action.payload

        draft.routes.loading = false
        draft.routes.error = error

        FacenNotification.error({ message: error })

        return draft
      }

      case VoyagesComparisonAction.FETCH_VOYAGE_NUMBER: {
        return draft
      }

      case VoyagesComparisonAction.FETCH_VOYAGE_NUMBER_SUCCESS: {
        const { data, color } = action.payload
        draft.voyages_comparison[color].filter_voyages_no = data

        return draft
      }
      case VoyagesComparisonAction.FETCH_VOYAGE_NUMBER_FAILED: {
        const { error } = action.payload
        FacenNotification.error({ message: error })

        return draft
      }

      case VoyagesComparisonAction.DUPLICATE_CONDITION: {
        const { color, duplicateColor } = action.payload
        draft.voyages_comparison[color] = {
          ...draft.voyages_comparison[duplicateColor],
          voyages: draft.voyages_comparison[color].voyages,
          payload_voyages: {
            ...draft.voyages_comparison[duplicateColor].payload_voyages,
            voyages_chart: {
              ...draft.voyages_comparison[duplicateColor].payload_voyages.voyages_chart,
              color: setFillComparisonColor(color),
            },
          },
        }
        draft.voyages_comparison[color].isChanged = true
        return draft
      }

      case VoyagesComparisonAction.DELETE_CONDITION: {
        const { color } = action.payload
        draft.voyages_comparison[color] = voyagesData
        draft.routes.data[color] = []
        return draft
      }

      case VoyagesComparisonAction.SAVE_CONDITIONS: {
        const { color } = action.payload
        draft.voyages_comparison[color].voyages = draft.voyages_comparison[color].payload_voyages
        return draft
      }

      case VoyagesComparisonAction.RESET_VOYAGES_COMPARISON: {
        draft = initialState // eslint-disable-line

        return draft
      }

      default:
        return genReducers(initialState, BaseActions)(state, action)
    }
  })
}

export default usersReducer
