import { createMemoSelector } from "services/_base/selectors"
import { IAppState } from "types/redux"

export const VoyagesComparisonSelector = createMemoSelector(
  (state: IAppState) => {
    const {
      vessels,
      dept_ports,
      arrival_ports,
      voyages_comparison,
      routes,
    } = state.voyages_comparison
    return {
      vessels,
      dept_ports,
      arrival_ports,
      voyages_comparison,
      routes,
    }
  },
  (voyagesComparisonResult) => voyagesComparisonResult
)
