export interface IRouteItem {
  id: number | string
  name: string
  can_delete: boolean
}

export interface IDataSettingsRouteState {
  list: {
    route: {
      loading: boolean
      error: string
      data: IRouteItem[]
      isDeleteItem: boolean
      isEditItem: boolean
    }
  }
  item: {
    loading: boolean
    error: string
    data: IRouteItem
  }
}

export enum DataSettingsRouteActions {
  FETCH_LIST_ROUTE_ALL = "[data settings route] fetch all",
  FETCH_LIST_ROUTE_ALL_SUCCESS = "[data settings route] fetch all success",
  FETCH_LIST_ROUTE_ALL_FINISHED = "[data settings route] fetch all finished",
  FETCH_LIST_ROUTE_ALL_FAILED = "[data settings route] fetch all failed",

  CREATE_ROUTE = "[data settings route] create route",
  CREATE_ROUTE_SUCCESS = "[data settings route] create route success",
  CREATE_ROUTE_FAILED = "[data settings route] create route failed",

  UPDATE_ROUTE = "[data settings route] update route",
  UPDATE_ROUTE_SUCCESS = "[data settings route] update route success",
  UPDATE_ROUTE_FAILED = "[data settings route] update route failed",

  DELETE_ROUTE = "[data settings route] delete route",
  DELETE_ROUTE_SUCCESS = "[data settings route] delete route success",
  DELETE_ROUTE_FAILED = "[data settings route] delete route failed",
}

export interface ICreateRoute {
  type: DataSettingsRouteActions.CREATE_ROUTE
  payload: {
    name: string
  }
}

export interface ICreateRouteSuccess {
  type: DataSettingsRouteActions.CREATE_ROUTE_SUCCESS
}

export interface ICreateRouteFailed {
  type: DataSettingsRouteActions.CREATE_ROUTE_FAILED
  payload: { error: string }
}

export interface IUpdateRoute {
  type: DataSettingsRouteActions.UPDATE_ROUTE
  payload: {
    name: string
  }
}

export interface IUpdateRouteSuccess {
  type: DataSettingsRouteActions.UPDATE_ROUTE_SUCCESS
}

export interface IUpdateRouteFailed {
  type: DataSettingsRouteActions.UPDATE_ROUTE_FAILED
  payload: { error: string }
}

export interface IDeleteRoute {
  type: DataSettingsRouteActions.DELETE_ROUTE
  payload: {
    id: string
  }
}

export interface IDeleteRouteSuccess {
  type: DataSettingsRouteActions.DELETE_ROUTE_SUCCESS
}

export interface IDeleteRouteFailed {
  type: DataSettingsRouteActions.DELETE_ROUTE_FAILED
  payload: { error: string }
}

export interface IListRouteFetchAll {
  type: DataSettingsRouteActions.FETCH_LIST_ROUTE_ALL
}

export interface IListRouteFetchAllSuccess {
  type: DataSettingsRouteActions.FETCH_LIST_ROUTE_ALL_SUCCESS
  payload: {
    data: []
  }
}

export interface IListRouteFetchAllFinished {
  type: DataSettingsRouteActions.FETCH_LIST_ROUTE_ALL_FINISHED
  payload: {
    allData: []
    headers: any
  }
}

export interface IListRouteFetchAllFailed {
  type: DataSettingsRouteActions.FETCH_LIST_ROUTE_ALL_FAILED
  payload: { error: string }
}

export type DataSettingsRouteTypes =
  | IListRouteFetchAll
  | IListRouteFetchAllSuccess
  | IListRouteFetchAllFailed
  | ICreateRoute
  | ICreateRouteSuccess
  | ICreateRouteFailed
  | IUpdateRoute
  | IUpdateRouteSuccess
  | IUpdateRouteFailed
  | IDeleteRoute
  | IDeleteRouteSuccess
  | IListRouteFetchAllFinished
  | IDeleteRouteFailed
