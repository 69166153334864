import { drawCircleRadius, rotatedPoint, startAngle, stopAngle } from "./weatherStorm/convertRadius"

const degreesToRadians = (degrees) => {
  return degrees * (Math.PI / 180)
}

const calcCoordinate = (cx, cy, x, y, phase) => {
  return {
    x: cx + (x - cx) * Math.cos(phase) - (y - cy) * Math.sin(phase),
    y: cy + (x - cx) * Math.sin(phase) + (y - cy) * Math.cos(phase),
  }
}

const setDirection = (direction, degrees = 10) => {
  return {
    startAngle: direction - degrees / 2,
    stopAngle: direction + degrees / 2,
  }
}

export const drawCanvas = (coordinates: any, markerParams: any, state: any) => {
  if (!markerParams) return null
  let marker = !markerParams.data ? markerParams : markerParams.data
  const cx = coordinates[0]
  const cy = coordinates[1]
  const ctx = state.context
  const shapeType = marker?.shapeType
  const size = marker?.shapeSize
  const shapeColor = marker?.shapeColor
  const borderColor = marker?.borderColor
  const borderWidth = marker?.borderWith
  const phase = degreesToRadians(marker?.angleDegrees)

  if (shapeType === "fat_arrow") {
    const coordinate1 = calcCoordinate(cx, cy, cx - size / 12, cy + size, phase)
    const x1 = coordinate1.x
    const y1 = coordinate1.y
    const coordinate2 = calcCoordinate(cx, cy, cx - size / 12, cy - size / 1.8, phase)
    const x2 = coordinate2.x
    const y2 = coordinate2.y
    const coordinate3 = calcCoordinate(cx, cy, cx - size / 3, cy - size / 1.8, phase)
    const x3 = coordinate3.x
    const y3 = coordinate3.y
    const coordinate4 = calcCoordinate(cx, cy, cx, cy - size, phase)
    const x4 = coordinate4.x
    const y4 = coordinate4.y
    const coordinate5 = calcCoordinate(cx, cy, cx + size / 3, cy - size / 1.8, phase)
    const x5 = coordinate5.x
    const y5 = coordinate5.y
    const coordinate6 = calcCoordinate(cx, cy, cx + size / 12, cy - size / 1.8, phase)
    const x6 = coordinate6.x
    const y6 = coordinate6.y
    const coordinate7 = calcCoordinate(cx, cy, cx + size / 12, cy + size, phase)
    const x7 = coordinate7.x
    const y7 = coordinate7.y

    ctx.beginPath()
    ctx.strokeStyle = borderColor
    ctx.fillStyle = shapeColor
    ctx.lineWidth = borderWidth
    ctx.moveTo(x1, y1)
    ctx.lineTo(x2, y2)
    ctx.lineTo(x3, y3)
    ctx.lineTo(x4, y4)
    ctx.lineTo(x5, y5)
    ctx.lineTo(x6, y6)
    ctx.lineTo(x7, y7)

    ctx.fill()
    ctx.closePath()
    ctx.stroke()
  } else if (shapeType === "thin_arrow") {
    const coordinate1 = calcCoordinate(cx, cy, cx, cy + size / 1.5, phase)
    const x1 = coordinate1.x
    const y1 = coordinate1.y
    const coordinate2 = calcCoordinate(cx, cy, cx, cy - size / 1.7, phase)
    const x2 = coordinate2.x
    const y2 = coordinate2.y
    const coordinate3 = calcCoordinate(cx, cy, cx - size / 3.5, cy - size / 3, phase)
    const x3 = coordinate3.x
    const y3 = coordinate3.y
    const coordinate4 = calcCoordinate(cx, cy, cx + size / 3.5, cy - size / 3, phase)
    const x4 = coordinate4.x
    const y4 = coordinate4.y
    ctx.beginPath()
    ctx.lineWidth = borderWidth
    ctx.strokeStyle = shapeColor
    ctx.moveTo(x1, y1)
    ctx.lineTo(x2, y2)
    ctx.moveTo(x2, y2)
    ctx.lineTo(x3, y3)
    ctx.lineTo(x2, y2)
    ctx.lineTo(x4, y4)
    ctx.stroke()
  } else if (shapeType === "line") {
    const coordinate1 = calcCoordinate(cx, cy, cx, cy + size, phase)
    const x1 = coordinate1.x
    const y1 = coordinate1.y
    const coordinate2 = calcCoordinate(cx, cy, cx, cy - size, phase)
    const x2 = coordinate2.x
    const y2 = coordinate2.y
    const coordinate3 = calcCoordinate(cx, cy, cx - size / 2.5, cy - size / 1.5, phase)
    const x3 = coordinate3.x
    const y3 = coordinate3.y
    const coordinate4 = calcCoordinate(cx, cy, cx + size / 2.5, cy - size / 1.5, phase)
    const x4 = coordinate4.x
    const y4 = coordinate4.y
    ctx.beginPath()
    ctx.lineWidth = borderWidth
    ctx.strokeStyle = shapeColor
    ctx.moveTo(x1, y1)
    ctx.lineTo(x2, y2)
    ctx.moveTo(x2, y2)
    ctx.lineTo(x3, y3)
    ctx.lineTo(x2, y2)
    ctx.lineTo(x4, y4)
    ctx.stroke()
  } else if (shapeType === "semicircle") {
    const lat = markerParams.coordinates[0]
    const lng = markerParams.coordinates[1]
    const { radius } = drawCircleRadius([lat, lng], marker)
    if (marker.circleAngle && marker.circleAngle !== 0)
      marker = { ...marker, ...setDirection(marker.angleDegrees, marker.circleAngle) }
    const r = radius
    const start = rotatedPoint(startAngle(marker), r)

    ctx.beginPath()
    ctx.moveTo(cx, cy)
    ctx.lineTo(cx + start.x, cy + start.y)
    ctx.arc(cx, cy, r, startAngle(marker), stopAngle(marker))
    ctx.lineTo(cx, cy)
    if (marker.fill) {
      ctx.strokeStyle = marker.fillColor || marker.color
      ctx.globalAlpha = marker.fillOpacity
      ctx.fillStyle = marker.fillColor || marker.color
      ctx.fill(marker.fillRule || "evenodd")
    }

    if (marker.stroke && marker.weight !== 0) {
      ctx.globalAlpha = marker.opacity
      ctx.lineWidth = marker.weight
      ctx.strokeStyle = marker.color
      ctx.lineCap = marker.lineCap
      ctx.lineJoin = marker.lineJoin
      ctx.stroke()
    }
    ctx.stroke()
    ctx.closePath()
  } else if (shapeType === "wind_barb") {
    let spd = marker.shapeSpeed ?? 0
    const small_step = size / 7
    let index = 0
    const short_line = size / 4
    const long_line = size / 2
    ctx.translate(cx, cy)
    ctx.rotate(phase)
    ctx.translate(-cx, -cy)
    if (spd === undefined || spd == null || spd === 0) {
      ctx.beginPath()
      ctx.arc(cx, cy, size / 10, 0, 2 * Math.PI, true)
      ctx.lineWidth = 1
      ctx.strokeStyle = "black"
      ctx.closePath()
      ctx.stroke()
    } else {
      const x1 = cx
      const y1 = cy + size / 2
      const x2 = cx
      const y2 = cy - size / 2

      ctx.beginPath()
      ctx.strokeStyle = shapeColor
      ctx.fillStyle = shapeColor
      ctx.lineWidth = borderWidth
      ctx.moveTo(x1, y1)
      ctx.lineTo(x2, y2)

      ctx.moveTo(cx, cy - size / 2)
      if (spd >= 5) {
        spd = 5 * Math.round(spd / 5)
        const fiftyLine = Math.floor(spd / 50)
        spd -= fiftyLine * 50
        const tenLines = Math.floor(spd / 10)
        const fiveLine = spd % 10 > 0
        if (fiftyLine) {
          // eslint-disable-next-line
          for (let i = 0; i < fiftyLine; i++) {
            ctx.moveTo(x2, y2 + index)
            const coordinate = calcCoordinate(
              x2,
              y2 + index,
              x2 + (long_line * 80) / 100,
              y2 + index,
              degreesToRadians(-20)
            )
            ctx.lineTo(coordinate.x, coordinate.y)
            index += small_step
            ctx.lineTo(x2, y2 + index)

            index += small_step / 2
          }
        }

        if (tenLines) {
          // eslint-disable-next-line
          for (let i = 0; i < tenLines; i++) {
            ctx.moveTo(x2, y2 + index)
            const coordinate = calcCoordinate(
              x2,
              y2 + index,
              x2 + long_line,
              y2 + index,
              degreesToRadians(-20)
            )
            ctx.lineTo(coordinate.x, coordinate.y)
            index += small_step
          }
        }

        if (fiveLine) {
          if (fiftyLine === 0 && tenLines === 0) {
            index += small_step
          }
          ctx.moveTo(x2, y2 + index)
          const coordinate = calcCoordinate(
            x2,
            y2 + index,
            x2 + short_line,
            y2 + index,
            degreesToRadians(-20)
          )
          ctx.lineTo(coordinate.x, coordinate.y)
        }
      }
      ctx.fill()
    }
    ctx.closePath()
    ctx.stroke()

    ctx.translate(cx, cy)
    ctx.rotate(-phase)
    ctx.translate(-cx, -cy)
  }

  return ctx
}
