/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { format, parse, addDays } from "date-fns"
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz"

export const MM_DD_YY = "MM/dd/yy"
export const MM_DD = "MM/dd"
export const MM_DD_YY_HH = "MM/dd/yy HH"
export const MM_DD_YY_HH_MM = "MM/dd/yy HH:mm"
export const YYYY_MM_DD = "yyyy-MM-dd"
export const YYYY_MM_DD_T_HH_MM_SS = "yyyy-MM-dd'T'HH:mm:ss"
export const YYYY_MM_DD_HH_MM_SS_UTC = "yyyy-MM-dd HH:mm:ss 'UTC'"
export const MMM_DD_HH_MM = "MMM-dd HH:mm"
export const DD_MONTH_HH_MM_UTC = "dd MMM HH:mm 'UTC'"
export const DD_MONTH_YYYY_HH_MM_UTC = "dd MMM yyyy HH:mm 'UTC'"
export const DD_MONTH_UTC = "dd/MM HH:mm 'UTC'"
export const DEFAULT_TIMEZONE = "Greenwich"
export const DEFAULT_DATETIME = "00/00/00 00:00:00"
export const YY_MM_DD_HH_MM_SS = "yy/MM/dd HH:mm:ss"
export const YYYY_MM_DD_HH_MM_SS = "yyyy-MM-dd HH:mm:ss"
export const MMM_dd_yyyy = "MMM dd yyyy"

// @Moment used
export const MOMENT_DD_MONTH_UTC = "DD/MM HH:mm UTC"
export const MOMENT_DD_MONTH_YYYY_HH_MM_UTC = "DD MMM YYYY HH:mm UTC"
export const MOMENT_DD_MONTH_YYYY_HH_MM_SS = "DD MMM YYYY HH:mm:ss"
export const MOMENT_DD_MONTH_YYYY_HH_MM_SS_UTC = "DD MMM YYYY HH:mm:ss UTC"
export const MOMENT_YYYY_MM_DD_HH_MM_SS_UTC = "YYYY-MM-DD HH:mm:ss UTC"
export const MOMENT_MM_DD_YY_HH = "MM/DD/YY HH"
export const MOMENT_MM_DD_YY = "MM/DD/YY"
export const MOMENT_MM_DD_YY_HH_MM = "MM/DD/YY HH:mm"
export const MOMENT_DD_MONTH_HH_MM_UTC = "DD MMM HH:mm UTC"
export const MOMENT_MMM_DD_HH_MM = "MMM-DD HH:mm"
export const MOMENT_YYYY_MM_DD_T_HH_MM_SS = "YYYY-MM-DDTHH:mm:ss"
export const MOMENT_YYYY_MM_DD = "YYYY-MM-DD"

export const createDateFromDateWithFormat = (currentDate: Date, dateFormat: string) => {
  const formatedDate = format(currentDate, dateFormat)
  return parse(formatedDate, dateFormat, new Date())
}

export const createDateWihoutTimeZone = (stringDate: string) => {
  if (!stringDate) return new Date()
  const numberDate = Date.parse(stringDate)
  const comparedPoint = new Date(numberDate)
  return utcToZonedTime(comparedPoint, DEFAULT_TIMEZONE)
}

export const createDateWithCurrentTimeZone = (stringDate: string) => {
  if (!stringDate) return new Date()
  const numberDate = Date.parse(stringDate)
  return new Date(numberDate)
  // return utcToZonedTime(comparedPoint, DEFAULT_TIMEZONE)
}

export const createMarks = (hours: number, startDate: Date) => {
  const marks = Array(Math.round(hours) / 24)
    .fill(1)
    .reduce((acc: { [key: number]: string }, _, index) => {
      acc[index * 24] = format(addDays(startDate, index), MM_DD)
      return acc
    }, {})
  return marks
}
