export interface IVoyageSummaryItem {
  id: number
  imo: number
  vessel_id: number
  vessel_name: string
  voyage_name: string
  voyage_no: string
  leg_id: number
  pacific_voyage: string | boolean
  voyage_leg: string
  port_dept: string
  atd_lt: string
  atd_utc: string
  port_arrival: string
  ata_lt: string
  ata_utc: string
  duration: string | number
  distance: number
  average_speed: string | number
  cargo_volume_at_port_of_arrival: number
  lng_consumption: number
  mgo_consumption: number
  average_boil_off_rate: number
  actual_heel: number
  adq: number
  estimated_heel: number
  estimated_edq: number
  apply_ata_lt: string
  apply_ata_utc: string
  apply_atd_lt: string
  apply_atd_utc: string
  apply_average_speed: number
  apply_distance: number
  apply_duration: number
  apply_port_arrival: string
  apply_port_dept: string
  manual_ata_lt: string
  manual_ata_time_zone: string
  manual_ata_utc: string
  manual_atd_lt: string
  manual_atd_time_zone: string
  manual_atd_utc: string
  manual_average_speed: number
  manual_distance: null
  manual_duration: number
  manual_port_arrival: string
  manual_port_dept: string
  plan_aq: number
  plan_avg_speed: number
  plan_cargo_vol_on_arrival: number
  plan_distance: number
  plan_duration: number
  plan_eta_lt: string
  plan_etd_lt: string
  plan_heel: number
  plan_lng_cons: number
  plan_port_arrival: string
  plan_port_dept: string
}

export interface IPowerCurveItem {
  tcp_curve_points: []
  tcp_plan_points: []
  actual_fitting_curve_points: []
  actual_plot_points: []
}

export interface IVoyagesState {
  vessels: { loading: boolean; error: string; data: [] }
  voyages: {
    loading: boolean
    error: string | null
    data: { [key: string]: string | null }[]
    total_records: number
    current_page: number
    per_page: number
    total_pages: number
  }
  filters: {
    payload: IVoyagesFilter
  }
  arrival_ports: {
    loading: boolean
    error: string | null
    data: []
  }
  dept_ports: {
    loading: boolean
    error: string | null
    data: []
  }
  export_voyage: {
    loading: boolean
    error: string | null
  }
  selected_voyage: {
    loading: boolean
    error: string | null
    data: IVoyageSummaryItem
  }
  manual_ports: {
    loading: boolean
    error: string
    data: string[]
    item: { loading: boolean; error: string }
  }
  voyage_charts: {
    isShowChart: boolean
    summary: {
      loading: boolean
      error: string | null
      data: { [key: string]: string | null }[]
    }
    speed_fuel: {
      avg_speed: {
        loading: boolean
        error: string | null
        data: { [key: string]: string | null }[]
      }
      lng_cons: {
        loading: boolean
        error: string | null
        data: { [key: string]: string | null }[]
      }
      mgo_cons: {
        loading: boolean
        error: string | null
        data: { [key: string]: string | null }[]
      }
    }
    power_curve: {
      loading: boolean
      error: string | null
      data: { [key: string]: number[] }
    }
    tank_conditions: {
      cargo_volume: {
        loading: boolean
        error: string | null
        data: { [key: string]: string | null }[]
      }
      tank_temp: {
        loading: boolean
        error: string | null
        data: { [key: string]: string | null }[]
      }
      forcing_vapor_volume: {
        loading: boolean
        error: string | null
        data: { [key: string]: string | null }[]
      }
      avg_boil_off_rate: {
        loading: boolean
        error: string | null
        data: { [key: string]: string | null }[]
      }
    }
  }
  import_voyage: {
    manual: { loading: boolean; error: string; data: string }
  }
}

export interface IVoyagesFilter {
  imo: string
  port_arrival: string
  port_dept: string
  voyage_leg: string
  voyage_no: string
  from_time: string
  to_time: string
  sort_by: string
  sort_order: string
  inactive_status: boolean
  show_plan: boolean
  page: number
  pacific_voyage: string
}

export enum VoyagesAction {
  FETCH_TARGET_VESSELS = "[data settings foc] fetch target vessel",
  FETCH_TARGET_VESSELS_SUCCESS = "[data settings foc] fetch target vessel success",
  FETCH_TARGET_VESSELS_FAILED = "[data settings foc] fetch target vessel failed",
  FETCH_TARGET_VESSELS_FINISHED = "[data settings foc] fetch target vessel finished",

  FETCH_VOYAGES = "[voyages] fetch all",
  FETCH_VOYAGES_SUCCESS = "[voyages] fetch all success",
  FETCH_VOYAGES_FAILED = "[voyages] fetch all failed",

  FETCH_VOYAGES_ITEM = "[voyages] fetch item",
  FETCH_VOYAGES_ITEM_SUCCESS = "[voyages] fetch item success",
  FETCH_VOYAGES_ITEM_FAILED = "[voyages] fetch item failed",

  SET_VOYAGES_FILTERS = "[voyages_filter] set filters",
  SET_SELECTED_VOYAGE = "[voyages_selected] set selected",

  FETCH_ARRIVAL_PORTS = "[voyages_arrival_ports] fetch all",
  FETCH_ARRIVAL_PORTS_SUCCESS = "[voyages_arrival_ports] fetch all success",
  FETCH_ARRIVAL_PORTS_FAILED = "[voyages_arrival_ports] fetch all failed",

  FETCH_DEPT_PORTS = "[voyages_dept_ports] fetch all",
  FETCH_DEPT_PORTS_SUCCESS = "[voyages_dept_ports] fetch all success",
  FETCH_DEPT_PORTS_FAILED = "[voyages_dept_ports] fetch all failed",

  EXPORT_VOYAGES_SUMMARY = "[voyages] export",
  EXPORT_VOYAGES_SUMMARY_SUCCESS = "[voyages] export success",
  EXPORT_VOYAGES_SUMMARY_FAILED = "[voyages] export failed",

  SHOW_CHART = "[voyage_charts] show Chart",

  FETCH_MANUAL_PORTS = "[voyage_manual] fetch ports",
  FETCH_MANUAL_PORTS_SUCCESS = "[voyage_manual] fetch ports success",
  FETCH_MANUAL_PORTS_FAILED = "[voyage_manual] fetch ports failed",

  FETCH_SUMMARY_CHARTS = "[voyage_charts] fetch summary",
  FETCH_SUMMARY_CHARTS_SUCCESS = "[voyage_charts] fetch summary success",
  FETCH_SUMMARY_CHARTS_FAILED = "[voyage_charts] fetch summary failed",

  FETCH_SPEED_FUEL_AVG_SPEED_CHART = "[voyage_charts] fetch speed fuel avg_speed",
  FETCH_SPEED_FUEL_AVG_SPEED_CHART_SUCCESS = "[voyage_charts] fetch speed fuel avg_speed success",
  FETCH_SPEED_FUEL_AVG_SPEED_CHART_FAILED = "[voyage_charts] fetch speed fuel avg_speed failed",

  FETCH_SPEED_FUEL_LNG_CONS_CHART = "[voyage_charts] fetch speed fuel lng_cons",
  FETCH_SPEED_FUEL_LNG_CONS_CHART_SUCCESS = "[voyage_charts] fetch speed fuel lng_cons success",
  FETCH_SPEED_FUEL_LNG_CONS_CHART_FAILED = "[voyage_charts] fetch speed fuel lng_cons failed",

  FETCH_SPEED_FUEL_MGO_CONS_CHART = "[voyage_charts] fetch speed fuel mgo_cons",
  FETCH_SPEED_FUEL_MGO_CONS_CHART_SUCCESS = "[voyage_charts] fetch speed fuel mgo_cons success",
  FETCH_SPEED_FUEL_MGO_CONS_CHART_FAILED = "[voyage_charts] fetch speed fuel mgo_cons failed",

  FETCH_POWER_CURVE_CHART = "[voyage_charts] fetch power curve",
  FETCH_POWER_CURVE_CHART_SUCCESS = "[voyage_charts] fetch power curve success",
  FETCH_POWER_CURVE_CHART_FAILED = "[voyage_charts] fetch power curve failed",
  FETCH_TANK_COND_CARGO_VOLUME_CHART = "[voyage_charts] fetch tank conditions cargo_volume",
  FETCH_TANK_COND_CARGO_VOLUME_CHART_SUCCESS = "[voyage_charts] fetch tank conditions cargo_volume success",
  FETCH_TANK_COND_CARGO_VOLUME_CHART_FAILED = "[voyage_charts] fetch tank conditions cargo_volume failed",

  FETCH_TANK_COND_TANK_TEMP_CHART = "[voyage_charts] fetch tank conditions tank_temp",
  FETCH_TANK_COND_TANK_TEMP_CHART_SUCCESS = "[voyage_charts] fetch tank conditions tank_temp success",
  FETCH_TANK_COND_TANK_TEMP_CHART_FAILED = "[voyage_charts] fetch tank conditions tank_temp failed",

  FETCH_TANK_COND_FORCING_VAPOR_VOLUME_CHART = "[voyage_charts] fetch tank conditions forcing_vapor_volume",
  FETCH_TANK_COND_FORCING_VAPOR_VOLUME_CHART_SUCCESS = "[voyage_charts] fetch tank conditions forcing_vapor_volume success",
  FETCH_TANK_COND_FORCING_VAPOR_VOLUME_CHART_FAILED = "[voyage_charts] fetch tank conditions forcing_vapor_volume failed",

  FETCH_TANK_COND_AVG_BOIL_OFF_RATE_CHART = "[voyage_charts] fetch tank conditions avg_boil_off_rate",
  FETCH_TANK_COND_AVG_BOIL_OFF_RATE_CHART_SUCCESS = "[voyage_charts] fetch tank conditions avg_boil_off_rate success",
  FETCH_TANK_COND_AVG_BOIL_OFF_RATE_CHART_FAILED = "[voyage_charts] fetch tank conditions avg_boil_off_rate failed",

  UPDATE_MANUAL_FIELDS = "[voyages summary] update manual fields",
  UPDATE_MANUAL_FIELDS_SUCCESS = "[voyages summary] update manual fields success",
  UPDATE_MANUAL_FIELDS_FAILED = "[voyages summary] update manual fields failed",

  GET_TZ = "[voyages] get tz",

  MANUAL_IMPORT = "[voyages] manual import",
  MANUAL_IMPORT_FAILED = "[voyages] manual import failed",

  CREATE_SUMMARY_VOYAGE = "[voyages summary] create summary voyage",
  CREATE_SUMMARY_VOYAGE_SUCCESS = "[voyages summary] create summary voyage success",
  CREATE_SUMMARY_VOYAGE_FAILED = "[voyages summary] create summary voyage failed",

  DELETE_VOYAGE = "[voyages] delete voyage",
  DELETE_VOYAGE_SUCCESS = "[voyages] delete voyage success",
  DELETE_VOYAGE_FAILED = "[voyages] delete voyage failed",

  RESTORE_VOYAGE = "[voyages] restore voyage",
  RESTORE_VOYAGE_SUCCESS = "[voyages] restore voyage success",
  RESTORE_VOYAGE_FAILED = "[voyages] restore voyage failed",
}

export interface IVoyagesFetch {
  type: VoyagesAction.FETCH_VOYAGES
  payload: any
}

export interface IVoyagesFetchSuccess {
  type: VoyagesAction.FETCH_VOYAGES_SUCCESS
  payload: any
}

export interface IVoyagesFetchFailed {
  type: VoyagesAction.FETCH_VOYAGES_FAILED
  payload: {
    error: string
  }
}

export interface IVoyagesItemFetch {
  type: VoyagesAction.FETCH_VOYAGES_ITEM
  payload: {
    id: string | number
  }
}

export interface IVoyagesItemFetchSuccess {
  type: VoyagesAction.FETCH_VOYAGES_ITEM_SUCCESS
  payload: { data: IVoyageSummaryItem }
}

export interface IVoyagesItemFetchFailed {
  type: VoyagesAction.FETCH_VOYAGES_ITEM_FAILED
  payload: {
    error: string
  }
}

export interface ITargetVesselsFetch {
  type: VoyagesAction.FETCH_TARGET_VESSELS
}

export interface ITargetVesselsFetchSuccess {
  type: VoyagesAction.FETCH_TARGET_VESSELS_SUCCESS
  payload: {
    data: []
  }
}

export interface ITargetVesselsFetchFinished {
  type: VoyagesAction.FETCH_TARGET_VESSELS_FINISHED
  payload: {
    allData: []
    headers: any
  }
}

export interface ITargetVesselsFetchFailed {
  type: VoyagesAction.FETCH_TARGET_VESSELS_FAILED
  payload: {
    error: string
  }
}

export interface ISetVoyageFilters {
  type: VoyagesAction.SET_VOYAGES_FILTERS
  payload: any
}

export interface ISetSelectedVoyage {
  type: VoyagesAction.SET_SELECTED_VOYAGE
  payload: any
}

export interface IFetchVoyageArrivalPorts {
  type: VoyagesAction.FETCH_ARRIVAL_PORTS
}

export interface IFetchVoyageArrivalPortsSuccess {
  type: VoyagesAction.FETCH_ARRIVAL_PORTS_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchVoyageArrivalPortsFailed {
  type: VoyagesAction.FETCH_ARRIVAL_PORTS_FAILED
  payload: {
    error: string
  }
}

export interface IFetchVoyageDeptPorts {
  type: VoyagesAction.FETCH_DEPT_PORTS
}

export interface IFetchVoyageDeptPortsSuccess {
  type: VoyagesAction.FETCH_DEPT_PORTS_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchVoyageDeptPortsFailed {
  type: VoyagesAction.FETCH_DEPT_PORTS_FAILED
  payload: {
    error: string
  }
}

export interface IExportVoyages {
  type: VoyagesAction.EXPORT_VOYAGES_SUMMARY
  payload: any
}

export interface IExportVoyagesSuccess {
  type: VoyagesAction.EXPORT_VOYAGES_SUMMARY_SUCCESS
}

export interface IExportVoyagesFailed {
  type: VoyagesAction.EXPORT_VOYAGES_SUMMARY_FAILED
  payload: {
    error: string
  }
}

export interface IFetchVoyageSummaryCharts {
  type: VoyagesAction.FETCH_SUMMARY_CHARTS
  payload: any
}

export interface IFetchVoyageSummaryChartsSuccess {
  type: VoyagesAction.FETCH_SUMMARY_CHARTS_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchVoyageSummaryChartsFailed {
  type: VoyagesAction.FETCH_SUMMARY_CHARTS_FAILED
  payload: { error: string }
}

export interface ISetShowChart {
  type: VoyagesAction.SHOW_CHART
  payload: {
    isShowChart: boolean
  }
}

export interface IFetchSpeedFuelAvgSpeedChart {
  type: VoyagesAction.FETCH_SPEED_FUEL_AVG_SPEED_CHART
  payload: any
}

export interface IFetchSpeedFuelAvgSpeedChartSuccess {
  type: VoyagesAction.FETCH_SPEED_FUEL_AVG_SPEED_CHART_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchSpeedFuelAvgSpeedChartFailed {
  type: VoyagesAction.FETCH_SPEED_FUEL_AVG_SPEED_CHART_FAILED
  payload: { error: string }
}

export interface IFetchSpeedFuelLngConsChart {
  type: VoyagesAction.FETCH_SPEED_FUEL_LNG_CONS_CHART
  payload: any
}

export interface IFetchSpeedFuelLngConsChartSuccess {
  type: VoyagesAction.FETCH_SPEED_FUEL_LNG_CONS_CHART_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchSpeedFuelLngConsChartFailed {
  type: VoyagesAction.FETCH_SPEED_FUEL_LNG_CONS_CHART_FAILED
  payload: { error: string }
}

export interface IFetchSpeedFuelMgoConsChart {
  type: VoyagesAction.FETCH_SPEED_FUEL_MGO_CONS_CHART
  payload: any
}

export interface IFetchSpeedFuelMgoConsChartSuccess {
  type: VoyagesAction.FETCH_SPEED_FUEL_MGO_CONS_CHART_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchSpeedFuelMgoConsChartFailed {
  type: VoyagesAction.FETCH_SPEED_FUEL_MGO_CONS_CHART_FAILED
  payload: { error: string }
}

export interface IFetchPowerCurveChart {
  type: VoyagesAction.FETCH_POWER_CURVE_CHART
  payload: {
    voyage_summary_id: number | string
    margin_drop: number
  }
}

export interface IFetchPowerCurveChartSuccess {
  type: VoyagesAction.FETCH_POWER_CURVE_CHART_SUCCESS
  payload: {
    data: { [key: string]: number[] }
  }
}

export interface IFetchPowerCurveChartFailed {
  type: VoyagesAction.FETCH_POWER_CURVE_CHART_FAILED
  payload: { error: string }
}
export interface IFetchTankCondCargoVolumeChart {
  type: VoyagesAction.FETCH_TANK_COND_CARGO_VOLUME_CHART
  payload: any
}

export interface IFetchTankCondCargoVolumeChartSuccess {
  type: VoyagesAction.FETCH_TANK_COND_CARGO_VOLUME_CHART_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchTankCondCargoVolumeChartFailed {
  type: VoyagesAction.FETCH_TANK_COND_CARGO_VOLUME_CHART_FAILED
  payload: { error: string }
}

export interface IFetchTankCondTankTempChart {
  type: VoyagesAction.FETCH_TANK_COND_TANK_TEMP_CHART
  payload: any
}

export interface IFetchTankCondTankTempChartSuccess {
  type: VoyagesAction.FETCH_TANK_COND_TANK_TEMP_CHART_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchTankCondTankTempChartFailed {
  type: VoyagesAction.FETCH_TANK_COND_TANK_TEMP_CHART_FAILED
  payload: { error: string }
}

export interface IFetchTankCondForcingVaporVolumeChart {
  type: VoyagesAction.FETCH_TANK_COND_FORCING_VAPOR_VOLUME_CHART
  payload: any
}

export interface IFetchTankCondForcingVaporVolumeChartSuccess {
  type: VoyagesAction.FETCH_TANK_COND_FORCING_VAPOR_VOLUME_CHART_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchTankCondForcingVaporVolumeChartFailed {
  type: VoyagesAction.FETCH_TANK_COND_FORCING_VAPOR_VOLUME_CHART_FAILED
  payload: { error: string }
}

export interface IFetchTankCondAvgBoilOffRateChart {
  type: VoyagesAction.FETCH_TANK_COND_AVG_BOIL_OFF_RATE_CHART
  payload: any
}

export interface IFetchTankCondAvgBoilOffRateChartSuccess {
  type: VoyagesAction.FETCH_TANK_COND_AVG_BOIL_OFF_RATE_CHART_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchTankCondAvgBoilOffRateChartFailed {
  type: VoyagesAction.FETCH_TANK_COND_AVG_BOIL_OFF_RATE_CHART_FAILED
  payload: { error: string }
}

export interface IVoyagesUpdateManualFields {
  type: VoyagesAction.UPDATE_MANUAL_FIELDS
  payload: any
}

export interface IVoyagesUpdateManualFieldsSuccess {
  type: VoyagesAction.UPDATE_MANUAL_FIELDS_SUCCESS
  payload: any
}

export interface IVoyagesUpdateManualFieldsFailed {
  type: VoyagesAction.UPDATE_MANUAL_FIELDS_FAILED
  payload: {
    error: string
  }
}

export interface IFetchManualPorts {
  type: VoyagesAction.FETCH_MANUAL_PORTS
}

export interface IFetchManualPortsSuccess {
  type: VoyagesAction.FETCH_MANUAL_PORTS_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchManualPortsFailed {
  type: VoyagesAction.FETCH_MANUAL_PORTS_FAILED
  payload: {
    error: string
  }
}

export interface IVoyagesGetTz {
  type: VoyagesAction.GET_TZ
  payload: any
}

export interface IVoyagesManualImport {
  type: VoyagesAction.MANUAL_IMPORT
  payload: {
    refetch: any
    form: {
      id: number
      job_id: string
    }
  }
}

export interface IVoyagesManualImportFailed {
  type: VoyagesAction.MANUAL_IMPORT_FAILED
  payload: {
    error: string
  }
}

export interface IVoyagesCreateSummaryVoyage {
  type: VoyagesAction.CREATE_SUMMARY_VOYAGE
  payload: any
}

export interface IVoyagesCreateSummaryVoyageSuccess {
  type: VoyagesAction.CREATE_SUMMARY_VOYAGE_SUCCESS
  payload: any
}

export interface IVoyagesCreateSummaryVoyageFailed {
  type: VoyagesAction.CREATE_SUMMARY_VOYAGE_FAILED
  payload: {
    error: string
  }
}

export interface IDeleteVoyage {
  type: VoyagesAction.DELETE_VOYAGE
  payload: any
}

export interface IDeleteVoyageSuccess {
  type: VoyagesAction.DELETE_VOYAGE_SUCCESS
}

export interface IDeleteVoyageFailed {
  type: VoyagesAction.DELETE_VOYAGE_FAILED
  payload: {
    error: string
  }
}

export interface IRestoreVoyage {
  type: VoyagesAction.RESTORE_VOYAGE
  payload: any
}

export interface IRestoreVoyageSuccess {
  type: VoyagesAction.RESTORE_VOYAGE_SUCCESS
}

export interface IRestoreVoyageFailed {
  type: VoyagesAction.RESTORE_VOYAGE_FAILED
  payload: {
    error: string
  }
}

export type VoyagesActionTypes =
  | IVoyagesFetch
  | IVoyagesFetchSuccess
  | IVoyagesFetchFailed
  | IVoyagesItemFetch
  | IVoyagesItemFetchSuccess
  | IVoyagesItemFetchFailed
  | ITargetVesselsFetch
  | ITargetVesselsFetchSuccess
  | ITargetVesselsFetchFailed
  | ITargetVesselsFetchFinished
  | ISetVoyageFilters
  | ISetSelectedVoyage
  | IFetchVoyageArrivalPorts
  | IFetchVoyageArrivalPortsSuccess
  | IFetchVoyageArrivalPortsFailed
  | IFetchVoyageDeptPorts
  | IFetchVoyageDeptPortsSuccess
  | IFetchVoyageDeptPortsFailed
  | IExportVoyages
  | IExportVoyagesFailed
  | IExportVoyagesSuccess
  | IFetchVoyageSummaryCharts
  | IFetchVoyageSummaryChartsSuccess
  | IFetchVoyageSummaryChartsFailed
  | ISetShowChart
  | IFetchSpeedFuelAvgSpeedChart
  | IFetchSpeedFuelAvgSpeedChartSuccess
  | IFetchSpeedFuelAvgSpeedChartFailed
  | IFetchSpeedFuelLngConsChart
  | IFetchSpeedFuelLngConsChartSuccess
  | IFetchSpeedFuelLngConsChartFailed
  | IFetchSpeedFuelMgoConsChart
  | IFetchSpeedFuelMgoConsChartSuccess
  | IFetchSpeedFuelMgoConsChartFailed
  | IFetchPowerCurveChart
  | IFetchPowerCurveChartSuccess
  | IFetchPowerCurveChartFailed
  | IFetchTankCondCargoVolumeChart
  | IFetchTankCondCargoVolumeChartSuccess
  | IFetchTankCondCargoVolumeChartFailed
  | IFetchTankCondTankTempChart
  | IFetchTankCondTankTempChartSuccess
  | IFetchTankCondTankTempChartFailed
  | IFetchTankCondForcingVaporVolumeChart
  | IFetchTankCondForcingVaporVolumeChartSuccess
  | IFetchTankCondForcingVaporVolumeChartFailed
  | IFetchTankCondAvgBoilOffRateChart
  | IFetchTankCondAvgBoilOffRateChartSuccess
  | IFetchTankCondAvgBoilOffRateChartFailed
  | IVoyagesUpdateManualFieldsSuccess
  | IVoyagesUpdateManualFieldsFailed
  | IVoyagesUpdateManualFields
  | IFetchManualPorts
  | IFetchManualPortsSuccess
  | IFetchManualPortsFailed
  | IVoyagesGetTz
  | IVoyagesManualImport
  | IVoyagesManualImportFailed
  | IVoyagesCreateSummaryVoyage
  | IVoyagesCreateSummaryVoyageSuccess
  | IVoyagesCreateSummaryVoyageFailed
  | IDeleteVoyage
  | IDeleteVoyageSuccess
  | IDeleteVoyageFailed
  | IRestoreVoyage
  | IRestoreVoyageSuccess
  | IRestoreVoyageFailed
