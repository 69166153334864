import { PlusCircleOutlined, SaveOutlined } from "@ant-design/icons"
import { Row, Col, Button, Modal } from "antd"
import { SagaInjectionModes } from "core/constants/common"
import { useInjectReducer } from "core/hooks/useInjectReducer"
import useInjectSaga from "core/hooks/useInjectSaga"
import { clone, difference } from "lodash"
import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { VoyagesComparisonSelector } from "services/voyagesComparison/selectors"
import voyagesComparisonReducer, {
  defaultConditionPayload,
} from "services/voyagesComparison/reducers"
import voyagesComparisonSaga from "services/voyagesComparison/sagas"
import { ALL_ROUTE_COLOR_NAME } from "core/constants/vesselRoutes"
import { dispatch } from "core/utils/dispatch"
import { IVoyagesConditionsPayload } from "services/voyagesComparison/types"
import {
  deleteCondition,
  fetchDeparturePorts,
  fetchArrivalPorts,
  fetchTargetVessels,
  fetchVoyagesHistoryRoutes,
  duplicateCondition,
} from "services/voyagesComparison/actions"
import VoyageComparisonForm from "../../VoyagesComparisonDetail/VoyageComparisonForm"

const Index = () => {
  useInjectReducer({ key: "voyages_comparison", reducer: voyagesComparisonReducer })
  useInjectSaga({
    key: "voyages_comparison",
    saga: voyagesComparisonSaga,
    mode: SagaInjectionModes.RESTART_ON_REMOUNT,
  })
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [conditions, setConditions] = useState({
    [ALL_ROUTE_COLOR_NAME[0]]: defaultConditionPayload,
  })
  const [conditionsSaved, setConditionsSaved] = useState({
    [ALL_ROUTE_COLOR_NAME[0]]: defaultConditionPayload,
  })
  const [conditionsColor, setConditionsColor] = useState([ALL_ROUTE_COLOR_NAME[0]])
  const [conditionsColorSaved, setConditionsColorSaved] = useState([ALL_ROUTE_COLOR_NAME[0]])
  const [isValid, setIsValid] = useState(false)
  const [resetFields, setResetFields] = useState(false)
  const validateForms = useRef([])
  const {
    voyages_comparison: {
      blue: {
        payload_voyages: { loading: blueLoading },
      },
      yellow: {
        payload_voyages: { loading: yellowLoading },
      },
      lime: {
        payload_voyages: { loading: limeLoading },
      },
      green: {
        payload_voyages: { loading: greenLoading },
      },
      red: {
        payload_voyages: { loading: redLoading },
      },
    },
    routes: { loading: routesLoading },
  } = useSelector(VoyagesComparisonSelector)

  useEffect(() => {
    dispatch(fetchTargetVessels())
    dispatch(fetchDeparturePorts())
    dispatch(fetchArrivalPorts())
  }, [])

  const createConditions = (duplicateData = null, duplicateColor = null) => {
    setIsValid(false)
    const newColor = ALL_ROUTE_COLOR_NAME.filter((color) => {
      const sameColor = conditionsColor.filter((conditionColor) => conditionColor === color)
      if (sameColor.length === 0) return color
      return false
    })[0]
    setConditionsColor([...conditionsColor, newColor])
    if (duplicateColor) {
      dispatch(duplicateCondition({ color: newColor, duplicateColor }))
    }
    setConditions({
      ...conditions,
      [newColor]: duplicateData ?? defaultConditionPayload,
    })
  }

  const handleOk = () => {
    setIsValid(true)
  }

  const onValidate = (isValid: boolean) => {
    if (!isValid) validateForms.current = []
    else {
      validateForms.current = [...validateForms.current, isValid]
      if (
        conditionsColor.length === validateForms.current.length &&
        !validateForms.current.includes(false)
      ) {
        const diffCondition = difference(conditionsColorSaved, conditionsColor)
        diffCondition.forEach((color) => {
          dispatch(deleteCondition({ color }))
        })
        dispatch(fetchVoyagesHistoryRoutes())
        setIsModalVisible(!isValid)
        setConditionsColorSaved(conditionsColor)
        setConditionsSaved(clone(conditions))
        validateForms.current = []
      }
    }
    setIsValid(false)
  }

  const showModal = () => {
    setConditionsColor(conditionsColorSaved)
    setConditions(conditionsSaved)
    setResetFields(false)
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setConditionsColor(conditionsColorSaved)
    setConditions(conditionsSaved)
    setResetFields(true)
    setIsModalVisible(false)
  }

  const onDelete = (colorParams: string) => {
    if (conditionsColor.length > 1) {
      const cloneConditions = clone(conditions)
      delete cloneConditions[colorParams]
      setConditions(cloneConditions)
      setConditionsColor(conditionsColor.filter((color) => color !== colorParams))
    }
  }

  const setPayload = (color: string, data: IVoyagesConditionsPayload) => {
    setConditions({ ...conditions, [color]: data })
  }

  const footer = () => {
    return [
      <Button key="back" onClick={handleCancel}>
        Cancel
      </Button>,
      <Button
        key="submit"
        type="primary"
        htmlType="submit"
        icon={<SaveOutlined />}
        onClick={handleOk}
        loading={blueLoading || yellowLoading || limeLoading || greenLoading || redLoading}
      >
        Show
      </Button>,
    ]
  }

  return (
    <>
      <Row gutter={24}>
        <Col flex="auto">
          <Button type="primary" onClick={showModal} loading={routesLoading}>
            Conditions
          </Button>
        </Col>
        {isModalVisible && (
          <Modal
            title="CONDITIONS"
            className="heel-edq voyages_comparison mb-0"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            centered
            width="990px"
            footer={footer()}
          >
            <div className="voyages_comparison__container">
              {conditionsColor.map((color, index) => (
                <VoyageComparisonForm
                  color={color}
                  conditionId={index + 1}
                  isValid={isValid}
                  resetFields={resetFields}
                  onValidate={onValidate}
                  onDelete={onDelete}
                  setPayload={setPayload}
                  data={conditions[color]}
                  createConditions={createConditions}
                  conditionsColor={conditionsColor}
                />
              ))}
              <div className="d-flex align-items-center justify-content-end">
                <Button
                  type="link"
                  icon={<PlusCircleOutlined />}
                  size="middle"
                  onClick={() => createConditions()}
                  disabled={conditionsColor.length === 5}
                >
                  Add Condition
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </Row>
    </>
  )
}

export default Index
