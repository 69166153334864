import { LADEN_FIELD } from "core/constants/heelEdq"
import {
  getCurrentUser,
  signOut,
  signIn,
  refreshToken,
  fetchListCompany as authFetchListCompany,
} from "services/auth/actions"
import {
  createUser,
  fetchUsersAll,
  editUser,
  deleteUser,
  fetchListCompanies,
} from "services/usersManagement/actions"
import {
  fetchVesselsAll,
  createVessel,
  editVessel,
  deleteVessel,
  createNonTargetVessel,
  fetchNonTargetVessels,
  deleteNonTargetVessel,
  uploadGenreFile,
  exportGenreMapping,
  exportGenreError,
  fetchSimsDataType,
  fetchListCompany as vesselFetchListCompany,
} from "services/vesselsManagement/actions"
import { fetchWeathers } from "services/historical/actions"
import {
  fetchVesselsAll as shipDataFetchVesselsAll,
  fetchChannelsAll,
  fetchHistoriesAll,
  exportProcessing,
  fetchTemplatesAll,
  createTemplate,
  editTemplate,
  deleteTemplate,
  fetchUnits,
} from "services/shipData/actions"
import { createFOC, deleteFOC, fetchListFOC, updateFOC } from "services/dataSettings/tcpFOC/actions"
import {
  createPort,
  deletePort,
  fetchCountries,
  fetchListPort,
  fetchTimeZones,
  updatePort,
} from "services/dataSettings/portList/actions"
import { createRoute, deleteRoute, fetchListRoute } from "services/dataSettings/routeList/actions"
import {
  createDistanceRoute,
  deleteDistanceRoute,
  fetchFilterRoute,
  fetchListDistanceRoute,
  fetchPorts,
  fetchRoutes,
  updateDistanceRoute,
} from "services/dataSettings/distanceRoute/actions"
import {
  fetchArrivalPorts as fetchArrivalPortsComparison,
  fetchDeparturePorts,
  fetchVoyagesComparison,
  fetchVoyagesHistoryRoutes,
} from "services/voyagesComparison/actions"
import {
  fetchPanamaLngCons,
  updatePanamaLngCons,
} from "services/dataSettings/lngConsInPanama/actions"
import { fetchUserManual, uploadUserManual } from "services/userManual/actions"
import {
  fetchVesselAll,
  fetchVesselActualRoutes,
  fetchVesselPlanRoutes,
  fetchVesselEcdisRoutes,
  fetchVesselEcdisPoints,
  fetchVesselInformations,
  fetchStageVesselInformations,
  fetchXdfVesselInformations,
  updateATATime,
  fetchChartDFDEEngine,
  fetchChartDFDECargo,
  fetchTargetVesselAll,
  fetchChartStageCargo,
  fetchChartXDFCargo,
  fetchChartStageEngine,
  fetchChartXDFEngine,
  fetchDfdeVesselInformations,
} from "../../../services/dashboard/actions"
import {
  fetchDeptPort,
  fetchCalculationResult,
  createResult,
  updateResult,
  deleteResult,
  fetchTargetVessels,
  fetchLadenArrivalPort,
  fetchBallastArrivalPort,
  fetchLadenPacificRoute,
  fetchBallastPacificRoute,
  ladenCalculateVoyage,
  ballastCalculateVoyage,
  fetchLadenTimeZoneLabel,
  fetchBallastTimeZoneLabel,
  fetchLngConsumptionPanama,
} from "../../../services/heelEdq/actions"
import {
  exportVoyages,
  fetchDeptPorts,
  fetchArrivalPorts,
  fetchVoyages,
  fetchVoyageSummaryCharts,
  fetchSpeedFuelMgoConsChart,
  fetchSpeedFuelAvgSpeedChart,
  fetchSpeedFuelLngConsChart,
  fetchPowerCurveChart,
  fetchTankCondCargoVolumeChart,
  fetchTankCondTankTempChart,
  fetchTankCondForcingVaporVolumeChart,
  fetchTankCondAvgBoilOffRateChart,
  fetchManualPorts,
  getManualTz,
  manualImportVoyage,
  createSummaryVoyage,
  updateManualFields,
  deleteVoyage,
  restoreVoyage,
} from "../../../services/voyages/actions"

import {
  createCompany,
  deleteCompany,
  fetchListCompany,
  updateCompany,
} from "../../../services/company/actions"

export const API_METHODS = {
  POST: "post",
  DELETE: "delete",
  PUT: "put",
  GET: "get",
}
export const API_NAME = {
  auth: {
    get: {
      identity_v1_me: "identity/v1/me",
      identity_v1_fetch_list_companies: "identity/v1/companies/fetch_list_companies",
    },
    post: {
      identity_v1_logout: "identity/v1/logout",
      identity_v1_refresh: "identity/v1/refresh",
      identity_v1_auth: "identity/v1/auth",
    },
  },
  dashboard: {
    get: {
      ais_v1_latestPositions: "ais/v1/latest_positions",
      ais_v1_vessels: "ais/v1/vessels",
      ais_v1_trackings: "ais/v1/trackings",
      ais_v1_planRoutes: "ais/v1/plan_routes",
      ais_v1_ecdis_routes: "ais/v1/ecdis/routes",
      ais_v1_ecdis_routes_point_routes: "ais/v1/ecdis/routes/point_routes",
      ais_v1_vesselsInfo: "ais/v1/vessels/info",
      ais_v1_closestDestination: "ais/v1/closest_destinations",
      analytic_v1_vessel_stage_spec: "analytic/v1/vessels/stage_specs",
      analytic_v1_vessel_xdf_spec: "analytic/v1/vessels/xdf_specs",
      analytic_v1_vessel_dfde_spec: "analytic/v1/vessels/dfde_specs",
      analytic_v1_charts_stage_cargo: "/analytic/v1/charts/stage_cargo",
      analytic_v1_charts_xdf_cargo: "/analytic/v1/charts/xdf_cargo",
      analytic_v1_charts_stage_engine: "/analytic/v1/charts/stage_engine",
      analytic_v1_charts_xdf_engine: "/analytic/v1/charts/xdf_engine",
      analytic_v1_charts_dfde_engine: "analytic/v1/charts/dfde_engine",
      analytic_v1_charts_dfde_cargo: "analytic/v1/charts/dfde_cargo",
    },
    post: {
      ais_v1_ecdis_update_original_eta: "ais/v1/ecdis/points/original_eta",
    },
  },
  historical_mode: {
    get: {
      weather_v1_marine: "weather/v1/marine",
    },
  },
  vesselManagement: {
    get: {
      ais_v1_vessels: "ais/v1/vessels",
      analytic_v1_export_genre_maping: "analytic/v1/vessels/id/genres/export_mapping",
      analytic_v1_export_genre_error: "analytic/v1/vessels/id/genres/export_errors",
      ais_v1_sims_data_type: "analytic/v1/datasets",
      identity_v1_companies: "/identity/v1/companies",
      identity_v1_fetch_list_companies: "identity/v1/companies/fetch_list_companies",
    },
    delete: {
      ais_v1_vessels: "ais/v1/vessels",
      ais_v1_vessels_non_targets: "ais/v1/vessels",
    },
    put: {
      ais_v1_vessels: "ais/v1/vessels",
    },
    post: {
      ais_v1_vessels: "ais/v1/vessels",
      ais_v1_vessels_non_targets: "ais/v1/vessels/non_targets",
      ais_v1_vessels_import_genre: "analytic/v1/vessels/id/genres/import",
    },
  },
  usersManagement: {
    get: {
      identity_v1_users: "identity/v1/users",
      identity_v1_companies: "identity/v1/companies/fetch_list_companies",
    },
    delete: {
      identity_v1_users: "identity/v1/users",
    },
    put: {
      identity_v1_users: "identity/v1/users",
    },
    post: {
      identity_v1_users: "identity/v1/users",
    },
  },
  shipData: {
    get: {
      ais_v1_vessels: "ais/v1/vessels",
      analytic_v1_downloads_histories: "analytic/v1/downloads/histories",
      analytic_v1_sim_channels: "analytic/v1/sim_channels",
      analytic_v1_downloads_templates: "analytic/v1/downloads/templates",
      analytic_v1_units: "analytic/v1/sim_channels/fetch_units",
      analytic_v1_genres: "analytic/v1/vessels/id/genres",
    },
    put: {
      analytic_v1_downloads_templates: "analytic/v1/downloads/templates",
    },
    post: {
      analytic_v1_downloads_sims: "analytic/v1/downloads/sims",
      analytic_v1_downloads_templates: "analytic/v1/downloads/templates",
    },
    delete: {
      analytic_v1_downloads_templates: "analytic/v1/downloads/templates",
    },
  },
  heel_edq: {
    get: {
      ais_v1_vessels: "ais/v1/vessels",
      analytic_v1_voyages_routes_fetch_first_ports: "analytic/v1/voyages/routes/fetch_first_ports",
      analytic_v1_voyages_routes_fetch_second_ports:
        "analytic/v1/voyages/routes/fetch_second_ports",
      analytic_v1_voyages_routes: "analytic/v1/voyages/routes",
      analytic_v1_edqs_result: "/analytic/v1/edqs/result",
      analytic_v1_heels_timezone_label: "/analytic/v1/heels/timezone_label",
      analytic_v1_settings_lng_consumption_in_panama:
        "analytic/v1/settings/lng_consumption_in_panama",
    },
    post: {
      analytic_v1_heels_calculator: "/analytic/v1/heels/calculator",
      analytic_v1_edqs_result: "/analytic/v1/edqs/result",
    },
    put: {
      analytic_v1_edqs_result: "/analytic/v1/edqs/result",
    },
    delete: {
      analytic_v1_edqs_result: "/analytic/v1/edqs/result",
    },
  },
  file: {
    post: {
      analytic_v1_upload_file: "analytic/v1/temp/upload",
    },
  },
  foc: {
    get: {
      analytic_v1_management_focs: "analytic/v1/management/focs",
      analytic_v1_export_foc: "analytic/v1/management/focs/export",
      analytic_v1_export_error_foc: "analytic/v1/management/focs/fetch_invalid_record_file",
    },
    post: {
      analytic_v1_management_focs: "analytic/v1/management/focs",
      analytic_v1_import_foc: "analytic/v1/management/focs/import",
      analytic_v1_import_distance_route: "analytic/v1/management/routes/import",
    },
    put: {
      analytic_v1_management_focs: "analytic/v1/management/focs",
    },
    delete: {
      analytic_v1_management_focs: "analytic/v1/management/focs",
    },
  },
  master_ports: {
    get: {
      analytic_v1_management_master_ports: "/analytic/v1/management/master_ports",
      analytic_v1_masters_countries: "/analytic/v1/masters/countries",
      analytic_v1_masters_timezones: "/analytic/v1/masters/timezones",
    },
    post: {
      analytic_v1_management_master_ports: "/analytic/v1/management/master_ports",
    },
    put: {
      analytic_v1_management_focs: "analytic/v1/management/focs",
    },
    delete: {
      analytic_v1_management_focs: "analytic/v1/management/focs",
    },
  },
  master_routes: {
    get: {
      analytic_v1_management_master_routes: "/analytic/v1/management/master_routes",
    },
    post: {
      analytic_v1_management_master_routes: "/analytic/v1/management/master_routes",
    },
    delete: {
      analytic_v1_management_master_routes: "analytic/v1/management/master_routes",
    },
  },
  distance_routes: {
    get: {
      analytic_v1_management_routes: "/analytic/v1/management/routes",
      analytic_v1_management_master_ports_fetch_autocomplete_ports:
        "analytic/v1/management/master_ports/fetch_autocomplete_ports",
      analytic_v1_voyages_routes: "analytic/v1/voyages/routes",
      analytic_v1_management_master_routes_fetch_autocomplete_routes:
        "analytic/v1/management/master_routes/fetch_autocomplete_routes",
      analytic_v1_export_distance_route: "analytic/v1/management/routes/export",
      analytic_v1_export_error_distance_route:
        "analytic/v1/management/routes/fetch_invalid_record_file",
    },
    post: {
      analytic_v1_management_routes: "/analytic/v1/management/routes",
    },
    put: {
      analytic_v1_management_routes: "/analytic/v1/management/routes",
    },
    delete: {
      analytic_v1_management_routes: "analytic/v1/management/routes",
    },
  },
  lng_cons_in_panama: {
    get: {
      analytic_v1_fetch_lng_consumption_in_panama:
        "/analytic/v1/settings/lng_consumption_in_panama",
    },
    post: {
      analytic_v1_update_lng_consumption_in_panama:
        "/analytic/v1/settings/lng_consumption_in_panama",
    },
  },
  voyage_summary: {
    get: {
      analytic_v1_voyages_voyage_summary: "analytic/v1/voyages/voyage_summary",
      analytic_v1_voyages_voyage_summary_arrival_ports:
        "analytic/v1/voyages/voyage_summary/arrival_ports",
      analytic_v1_voyages_voyage_summary_dept_ports:
        "analytic/v1/voyages/voyage_summary/dept_ports",
      analytic_v1_voyages_voyage_summary_export: "analytic/v1/voyages/voyage_summary/export",
      analytic_v1_voyages_summary_fetch_manual_ports:
        "analytic/v1/voyages/routes/fetch_first_ports",
      analytic_v1_voyages_summary_timezone_label: "/analytic/v1/heels/timezone_label",
    },
    post: {
      analytic_v1_voyages_voyage_summary_update_manual_fields:
        "analytic/v1/voyages/voyage_summary/id/update_manual_fields",
      analytic_v1_voyages_voyage_summary_manual_reimport:
        "analytic/v1/voyages/voyage_summary/id/manual_reimport",
      analytic_v1_voyages_voyage_summary_create: "analytic/v1/voyages/voyage_summary",
    },
    put: {
      analytic_v1_voyages_voyage_summary_restore_voyage:
        "analytic/v1/voyages/voyage_summary/id/restore",
      analytic_v1_voyages_voyage_summary_delete:
        "analytic/v1/voyages/voyage_summary/id/soft_delete",
    },
  },
  voyage_map: {
    get: {
      analytic_v1_voyages_voyage_summary: "analytic/v1/voyages/voyage_summary",
      analytic_v1_chart_power_curve: "/analytic/v1/charts/power_curve",
    },
  },
  voyage_chart: {
    get: {
      analytic_v1_voyages_voyage_summary_charts: "analytic/v1/charts/voyage_summary/summaries/id",
      analytic_v1_voyage_speed_fuel_avg_speed_chart:
        "analytic/v1/charts/voyage_summary/average_speeds/id",
      analytic_v1_voyage_speed_fuel_lng_cons_chart:
        "analytic/v1/charts/voyage_summary/lng_consumption/id",
      analytic_v1_voyage_speed_fuel_mgo_cons_chart:
        "analytic/v1/charts/voyage_summary/mgo_consumption/id",
      analytic_v1_voyage_tank_cond_cargo_volume_chart:
        "analytic/v1/charts/voyage_summary/cargo_volumns/id",
      analytic_v1_voyage_tank_cond_tank_temp_chart:
        "analytic/v1/charts/voyage_summary/tank_temperature/id",
      analytic_v1_voyage_tank_cond_forcing_vapor_volume_chart:
        "analytic/v1/charts/voyage_summary/forcing_vapor_volumns/id",
      analytic_v1_voyage_tank_cond_avg_boil_off_rate_chart:
        "analytic/v1/charts/voyage_summary/average_boil_off_rates/id",
    },
  },
  companies: {
    get: {
      identity_v1_companies: "/identity/v1/companies",
    },
    post: {
      identity_v1_companies: "/identity/v1/companies",
    },
    put: {
      identity_v1_companies: "/identity/v1/companies/id",
    },
    delete: {
      identity_v1_companies: "identity/v1/companies/id",
    },
  },
  voyage_comparison: {
    get: {
      analytic_v1_voyages_seasonal_voyage_summaries:
        "/analytic/v1/voyages/seasonal_voyage_summaries",
      analytic_v1_voyages_seasonal_voyage_summaries_fetch_departure_ports:
        "/analytic/v1/voyages/seasonal_voyage_summaries/fetch_departure_ports",
      analytic_v1_voyages_seasonal_voyage_summaries_fetch_arrival_ports:
        "/analytic/v1/voyages/seasonal_voyage_summaries/fetch_arrival_ports",
    },
  },
  user_manual: {
    get: {
      analytic_v1_user_manual: "/analytic/v1/user_manual/download_url",
    },
    post: {
      analytic_v1_user_manual: "/analytic/v1/user_manual/upload",
    },
  },
}

export const API_ACTION = {
  auth: {
    get: {
      [API_NAME.auth.get.identity_v1_me]: () => getCurrentUser({}),
      [API_NAME.auth.get.identity_v1_fetch_list_companies]: () => authFetchListCompany(),
    },

    post: {
      [API_NAME.auth.post.identity_v1_logout]: (params) => signOut(params),
      [API_NAME.auth.post.identity_v1_refresh]: (params) => refreshToken(params),
      [API_NAME.auth.post.identity_v1_auth]: (params) => signIn(params),
    },
  },

  dashboard: {
    get: {
      [API_NAME.dashboard.get.ais_v1_latestPositions]: () => fetchVesselAll(),
      [API_NAME.dashboard.get.ais_v1_vessels]: () => fetchTargetVesselAll(),
      [API_NAME.dashboard.get.ais_v1_trackings]: (params) => fetchVesselActualRoutes(params),
      [API_NAME.dashboard.get.ais_v1_planRoutes]: (params) => fetchVesselPlanRoutes(params),
      [API_NAME.dashboard.get.ais_v1_ecdis_routes]: (params) => fetchVesselEcdisRoutes(params),
      [API_NAME.dashboard.get.ais_v1_ecdis_routes_point_routes]: (params) =>
        fetchVesselEcdisPoints(params),
      [API_NAME.dashboard.get.ais_v1_vesselsInfo]: (params) => fetchVesselInformations(params),
      [API_NAME.dashboard.get.analytic_v1_vessel_stage_spec]: (params) =>
        fetchStageVesselInformations(params),
      [API_NAME.dashboard.get.analytic_v1_vessel_xdf_spec]: (params) =>
        fetchXdfVesselInformations(params),
      [API_NAME.dashboard.get.analytic_v1_vessel_dfde_spec]: (params) =>
        fetchDfdeVesselInformations(params),
      [API_NAME.dashboard.get.analytic_v1_charts_stage_engine]: (params) =>
        fetchChartStageEngine(params),
      [API_NAME.dashboard.get.analytic_v1_charts_xdf_engine]: (params) =>
        fetchChartXDFEngine(params),
      [API_NAME.dashboard.get.analytic_v1_charts_stage_cargo]: (params) =>
        fetchChartStageCargo(params),
      [API_NAME.dashboard.get.analytic_v1_charts_xdf_cargo]: (params) => fetchChartXDFCargo(params),
      [API_NAME.dashboard.get.analytic_v1_charts_dfde_engine]: (params) =>
        fetchChartDFDEEngine(params),
      [API_NAME.dashboard.get.analytic_v1_charts_dfde_cargo]: (params) =>
        fetchChartDFDECargo(params),
    },

    post: {
      [API_NAME.dashboard.post.ais_v1_ecdis_update_original_eta]: (params) => updateATATime(params),
    },
  },

  historical_mode: {
    get: {
      [API_NAME.historical_mode.get.weather_v1_marine]: (params) => fetchWeathers(params),
    },
  },

  vessels_management: {
    get: {
      [API_NAME.vesselManagement.get.ais_v1_vessels]: (params) =>
        params && params.target === "false" ? fetchNonTargetVessels() : fetchVesselsAll(),
      [API_NAME.vesselManagement.get.analytic_v1_export_genre_maping]: (params) =>
        exportGenreMapping(params),
      [API_NAME.vesselManagement.get.analytic_v1_export_genre_error]: (params) =>
        exportGenreError(params),
      [API_NAME.vesselManagement.get.ais_v1_sims_data_type]: (params) => fetchSimsDataType(params),
      [API_NAME.vesselManagement.get.identity_v1_fetch_list_companies]: () =>
        vesselFetchListCompany(),
    },
    put: {
      [API_NAME.vesselManagement.get.ais_v1_vessels]: (params) => editVessel(params),
    },
    post: {
      [API_NAME.vesselManagement.post.ais_v1_vessels]: (params) => createVessel(params),
      [API_NAME.vesselManagement.post.ais_v1_vessels_non_targets]: (params) =>
        createNonTargetVessel(params),
    },
    delete: {
      [API_NAME.vesselManagement.delete.ais_v1_vessels]: (params) => deleteVessel(params),
      [API_NAME.vesselManagement.delete.ais_v1_vessels_non_targets]: (imo) =>
        deleteNonTargetVessel({ imo }),
    },
  },

  users_management: {
    get: {
      [API_NAME.usersManagement.get.identity_v1_users]: (params) => fetchUsersAll(params),
      [API_NAME.usersManagement.get.identity_v1_companies]: (params) => fetchListCompanies(params),
    },

    post: {
      [API_NAME.usersManagement.post.identity_v1_users]: (params) => createUser(params),
    },

    put: {
      [API_NAME.usersManagement.put.identity_v1_users]: (params) => editUser(params),
    },

    delete: { [API_NAME.usersManagement.delete.identity_v1_users]: (id) => deleteUser({ id }) },
  },

  ship_data: {
    get: {
      [API_NAME.shipData.get.ais_v1_vessels]: () => shipDataFetchVesselsAll(),
      [API_NAME.shipData.get.analytic_v1_downloads_histories]: (params) =>
        fetchHistoriesAll(params),
      [API_NAME.shipData.get.analytic_v1_downloads_templates]: (params) =>
        fetchTemplatesAll(params),
      [API_NAME.shipData.get.analytic_v1_sim_channels]: (params) => fetchChannelsAll(params),
      [API_NAME.shipData.get.analytic_v1_units]: () => fetchUnits(),
    },
    post: {
      [API_NAME.shipData.post.analytic_v1_downloads_sims]: (params) => exportProcessing(params),
      [API_NAME.shipData.post.analytic_v1_downloads_templates]: (params) => createTemplate(params),
    },
    put: {
      [API_NAME.shipData.get.analytic_v1_downloads_templates]: (id) => editTemplate({ id }),
    },
    delete: {
      [API_NAME.shipData.delete.analytic_v1_downloads_templates]: (id) => deleteTemplate({ id }),
    },
  },
  heel_edq: {
    get: {
      [API_NAME.heel_edq.get.ais_v1_vessels]: () => fetchTargetVessels(),
      [API_NAME.heel_edq.get.analytic_v1_settings_lng_consumption_in_panama]: () =>
        fetchLngConsumptionPanama(),
      [API_NAME.heel_edq.get.analytic_v1_voyages_routes_fetch_first_ports]: () => fetchDeptPort(),
      [API_NAME.heel_edq.get.analytic_v1_voyages_routes_fetch_second_ports]: (params) => {
        if (params.voyage_type === LADEN_FIELD) return fetchLadenArrivalPort(params)
        return fetchBallastArrivalPort(params)
      },
      [API_NAME.heel_edq.get.analytic_v1_voyages_routes]: (params) => {
        if (params.voyage_type === LADEN_FIELD) return fetchLadenPacificRoute(params)
        return fetchBallastPacificRoute(params)
      },
      [API_NAME.heel_edq.get.analytic_v1_edqs_result]: (params) => fetchCalculationResult(params),
      [API_NAME.heel_edq.get.analytic_v1_heels_timezone_label]: (params) => {
        if (params.voyage_type === LADEN_FIELD) return fetchLadenTimeZoneLabel(params)
        return fetchBallastTimeZoneLabel(params)
      },
    },
    post: {
      [API_NAME.heel_edq.post.analytic_v1_heels_calculator]: (params) => {
        if (params.voyage_type === LADEN_FIELD) return ladenCalculateVoyage({ data: params })
        return ballastCalculateVoyage({ data: params })
      },
      [API_NAME.heel_edq.get.analytic_v1_edqs_result]: (params) => createResult(params),
    },
    put: { [API_NAME.heel_edq.get.analytic_v1_edqs_result]: (params) => updateResult(params) },
    delete: { [API_NAME.heel_edq.get.analytic_v1_edqs_result]: (params) => deleteResult(params) },
  },
  file: {
    post: {
      [API_NAME.file.post.analytic_v1_upload_file]: (params) => uploadGenreFile(params),
    },
  },
  foc: {
    get: {
      [API_NAME.foc.get.analytic_v1_management_focs]: (params) => fetchListFOC(params),
    },
    post: {
      [API_NAME.foc.get.analytic_v1_management_focs]: (params) => createFOC(params),
    },
    put: {
      [API_NAME.foc.get.analytic_v1_management_focs]: (params) => updateFOC(params),
    },
    delete: {
      [API_NAME.foc.get.analytic_v1_management_focs]: (params) => deleteFOC(params),
    },
  },
  master_ports: {
    get: {
      [API_NAME.master_ports.get.analytic_v1_management_master_ports]: (params) =>
        fetchListPort(params),
      [API_NAME.master_ports.get.analytic_v1_masters_countries]: () => fetchCountries(),
      [API_NAME.master_ports.get.analytic_v1_masters_timezones]: (params) => fetchTimeZones(params),
    },
    post: {
      [API_NAME.master_ports.get.analytic_v1_management_master_ports]: (params) =>
        createPort(params),
    },
    put: {
      [API_NAME.master_ports.get.analytic_v1_management_master_ports]: (params) =>
        updatePort(params),
    },
    delete: {
      [API_NAME.master_ports.get.analytic_v1_management_master_ports]: (params) =>
        deletePort(params),
    },
  },
  master_routes: {
    get: {
      [API_NAME.master_routes.get.analytic_v1_management_master_routes]: () => fetchListRoute(),
    },
    post: {
      [API_NAME.master_routes.get.analytic_v1_management_master_routes]: (params) =>
        createRoute(params),
    },
    delete: {
      [API_NAME.master_routes.get.analytic_v1_management_master_routes]: (params) =>
        deleteRoute(params),
    },
  },
  distance_routes: {
    get: {
      [API_NAME.distance_routes.get.analytic_v1_management_routes]: (params) =>
        fetchListDistanceRoute(params),
      [API_NAME.distance_routes.get
        .analytic_v1_management_master_ports_fetch_autocomplete_ports]: () => fetchPorts(),
      [API_NAME.distance_routes.get.analytic_v1_voyages_routes]: () => fetchRoutes(),
      [API_NAME.distance_routes.get.analytic_v1_management_routes]: () => fetchFilterRoute(),
    },
    post: {
      [API_NAME.distance_routes.get.analytic_v1_management_routes]: (params) =>
        createDistanceRoute(params),
    },
    put: {
      [API_NAME.distance_routes.get.analytic_v1_management_routes]: (params) =>
        updateDistanceRoute(params),
    },
    delete: {
      [API_NAME.distance_routes.get.analytic_v1_management_routes]: (params) =>
        deleteDistanceRoute(params),
    },
  },
  lng_cons_in_panama: {
    get: {
      [API_NAME.lng_cons_in_panama.get.analytic_v1_fetch_lng_consumption_in_panama]: () =>
        fetchPanamaLngCons(),
    },
    post: {
      [API_NAME.lng_cons_in_panama.post.analytic_v1_update_lng_consumption_in_panama]: (params) =>
        updatePanamaLngCons(params),
    },
  },
  voyage_summary: {
    get: {
      [API_NAME.voyage_summary.get.analytic_v1_voyages_voyage_summary]: (params) =>
        fetchVoyages(params),
      [API_NAME.voyage_summary.get.analytic_v1_voyages_voyage_summary_arrival_ports]: () =>
        fetchArrivalPorts(),
      [API_NAME.voyage_summary.get.analytic_v1_voyages_voyage_summary_dept_ports]: () =>
        fetchDeptPorts(),
      [API_NAME.voyage_summary.get.analytic_v1_voyages_voyage_summary_export]: (params) =>
        exportVoyages(params),
      [API_NAME.voyage_summary.get.analytic_v1_voyages_summary_fetch_manual_ports]: () =>
        fetchManualPorts(),
      [API_NAME.voyage_summary.get.analytic_v1_voyages_summary_timezone_label]: (params) =>
        getManualTz(params),
    },
    post: {
      [API_NAME.voyage_summary.post.analytic_v1_voyages_voyage_summary_manual_reimport]: (params) =>
        manualImportVoyage(params),
      [API_NAME.voyage_summary.post.analytic_v1_voyages_voyage_summary_update_manual_fields]: (
        params
      ) => updateManualFields(params),
      [API_NAME.voyage_summary.post.analytic_v1_voyages_voyage_summary_create]: (params) =>
        createSummaryVoyage(params),
    },
    put: {
      [API_NAME.voyage_summary.put.analytic_v1_voyages_voyage_summary_restore_voyage]: (params) =>
        restoreVoyage(params),
      [API_NAME.voyage_summary.put.analytic_v1_voyages_voyage_summary_delete]: (params) =>
        deleteVoyage(params),
    },
  },
  voyage_map: {
    get: {
      [API_NAME.voyage_map.get.analytic_v1_voyages_voyage_summary]: (params) =>
        fetchVoyages(params),
      [API_NAME.voyage_map.get.analytic_v1_chart_power_curve]: (params) =>
        fetchPowerCurveChart(params),
    },
  },
  voyage_chart: {
    get: {
      [API_NAME.voyage_chart.get.analytic_v1_voyages_voyage_summary_charts]: (params) =>
        fetchVoyageSummaryCharts(params),
      [API_NAME.voyage_chart.get.analytic_v1_voyage_speed_fuel_avg_speed_chart]: (params) =>
        fetchSpeedFuelAvgSpeedChart(params),
      [API_NAME.voyage_chart.get.analytic_v1_voyage_speed_fuel_lng_cons_chart]: (params) =>
        fetchSpeedFuelLngConsChart(params),
      [API_NAME.voyage_chart.get.analytic_v1_voyage_speed_fuel_mgo_cons_chart]: (params) =>
        fetchSpeedFuelMgoConsChart(params),
      [API_NAME.voyage_chart.get.analytic_v1_voyage_tank_cond_cargo_volume_chart]: (params) =>
        fetchTankCondCargoVolumeChart(params),
      [API_NAME.voyage_chart.get.analytic_v1_voyage_tank_cond_tank_temp_chart]: (params) =>
        fetchTankCondTankTempChart(params),
      [API_NAME.voyage_chart.get.analytic_v1_voyage_tank_cond_forcing_vapor_volume_chart]: (
        params
      ) => fetchTankCondForcingVaporVolumeChart(params),
      [API_NAME.voyage_chart.get.analytic_v1_voyage_tank_cond_avg_boil_off_rate_chart]: (params) =>
        fetchTankCondAvgBoilOffRateChart(params),
    },
  },
  voyage_comparison: {
    get: {
      [API_NAME.voyage_comparison.get.analytic_v1_voyages_seasonal_voyage_summaries]: (params) =>
        fetchVoyagesComparison(params),
      [API_NAME.dashboard.get.ais_v1_trackings]: () => fetchVoyagesHistoryRoutes(),
      [API_NAME.voyage_comparison.get
        .analytic_v1_voyages_seasonal_voyage_summaries_fetch_departure_ports]: () =>
        fetchDeparturePorts(),
      [API_NAME.voyage_comparison.get
        .analytic_v1_voyages_seasonal_voyage_summaries_fetch_arrival_ports]: () =>
        fetchArrivalPortsComparison(),
    },
  },

  companies: {
    get: {
      [API_NAME.companies.get.identity_v1_companies]: (params) => fetchListCompany(params),
    },
    post: {
      [API_NAME.companies.get.identity_v1_companies]: (params) => createCompany(params),
    },
    put: {
      [API_NAME.companies.get.identity_v1_companies]: (params) => updateCompany(params),
    },
    delete: {
      [API_NAME.companies.get.identity_v1_companies]: (params) => deleteCompany(params),
    },
  },

  user_manual: {
    get: {
      [API_NAME.user_manual.get.analytic_v1_user_manual]: () => fetchUserManual(),
    },
    post: {
      [API_NAME.user_manual.get.analytic_v1_user_manual]: (params) => uploadUserManual(params),
    },
  },
}
