import Loader from "components/Common/Loader"
import { ConnectedRouter } from "connected-react-router"
import { AUTH_ROUTES, IRoute, PRIVATE_ROUTES, PUBLIC_ROUTES } from "core/constants/routes"
import { AppDispatch } from "core/utils/dispatch"
import { mergeParentChild } from "core/utils/route"
import { History } from "history"
import IndexLayout from "layouts"
import { memo, Suspense, useCallback, useMemo } from "react"
import { Switch, Route } from "react-router-dom"

const Router = memo(({ history }: { history: History }) => {
  const renderRoute = useCallback(({ exact = false, ...route }: IRoute) => {
    if (!route.children)
      return <Route path={route.path} component={route.component} key={route.path} exact={exact} />
    route.children.map((child) => {
      return <Route path={child.path} component={child.component} key={child.path} exact={exact} />
    })
  }, [])

  const memoRoutes = useMemo(() => {
    const privateRoutes = mergeParentChild(PRIVATE_ROUTES)
    return (
      <>
        <Switch>
          {privateRoutes.map(renderRoute)}
          {AUTH_ROUTES.map(renderRoute)}
          {PUBLIC_ROUTES.map(renderRoute)}
        </Switch>
      </>
    )
  }, [renderRoute])

  return (
    <ConnectedRouter history={history}>
      <IndexLayout>
        <Suspense fallback={<Loader size="large" />}>{memoRoutes}</Suspense>
        <AppDispatch />
      </IndexLayout>
    </ConnectedRouter>
  )
})

export default Router
