import { all, call, put, takeLatest } from "redux-saga/effects"
import authServices from "services/auth/api"
import { get } from "lodash"
import {
  signInSuccess,
  signInFailed,
  signOutSuccess,
  signOutFailed,
  getCurrentUser as actionGetCurrentUser,
  getCurrentUserSuccess,
  getCurrentUserFailed,
  refreshTokenSuccess,
  refreshTokenFailed,
  fetchListCompanyFailed,
  fetchListCompanySuccess,
  fetchListCompanyFinished,
} from "./actions"
import {
  AuthActions,
  ISignInAction,
  IRefreshToken,
  ISignOutAction,
  IGetCurrentUserAction,
} from "./types"

export function* signIn({ payload }: ISignInAction) {
  const { params } = payload
  if (params === undefined) return
  try {
    yield call(authServices.signIn, params)
    yield put(signInSuccess())
    yield put(actionGetCurrentUser({}))
  } catch (e) {
    yield put(
      signInFailed({
        error:
          get(e, "response.data.message") ||
          "Unable to connect to the server. Please try again later!",
      })
    )
  }
}

export function* getCurrentUser({ payload }: IGetCurrentUserAction) {
  const { onFailed } = payload
  try {
    const {
      data: { attributes },
    } = yield call(authServices.getCurrentUser)
    yield put(getCurrentUserSuccess({ data: attributes }))
  } catch (e) {
    yield put(
      getCurrentUserFailed({
        error:
          get(e, "response.data.message") ||
          "Unexpected error! Please reload the page and try again!",
      })
    )
    if (onFailed) onFailed()
  }
}

export function* signOut({ payload }: ISignOutAction) {
  const { onSuccess } = payload
  try {
    yield call(authServices.signOut)
    yield put(signOutSuccess())
    onSuccess()
  } catch (e) {
    yield put(
      signOutFailed({
        error:
          get(e, "response.data.message") ||
          "Unexpected error! Please reload the page and try again!",
      })
    )
  }
}

export function* refreshToken({ payload }: IRefreshToken) {
  const { setIsWaiting, onSuccess, onFailed } = payload
  try {
    if (setIsWaiting) setIsWaiting(true)
    yield call(authServices.refreshToken)
    yield put(refreshTokenSuccess())
    if (setIsWaiting) setIsWaiting(false)
    if (onSuccess) onSuccess()
  } catch (e) {
    yield put(
      refreshTokenFailed({
        error:
          get(e, "response.data.message") ||
          "Unexpected error! Please reload the page and try again!",
      })
    )
    if (onFailed) onFailed()
  }
}

export function* fetchListCompanyAllSaga() {
  let allData: any[] = []

  const filter = { page: 1, target: false }
  try {
    while (true) {
      const {
        data: { data },
        headers,
      } = yield call(authServices.fetchCompanies, filter)

      allData = [...allData, ...data]

      yield put(fetchListCompanySuccess({ data: allData }))

      if (!get(headers, "link").includes("next")) {
        yield put(fetchListCompanyFinished())
        break
      }
      filter.page += 1
    }
  } catch (e) {
    if (e?.response?.status !== 401) {
      yield put(
        fetchListCompanyFailed({
          error:
            get(e, "response.data.message") ||
            "Unexpected error! Please reload the page and try again!",
        })
      )
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(AuthActions.SIGNIN, signIn),
    takeLatest(AuthActions.SIGNOUT, signOut),
    takeLatest(AuthActions.GET_CURRENT_USER, getCurrentUser),
    takeLatest(AuthActions.REFRESH_TOKEN, refreshToken),
    takeLatest(AuthActions.FETCH_LIST_COMPANY_ALL, fetchListCompanyAllSaga),
  ])
}
