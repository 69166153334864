export interface IUserManualState {
  user_manual: {
    fetching: boolean
    uploading: boolean
    error: string
    file_url: string | number
  }
}

export enum UserManualActions {
  UPLOAD_USER_MANUAL_REQUEST = "UPLOAD_USER_MANUAL_REQUEST",
  UPLOAD_USER_MANUAL_SUCCESS = "UPLOAD_USER_MANUAL_SUCCESS",
  UPLOAD_USER_MANUAL_FAILURE = "UPLOAD_USER_MANUAL_FAILURE",
  FETCH_USER_MANUAL_REQUEST = "FETCH_USER_MANUAL_REQUEST",
  FETCH_USER_MANUAL_SUCCESS = "FETCH_USER_MANUAL_SUCCESS",
  FETCH_USER_MANUAL_FAILURE = "FETCH_USER_MANUAL_FAILURE",
}

export interface IFetchUserManualAction {
  type: UserManualActions.FETCH_USER_MANUAL_REQUEST
}

export interface IFetchUserManualSuccessAction {
  type: UserManualActions.FETCH_USER_MANUAL_SUCCESS
  payload: {
    file_url: string
  }
}

export interface IFetchUserManualFailedAction {
  type: UserManualActions.FETCH_USER_MANUAL_FAILURE
  payload: {
    error: string
  }
}

export interface IUploadUserManualAction {
  type: UserManualActions.UPLOAD_USER_MANUAL_REQUEST
  payload: {
    file: File
  }
}

export interface IUploadUserManualSuccessAction {
  type: UserManualActions.UPLOAD_USER_MANUAL_SUCCESS
}

export interface IUploadUserManualFailedAction {
  type: UserManualActions.UPLOAD_USER_MANUAL_FAILURE
  payload: {
    error: string
  }
}

export type UserManualTypes =
  | IFetchUserManualAction
  | IFetchUserManualSuccessAction
  | IFetchUserManualFailedAction
  | IUploadUserManualAction
  | IUploadUserManualSuccessAction
  | IUploadUserManualFailedAction
