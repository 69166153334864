import genActions from "services/_base/actions"
import {
  CompanyActions,
  ICreateCompany,
  ICreateCompanySuccess,
  ICreateCompanyFailed,
  IUpdateCompany,
  IUpdateCompanySuccess,
  IUpdateCompanyFailed,
  IDeleteCompany,
  IDeleteCompanySuccess,
  IDeleteCompanyFailed,
  IListCompanyFetchAll,
  IListCompanyFetchAllSuccess,
  IListCompanyFetchAllFinished,
  IListCompanyFetchAllFailed,
} from "./types"

export const { funcs: BaseFunctions, types: BaseActions } = genActions("super_admin_company")

export function createCompany(payload: any): ICreateCompany {
  return {
    type: CompanyActions.CREATE_COMPANY,
    payload,
  }
}

export function createCompanySuccess(): ICreateCompanySuccess {
  return {
    type: CompanyActions.CREATE_COMPANY_SUCCESS,
  }
}

export function createCompanyFailed(payload: any): ICreateCompanyFailed {
  return {
    type: CompanyActions.CREATE_COMPANY_FAILED,
    payload,
  }
}

export function updateCompany(payload: any): IUpdateCompany {
  return {
    type: CompanyActions.UPDATE_COMPANY,
    payload,
  }
}

export function updateCompanySuccess(): IUpdateCompanySuccess {
  return {
    type: CompanyActions.UPDATE_COMPANY_SUCCESS,
  }
}

export function updateCompanyFailed(payload: any): IUpdateCompanyFailed {
  return {
    type: CompanyActions.UPDATE_COMPANY_FAILED,
    payload,
  }
}

export function deleteCompany(payload: any): IDeleteCompany {
  return {
    type: CompanyActions.DELETE_COMPANY,
    payload,
  }
}

export function deleteCompanySuccess(): IDeleteCompanySuccess {
  return {
    type: CompanyActions.DELETE_COMPANY_SUCCESS,
  }
}

export function deleteCompanyFailed(payload: any): IDeleteCompanyFailed {
  return {
    type: CompanyActions.DELETE_COMPANY_FAILED,
    payload,
  }
}

export function fetchListCompany(payload: any): IListCompanyFetchAll {
  return {
    type: CompanyActions.FETCH_LIST_COMPANY_ALL,
    payload,
  }
}

export function fetchListCompanySuccess(payload: any): IListCompanyFetchAllSuccess {
  return {
    type: CompanyActions.FETCH_LIST_COMPANY_ALL_SUCCESS,
    payload,
  }
}

export function fetchListCompanyFinished(payload: any): IListCompanyFetchAllFinished {
  return {
    type: CompanyActions.FETCH_LIST_COMPANY_ALL_FINISHED,
    payload,
  }
}

export function fetchListCompanyFailed(payload: { error: string }): IListCompanyFetchAllFailed {
  return {
    type: CompanyActions.FETCH_LIST_COMPANY_ALL_FAILED,
    payload,
  }
}
