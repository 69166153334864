import { createMemoSelector } from "services/_base/selectors"
import { IAppState } from "types/redux"

export const AuthSelector = createMemoSelector(
  (state: IAppState) => {
    const {
      loading,
      authorized,
      authorizing,
      error,
      refreshing,
      current_user,
      companies,
    } = state.asyncReducer.auth
    return { authorizing, authorized, loading, error, refreshing, current_user, companies }
  },
  (authResult) => authResult
)
