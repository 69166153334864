export interface ICompanyItem {
  id: number | string
  name: string
  code: string
  updated_at: string
  updated_by: string
}

export interface ICompanyFilter {
  sort_by: string
  sort_order: string
  page: number
}

export interface ICompanyState {
  list: {
    companies: {
      loading: boolean
      error: string
      data: ICompanyItem[]
      total_records: number
      current_page: number
      per_page: number
      total_pages: number
      isDeleteItem: boolean
      isEditItem: boolean
    }
  }
  item: {
    loading: boolean
    error: string
    data: ICompanyItem
  }
  filters: {
    companies: {
      payload: ICompanyFilter
    }
  }
}

export enum CompanyActions {
  CREATE_COMPANY = "[super admin company] create company",
  CREATE_COMPANY_SUCCESS = "[super admin company] create company success",
  CREATE_COMPANY_FAILED = "[super admin company] create company failed",

  UPDATE_COMPANY = "[super admin company] update company",
  UPDATE_COMPANY_SUCCESS = "[super admin company] update company success",
  UPDATE_COMPANY_FAILED = "[super admin company] update company failed",

  DELETE_COMPANY = "[super admin company] delete company",
  DELETE_COMPANY_SUCCESS = "[super admin company] delete company success",
  DELETE_COMPANY_FAILED = "[super admin company] delete company failed",

  FETCH_LIST_COMPANY_ALL = "[super admin company] fetch all",
  FETCH_LIST_COMPANY_ALL_SUCCESS = "[super admin company] fetch all success",
  FETCH_LIST_COMPANY_ALL_FINISHED = "[super admin company] fetch all finished",
  FETCH_LIST_COMPANY_ALL_FAILED = "[super admin company] fetch all failed",

  FETCH_FILTER_COMPANIES = "[super admin company] fetch filter companies",
  FETCH_FILTER_COMPANIES_SUCCESS = "[super admin company] fetch filter companies success",
  FETCH_FILTER_COMPANIES_FAILED = "[super admin company] fetch filter companies failed",
}

export interface ICreateCompany {
  type: CompanyActions.CREATE_COMPANY
  payload: {
    data: any
  }
}

export interface ICreateCompanySuccess {
  type: CompanyActions.CREATE_COMPANY_SUCCESS
}

export interface ICreateCompanyFailed {
  type: CompanyActions.CREATE_COMPANY_FAILED
  payload: { error: string }
}

export interface IUpdateCompany {
  type: CompanyActions.UPDATE_COMPANY
  payload: {
    id: string
    data: any
  }
}

export interface IUpdateCompanySuccess {
  type: CompanyActions.UPDATE_COMPANY_SUCCESS
}

export interface IUpdateCompanyFailed {
  type: CompanyActions.UPDATE_COMPANY_FAILED
  payload: { error: string }
}

export interface IDeleteCompany {
  type: CompanyActions.DELETE_COMPANY
  payload: {
    id: string
  }
}

export interface IDeleteCompanySuccess {
  type: CompanyActions.DELETE_COMPANY_SUCCESS
}

export interface IDeleteCompanyFailed {
  type: CompanyActions.DELETE_COMPANY_FAILED
  payload: { error: string }
}

export interface IListCompanyFetchAll {
  type: CompanyActions.FETCH_LIST_COMPANY_ALL
  payload: ICompanyFilter
}

export interface IListCompanyFetchAllSuccess {
  type: CompanyActions.FETCH_LIST_COMPANY_ALL_SUCCESS
  payload: {
    data: []
    headers: any
  }
}

export interface IListCompanyFetchAllFinished {
  type: CompanyActions.FETCH_LIST_COMPANY_ALL_FINISHED
  payload: {
    allData: []
    headers: any
  }
}

export interface IListCompanyFetchAllFailed {
  type: CompanyActions.FETCH_LIST_COMPANY_ALL_FAILED
  payload: { error: string }
}

export type CompanyTypes =
  | ICreateCompany
  | ICreateCompanySuccess
  | ICreateCompanyFailed
  | IUpdateCompany
  | IUpdateCompanySuccess
  | IUpdateCompanyFailed
  | IDeleteCompany
  | IDeleteCompanySuccess
  | IDeleteCompanyFailed
  | IListCompanyFetchAll
  | IListCompanyFetchAllSuccess
  | IListCompanyFetchAllFinished
  | IListCompanyFetchAllFailed
