export interface IFOCItem {
  id: number | string
  imo: number
  speed: number
  laden: number
  ballast: number
  updated_at: string
  vessel_name: string
  updated_by: string
}

export interface IDataSettingsFilter {
  imo: string
  sort_by_table: boolean
  sort_by: string
  sort_order: string
  page: number
}

export interface IDataSettingsFOCState {
  vessels: { loading: boolean; error: string; data: [] }
  list: {
    foc: {
      loading: boolean
      error: string
      data: IFOCItem[]
      total_records: number
      current_page: number
      per_page: number
      total_pages: number
      isDeleteItem: boolean
      isEditItem: boolean
    }
  }
  item: {
    loading: boolean
    error: string
    data: IFOCItem
  }
  filters: {
    foc: {
      payload: IDataSettingsFilter
    }
  }
  export_error_log: {
    foc: {
      loading: boolean
      error: string | null
      url: string
    }
  }
  import_file: {
    foc: {
      loading: boolean
      error: string | null
      data: string
    }
  }
  export_file: {
    loading: boolean
    error: string | null
  }
}

export enum DataSettingsFOCActions {
  FETCH_TARGET_VESSELS = "[data settings] fetch target vessel",
  FETCH_TARGET_VESSELS_SUCCESS = "[data settings] fetch target vessel success",
  FETCH_TARGET_VESSELS_FAILED = "[data settings] fetch target vessel failed",
  FETCH_TARGET_VESSELS_FINISHED = "[data settings] fetch target vessel finished",

  // FOC
  CREATE_FOC = "[data settings foc] create foc",
  CREATE_FOC_SUCCESS = "[data settings foc] create foc success",
  CREATE_FOC_FAILED = "[data settings foc] create foc failed",

  UPDATE_FOC = "[data settings foc] update foc",
  UPDATE_FOC_SUCCESS = "[data settings foc] update foc success",
  UPDATE_FOC_FAILED = "[data settings foc] update foc failed",

  DELETE_FOC = "[data settings foc] delete foc",
  DELETE_FOC_SUCCESS = "[data settings foc] delete foc success",
  DELETE_FOC_FAILED = "[data settings foc] delete foc failed",

  FETCH_LIST_FOC_ALL = "[data settings foc] fetch all",
  FETCH_LIST_FOC_ALL_SUCCESS = "[data settings foc] fetch all success",
  FETCH_LIST_FOC_ALL_FINISHED = "[data settings foc] fetch all finished",
  FETCH_LIST_FOC_ALL_FAILED = "[data settings foc] fetch all failed",

  SET_FOC_FILTERS = "[data settings foc filter] set filters",

  IMPORT_FOC = "[importFoc] import",
  IMPORT_FOC_SUCCESS = "[importFoc] import success",
  IMPORT_FOC_FAILED = "[importFoc] import failed",

  EXPORT_FOC = "[exportFoc] export",
  EXPORT_FOC_SUCCESS = "[exportFoc] export success",
  EXPORT_FOC_FAILED = "[exportFoc] export failed",

  EXPORT_FOC_ERROR = "[exportFocError] export",
  EXPORT_FOC_ERROR_FAILED = "[exportFocError] export failed",
  EXPORT_FOC_ERROR_SUCCESS = "[exportFocError] export success",
}

export interface ITargetVesselsFetch {
  type: DataSettingsFOCActions.FETCH_TARGET_VESSELS
}

export interface ITargetVesselsFetchSuccess {
  type: DataSettingsFOCActions.FETCH_TARGET_VESSELS_SUCCESS
  payload: {
    data: []
  }
}

export interface ITargetVesselsFetchFinished {
  type: DataSettingsFOCActions.FETCH_TARGET_VESSELS_FINISHED
  payload: {
    allData: []
    headers: any
  }
}

export interface ITargetVesselsFetchFailed {
  type: DataSettingsFOCActions.FETCH_TARGET_VESSELS_FAILED
  payload: {
    error: string
  }
}

// FOC
export interface ICreateFOC {
  type: DataSettingsFOCActions.CREATE_FOC
  payload: {
    data: any
  }
}

export interface ICreateFOCSuccess {
  type: DataSettingsFOCActions.CREATE_FOC_SUCCESS
}

export interface ICreateFOCFailed {
  type: DataSettingsFOCActions.CREATE_FOC_FAILED
  payload: { error: string }
}

export interface IUpdateFOC {
  type: DataSettingsFOCActions.UPDATE_FOC
  payload: {
    id: string
    data: any
  }
}

export interface IUpdateFOCSuccess {
  type: DataSettingsFOCActions.UPDATE_FOC_SUCCESS
}

export interface IUpdateFOCFailed {
  type: DataSettingsFOCActions.UPDATE_FOC_FAILED
  payload: { error: string }
}

export interface IDeleteFOC {
  type: DataSettingsFOCActions.DELETE_FOC
  payload: {
    id: string
  }
}

export interface IDeleteFOCSuccess {
  type: DataSettingsFOCActions.DELETE_FOC_SUCCESS
}

export interface IDeleteFOCFailed {
  type: DataSettingsFOCActions.DELETE_FOC_FAILED
  payload: { error: string }
}

export interface ISetFOCFilter {
  type: DataSettingsFOCActions.SET_FOC_FILTERS
  payload: { data: any }
}

export interface IListFOCFetchAll {
  type: DataSettingsFOCActions.FETCH_LIST_FOC_ALL
  payload: IDataSettingsFilter
}

export interface IListFOCFetchAllSuccess {
  type: DataSettingsFOCActions.FETCH_LIST_FOC_ALL_SUCCESS
  payload: {
    data: []
    headers: any
  }
}

export interface IListFOCFetchAllFinished {
  type: DataSettingsFOCActions.FETCH_LIST_FOC_ALL_FINISHED
  payload: {
    allData: []
    headers: any
  }
}

export interface IListFOCFetchAllFailed {
  type: DataSettingsFOCActions.FETCH_LIST_FOC_ALL_FAILED
  payload: { error: string }
}

export interface IImportFocFileSuccessAction {
  type: DataSettingsFOCActions.IMPORT_FOC_SUCCESS
}

export interface IImportFocFileFailedAction {
  type: DataSettingsFOCActions.IMPORT_FOC_FAILED
  payload: { error: string }
}

export interface IImportFocFileAction {
  type: DataSettingsFOCActions.IMPORT_FOC
  payload: {
    data: []
  }
}

export interface IExportFocFailedAction {
  type: DataSettingsFOCActions.EXPORT_FOC_FAILED
  payload: { error: string }
}

export interface IExportFocSuccessAction {
  type: DataSettingsFOCActions.EXPORT_FOC_SUCCESS
}

export interface IExportFocAction {
  type: DataSettingsFOCActions.EXPORT_FOC
  payload: {
    imo: number
  }
}

export interface IExportFocErrorFailedAction {
  type: DataSettingsFOCActions.EXPORT_FOC_ERROR_FAILED
  payload: { error: string }
}

export interface IExportFocErrorAction {
  type: DataSettingsFOCActions.EXPORT_FOC_ERROR
}

export interface IExportFocErrorSuccessAction {
  type: DataSettingsFOCActions.EXPORT_FOC_ERROR_SUCCESS
  payload
}

export type DataSettingsFOCTypes =
  | ITargetVesselsFetch
  | ITargetVesselsFetchSuccess
  | ITargetVesselsFetchFinished
  | ITargetVesselsFetchFailed
  | ICreateFOC
  | ICreateFOCSuccess
  | ICreateFOCFailed
  | IUpdateFOC
  | IUpdateFOCSuccess
  | IUpdateFOCFailed
  | IDeleteFOC
  | IDeleteFOCSuccess
  | IDeleteFOCFailed
  | ISetFOCFilter
  | IListFOCFetchAll
  | IListFOCFetchAllSuccess
  | IListFOCFetchAllFinished
  | IListFOCFetchAllFailed
  | IImportFocFileSuccessAction
  | IImportFocFileAction
  | IImportFocFileFailedAction
  | IExportFocFailedAction
  | IExportFocSuccessAction
  | IExportFocAction
  | IExportFocErrorAction
  | IExportFocErrorFailedAction
  | IExportFocErrorSuccessAction
