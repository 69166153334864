import genActions from "services/_base/actions"
import {
  DataSettingsDistanceRouteActions,
  IImportDistanceRouteFileSuccessAction,
  IImportDistanceRouteFileAction,
  IImportDistanceRouteFileFailedAction,
  IExportDistanceRouteFailedAction,
  IExportDistanceRouteAction,
  IExportDistanceRouteErrorAction,
  IExportDistanceRouteErrorFailedAction,
  IExportDistanceRouteErrorSuccessAction,
  // Port List
  IPortsFetch,
  IPortsFetchSuccess,
  IPortsFetchFailed,
  IRoutesFetch,
  IRoutesFetchSuccess,
  IRoutesFetchFailed,
  ICreateDistanceRoute,
  ICreateDistanceRouteSuccess,
  ICreateDistanceRouteFailed,
  IUpdateDistanceRoute,
  IUpdateDistanceRouteSuccess,
  IUpdateDistanceRouteFailed,
  IDeleteDistanceRoute,
  IDeleteDistanceRouteSuccess,
  IDeleteDistanceRouteFailed,
  IListDistanceRouteFetchAll,
  IListDistanceRouteFetchAllSuccess,
  IListDistanceRouteFetchAllFinished,
  IListDistanceRouteFetchAllFailed,
  IFilterRoutesFetch,
  IFilterRoutesFetchSuccess,
  IFilterRoutesFetchFailed,
  IExportDistanceRouteSuccessAction,
} from "./types"

export const { funcs: BaseFunctions, types: BaseActions } = genActions(
  "data_settings_distance_route"
)

export function fetchPorts(): IPortsFetch {
  return {
    type: DataSettingsDistanceRouteActions.FETCH_PORTS,
  }
}

export function fetchPortsSuccess(payload: any): IPortsFetchSuccess {
  return {
    type: DataSettingsDistanceRouteActions.FETCH_PORTS_SUCCESS,
    payload,
  }
}

export function fetchPortsFailed(payload: any): IPortsFetchFailed {
  return {
    type: DataSettingsDistanceRouteActions.FETCH_PORTS_FAILED,
    payload,
  }
}

export function fetchRoutes(): IRoutesFetch {
  return {
    type: DataSettingsDistanceRouteActions.FETCH_ROUTES,
  }
}

export function fetchRoutesSuccess(payload: any): IRoutesFetchSuccess {
  return {
    type: DataSettingsDistanceRouteActions.FETCH_ROUTES_SUCCESS,
    payload,
  }
}

export function fetchRoutesFailed(payload: any): IRoutesFetchFailed {
  return {
    type: DataSettingsDistanceRouteActions.FETCH_ROUTES_FAILED,
    payload,
  }
}

export function fetchFilterRoute(): IFilterRoutesFetch {
  return {
    type: DataSettingsDistanceRouteActions.FETCH_FILTER_ROUTES,
  }
}

export function fetchFilterRouteSuccess(payload: any): IFilterRoutesFetchSuccess {
  return {
    type: DataSettingsDistanceRouteActions.FETCH_FILTER_ROUTES_SUCCESS,
    payload,
  }
}

export function fetchFilterRouteFailed(payload: any): IFilterRoutesFetchFailed {
  return {
    type: DataSettingsDistanceRouteActions.FETCH_FILTER_ROUTES_FAILED,
    payload,
  }
}

// Distance Route
export function createDistanceRoute(payload: any): ICreateDistanceRoute {
  return {
    type: DataSettingsDistanceRouteActions.CREATE_DISTANCE_ROUTE,
    payload,
  }
}

export function createDistanceRouteSuccess(): ICreateDistanceRouteSuccess {
  return {
    type: DataSettingsDistanceRouteActions.CREATE_DISTANCE_ROUTE_SUCCESS,
  }
}

export function createDistanceRouteFailed(payload: any): ICreateDistanceRouteFailed {
  return {
    type: DataSettingsDistanceRouteActions.CREATE_DISTANCE_ROUTE_FAILED,
    payload,
  }
}

export function updateDistanceRoute(payload: any): IUpdateDistanceRoute {
  return {
    type: DataSettingsDistanceRouteActions.UPDATE_DISTANCE_ROUTE,
    payload,
  }
}

export function updateDistanceRouteSuccess(): IUpdateDistanceRouteSuccess {
  return {
    type: DataSettingsDistanceRouteActions.UPDATE_DISTANCE_ROUTE_SUCCESS,
  }
}

export function updateDistanceRouteFailed(payload: any): IUpdateDistanceRouteFailed {
  return {
    type: DataSettingsDistanceRouteActions.UPDATE_DISTANCE_ROUTE_FAILED,
    payload,
  }
}

export function deleteDistanceRoute(payload: any): IDeleteDistanceRoute {
  return {
    type: DataSettingsDistanceRouteActions.DELETE_DISTANCE_ROUTE,
    payload,
  }
}

export function deleteDistanceRouteSuccess(): IDeleteDistanceRouteSuccess {
  return {
    type: DataSettingsDistanceRouteActions.DELETE_DISTANCE_ROUTE_SUCCESS,
  }
}

export function deleteDistanceRouteFailed(payload: any): IDeleteDistanceRouteFailed {
  return {
    type: DataSettingsDistanceRouteActions.DELETE_DISTANCE_ROUTE_FAILED,
    payload,
  }
}

export function fetchListDistanceRoute(payload: any): IListDistanceRouteFetchAll {
  return {
    type: DataSettingsDistanceRouteActions.FETCH_LIST_DISTANCE_ROUTE_ALL,
    payload,
  }
}

export function fetchListDistanceRouteSuccess(payload: any): IListDistanceRouteFetchAllSuccess {
  return {
    type: DataSettingsDistanceRouteActions.FETCH_LIST_DISTANCE_ROUTE_ALL_SUCCESS,
    payload,
  }
}

export function fetchListDistanceRouteFinished(payload: any): IListDistanceRouteFetchAllFinished {
  return {
    type: DataSettingsDistanceRouteActions.FETCH_LIST_DISTANCE_ROUTE_ALL_FINISHED,
    payload,
  }
}

export function fetchListDistanceRouteFailed(payload: {
  error: string
}): IListDistanceRouteFetchAllFailed {
  return {
    type: DataSettingsDistanceRouteActions.FETCH_LIST_DISTANCE_ROUTE_ALL_FAILED,
    payload,
  }
}

export function exportDistanceRouteErrorSuccess(
  payload: any
): IExportDistanceRouteErrorSuccessAction {
  return {
    type: DataSettingsDistanceRouteActions.EXPORT_DISTANCE_ROUTE_ERROR_SUCCESS,
    payload,
  }
}

export function exportDistanceRouteError(): IExportDistanceRouteErrorAction {
  return {
    type: DataSettingsDistanceRouteActions.EXPORT_DISTANCE_ROUTE_ERROR,
  }
}

export function exportDistanceRouteErrorFailed(payload: {
  error: string
}): IExportDistanceRouteErrorFailedAction {
  return {
    type: DataSettingsDistanceRouteActions.EXPORT_DISTANCE_ROUTE_ERROR_FAILED,
    payload,
  }
}

export function importDistanceRouteAction(payload: any): IImportDistanceRouteFileAction {
  return { type: DataSettingsDistanceRouteActions.IMPORT_DISTANCE_ROUTE, payload }
}

export function importDistanceRouteSuccessAction(): IImportDistanceRouteFileSuccessAction {
  return { type: DataSettingsDistanceRouteActions.IMPORT_DISTANCE_ROUTE_SUCCESS }
}

export function importDistanceRouteFailedAction(
  payload: any
): IImportDistanceRouteFileFailedAction {
  return { type: DataSettingsDistanceRouteActions.IMPORT_DISTANCE_ROUTE_FAILED, payload }
}

export function exportDistanceRoute(payload: any): IExportDistanceRouteAction {
  return {
    type: DataSettingsDistanceRouteActions.EXPORT_DISTANCE_ROUTE,
    payload,
  }
}
export function exportDistanceRouteSuccess(): IExportDistanceRouteSuccessAction {
  return {
    type: DataSettingsDistanceRouteActions.EXPORT_DISTANCE_ROUTE_SUCCESS,
  }
}
export function exportDistanceRouteFailed(payload: {
  error: string
}): IExportDistanceRouteFailedAction {
  return {
    type: DataSettingsDistanceRouteActions.EXPORT_DISTANCE_ROUTE_FAILED,
    payload,
  }
}
