import { createMemoSelector } from "services/_base/selectors"
import { IAppState } from "types/redux"

export const DashboardSelector = createMemoSelector(
  (state: IAppState) => {
    const { vessel, weather, port, chart, targetVessel } = state.dashboard || {}
    return { vessel, weather, port, chart, targetVessel }
  },
  (dashboardResult) => dashboardResult
)
