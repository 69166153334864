import genActions from "services/_base/actions"
import {
  LayoutActions,
  ISetMenuCollapsedAction,
  ISetFullscreen,
  ISetPinHeader,
  ISetShowHeader,
} from "./types"

export const { funcs: BaseFunctions, types: BaseActions } = genActions("layout")

export function setMenuCollapsed(payload: any): ISetMenuCollapsedAction {
  return { type: LayoutActions.SET_MENU_COLLAPSED, payload }
}
export function setFullscreenAction(payload: any): ISetFullscreen {
  return { type: LayoutActions.SET_FULLSCREEN, payload }
}
export function setShowHeaderAction(payload: any): ISetShowHeader {
  return { type: LayoutActions.SET_SHOW_HEADER, payload }
}
export function setPinHeaderAction(payload: any): ISetPinHeader {
  return { type: LayoutActions.SET_PIN_HEADER, payload }
}
