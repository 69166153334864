import genActions from "services/_base/actions"
import {
  DataSettingsFOCActions,
  ICreateFOC,
  ICreateFOCSuccess,
  ICreateFOCFailed,
  IUpdateFOC,
  IUpdateFOCSuccess,
  IUpdateFOCFailed,
  IListFOCFetchAll,
  IListFOCFetchAllSuccess,
  IListFOCFetchAllFinished,
  IListFOCFetchAllFailed,
  IDeleteFOC,
  IDeleteFOCSuccess,
  IDeleteFOCFailed,
  ITargetVesselsFetch,
  ITargetVesselsFetchSuccess,
  ITargetVesselsFetchFailed,
  ITargetVesselsFetchFinished,
  ISetFOCFilter,
  IExportFocAction,
  IExportFocErrorAction,
  IExportFocErrorFailedAction,
  IExportFocErrorSuccessAction,
  IExportFocFailedAction,
  IImportFocFileAction,
  IImportFocFileFailedAction,
  IImportFocFileSuccessAction,
  IExportFocSuccessAction,
} from "./types"

export const { funcs: BaseFunctions, types: BaseActions } = genActions("data_settings_foc")

export function fetchTargetVessels(): ITargetVesselsFetch {
  return {
    type: DataSettingsFOCActions.FETCH_TARGET_VESSELS,
  }
}

export function fetchTargetVesselsSuccess(payload: any): ITargetVesselsFetchSuccess {
  return {
    type: DataSettingsFOCActions.FETCH_TARGET_VESSELS_SUCCESS,
    payload,
  }
}

export function fetchTargetVesselsFinished(payload: any): ITargetVesselsFetchFinished {
  return {
    type: DataSettingsFOCActions.FETCH_TARGET_VESSELS_FINISHED,
    payload,
  }
}

export function fetchTargetVesselsFailed(payload: any): ITargetVesselsFetchFailed {
  return {
    type: DataSettingsFOCActions.FETCH_TARGET_VESSELS_FAILED,
    payload,
  }
}

export function createFOC(payload: any): ICreateFOC {
  return {
    type: DataSettingsFOCActions.CREATE_FOC,
    payload,
  }
}

export function createFOCSuccess(): ICreateFOCSuccess {
  return {
    type: DataSettingsFOCActions.CREATE_FOC_SUCCESS,
  }
}

export function createFOCFailed(payload: any): ICreateFOCFailed {
  return {
    type: DataSettingsFOCActions.CREATE_FOC_FAILED,
    payload,
  }
}

export function updateFOC(payload: any): IUpdateFOC {
  return {
    type: DataSettingsFOCActions.UPDATE_FOC,
    payload,
  }
}

export function updateFOCSuccess(): IUpdateFOCSuccess {
  return {
    type: DataSettingsFOCActions.UPDATE_FOC_SUCCESS,
  }
}

export function updateFOCFailed(payload: any): IUpdateFOCFailed {
  return {
    type: DataSettingsFOCActions.UPDATE_FOC_FAILED,
    payload,
  }
}

export function deleteFOC(payload: any): IDeleteFOC {
  return {
    type: DataSettingsFOCActions.DELETE_FOC,
    payload,
  }
}

export function deleteFOCSuccess(): IDeleteFOCSuccess {
  return {
    type: DataSettingsFOCActions.DELETE_FOC_SUCCESS,
  }
}

export function deleteFOCFailed(payload: any): IDeleteFOCFailed {
  return {
    type: DataSettingsFOCActions.DELETE_FOC_FAILED,
    payload,
  }
}

export function setFOCFilters(payload: any): ISetFOCFilter {
  return {
    type: DataSettingsFOCActions.SET_FOC_FILTERS,
    payload,
  }
}

export function fetchListFOC(payload: any): IListFOCFetchAll {
  return {
    type: DataSettingsFOCActions.FETCH_LIST_FOC_ALL,
    payload,
  }
}

export function fetchListFOCSuccess(payload: any): IListFOCFetchAllSuccess {
  return {
    type: DataSettingsFOCActions.FETCH_LIST_FOC_ALL_SUCCESS,
    payload,
  }
}

export function fetchListFOCFinished(payload: any): IListFOCFetchAllFinished {
  return {
    type: DataSettingsFOCActions.FETCH_LIST_FOC_ALL_FINISHED,
    payload,
  }
}

export function fetchListFOCFailed(payload: { error: string }): IListFOCFetchAllFailed {
  return {
    type: DataSettingsFOCActions.FETCH_LIST_FOC_ALL_FAILED,
    payload,
  }
}

export function importFocAction(payload: any): IImportFocFileAction {
  return { type: DataSettingsFOCActions.IMPORT_FOC, payload }
}

export function importFocSuccessAction(): IImportFocFileSuccessAction {
  return { type: DataSettingsFOCActions.IMPORT_FOC_SUCCESS }
}

export function importFocFailedAction(payload: any): IImportFocFileFailedAction {
  return { type: DataSettingsFOCActions.IMPORT_FOC_FAILED, payload }
}

export function exportFoc(payload: any): IExportFocAction {
  return {
    type: DataSettingsFOCActions.EXPORT_FOC,
    payload,
  }
}

export function exportFocSuccess(): IExportFocSuccessAction {
  return {
    type: DataSettingsFOCActions.EXPORT_FOC_SUCCESS,
  }
}

export function exportFocFailed(payload: { error: string }): IExportFocFailedAction {
  return {
    type: DataSettingsFOCActions.EXPORT_FOC_FAILED,
    payload,
  }
}

export function exportFocError(): IExportFocErrorAction {
  return {
    type: DataSettingsFOCActions.EXPORT_FOC_ERROR,
  }
}

export function exportFocErrorFailed(payload: { error: string }): IExportFocErrorFailedAction {
  return {
    type: DataSettingsFOCActions.EXPORT_FOC_ERROR_FAILED,
    payload,
  }
}

export function exportFocErrorSuccess(payload: any): IExportFocErrorSuccessAction {
  return {
    type: DataSettingsFOCActions.EXPORT_FOC_ERROR_SUCCESS,
    payload,
  }
}
