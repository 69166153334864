import {
  AUTH_ROUTES,
  HOME,
  SIGNIN,
  DASHBOARD,
  VESSEL_MANAGEMENT,
  SHIP_DATA,
  USERS_MANAGEMENT,
  PRIVATE_ROUTES,
  HEEL_EDQ_RESULT,
  PRIVATE_ROUTES_ITEMS,
  HEEL_EDQ_CREATE_ITEM,
  HEEL_EDQ_EDIT_ITEM,
} from "core/constants/routes"
import { notification } from "antd"
import usePrevious from "core/hooks/usePrevious"
import useQuery from "core/hooks/useQuery"
import i18n from "core/lib/i18n"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect, useLocation } from "react-router-dom"
import { AuthSelector } from "services/auth/selectors"
import instance from "services/_base/_axios"
import { refreshToken, signOutSuccess } from "services/auth/actions"
import { delay, isEmpty } from "lodash"
import { mergeParentChild } from "core/utils/route"
import Axios from "services/_base/api"
import MainLayout from "./Main"
import PublicLayout from "./Public"
import { API_ACTION } from "../core/model/Api"

interface IndexLayoutProps {
  children: React.ReactNode
}

const Layouts = {
  main: MainLayout,
  login: PublicLayout,
  public: PublicLayout,
}

function Layout(props: IndexLayoutProps) {
  const query = useQuery()
  const { pathname } = useLocation()
  const { authorizing, authorized, refreshing } = useSelector(AuthSelector)
  const isWaitingRef = useRef(false)
  const prevLng = usePrevious(query.get("lng"))
  const dispatch = useDispatch()

  const { children } = props

  const { setRefreshing, getOriginalRequests, setOriginalRequests } = Axios

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const layout = useMemo(() => {
    const allPrivateRoutes = mergeParentChild(PRIVATE_ROUTES)
    if (
      allPrivateRoutes.find(({ path }) => path === pathname) ||
      PRIVATE_ROUTES_ITEMS.find(({ parentPath }) => pathname.includes(parentPath))
    ) {
      return "main"
    }

    if (AUTH_ROUTES.find(({ path }) => path === pathname)) {
      return "login"
    }

    return "public"
  }, [pathname])

  useEffect(() => {
    const lng = query.get("lng") || "en"

    if (prevLng !== lng && lng) {
      i18n.changeLanguage(lng)
    }
  }, [prevLng, query])

  const BootstrappedLayout = useMemo(() => {
    const Container = Layouts[layout]
    const isLoginLayout = layout === "login"
    if (pathname === HOME) {
      return <Redirect to={DASHBOARD} />
    }
    // show loader when user in check authorization process, not authorized yet and not on login pages
    // if (authorizing && !authorized && !isLoginLayout) {
    //   return <Loader size="large" />
    // }

    // redirect to login page if current is not login page and user not authorized

    // redirect to main dashboard when user on login page and authorized
    const canRedirect = isLoginLayout && authorized && !authorizing
    if (canRedirect && pathname === SHIP_DATA) {
      return <Redirect to={SHIP_DATA} />
    }
    if (canRedirect && pathname === VESSEL_MANAGEMENT) {
      return <Redirect to={VESSEL_MANAGEMENT} />
    }
    if (canRedirect && pathname === USERS_MANAGEMENT) {
      return <Redirect to={USERS_MANAGEMENT} />
    }
    if (canRedirect && pathname === HEEL_EDQ_RESULT) {
      return <Redirect to={HEEL_EDQ_RESULT} />
    }
    if (canRedirect && pathname === HEEL_EDQ_CREATE_ITEM) {
      return <Redirect to={HEEL_EDQ_CREATE_ITEM} />
    }
    if (canRedirect && pathname === HEEL_EDQ_EDIT_ITEM) {
      return <Redirect to={HEEL_EDQ_EDIT_ITEM} />
    }
    if (canRedirect) {
      return <Redirect to={DASHBOARD} />
    }

    // in other case render previously set layout
    return <Container>{children}</Container>
  }, [authorized, children, layout, authorizing, pathname])

  const onFailedRefresh = () => {
    dispatch(signOutSuccess())
    if (pathname !== SIGNIN) {
      window.location.href = SIGNIN
    }
    return null
  }

  useEffect(() => {
    setRefreshing(refreshing)
    const request = getOriginalRequests()
    const tempPath = pathname.substr(1)
    const itemIndex = tempPath.indexOf("/")
    let components = ""
    if (itemIndex < 0) components = tempPath === "signin" ? "auth" : tempPath
    else components = tempPath.substring(0, itemIndex)
    if (!refreshing && authorized && !isEmpty(request)) {
      request.map((request) => {
        if (!API_ACTION[components][request.method][request.pathname]) {
          notification?.error({
            message: "Unexpected error! Please reload the page and try again!",
          })
          return null
        }
        return dispatch(API_ACTION[components][request.method][request.pathname](request.params))
      })

      delay(
        () => {
          isWaitingRef.current = false
          setOriginalRequests()
        },
        10000,
        "later"
      )
    }
  }, [refreshing, authorized, dispatch])

  const setIsWaiting = (value: boolean) => {
    isWaitingRef.current = value
  }

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config
      if (error?.response?.status === 401 && !originalRequest.retry) {
        originalRequest.retry = true
        setOriginalRequests(originalRequest.url, originalRequest.method, originalRequest.data)
        if (!isWaitingRef.current) {
          dispatch(refreshToken({ setIsWaiting, onFailed: () => onFailedRefresh() }))
          setRefreshing(true)
        }
      }
      return Promise.reject(error)
    }
  )

  return <>{BootstrappedLayout}</>
}

export default Layout
