/* eslint-disable react-hooks/exhaustive-deps */
import GuardianPopoverTooltip from "components/GuardianPopoverTooltip"
import GuardianSlider from "components/GuardianSlider"
import SliderSettings from "components/SliderSettings"
import GuardianTooltip from "components/GuardianTooltip"
import { WeatherElements } from "core/model/Weather"
import { Row, Col, Checkbox, Button, Radio, Space } from "antd"
import {
  showWeatherWaveHeightAction,
  showWeatherPressurePatternAction,
  showWeatherOcceanCurrentAction,
  showWeatherTropicalStormAction,
  showWeatherTropicalStormTrackAction,
  showWeatherWindAction,
  setWeatherDate,
  setFromDate,
  setToDate,
  showNoneTargetVessel as actionShowNoneTargetVessel,
  showLabelNoneTargetVessel as actionShowLabelNoneTargetVessel,
  setSelectedPoint,
  hideWeathers,
  fetchVesselAll,
  setVesselSelectedImo,
  resetVesselList,
  fetchTargetVesselAll,
} from "services/dashboard/actions"
import { DashboardActionTypes } from "services/dashboard/types"
import { useDispatch, useSelector } from "react-redux"
import { useInjectReducer } from "core/hooks/useInjectReducer"
import dashboardReducer from "services/dashboard/reducers"
import { DashboardSelector } from "services/dashboard/selectors"
import { createMarks } from "core/model/DateTime"
import { CloudOutlined, EnvironmentOutlined, SettingOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"
import { debounce, parseInt } from "lodash"
import moment from "moment"
import { useLocation } from "react-router-dom"
import CargoShip from "components/Icons/CargoShip"
import FacenNotification from "components/Common/Notification"
import { setPinHeaderAction } from "services/layout/actions"
import { setSelectedImos, setSelectedVesselId } from "services/historical/actions"

const TWO_WEEKS_HOURS = 336
const A_WEEK_HOURS = 168
const defaultFromDate = moment.utc().subtract(7, "d").startOf("d")

const Index = () => {
  useInjectReducer({ key: "dashboard", reducer: dashboardReducer })
  const {
    targetVessel: { data: targetVesselData, isFinished: targetVesselIsFinished },
    weather,
    vessel: {
      showNoneTargetVessel,
      showLabelNoneTargetVessel,
      selectedVessel: { imo: selectedImo, selectedPoint: vesselSelectedPoint },
      isFinished,
    },
  } = useSelector(DashboardSelector)
  const dispatch = useDispatch()
  const onSelectWeatherType = (action: DashboardActionTypes) => {
    dispatch(action)
  }
  const [sliderMarks, setSliderMarks] = useState(createMarks(360, defaultFromDate.toDate()))
  const [sliderValue, setSliderValue] = useState(A_WEEK_HOURS)
  const [currentHourValue, setCurrentHourValue] = useState(A_WEEK_HOURS)
  const [maximumHoursValue, setMaximumHoursValue] = useState(TWO_WEEKS_HOURS)
  const [fromDateValue, setFromDateValue] = useState(new Date())
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [popupVisible, setPopupVisible] = useState(false)
  const [sliderVisible, setSliderVisible] = useState(false)
  const [isCustomSlider, setIsCustomSlider] = useState(false)
  const [vesselVisible, setVesselVisible] = useState(false)
  const [settingsVisible, setSettingsVisible] = useState(false)
  const [imoSelectValue, setImoSelectValue] = useState(null)
  const [mounted, setMounted] = useState(false)
  const [isShowNonTarget, setIsShowNonTarget] = useState(showNoneTargetVessel ?? false)
  const [isShowLabelNonTarget, setIsShowLabelNonTarget] = useState(
    showLabelNoneTargetVessel ?? false
  )
  const [isCanSetCurrentTime, setIsCanSetCurrentTime] = useState(true)
  const selectedPoint = vesselSelectedPoint
  const location = useLocation()

  useEffect(() => {
    return () => {
      dispatch(hideWeathers())
      setImoSelectValue(null)
      dispatch(setSelectedImos({ imos: [] }))
      dispatch(setSelectedVesselId({ vessel_id: null }))
    }
  }, [location])

  useEffect(() => {
    setMounted(true)
    onGetcurrentPositonTime(true)
    return () => {
      setMounted(false)
      dispatch(resetVesselList())
    }
  }, [])

  useEffect(() => {
    if (targetVesselData.length === 0 && isFinished) dispatch(fetchTargetVesselAll())
  }, [isFinished])

  useEffect(() => {
    if (selectedPoint?.dateTime && mounted) {
      const fromDate = moment.utc(fromDateValue)
      const selectedTime = moment.utc(selectedPoint.dateTime)
      const duration = moment.duration(fromDate.diff(selectedTime))
      const durationHours = Math.abs(duration.asHours())
      const durationHoursVal = fromDate.isBefore(selectedTime.format()) ? durationHours : 0
      dispatch(setWeatherDate({ date: selectedTime.toDate() }))
      setCurrentHourValue(durationHoursVal)
      setSliderValue(durationHoursVal)
    }
  }, [selectedPoint?.dateTime])

  useEffect(() => {
    if (weather) {
      const {
        data: { currentDate, fromDate },
      } = weather
      setSelectedDate(currentDate)
      setFromDateValue(fromDate)
    }
  }, [weather])

  const onSelectWeatherSlider = (hours: number) => {
    setSliderValue(hours)
    const date = moment.utc(fromDateValue).startOf("h").add(hours, "hours")
    dispatch(setWeatherDate({ date: date.toDate() }))
    setIsCustomSlider(true)
    setCurrentHourValue(hours)
    const selectedValue = {
      dateTime: date.format(),
      imo: selectedPoint?.imo,
      vessel_id: selectedPoint?.vessel_id,
    }
    dispatch(setSelectedPoint(selectedValue))
  }

  const tipFormatter = (value: number | undefined) => {
    if (value === TWO_WEEKS_HOURS && !isCustomSlider) {
      onGetcurrentPositonTime(false)
    } else {
      const hour = value % 24
      return `${hour}:00`
    }

    return ""
  }

  const onGetcurrentPositonTime = (isCurrentDate: boolean) => {
    if (!isCanSetCurrentTime) {
      if (isCurrentDate)
        FacenNotification.warning({
          message: "Warning",
          description: "Current date is out of selected date range",
        })
      return
    }
    const fromDate = moment.utc(fromDateValue).startOf("d")
    const currentUtcDate = moment.utc()
    const currentDateHour = currentUtcDate.startOf("h").add(1, "hours")
    const rangeDatesHours = moment.duration(fromDate.diff(currentDateHour)).asHours()
    setSliderValue(Math.abs(rangeDatesHours))
    dispatch(setWeatherDate({ date: currentDateHour.toDate() }))
    const selectedValue = {
      dateTime: currentDateHour.format(),
      imo: selectedPoint?.imo,
      vessel_id: selectedPoint?.vessel_id,
    }
    dispatch(setSelectedPoint(selectedValue))
  }

  const onToggleNoneTargetVessel = () => {
    if (isShowNonTarget && isShowLabelNonTarget) onToggleLabelNoneTargetVessel()
    setIsShowNonTarget(!isShowNonTarget)
    dispatch(actionShowNoneTargetVessel({ checked: !showNoneTargetVessel }))
  }

  const onToggleLabelNoneTargetVessel = () => {
    setIsShowLabelNonTarget(!isShowLabelNonTarget)
    dispatch(actionShowLabelNoneTargetVessel({ checked: !showLabelNoneTargetVessel }))
  }

  const onSelectDate = (startDate, endDate) => {
    const fromDate = moment.utc(startDate).startOf("d")
    const toDate = moment.utc(endDate).endOf("d")
    const duration = moment.duration(fromDate.diff(toDate))
    const durationHours = Math.abs(duration.asHours())

    const markHoursValue = durationHours + 24
    let selectedValue = { ...selectedPoint }
    const currentUtcDate = moment.utc()
    if (currentUtcDate.isBetween(fromDate, toDate)) {
      const currentDateHour = currentUtcDate.startOf("h").add(1, "hours")
      const currentHour = currentDateHour.hours()
      const rangeDatesHours = moment.duration(currentUtcDate.diff(fromDate)).asHours()
      const sliderValueUpdated = rangeDatesHours + currentHour
      setSliderValue(sliderValueUpdated)
      setCurrentHourValue(rangeDatesHours)
      setIsCanSetCurrentTime(true)
      selectedValue = {
        dateTime: currentDateHour.format(),
        imo: selectedPoint?.imo,
        vessel_id: selectedPoint?.vessel_id,
      }
    } else {
      setSliderValue(0)
      setCurrentHourValue(0)
      setIsCanSetCurrentTime(false)
      selectedValue = {
        dateTime: fromDate.format(),
        imo: selectedPoint?.imo,
        vessel_id: selectedPoint?.vessel_id,
      }
    }

    setMaximumHoursValue(durationHours)
    setSliderMarks(createMarks(markHoursValue, moment.utc(startDate).toDate()))
    setIsCustomSlider(true)
    setFromDateValue(fromDate.toDate())
    dispatch(setFromDate({ fromDate: fromDate.toDate() }))
    dispatch(setToDate({ toDate: toDate.toDate() }))
    dispatch(fetchVesselAll())
    dispatch(setSelectedPoint(selectedValue))
  }

  const weatherOptions = () => (
    <>
      <Checkbox
        className="marginLeft0"
        onChange={(e) => {
          return onSelectWeatherType(showWeatherOcceanCurrentAction({ checked: e.target.checked }))
        }}
      >
        {WeatherElements.OceanCurrent}
      </Checkbox>
      <Checkbox
        className="marginLeft0"
        onChange={(e) => {
          return onSelectWeatherType(
            showWeatherPressurePatternAction({ checked: e.target.checked })
          )
        }}
      >
        {WeatherElements.SurfacePressure}
      </Checkbox>
      <Checkbox
        className="marginLeft0"
        onChange={(e) => {
          return onSelectWeatherType(showWeatherTropicalStormAction({ checked: e.target.checked }))
        }}
      >
        {WeatherElements.TropicalStormForecast}
      </Checkbox>
      <Checkbox
        className="marginLeft0"
        onChange={(e) => {
          return onSelectWeatherType(
            showWeatherTropicalStormTrackAction({ checked: e.target.checked })
          )
        }}
      >
        {WeatherElements.TropicalStormTrack}
      </Checkbox>
      <Checkbox
        className="marginLeft0"
        onChange={(e) => {
          return onSelectWeatherType(showWeatherWaveHeightAction({ checked: e.target.checked }))
        }}
      >
        {WeatherElements.WaveHeight}
      </Checkbox>
      <Checkbox
        className="marginLeft0"
        onChange={(e) => {
          return onSelectWeatherType(showWeatherWindAction({ checked: e.target.checked }))
        }}
      >
        {WeatherElements.Wind}
      </Checkbox>
    </>
  )

  const settingsOptions = () => (
    <>
      <Checkbox
        className="marginLeft0"
        checked={isShowNonTarget}
        onChange={() => {
          return onToggleNoneTargetVessel()
        }}
      >
        Show Other LNG vessels
      </Checkbox>
      <Checkbox
        className="marginLeft0"
        disabled={!isShowNonTarget}
        checked={isShowLabelNonTarget}
        onChange={() => {
          return onToggleLabelNoneTargetVessel()
        }}
      >
        Show Vessel Label
      </Checkbox>
    </>
  )

  useEffect(() => {
    dispatch(setPinHeaderAction({ isPinHeader: popupVisible || vesselVisible || sliderVisible }))
  }, [popupVisible, vesselVisible, sliderVisible])

  const onSelectVessel = (e) => {
    setImoSelectValue(e.target.value)
    const vesselSelectHeader: any = targetVesselData.filter(
      (record: any) => record.imo === e.target.value
    )[0]
    dispatch(
      setVesselSelectedImo({
        imo: vesselSelectHeader.imo,
        name: vesselSelectHeader.name,
        vessel_id: vesselSelectHeader.id,
      })
    )
  }

  useEffect(() => {
    setImoSelectValue(parseInt(selectedImo))
  }, [selectedImo])

  const vesselOptions = () => (
    <>
      <Radio.Group onChange={onSelectVessel} value={imoSelectValue}>
        <Space direction="vertical">
          {targetVesselData.map((el: any) => (
            <Radio value={el.imo}>{el.name}</Radio>
          ))}
        </Space>
      </Radio.Group>
    </>
  )

  return (
    <>
      <Row gutter={12}>
        <Col className="slider-control">
          <Row gutter={12}>
            <Col>
              <GuardianPopoverTooltip
                title=""
                placement="topLeft"
                hoverContent="Weather Types"
                content={weatherOptions}
                trigger="click"
                isVisible={popupVisible}
                setIsVisible={setPopupVisible}
                visibleClassName="weather-type__popover"
              >
                <Button shape="circle" className="get-current" icon={<CloudOutlined />} />
              </GuardianPopoverTooltip>
            </Col>
            <Col>
              <GuardianPopoverTooltip
                title=""
                placement="topLeft"
                hoverContent="Target LNG vessels"
                content={vesselOptions}
                trigger="click"
                isVisible={vesselVisible}
                setIsVisible={setVesselVisible}
              >
                <Button
                  shape="circle"
                  className="get-current"
                  icon={<CargoShip name="cargo-ship-header" />}
                  loading={!targetVesselIsFinished}
                />
              </GuardianPopoverTooltip>
            </Col>
            <Col>
              <GuardianPopoverTooltip
                title=""
                placement="topLeft"
                hoverContent="Other LNG vessels"
                content={settingsOptions}
                trigger="click"
                isVisible={settingsVisible}
                setIsVisible={setSettingsVisible}
                visibleClassName="non-target__popover"
              >
                <Button shape="circle" className="get-current" icon={<SettingOutlined />} />
              </GuardianPopoverTooltip>
            </Col>
            <Col>
              <GuardianTooltip
                title="Current date"
                className="header-icon__location"
                color="#14639f"
              >
                <Button
                  shape="circle"
                  className="get-current"
                  onClick={() => onGetcurrentPositonTime(true)}
                  icon={<EnvironmentOutlined />}
                />
              </GuardianTooltip>
            </Col>
            <Col>
              <SliderSettings
                onSelectDate={onSelectDate}
                setPopupVisibleProps={setSliderVisible}
                hoverContent="Slide bar duration"
              />
            </Col>
          </Row>
        </Col>
        <Col flex="auto">
          <GuardianSlider
            marks={sliderMarks}
            onSelectWeatherSlider={debounce(onSelectWeatherSlider, 150)}
            value={sliderValue}
            step={1}
            defaultValue={currentHourValue}
            max={maximumHoursValue}
            className="WeatherSettingSlider"
            tipFormatter={tipFormatter}
          />
        </Col>
      </Row>
    </>
  )
}

export default Index
