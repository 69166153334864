import { produce } from "immer"
import { stringify } from "qs"
import Axios from "services/_base/api"
import { API_NAME } from "core/model/Api"
import VoyageSummaryCharts from "core/model/Chart/Bar/index"
import SpeedFuelMgoCons from "core/model/Chart/SpeedFuel/MgoCons"
import SpeedFuelLngCons from "core/model/Chart/SpeedFuel/LngCons"
import SpeedFuelAvgSpeed from "core/model/Chart/SpeedFuel/AvgSpeed"
import TankCondCargoVolume from "core/model/Chart/TankConditions/CargoVolume"
import TankCondStageTankTemp from "core/model/Chart/TankConditions/StageTankTemp"
import TankCondXdfTankTemp from "core/model/Chart/TankConditions/XdfTankTemp"
import TankCondDfdeTankTemp from "core/model/Chart/TankConditions/DfdeTankTemp"
import TankCondForcingVaporVolume from "core/model/Chart/TankConditions/ForcingVaporVolume"
import TankCondAvgBoilOffrate from "core/model/Chart/TankConditions/AvgBoilOffRate"

class VoyagesServices {
  public async fetchTargetVessels(params) {
    const response = await Axios.get(`${API_NAME.heel_edq.get.ais_v1_vessels}?${stringify(params)}`)
    const data = {
      data: {
        data: response.data.data.map((record) => {
          const attributes = produce(record.attributes, (draft) => {
            draft.properties = { ...draft, ...draft.properties }
          })
          return attributes
        }),
      },
      headers: response.headers,
    }
    return data
  }

  public async fetchVoyages(params: Record<"page", number>) {
    const response = await Axios.get(
      `${API_NAME.voyage_summary.get.analytic_v1_voyages_voyage_summary}?${stringify(params, {
        arrayFormat: "brackets",
        encode: true,
      })}`
    )
    const data = {
      data: {
        data: response.data.data.map((record) => {
          const attributes = produce(record.attributes, (draft) => {
            draft.id = record.id
          })
          return attributes
        }),
      },
      headers: response.headers,
    }
    return data
  }

  public async fetchArrivalPorts() {
    const response = await Axios.get(
      `${API_NAME.voyage_summary.get.analytic_v1_voyages_voyage_summary_arrival_ports}`
    )
    return response.data
  }

  public async fetchDeptPorts() {
    const response = await Axios.get(
      `${API_NAME.voyage_summary.get.analytic_v1_voyages_voyage_summary_dept_ports}`
    )
    return response.data
  }

  public async exportVoyagesSummary(params: any) {
    const response = await Axios.get(
      `${API_NAME.voyage_summary.get.analytic_v1_voyages_voyage_summary_export}?${stringify(
        params,
        {
          encode: true,
        }
      )}`
    )
    return response.data
  }

  public async getCreateItem(api, method = "get", params = null, hasAttr = false) {
    const tmpParams = params ? `?${stringify(params)}` : ""
    let response = null
    if (!hasAttr) response = await Axios[method](`${api}${tmpParams}`)
    else {
      response = await Axios[method](`${api}${tmpParams}`)

      if (!response.message) {
        const record = response.data.data
        const attributes = produce(record.attributes, (draft) => {
          draft.id = record.id
        })
        return attributes
      }

      return response
    }
    return Object.keys(response).length > 0 ? response.data : response
  }

  public async fetchVoyageSummaryCharts(params: any) {
    const url = `${API_NAME.voyage_chart.get.analytic_v1_voyages_voyage_summary_charts}`
    const response = await Axios.get(`${url.replace("id", params.id)}`)
    const data = {
      data: {
        data: response.data.data.map((record) => {
          return new VoyageSummaryCharts(record.attributes)
        }),
      },
      headers: response.headers,
    }
    return data
  }

  public async fetchSpeedFuelAvgSpeedChart(params: any) {
    const url = `${API_NAME.voyage_chart.get.analytic_v1_voyage_speed_fuel_avg_speed_chart}`
    const response = await Axios.get(`${url.replace("id", params.id)}`)
    const data = {
      data: {
        data: response.data.data.map((record) => {
          return new SpeedFuelAvgSpeed(record.attributes)
        }),
      },
      headers: response.headers,
    }
    return data
  }

  public async fetchSpeedFuelLngConsChart(params: any) {
    const url = `${API_NAME.voyage_chart.get.analytic_v1_voyage_speed_fuel_lng_cons_chart}`
    const response = await Axios.get(`${url.replace("id", params.id)}`)
    const data = {
      data: {
        data: response.data.data.map((record) => {
          return new SpeedFuelLngCons(record.attributes)
        }),
      },
      headers: response.headers,
    }
    return data
  }

  public async fetchSpeedFuelMgoConsChart(params: any) {
    const url = `${API_NAME.voyage_chart.get.analytic_v1_voyage_speed_fuel_mgo_cons_chart}`
    const response = await Axios.get(`${url.replace("id", params.id)}`)
    const data = {
      data: {
        data: response.data.data.map((record) => {
          return new SpeedFuelMgoCons(record.attributes)
        }),
      },
      headers: response.headers,
    }
    return data
  }

  public async fetchPowerCurveChart(params: any) {
    const response = await Axios.get(
      `${API_NAME.voyage_map.get.analytic_v1_chart_power_curve}?${stringify(params)}`
    )
    if (!response.message) {
      const data = {
        data: {
          data: response.data.data.attributes,
        },
      }
      return data
    }
    return response
  }

  public async fetchTankCondCargoVolumeChart(params: any) {
    const url = `${API_NAME.voyage_chart.get.analytic_v1_voyage_tank_cond_cargo_volume_chart}`
    const response = await Axios.get(`${url.replace("id", params.id)}`)
    const data = {
      data: {
        data: response.data.data.map((record) => {
          return new TankCondCargoVolume(record.attributes)
        }),
      },
      headers: response.headers,
    }
    return data
  }

  public async fetchTankCondTankTempChart(params: any) {
    const url = `${API_NAME.voyage_chart.get.analytic_v1_voyage_tank_cond_tank_temp_chart}`
    const response = await Axios.get(`${url.replace("id", params.id)}`)
    const data = {
      data: {
        data: response.data.data.map((record) => {
          if (record.type === "xdf") return new TankCondXdfTankTemp(record.attributes)
          if (record.type === "dfde") return new TankCondDfdeTankTemp(record.attributes)
          return new TankCondStageTankTemp(record.attributes)
        }),
      },
      headers: response.headers,
    }
    return data
  }

  public async fetchTankCondForcingVaporVolumeChart(params: any) {
    const url = `${API_NAME.voyage_chart.get.analytic_v1_voyage_tank_cond_forcing_vapor_volume_chart}`
    const response = await Axios.get(`${url.replace("id", params.id)}`)
    const data = {
      data: {
        data: response.data.data.map((record) => {
          return new TankCondForcingVaporVolume(record.attributes)
        }),
      },
      headers: response.headers,
    }
    return data
  }

  public async fetchTankCondAvgBoilOffRateChart(params: any) {
    const url = `${API_NAME.voyage_chart.get.analytic_v1_voyage_tank_cond_avg_boil_off_rate_chart}`
    const response = await Axios.get(`${url.replace("id", params.id)}`)
    const data = {
      data: {
        data: response.data.data.map((record) => {
          return new TankCondAvgBoilOffrate(record.attributes)
        }),
      },
      headers: response.headers,
    }
    return data
  }

  public async updateManualFieldsApi(params: any) {
    const url = `${API_NAME.voyage_summary.post.analytic_v1_voyages_voyage_summary_update_manual_fields}`
    const response = await Axios.post(url.replace("id", params.id), params)
    const data = {
      data: {
        data: response.data,
      },
    }
    return data
  }

  public async fetchManualPorts() {
    const response = await Axios.get(
      `${API_NAME.distance_routes.get.analytic_v1_management_master_ports_fetch_autocomplete_ports}`
    )
    return response.data
  }

  public async getManualTzApi(params) {
    const response = await Axios.get(
      `${API_NAME.voyage_summary.get.analytic_v1_voyages_summary_timezone_label}?${stringify(
        params
      )}`
    )
    return response.data.time_zone
  }

  public async manualImportVoyageApi(params: any) {
    const url = `${API_NAME.voyage_summary.post.analytic_v1_voyages_voyage_summary_manual_reimport}`
    const response = await Axios.post(url.replace("id", params.id), params)
    const data = {
      data: {
        data: response.data,
      },
    }
    return data
  }

  public async createSummaryVoyageApi(params: any) {
    const response = await Axios.post(
      `${API_NAME.voyage_summary.post.analytic_v1_voyages_voyage_summary_create}`,
      params
    )
    const data = {
      data: {
        data: response.data,
      },
    }
    return data
  }

  public async deleteVoyageApi(params: any) {
    const url = `${API_NAME.voyage_summary.put.analytic_v1_voyages_voyage_summary_delete}`
    const response = await Axios.put(url.replace("id", params.id), params)
    const data = {
      data: {
        data: response.data,
      },
    }
    return data
  }

  public async restoreVoyageApi(params: any) {
    const url = `${API_NAME.voyage_summary.put.analytic_v1_voyages_voyage_summary_restore_voyage}`
    const response = await Axios.put(url.replace("id", params.id), params)
    const data = {
      data: {
        data: response.data,
      },
    }
    return data
  }
}

export default new VoyagesServices()
