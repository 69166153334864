import axios from "axios"
import format from "date-fns/format"

const TIME_ZONE_OFFSET = format(new Date(), "XX")

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/`,
  headers: {
    "x-time-zone-offset": TIME_ZONE_OFFSET,
  },
  withCredentials: true,
})

instance.interceptors.request.use(
  (config) => {
    return {
      ...config,
      headers: {
        ...config.headers,
      },
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default instance
