import genActions from "services/_base/actions"
import {
  DataSettingsPortActions,
  // Port List
  ICreatePort,
  ICreatePortSuccess,
  ICreatePortFailed,
  IUpdatePort,
  IUpdatePortSuccess,
  IUpdatePortFailed,
  IDeletePort,
  IDeletePortSuccess,
  IDeletePortFailed,
  IListPortFetchAll,
  IListPortFetchAllSuccess,
  IListPortFetchAllFinished,
  IListPortFetchAllFailed,
  ITimeZonesFetch,
  ITimeZonesFetchFailed,
  ITimeZonesFetchSuccess,
  ICountriesFetch,
  ICountriesFetchSuccess,
  ICountriesFetchFailed,
} from "./types"

export const { funcs: BaseFunctions, types: BaseActions } = genActions("data_settings_port")

export function fetchTimeZones(payload: any): ITimeZonesFetch {
  return {
    type: DataSettingsPortActions.FETCH_TIME_ZONES,
    payload,
  }
}

export function fetchTimeZonesSuccess(payload: any): ITimeZonesFetchSuccess {
  return {
    type: DataSettingsPortActions.FETCH_TIME_ZONES_SUCCESS,
    payload,
  }
}

export function fetchTimeZonesFailed(payload: any): ITimeZonesFetchFailed {
  return {
    type: DataSettingsPortActions.FETCH_TIME_ZONES_FAILED,
    payload,
  }
}

export function fetchCountries(): ICountriesFetch {
  return {
    type: DataSettingsPortActions.FETCH_COUNTRIES,
  }
}

export function fetchCountriesSuccess(payload: any): ICountriesFetchSuccess {
  return {
    type: DataSettingsPortActions.FETCH_COUNTRIES_SUCCESS,
    payload,
  }
}

export function fetchCountriesFailed(payload: any): ICountriesFetchFailed {
  return {
    type: DataSettingsPortActions.FETCH_COUNTRIES_FAILED,
    payload,
  }
}

// Port
export function createPort(payload: any): ICreatePort {
  return {
    type: DataSettingsPortActions.CREATE_PORT,
    payload,
  }
}

export function createPortSuccess(): ICreatePortSuccess {
  return {
    type: DataSettingsPortActions.CREATE_PORT_SUCCESS,
  }
}

export function createPortFailed(payload: any): ICreatePortFailed {
  return {
    type: DataSettingsPortActions.CREATE_PORT_FAILED,
    payload,
  }
}

export function updatePort(payload: any): IUpdatePort {
  return {
    type: DataSettingsPortActions.UPDATE_PORT,
    payload,
  }
}

export function updatePortSuccess(): IUpdatePortSuccess {
  return {
    type: DataSettingsPortActions.UPDATE_PORT_SUCCESS,
  }
}

export function updatePortFailed(payload: any): IUpdatePortFailed {
  return {
    type: DataSettingsPortActions.UPDATE_PORT_FAILED,
    payload,
  }
}

export function deletePort(payload: any): IDeletePort {
  return {
    type: DataSettingsPortActions.DELETE_PORT,
    payload,
  }
}

export function deletePortSuccess(): IDeletePortSuccess {
  return {
    type: DataSettingsPortActions.DELETE_PORT_SUCCESS,
  }
}

export function deletePortFailed(payload: any): IDeletePortFailed {
  return {
    type: DataSettingsPortActions.DELETE_PORT_FAILED,
    payload,
  }
}

export function fetchListPort(payload: any): IListPortFetchAll {
  return {
    type: DataSettingsPortActions.FETCH_LIST_PORT_ALL,
    payload,
  }
}

export function fetchListPortSuccess(payload: any): IListPortFetchAllSuccess {
  return {
    type: DataSettingsPortActions.FETCH_LIST_PORT_ALL_SUCCESS,
    payload,
  }
}

export function fetchListPortFinished(payload: any): IListPortFetchAllFinished {
  return {
    type: DataSettingsPortActions.FETCH_LIST_PORT_ALL_FINISHED,
    payload,
  }
}

export function fetchListPortFailed(payload: { error: string }): IListPortFetchAllFailed {
  return {
    type: DataSettingsPortActions.FETCH_LIST_PORT_ALL_FAILED,
    payload,
  }
}
