import { unifyValue } from "core/utils/unifyValueChartData"
import { format } from "date-fns"
import { MMM_dd_yyyy, createDateWihoutTimeZone } from "../../DateTime"

export default class DfdeTankTemp {
  name: string

  timestamp: string

  selected_voyage_no: string

  vessel_name: string

  keys: any

  props: any

  constructor(data) {
    this.name = data.view_day
    this.selected_voyage_no = data.selected_voyage_no
    this.vessel_name = data.vessel_name

    const keys_tmp = {}
    Object.keys(data.all_voyages).map((voyage) => {
      keys_tmp[`${voyage}__average_liq_temp`] = `${voyage}__average_liq_temp`
      keys_tmp[`${voyage}__average_vap_temp`] = `${voyage}__average_vap_temp`
      return null
    })
    this.keys = keys_tmp

    const props_temp = []
    data.voyage_props.map((item) => {
      props_temp.push({
        key: `${item.key}__average_liq_temp`,
        label: `${item.key} Average Liq Temp`,
      })
      props_temp.push({
        key: `${item.key}__average_vap_temp`,
        label: `${item.key} Average Vap Temp`,
      })
      return null
    })

    this.props = props_temp
    data.avg_jsmea_mac_cargotk_liquid_temp.map((item) => {
      this[`${item.voyage_no}__average_liq_temp`] = unifyValue(item.value, 1)
      this[`${item.voyage_no}__average_liq_temp_timestamp`] = format(
        createDateWihoutTimeZone(item.date),
        MMM_dd_yyyy
      )
      return null
    })
    data.avg_jsmea_mac_cts522.map((item) => {
      this[`${item.voyage_no}__average_vap_temp`] = unifyValue(item.value, 1)
      this[`${item.voyage_no}__average_vap_temp_timestamp`] = format(
        createDateWihoutTimeZone(item.date),
        MMM_dd_yyyy
      )
      return null
    })
  }
}
