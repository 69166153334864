export interface IUserFilter {
  company_id: string
  sort_by_table: boolean
  sort_by: string
  sort_order: string
  page: number
}

export interface IAllCompany {
  all: boolean
}
export interface IUsersManagementState {
  users: {
    loading: boolean
    error: string | null
    data: { [key: string]: string | null }[]
    total_records: number
    current_page: number
    per_page: number
    total_pages: number
  }
  user: {
    loading: boolean
    error: string | null
    data: any
    editSuccess: boolean
  }
  companies: {
    loading: boolean
    error: string
    data: { [key: string]: string | null }[]
    total_records: number
    current_page: number
    per_page: number
    total_pages: number
    company_id: number
  }
  filters: {
    users: {
      payload: IUserFilter
    }
  }
}

export enum UsersManagementActions {
  FETCH_USERS_ALL = "[vessels] fetch all",
  FETCH_USERS_ALL_SUCCESS = "[vessels] fetch all success",
  FETCH_USERS_ALL_FINISHED = "[vessels] fetch all finished",
  FETCH_USERS_ALL_FAILED = "[vessels] fetch all failed",
  CREATE_USER = "[User] create",
  CREATE_USER_SUCCESS = "[User] create success",
  CREATE_USER_FAILED = "[User] create failed",
  EDIT_USER = "[User] edit",
  EDIT_USER_SUCCESS = "[User] edit success",
  EDIT_USER_FAILED = "[User] edit failed",
  DELETE_USER = "[User] delete",
  DELETE_USER_SUCCESS = "[User] delete success",
  DELETE_USER_FAILED = "[User] delete failed",
  FETCH_LIST_COMPANIES = "[company] fetch list of company",
  FETCH_LIST_COMPANIES_SUCCESS = "[company] fetch list of company success",
  FETCH_LIST_COMPANIES_FAILED = "[company] fetch list of company failed",
  SET_USER_FILTERS = "[set user] set list of user filter",
  SET_COMPANY_ID = "[User] set company id",
}

export interface IUsersFetchAllSuccessAction {
  type: UsersManagementActions.FETCH_USERS_ALL_SUCCESS
  payload: {
    data: []
  }
}

export interface IUsersFetchAllFinishedAction {
  type: UsersManagementActions.FETCH_USERS_ALL_FINISHED
  payload: {
    data: []
    headers: any
  }
}

export interface IUsersFetchAllFailedAction {
  type: UsersManagementActions.FETCH_USERS_ALL_FAILED
  payload: { error: string }
}

export interface IUsersFetchAllAction {
  type: UsersManagementActions.FETCH_USERS_ALL
  payload: IUserFilter
}

export interface IUserCreateSuccessAction {
  type: UsersManagementActions.CREATE_USER_SUCCESS
}

export interface IUserCreateFailedAction {
  type: UsersManagementActions.CREATE_USER_FAILED
  payload: { error: string }
}

export interface ISetUserFilterAction {
  type: UsersManagementActions.SET_USER_FILTERS
  payload: { data: any }
}

export interface ISetCompanyId {
  type: UsersManagementActions.SET_COMPANY_ID
  payload: {
    company_id: number
  }
}

export interface IUserCreateAction {
  type: UsersManagementActions.CREATE_USER
  payload: any
}

export interface IUserEditSuccessAction {
  type: UsersManagementActions.EDIT_USER_SUCCESS
}

export interface IUserEditFailedAction {
  type: UsersManagementActions.EDIT_USER_FAILED
  payload: { error: string }
}

export interface IUserEditAction {
  type: UsersManagementActions.EDIT_USER
  payload: any
}

export interface IUserDeleteSuccessAction {
  type: UsersManagementActions.DELETE_USER_SUCCESS
}

export interface IUserDeleteFailedAction {
  type: UsersManagementActions.DELETE_USER_FAILED
  payload: { error: string }
}

export interface IUserDeleteAction {
  type: UsersManagementActions.DELETE_USER
  payload: any
}

export interface IFetchListCompanyAction {
  type: UsersManagementActions.FETCH_LIST_COMPANIES
  payload: IAllCompany
}

export interface IFetchListCompanySuccessAction {
  type: UsersManagementActions.FETCH_LIST_COMPANIES_SUCCESS
  payload: {
    data: []
  }
}

export interface IFetchListCompanyFailedAction {
  type: UsersManagementActions.FETCH_LIST_COMPANIES_FAILED
  payload: { error: string }
}

export type UsersManagementActionTypes =
  | IUserDeleteAction
  | IUserDeleteFailedAction
  | IUserDeleteSuccessAction
  | IUserEditAction
  | IUserEditFailedAction
  | IUserEditSuccessAction
  | IUserCreateSuccessAction
  | IUserCreateAction
  | IUserCreateFailedAction
  | IUsersFetchAllAction
  | IUsersFetchAllFinishedAction
  | IUsersFetchAllSuccessAction
  | IUsersFetchAllFailedAction
  | IFetchListCompanyAction
  | IFetchListCompanySuccessAction
  | IFetchListCompanyFailedAction
  | ISetCompanyId
