export interface ILayoutState {
  isCollapsed: boolean
  isFullscreen: boolean
  isShowHeader: boolean
  isPinHeader: boolean
}

export enum LayoutActions {
  SET_MENU_COLLAPSED = "[layout] set menu collapsed",
  SET_FULLSCREEN = "[layout] set fullscreen",
  SET_SHOW_HEADER = "[layout] set show header",
  SET_PIN_HEADER = "[layout] set pin header",
}

export interface ISetMenuCollapsedAction {
  type: LayoutActions.SET_MENU_COLLAPSED
  payload: {
    isCollapsed: boolean
  }
}
export interface ISetFullscreen {
  type: LayoutActions.SET_FULLSCREEN
  payload: {
    isFullscreen: boolean
  }
}
export interface ISetShowTop {
  type: LayoutActions.SET_SHOW_HEADER
  payload: {
    isShowHeader: boolean
  }
}

export interface ISetShowHeader {
  type: LayoutActions.SET_SHOW_HEADER
  payload: {
    isShowHeader: boolean
  }
}

export interface ISetPinHeader {
  type: LayoutActions.SET_PIN_HEADER
  payload: {
    isPinHeader: boolean
  }
}

export type LayoutActionTypes =
  | ISetMenuCollapsedAction
  | ISetFullscreen
  | ISetShowHeader
  | ISetPinHeader
