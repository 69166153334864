export interface ShipDataState {
  vessels: {
    loading: boolean
    error: string | null
    data: { [key: string]: string | null }[]
    totalRecords: number
    currentPage: number
    perPage: number
    totalPages: number
  }
  channels: {
    loading: boolean
    error: string | null
    data: { [key: string]: string | null }[]
    totalRecords: number
    currentPage: number
    perPage: number
    totalPages: number
    channel_ids: Array<string>
  }
  histories: {
    loading: boolean
    error: string | null
    data: { [key: string]: string | null }[]
    totalRecords: number
    currentPage: number
    perPage: number
    totalPages: number
  }
  templates: {
    loading: boolean
    error: string | null
    data: { [key: string]: string | null }[]
  }
  template: {
    loading: boolean
    error: string | null
    data: any
    editSuccess: boolean
  }
  exportProcess: {
    loading: boolean
    error: string | null
    data: any
  }
  filterParams: filterParams
  units: {
    loading: boolean
    error: string | null
    data: any
  }
  genres: {
    loading: boolean
    error: string | null
    data: any
  }
}
export enum ShipDataActions {
  FETCH_VESSELS_ALL = "[vessels] fetch all",
  FETCH_VESSELS_ALL_SUCCESS = "[vessels] fetch all success",
  FETCH_VESSELS_ALL_FAILED = "[vessels] fetch all failed",
  FETCH_VESSELS_ALL_FINISHED = "[vessels] fetch all finished",

  FETCH_TEMPLATES_ALL = "[templates] fetch all",
  FETCH_TEMPLATES_ALL_SUCCESS = "[templates] fetch all success",
  FETCH_TEMPLATES_ALL_FAILED = "[templates] fetch all failed",
  FETCH_TEMPLATES_ALL_FINISHED = "[templates] fetch all finished",
  CREATE_TEMPLATE = "[template] create",
  CREATE_TEMPLATE_SUCCESS = "[template] create success",
  CREATE_TEMPLATE_FAILED = "[template] create failed",
  EDIT_TEMPLATE = "[template] edit",
  EDIT_TEMPLATE_SUCCESS = "[template] edit success",
  EDIT_TEMPLATE_FAILED = "[template] edit failed",
  DELETE_TEMPLATE = "[template] delete",
  DELETE_TEMPLATE_SUCCESS = "[template] delete success",
  DELETE_TEMPLATE_FAILED = "[template] delete failed",

  FETCH_CHANNELS_ALL = "[channels] fetch all",
  FETCH_CHANNELS_ALL_SUCCESS = "[channels] fetch all success",
  FETCH_CHANNELS_ALL_FAILED = "[channels] fetch all failed",
  FETCH_CHANNELS_ALL_FINISHED = "[channels] fetch all finished",
  CLEAR_CHANNELS_ALL = "[channels] clear all",

  FETCH_HISTORIES_ALL = "[histories] fetch all",
  FETCH_HISTORIES_ALL_SUCCESS = "[histories] fetch all success",
  FETCH_HISTORIES_ALL_FAILED = "[histories] fetch all failed",
  FETCH_HISTORIES_ALL_FINISHED = "[histories] fetch all finished",

  EXPORT_PROCESSING = "[export processing] fetch all",
  EXPORT_PROCESSING_SUCCESS = "[export processing] fetch all success",
  EXPORT_PROCESSING_FAILED = "[export processing] fetch all failed",

  FILTER_PARAMS_CHANGE = "[time range] change",
  SEARCH_CHANNELS = "[channels] search channels",

  SEARCH_UNIT = "[units] search unit",
  FETCH_UNITS = "[units] fetchs units",
  FETCH_UNITS_SUCCESS = "[units] fetchs units success",
  FETCH_UNITS_FAILED = "[units] fetchs units failed",

  FETCH_GENRES = "[genres] fetchs genres",
  FETCH_GENRES_SUCCESS = "[genres] fetchs genres success",
  FETCH_GENRES_FAILED = "[genres] fetchs genres failed",
}

export interface filterParams {
  timestamp_from_at: string
  timestamp_to_at: string
  imos: number[] | string[]
  channels: []
  historiesCurrentPage: number
}

export interface VesselsFetchAllAction {
  type: ShipDataActions.FETCH_VESSELS_ALL
  payload: {
    target: boolean
  }
}

export interface VesselsFetchAllSuccessAction {
  type: ShipDataActions.FETCH_VESSELS_ALL_SUCCESS
  payload: {
    data: []
  }
}

export interface VesselsFetchAllFailedAction {
  type: ShipDataActions.FETCH_VESSELS_ALL_FAILED
  payload: { error: string }
}

export interface VesselsFetchAllFinishedAction {
  type: ShipDataActions.FETCH_VESSELS_ALL_FINISHED
  payload: {
    allData: []
    headers: any
  }
}

export interface TemplatesFetchAllAction {
  type: ShipDataActions.FETCH_TEMPLATES_ALL
  payload: {
    query: string
  }
}

export interface TemplatesFetchAllSuccessAction {
  type: ShipDataActions.FETCH_TEMPLATES_ALL_SUCCESS
  payload: {
    data: []
  }
}

export interface TemplatesFetchAllFailedAction {
  type: ShipDataActions.FETCH_TEMPLATES_ALL_FAILED
  payload: { error: string }
}

export interface TemplatesFetchAllFinishedAction {
  type: ShipDataActions.FETCH_TEMPLATES_ALL_FINISHED
  payload: {
    allData: []
  }
}

export interface TemplateCreateAction {
  type: ShipDataActions.CREATE_TEMPLATE
  payload: {
    data: []
  }
}
export interface TemplateCreateSuccessAction {
  type: ShipDataActions.CREATE_TEMPLATE_SUCCESS
}

export interface TemplateCreateFailedAction {
  type: ShipDataActions.CREATE_TEMPLATE_FAILED
  payload: { error: string }
}

export interface TemplateEditAction {
  type: ShipDataActions.EDIT_TEMPLATE
  payload: {
    data: []
  }
}
export interface TemplateEditSuccessAction {
  type: ShipDataActions.EDIT_TEMPLATE_SUCCESS
}

export interface TemplateEditFailedAction {
  type: ShipDataActions.EDIT_TEMPLATE_FAILED
  payload: { error: string }
}

export interface TemplateDeleteAction {
  type: ShipDataActions.DELETE_TEMPLATE
  payload: {
    id: number
  }
}

export interface TemplateDeleteSuccessAction {
  type: ShipDataActions.DELETE_TEMPLATE_SUCCESS
}

export interface TemplateDeleteFailedAction {
  type: ShipDataActions.DELETE_TEMPLATE_FAILED
  payload: { error: string }
}

export interface ChannelsFetchAllAction {
  type: ShipDataActions.FETCH_CHANNELS_ALL
  payload: {
    page: number
    target: boolean
    imo: number
  }
}

export interface ChannelsClearAllAction {
  type: ShipDataActions.CLEAR_CHANNELS_ALL
}

export interface ChannelsFetchAllSuccessAction {
  type: ShipDataActions.FETCH_CHANNELS_ALL_SUCCESS
  payload: {
    channel_ids: []
    data: []
    headers: {
      "total-pages": number
      "total-count": number
      "current-page": number
      "page-items": number
    }
  }
}

export interface ChannelsFetchAllFailedAction {
  type: ShipDataActions.FETCH_CHANNELS_ALL_FAILED
  payload: { error: string }
}

export interface ChannelsFetchAllFinishedAction {
  type: ShipDataActions.FETCH_CHANNELS_ALL_FINISHED
  payload: {
    allData: []
    headers: any
  }
}

export interface HistoriesFetchAllAction {
  type: ShipDataActions.FETCH_HISTORIES_ALL
  payload: {
    source: string
    page: number
    imos: []
  }
}

export interface HistoriesFetchAllSuccessAction {
  type: ShipDataActions.FETCH_HISTORIES_ALL_SUCCESS
  payload: {
    data: []
    headers: {
      "total-pages": number
      "total-count": number
      "current-page": number
      "page-items": number
    }
  }
}

export interface HistoriesFetchAllFailedAction {
  type: ShipDataActions.FETCH_HISTORIES_ALL_FAILED
  payload: { error: string }
}

export interface HistoriesFetchAllFinishedAction {
  type: ShipDataActions.FETCH_HISTORIES_ALL_FINISHED
  payload: {
    allData: []
    headers: any
  }
}

export interface ExportProcessingAction {
  type: ShipDataActions.EXPORT_PROCESSING
  payload: ExportProcessingProps
}

export interface ExportProcessingProps {
  timestamp_from_at: string
  timestamp_to_at: string
  imos: []
}

export interface ExportProcessingSuccessAction {
  type: ShipDataActions.EXPORT_PROCESSING_SUCCESS
  payload: {
    data: []
  }
}

export interface ExportProcessingFailedAction {
  type: ShipDataActions.EXPORT_PROCESSING_FAILED
  payload: { error: string }
}
export interface ChannelsFilterParamsChangeAction {
  type: ShipDataActions.FILTER_PARAMS_CHANGE
  payload: {
    filterParams: filterParams
  }
}

export interface SearchChannelsAction {
  type: ShipDataActions.SEARCH_CHANNELS
  payload: {
    local_name: string
    imo: number
  }
}

export interface SearchUnitAction {
  type: ShipDataActions.SEARCH_UNIT
  payload: {
    unit: string
    imo: number
  }
}

export interface FetchUnitsAction {
  type: ShipDataActions.FETCH_UNITS
}

export interface FetchUnitsSuccessAction {
  type: ShipDataActions.FETCH_UNITS_SUCCESS
  payload: {
    data: []
  }
}

export interface FetchUnitsFailedAction {
  type: ShipDataActions.FETCH_UNITS_FAILED
  payload: { error: string }
}

export interface FetchGenresAction {
  type: ShipDataActions.FETCH_GENRES
  payload: {
    imo: number
  }
}

export interface FetchGenresSuccessAction {
  type: ShipDataActions.FETCH_GENRES_SUCCESS
  payload: {
    data: []
  }
}

export interface FetchGenresFailedAction {
  type: ShipDataActions.FETCH_GENRES_FAILED
  payload: { error: string }
}

export type ShipDataActionTypes =
  | VesselsFetchAllSuccessAction
  | VesselsFetchAllFailedAction
  | VesselsFetchAllFinishedAction
  | VesselsFetchAllAction
  | TemplatesFetchAllSuccessAction
  | TemplatesFetchAllFailedAction
  | TemplatesFetchAllFinishedAction
  | TemplatesFetchAllAction
  | TemplateCreateSuccessAction
  | TemplateCreateFailedAction
  | TemplateEditSuccessAction
  | TemplateEditFailedAction
  | TemplateDeleteSuccessAction
  | TemplateDeleteFailedAction
  | ChannelsFetchAllSuccessAction
  | ChannelsFetchAllFailedAction
  | ChannelsFetchAllFinishedAction
  | ChannelsFetchAllAction
  | HistoriesFetchAllSuccessAction
  | HistoriesFetchAllFailedAction
  | HistoriesFetchAllFinishedAction
  | HistoriesFetchAllAction
  | ExportProcessingSuccessAction
  | ExportProcessingFailedAction
  | ExportProcessingAction
  | ChannelsFilterParamsChangeAction
  | SearchChannelsAction
  | ChannelsClearAllAction
  | SearchUnitAction
  | FetchUnitsAction
  | FetchUnitsSuccessAction
  | FetchUnitsFailedAction
  | FetchGenresAction
  | FetchGenresSuccessAction
  | FetchGenresFailedAction
