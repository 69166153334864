import { IResponseCurrentUser } from "types/auth"

export interface IAuthState {
  auth: null | IResponseCurrentUser
  loading: boolean
  error: string | null
  authorized: boolean
  authorizing: boolean
  refreshing: boolean
  companies: {
    loading: boolean
    error: string
    data: { [key: string]: string | null }[]
    company_id: number
  }
  current_user: {
    loading: boolean
    error: string | null
    isSuperAdmin: boolean
    data: {
      id: number
      company_id: number
      fullname: string
      email: string
      role: string
      company_code: string
      company_name: string
      menu: any
    }
  }
}

export enum AuthActions {
  SIGNIN = "[auth] sign in",
  SIGNIN_SUCCESS = "[auth] sign in success",
  SIGNIN_FAILED = "[auth] sign in failed",
  SIGNOUT = "[auth] sign out",
  SIGNOUT_SUCCESS = "[auth] sign out success",
  SIGNOUT_FAILED = "[auth] sign out failed",

  GET_CURRENT_USER = "[auth] get current user",
  GET_CURRENT_USER_SUCCESS = "[auth] get current user success",
  GET_CURRENT_USER_FAILED = "[auth] get current user failed",
  REFRESH_TOKEN = "[auth] refresh token",

  REFRESH_TOKEN_SUCCESSS = "[auth] refresh token success",
  REFRESH_TOKEN_FAILED = "[auth] refresh token failed",
  REFRESH_TOKEN_INVALIDED = "[auth] refresh token invalid",

  FETCH_LIST_COMPANY_ALL = "[auth] fetch all company",
  FETCH_LIST_COMPANY_ALL_SUCCESS = "[auth] fetch all company success",
  FETCH_LIST_COMPANY_ALL_FINISHED = "[auth] fetch all company finished",
  FETCH_LIST_COMPANY_ALL_FAILED = "[auth] fetch all company failed",

  SET_AUTH_COMPANY_ID = "[auth] set company id",
}

export interface ISignInAction {
  type: AuthActions.SIGNIN
  payload: {
    params: Record<"email" | "password", string>
  }
}

export interface ISignInActionSuccess {
  type: AuthActions.SIGNIN_SUCCESS
}
export interface ISignInActionFailed {
  type: AuthActions.SIGNIN_FAILED
  payload: { error: string }
}

export interface ISignOutAction {
  type: AuthActions.SIGNOUT
  payload: {
    onSuccess: () => void
  }
}

export interface ISignOutActionSuccess {
  type: AuthActions.SIGNOUT_SUCCESS
}

export interface ISignOutActionFailed {
  type: AuthActions.SIGNOUT_FAILED
  payload: { error: string }
}

export interface IGetCurrentUserAction {
  type: AuthActions.GET_CURRENT_USER
  payload: {
    onFailed?: () => void
  }
}
export interface IGetCurrentUserActionSuccess {
  type: AuthActions.GET_CURRENT_USER_SUCCESS
  payload: any
}
export interface IGetCurrentUserActionFailed {
  type: AuthActions.GET_CURRENT_USER_FAILED
  payload: { error: string }
}

export interface IRefreshToken {
  type: AuthActions.REFRESH_TOKEN
  payload: {
    setIsWaiting: (value) => void
    onSuccess?: () => void
    onFailed?: () => void
  }
}

export interface IRefreshTokenSuccess {
  type: AuthActions.REFRESH_TOKEN_SUCCESSS
}
export interface IRefreshTokenFailed {
  type: AuthActions.REFRESH_TOKEN_FAILED
  payload: { error: string }
}
export interface IRefreshTokenInvalided {
  type: AuthActions.REFRESH_TOKEN_INVALIDED
}

export interface IListCompanyFetchAll {
  type: AuthActions.FETCH_LIST_COMPANY_ALL
}

export interface IListCompanyFetchAllSuccess {
  type: AuthActions.FETCH_LIST_COMPANY_ALL_SUCCESS
  payload: {
    data: []
  }
}

export interface IListCompanyFetchAllFinished {
  type: AuthActions.FETCH_LIST_COMPANY_ALL_FINISHED
}

export interface IListCompanyFetchAllFailed {
  type: AuthActions.FETCH_LIST_COMPANY_ALL_FAILED
  payload: { error: string }
}

export interface ISetAuthCompanyId {
  type: AuthActions.SET_AUTH_COMPANY_ID
  payload: {
    company_id: number
  }
}

export type AuthActionTypes =
  | ISignInAction
  | ISignInActionSuccess
  | ISignInActionFailed
  | ISignOutAction
  | ISignOutActionSuccess
  | ISignOutActionFailed
  | IGetCurrentUserAction
  | IGetCurrentUserActionSuccess
  | IGetCurrentUserActionFailed
  | IRefreshToken
  | IRefreshTokenSuccess
  | IRefreshTokenFailed
  | IRefreshTokenInvalided
  | IListCompanyFetchAll
  | IListCompanyFetchAllSuccess
  | IListCompanyFetchAllFinished
  | IListCompanyFetchAllFailed
  | ISetAuthCompanyId
