import produce from "immer"
import { Reducer } from "redux"
import genReducers from "services/_base/reducers"
import { FacenNotification } from "components/Common/Notification"
import { BaseActions } from "./actions"
import { VoyagesAction, VoyagesActionTypes, IVoyagesState } from "./types"

export const defaultFilter = {
  imo: "",
  port_arrival: "",
  port_dept: "",
  voyage_no: "",
  voyage_leg: "",
  from_time: "",
  to_time: "",
  sort_by: "voyage_name",
  sort_order: "desc",
  pacific_voyage: "",
  inactive_status: false,
  show_plan: false,
  page: 1,
}

export const initialState: IVoyagesState = {
  vessels: { loading: false, error: null, data: [] },
  voyages: {
    loading: false,
    error: "",
    data: [],
    total_records: null,
    current_page: 1,
    per_page: 10,
    total_pages: null,
  },
  filters: {
    payload: defaultFilter,
  },
  arrival_ports: {
    loading: false,
    error: "",
    data: [],
  },
  dept_ports: {
    loading: false,
    error: "",
    data: [],
  },
  selected_voyage: {
    loading: false,
    error: "",
    data: null,
  },
  export_voyage: {
    loading: false,
    error: null,
  },
  manual_ports: {
    loading: false,
    error: "",
    data: [],
    item: { loading: false, error: "" },
  },
  voyage_charts: {
    isShowChart: false,
    summary: {
      loading: false,
      error: "",
      data: [],
    },
    speed_fuel: {
      avg_speed: {
        loading: false,
        error: "",
        data: [],
      },
      lng_cons: {
        loading: false,
        error: "",
        data: [],
      },
      mgo_cons: {
        loading: false,
        error: "",
        data: [],
      },
    },
    power_curve: {
      loading: false,
      error: "",
      data: null,
    },
    tank_conditions: {
      cargo_volume: {
        loading: false,
        error: "",
        data: [],
      },
      tank_temp: {
        loading: false,
        error: "",
        data: [],
      },
      forcing_vapor_volume: {
        loading: false,
        error: "",
        data: [],
      },
      avg_boil_off_rate: {
        loading: false,
        error: "",
        data: [],
      },
    },
  },
  import_voyage: {
    manual: {
      loading: false,
      error: null,
      data: null,
    },
  },
}

const usersReducer: Reducer<IVoyagesState, VoyagesActionTypes> = (state = initialState, action) => {
  return produce<IVoyagesState>(state, (draft) => {
    switch (action.type) {
      case VoyagesAction.FETCH_TARGET_VESSELS: {
        draft.vessels.loading = true
        draft.vessels.error = null

        return draft
      }

      case VoyagesAction.FETCH_TARGET_VESSELS_SUCCESS: {
        const { data } = action.payload
        draft.vessels.data = data

        return draft
      }

      case VoyagesAction.FETCH_TARGET_VESSELS_FAILED: {
        const { error } = action.payload
        draft.vessels.loading = false
        draft.vessels.error = error
        FacenNotification.error({ message: error })

        return draft
      }

      case VoyagesAction.FETCH_TARGET_VESSELS_FINISHED: {
        const { allData } = action.payload
        draft.vessels.data = allData
        draft.vessels.loading = false

        return draft
      }

      case VoyagesAction.FETCH_VOYAGES: {
        draft.voyages.error = null
        draft.voyages.loading = true

        return draft
      }

      case VoyagesAction.FETCH_VOYAGES_SUCCESS: {
        const { data, headers } = action.payload
        draft.voyages.loading = false
        const expandData = data.map((record) => {
          return {
            ...record,
            isChild: false,
            children: [
              {
                key: `${record.imo}-${record.voyage_name}-${record.leg_id}-${record.id}`,
                apply_average_speed: record.plan_avg_speed,
                cargo_volume_at_port_of_arrival: record.plan_cargo_vol_on_arrival,
                apply_distance: record.plan_distance,
                apply_duration: record.plan_duration,
                apply_ata_lt: record.plan_eta_lt,
                apply_atd_lt: record.plan_etd_lt,
                estimated_heel: record.plan_heel,
                lng_consumption: record.plan_lng_cons,
                apply_port_arrival: record.plan_port_arrival,
                apply_port_dept: record.plan_port_dept,
                adq: record.estimated_edq,
                actual_heel: record.estimated_heel,
                isChild: true,
              },
            ],
          }
        })
        draft.voyages.data = expandData
        draft.voyages.total_pages = headers["total-pages"]
        draft.voyages.total_records = headers["total-count"]
        draft.voyages.current_page = headers["current-page"]
        draft.voyages.per_page = headers["page-items"]
        return draft
      }

      case VoyagesAction.FETCH_VOYAGES_FAILED: {
        const { error } = action.payload
        draft.voyages.loading = false
        draft.voyages.error = error
        FacenNotification.error({ message: error })

        return draft
      }

      case VoyagesAction.FETCH_VOYAGES_ITEM: {
        draft.selected_voyage.error = null
        draft.selected_voyage.loading = true

        return draft
      }

      case VoyagesAction.FETCH_VOYAGES_ITEM_SUCCESS: {
        const { data } = action.payload
        draft.selected_voyage.loading = false
        draft.selected_voyage.data = data

        return draft
      }

      case VoyagesAction.FETCH_VOYAGES_ITEM_FAILED: {
        const { error } = action.payload
        draft.selected_voyage.loading = false
        draft.selected_voyage.error = error
        FacenNotification.error({ message: error })

        return draft
      }

      case VoyagesAction.FETCH_ARRIVAL_PORTS: {
        draft.arrival_ports.error = null
        draft.arrival_ports.loading = true

        return draft
      }

      case VoyagesAction.FETCH_ARRIVAL_PORTS_SUCCESS: {
        const { data } = action.payload
        draft.arrival_ports.data = data
        draft.arrival_ports.loading = false
        return draft
      }

      case VoyagesAction.FETCH_ARRIVAL_PORTS_FAILED: {
        const { error } = action.payload
        draft.arrival_ports.loading = false
        draft.arrival_ports.error = error
        FacenNotification.error({ message: error })

        return draft
      }

      case VoyagesAction.FETCH_DEPT_PORTS: {
        draft.dept_ports.error = null
        draft.dept_ports.loading = true

        return draft
      }

      case VoyagesAction.FETCH_DEPT_PORTS_SUCCESS: {
        const { data } = action.payload
        draft.dept_ports.data = data
        draft.dept_ports.loading = false

        return draft
      }

      case VoyagesAction.FETCH_DEPT_PORTS_FAILED: {
        const { error } = action.payload
        draft.dept_ports.loading = false
        draft.dept_ports.error = error
        FacenNotification.error({ message: error })

        return draft
      }

      case VoyagesAction.SET_VOYAGES_FILTERS: {
        draft.filters.payload = action.payload
        return draft
      }

      case VoyagesAction.SET_SELECTED_VOYAGE: {
        draft.selected_voyage.data = action.payload
        return draft
      }

      case VoyagesAction.EXPORT_VOYAGES_SUMMARY: {
        draft.export_voyage.error = null
        draft.export_voyage.loading = true
        return draft
      }

      case VoyagesAction.EXPORT_VOYAGES_SUMMARY_FAILED: {
        const { error } = action.payload
        draft.export_voyage.error = error
        draft.export_voyage.loading = false
        FacenNotification.error({ message: "Error", description: error })

        return draft
      }

      case VoyagesAction.EXPORT_VOYAGES_SUMMARY_SUCCESS: {
        draft.export_voyage.loading = false
        return draft
      }

      case VoyagesAction.SHOW_CHART: {
        const { isShowChart } = action.payload
        draft.voyage_charts.isShowChart = isShowChart
        return draft
      }

      case VoyagesAction.FETCH_SUMMARY_CHARTS: {
        draft.voyage_charts.summary.error = null
        draft.voyage_charts.summary.loading = true

        return draft
      }

      case VoyagesAction.FETCH_SUMMARY_CHARTS_SUCCESS: {
        const { data } = action.payload
        draft.voyage_charts.summary.loading = false
        draft.voyage_charts.summary.data = data

        return draft
      }

      case VoyagesAction.FETCH_SUMMARY_CHARTS_FAILED: {
        const { error } = action.payload
        draft.voyage_charts.summary.loading = false
        draft.voyage_charts.summary.error = error
        FacenNotification.error({ message: error })

        return draft
      }

      case VoyagesAction.FETCH_SPEED_FUEL_AVG_SPEED_CHART: {
        draft.voyage_charts.speed_fuel.avg_speed.error = null
        draft.voyage_charts.speed_fuel.avg_speed.loading = true

        return draft
      }

      case VoyagesAction.FETCH_SPEED_FUEL_AVG_SPEED_CHART_SUCCESS: {
        const { data } = action.payload
        draft.voyage_charts.speed_fuel.avg_speed.loading = false
        draft.voyage_charts.speed_fuel.avg_speed.data = data

        return draft
      }

      case VoyagesAction.FETCH_SPEED_FUEL_AVG_SPEED_CHART_FAILED: {
        const { error } = action.payload
        draft.voyage_charts.speed_fuel.avg_speed.loading = false
        draft.voyage_charts.speed_fuel.avg_speed.error = error
        FacenNotification.error({ message: error })

        return draft
      }

      case VoyagesAction.FETCH_SPEED_FUEL_LNG_CONS_CHART: {
        draft.voyage_charts.speed_fuel.lng_cons.error = null
        draft.voyage_charts.speed_fuel.lng_cons.loading = true

        return draft
      }

      case VoyagesAction.FETCH_SPEED_FUEL_LNG_CONS_CHART_SUCCESS: {
        const { data } = action.payload
        draft.voyage_charts.speed_fuel.lng_cons.loading = false
        draft.voyage_charts.speed_fuel.lng_cons.data = data

        return draft
      }

      case VoyagesAction.FETCH_SPEED_FUEL_LNG_CONS_CHART_FAILED: {
        const { error } = action.payload
        draft.voyage_charts.speed_fuel.lng_cons.loading = false
        draft.voyage_charts.speed_fuel.lng_cons.error = error
        FacenNotification.error({ message: error })

        return draft
      }

      case VoyagesAction.FETCH_SPEED_FUEL_MGO_CONS_CHART: {
        draft.voyage_charts.speed_fuel.mgo_cons.error = null
        draft.voyage_charts.speed_fuel.mgo_cons.loading = true

        return draft
      }

      case VoyagesAction.FETCH_SPEED_FUEL_MGO_CONS_CHART_SUCCESS: {
        const { data } = action.payload
        draft.voyage_charts.speed_fuel.mgo_cons.loading = false
        draft.voyage_charts.speed_fuel.mgo_cons.data = data

        return draft
      }

      case VoyagesAction.FETCH_SPEED_FUEL_MGO_CONS_CHART_FAILED: {
        const { error } = action.payload
        draft.voyage_charts.speed_fuel.mgo_cons.loading = false
        draft.voyage_charts.speed_fuel.mgo_cons.error = error
        FacenNotification.error({ message: error })

        return draft
      }

      case VoyagesAction.FETCH_POWER_CURVE_CHART: {
        draft.voyage_charts.power_curve.error = null
        draft.voyage_charts.power_curve.loading = true
        draft.voyage_charts.power_curve.data = null
        return draft
      }

      case VoyagesAction.FETCH_POWER_CURVE_CHART_SUCCESS: {
        const { data } = action.payload
        draft.voyage_charts.power_curve.loading = false
        draft.voyage_charts.power_curve.data = data
        return draft
      }

      case VoyagesAction.FETCH_POWER_CURVE_CHART_FAILED: {
        const { error } = action.payload
        draft.voyage_charts.power_curve.loading = false
        draft.voyage_charts.power_curve.error = error
        draft.voyage_charts.power_curve.data = null
        return draft
      }

      case VoyagesAction.FETCH_TANK_COND_CARGO_VOLUME_CHART: {
        draft.voyage_charts.tank_conditions.cargo_volume.error = null
        draft.voyage_charts.tank_conditions.cargo_volume.loading = true

        return draft
      }

      case VoyagesAction.FETCH_TANK_COND_CARGO_VOLUME_CHART_SUCCESS: {
        const { data } = action.payload
        draft.voyage_charts.tank_conditions.cargo_volume.loading = false
        draft.voyage_charts.tank_conditions.cargo_volume.data = data

        return draft
      }

      case VoyagesAction.FETCH_TANK_COND_CARGO_VOLUME_CHART_FAILED: {
        const { error } = action.payload
        draft.voyage_charts.tank_conditions.cargo_volume.loading = false
        draft.voyage_charts.tank_conditions.cargo_volume.error = error
        FacenNotification.error({ message: error })

        return draft
      }

      case VoyagesAction.FETCH_TANK_COND_TANK_TEMP_CHART: {
        draft.voyage_charts.tank_conditions.tank_temp.error = null
        draft.voyage_charts.tank_conditions.tank_temp.loading = true
        return draft
      }

      case VoyagesAction.FETCH_TANK_COND_TANK_TEMP_CHART_SUCCESS: {
        const { data } = action.payload
        draft.voyage_charts.tank_conditions.tank_temp.loading = false
        draft.voyage_charts.tank_conditions.tank_temp.data = data

        return draft
      }

      case VoyagesAction.FETCH_TANK_COND_TANK_TEMP_CHART_FAILED: {
        const { error } = action.payload
        draft.voyage_charts.tank_conditions.tank_temp.loading = false
        draft.voyage_charts.tank_conditions.tank_temp.error = error
        FacenNotification.error({ message: error })

        return draft
      }

      case VoyagesAction.FETCH_TANK_COND_FORCING_VAPOR_VOLUME_CHART: {
        draft.voyage_charts.tank_conditions.forcing_vapor_volume.error = null
        draft.voyage_charts.tank_conditions.forcing_vapor_volume.loading = true

        return draft
      }

      case VoyagesAction.FETCH_TANK_COND_FORCING_VAPOR_VOLUME_CHART_SUCCESS: {
        const { data } = action.payload
        draft.voyage_charts.tank_conditions.forcing_vapor_volume.loading = false
        draft.voyage_charts.tank_conditions.forcing_vapor_volume.data = data

        return draft
      }

      case VoyagesAction.FETCH_TANK_COND_FORCING_VAPOR_VOLUME_CHART_FAILED: {
        const { error } = action.payload
        draft.voyage_charts.tank_conditions.forcing_vapor_volume.loading = false
        draft.voyage_charts.tank_conditions.forcing_vapor_volume.error = error
        FacenNotification.error({ message: error })

        return draft
      }

      case VoyagesAction.FETCH_TANK_COND_AVG_BOIL_OFF_RATE_CHART: {
        draft.voyage_charts.tank_conditions.avg_boil_off_rate.error = null
        draft.voyage_charts.tank_conditions.avg_boil_off_rate.loading = true

        return draft
      }

      case VoyagesAction.FETCH_TANK_COND_AVG_BOIL_OFF_RATE_CHART_SUCCESS: {
        const { data } = action.payload
        draft.voyage_charts.tank_conditions.avg_boil_off_rate.loading = false
        draft.voyage_charts.tank_conditions.avg_boil_off_rate.data = data

        return draft
      }

      case VoyagesAction.FETCH_TANK_COND_AVG_BOIL_OFF_RATE_CHART_FAILED: {
        const { error } = action.payload
        draft.voyage_charts.tank_conditions.avg_boil_off_rate.loading = false
        draft.voyage_charts.tank_conditions.avg_boil_off_rate.error = error
        FacenNotification.error({ message: error })
        return draft
      }

      case VoyagesAction.FETCH_MANUAL_PORTS: {
        draft.manual_ports.loading = true
        draft.manual_ports.error = null
        return draft
      }

      case VoyagesAction.FETCH_MANUAL_PORTS_SUCCESS: {
        const { data } = action.payload
        draft.manual_ports.loading = false
        draft.manual_ports.data = data

        return draft
      }

      case VoyagesAction.FETCH_MANUAL_PORTS_FAILED: {
        const { error } = action.payload
        draft.manual_ports.loading = false
        draft.manual_ports.error = error

        FacenNotification.error({ message: error })

        return draft
      }

      case VoyagesAction.UPDATE_MANUAL_FIELDS: {
        FacenNotification.info({ message: "Be Patient! We are updating data, please wait!" })
        draft.manual_ports.item.loading = true
        draft.manual_ports.item.error = null
        return draft
      }

      case VoyagesAction.UPDATE_MANUAL_FIELDS_SUCCESS: {
        FacenNotification.success({ message: "Update voyage successfully!" })
        draft.manual_ports.item.loading = false
        return draft
      }

      case VoyagesAction.UPDATE_MANUAL_FIELDS_FAILED: {
        const { error } = action.payload
        FacenNotification.error({ message: error })
        draft.manual_ports.item.loading = false
        draft.manual_ports.item.error = error
        return draft
      }

      case VoyagesAction.MANUAL_IMPORT_FAILED: {
        const { error } = action.payload
        FacenNotification.error({ message: error })

        return draft
      }

      case VoyagesAction.CREATE_SUMMARY_VOYAGE: {
        draft.manual_ports.item.loading = true
        draft.manual_ports.item.error = null
        return draft
      }

      case VoyagesAction.CREATE_SUMMARY_VOYAGE_SUCCESS: {
        FacenNotification.success({ message: "Create voyage successfully." })
        draft.manual_ports.item.loading = false
        return draft
      }

      case VoyagesAction.CREATE_SUMMARY_VOYAGE_FAILED: {
        const { error } = action.payload
        FacenNotification.error({ message: error })
        draft.manual_ports.item.loading = false
        draft.manual_ports.item.error = error
        return draft
      }

      case VoyagesAction.DELETE_VOYAGE_FAILED: {
        const { error } = action.payload
        FacenNotification.error({ message: error })
        draft.manual_ports.item.loading = false
        draft.manual_ports.item.error = error
        return draft
      }

      case VoyagesAction.DELETE_VOYAGE_SUCCESS: {
        FacenNotification.success({ message: "Delete voyage successfully." })

        return draft
      }

      case VoyagesAction.RESTORE_VOYAGE_FAILED: {
        const { error } = action.payload
        FacenNotification.error({ message: error })

        return draft
      }

      case VoyagesAction.RESTORE_VOYAGE_SUCCESS: {
        FacenNotification.success({ message: "Restore voyage successfully." })

        return draft
      }

      default:
        return genReducers(initialState, BaseActions)(state, action)
    }
  })
}

export default usersReducer
