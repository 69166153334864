export interface IDataSettingsLngConsInPanamaState {
  lng_cons: {
    loading: boolean
    error: string
    data: string | number
  }
}

export enum DataSettingsLngConsInPanamaActions {
  FETCH_PANAMA_LNG_CONS = "[data settings panama] fetch lng cons.",
  FETCH_PANAMA_LNG_CONS_SUCCESS = "[data settings panama] fetch lng cons. success",
  FETCH_PANAMA_LNG_CONS_FAILED = "[data settings panama] fetch lng cons. failed",

  UPDATE_FETCH_PANAMA_LNG_CONS = "[data settings panama] update lng cons. route",
  UPDATE_FETCH_PANAMA_LNG_CONS_SUCCESS = "[data settings panama] update lng cons. success",
  UPDATE_FETCH_PANAMA_LNG_CONS_FAILED = "[data settings panama] update lng cons. failed",
}

export interface IPanamaLngConsFetch {
  type: DataSettingsLngConsInPanamaActions.FETCH_PANAMA_LNG_CONS
}

export interface IPanamaLngConsFetchSuccess {
  type: DataSettingsLngConsInPanamaActions.FETCH_PANAMA_LNG_CONS_SUCCESS
  payload: {
    value: number
  }
}

export interface IPanamaLngConsFetchFailed {
  type: DataSettingsLngConsInPanamaActions.FETCH_PANAMA_LNG_CONS_FAILED
  payload: {
    error: string
  }
}

export interface IUpdatePanamaLngCons {
  type: DataSettingsLngConsInPanamaActions.UPDATE_FETCH_PANAMA_LNG_CONS
  payload: {
    value: number
  }
}

export interface IUpdatePanamaLngConsSuccess {
  type: DataSettingsLngConsInPanamaActions.UPDATE_FETCH_PANAMA_LNG_CONS_SUCCESS
}

export interface IUpdatePanamaLngConsFailed {
  type: DataSettingsLngConsInPanamaActions.UPDATE_FETCH_PANAMA_LNG_CONS_FAILED
  payload: { error: string }
}

export type DataSettingsPanamaLngConsTypes =
  | IPanamaLngConsFetch
  | IPanamaLngConsFetchSuccess
  | IPanamaLngConsFetchFailed
  | IUpdatePanamaLngCons
  | IUpdatePanamaLngConsSuccess
  | IUpdatePanamaLngConsFailed
