import { LogoutOutlined, UserOutlined } from "@ant-design/icons"
import { Layout, Menu } from "antd"
import { dispatch } from "core/utils/dispatch"
import { PRIVATE_ROUTES, SIGNIN, VOYAGES_MAP, VOYAGES_SUMMARY } from "core/constants/routes"
import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { signOut } from "services/auth/actions"
import { useSelector } from "react-redux"
import { AuthSelector } from "services/auth/selectors"
import { mergeParentChild, onRouterClick } from "core/utils/route"
import SubMenu from "antd/lib/menu/SubMenu"
import CompanySelect from "components/CompanySelect"
import logo from "../../assets/styles/share/logo.jpeg"

interface SideBarProps {
  collapsed: boolean
  onBreakPoint: (broken: boolean) => void
}

function SideBar({ collapsed, onBreakPoint }: SideBarProps) {
  const history = useHistory()
  const { pathname, search } = useLocation()
  const { current_user } = useSelector(AuthSelector)
  const [currentUser, setCurrentUser] = useState(current_user.data)

  useEffect(() => {
    setCurrentUser(current_user.data)
  }, [current_user])

  const menuItems = (path, name, iconProps, children, showChild, isExternal, search) => {
    return children && showChild ? (
      <SubMenu key={path} {...iconProps} title={name}>
        {children.map((child) => (
          <Menu.Item key={child.path}>
            {/* eslint-disable-next-line */}
            <a onClick={(e) => onRouterClick(e, child.path, history, isExternal, search)}>
              {child.name}
            </a>{" "}
          </Menu.Item>
        ))}
      </SubMenu>
    ) : (
      <Menu.Item key={path} {...iconProps}>
        {/* eslint-disable-next-line */}
        <a onClick={(e) => onRouterClick(e, path, history, isExternal, search)}>{name}</a>{" "}
      </Menu.Item>
    )
  }

  const privateRoutes = useMemo(() => {
    return PRIVATE_ROUTES.map(({ icon: Icon, path, name, children, showChild, external }) => {
      const iconProps = Icon ? { icon: <Icon /> } : {}
      let items = null

      if (currentUser.menu.includes(name)) {
        items = menuItems(path, name, iconProps, children, showChild, external ?? false, search)
      }

      return items
    })
  }, [history])

  const selectedRoute = useMemo(() => {
    const allPrivateRoutes = mergeParentChild(PRIVATE_ROUTES)
    const matchedRoute = allPrivateRoutes.find(({ path }) => {
      if (path === "/") return pathname === path

      if (pathname.includes(VOYAGES_MAP)) return path.includes(VOYAGES_SUMMARY)

      return pathname.includes(path)
    })

    return matchedRoute?.path ? [matchedRoute.path] : undefined
  }, [pathname])

  const onSuccessSignOut = () => {
    return window.location.replace(SIGNIN)
  }

  const handleSignOut = useCallback(() => {
    dispatch(signOut({ onSuccess: () => onSuccessSignOut() }))
  }, [])

  return (
    <Layout.Sider
      id="layoutSider"
      trigger={null}
      collapsed={collapsed}
      onBreakpoint={onBreakPoint}
      breakpoint="lg"
      collapsedWidth="80"
      className="ant-sider"
      width={220}
    >
      <div className="logoContainer">
        <figure className="cardFigureSlider">
          <img className={`cardImage ${collapsed ? "collapsed" : ""}`} src={logo} alt="LNG" />
        </figure>
      </div>

      <Menu theme="dark" mode="inline" selectedKeys={selectedRoute}>
        <CompanySelect />
        {currentUser && (
          <Menu.Item icon={<UserOutlined />} className="sidebar-user-info">
            <p title={currentUser?.fullname || ""} style={{ margin: 0 }}>
              {currentUser?.fullname || ""}
            </p>
            <p title={currentUser?.email || ""} style={{ margin: 0 }}>
              {currentUser?.email || ""}
            </p>
          </Menu.Item>
        )}
        {privateRoutes}
        <Menu.Item icon={<LogoutOutlined />} onClick={handleSignOut}>
          Sign Out
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  )
}

export default memo(SideBar)
