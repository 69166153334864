import produce from "immer"
import { Reducer } from "redux"
import genReducers from "services/_base/reducers"
import moment from "moment"
import { FacenNotification } from "components/Common/Notification"
import { BaseActions } from "./actions"
import { DashboardActions, DashboardActionTypes, IDashboardState } from "./types"

const ChartCommonDefault = {
  loading: false,
  error: "",
}
const ChartDefault = {
  data: [],
}

export const initialState: IDashboardState = {
  vessel: {
    loading: false,
    error: "",
    data: [],
    isFinished: false,
    total: "",
    next_page: 1,
    per_page: "",
    selectedVessel: {
      informations: {
        imo: "",
        name: "",
        mmsi: "",
        callsign: "",
        date_of_build: "",
        engine_type: "",
      },
      vesselSpec: {
        stageSpec: {},
        xdfSpec: {},
        dfdeSpec: {},
      },
      imo: null,
      name: "",
      vessel_id: null,
      historyRoutes: [],
      planRoutes: {
        data: [],
        last_modified_at: null,
        last_get_failed_at: null,
      },
      ecdis: {
        routes: {
          loading: false,
          error: "",
          data: [],
        },
        points: {
          loading: false,
          error: "",
          data: [],
        },
      },
      updatedATATime: {
        loading: false,
        success: false,
        error: "",
      },
      selectedPoint: null,
      historyPointSelected: null,
      manualEtaPeriod: [],
      calculatedEtaPoints: [],
      isChartPointClick: false,
      historyRoutePointFiltered: [],
    },
    showNoneTargetVessel: false,
    showLabelNoneTargetVessel: false,
  },
  weather: {
    loading: false,
    error: null,
    data: {
      showWaveHeight: false,
      showPressurePattern: false,
      showTropicalStorm: false,
      showTropicalStormTrack: false,
      showOcceanCurrent: false,
      showWind: false,
      currentDate: moment.utc().startOf("h").add(1, "hours").toDate(),
      fromDate: moment.utc().subtract(7, "d").startOf("d").toDate(),
      toDate: moment.utc().add(7, "d").endOf("d").toDate(),
    },
  },
  port: {
    loading: false,
    error: "",
    data: [],
    total: "",
    next_page: 1,
    per_page: "",
    meta: {},
    selectedPort: {},
  },
  chart: {
    isShowChart: false,
    isShowCargoMonitorChart: false,
    isShowEngineMonitorChart: false,
    engineMonitor: {
      stageTrend33: ChartDefault,
      stageEmBlr: ChartDefault,
      stageEmFdumpVOpen: ChartDefault,
      xdfTrend315: ChartDefault,
      xdfTrend316: ChartDefault,
      xdfTrend317: ChartDefault,
      xdfFoc1: ChartDefault,
      stageFoc1: ChartDefault,
      stageTotalFoc: ChartDefault,
      dfdeFoc1: ChartDefault,
      dfdeTotalFoc: ChartDefault,
      dfdeDgFuelMode: ChartDefault,
    },
    cargoMonitor: {
      stageTrend1Cargo: ChartDefault,
      stageTrend21: ChartDefault,
      stageTankEquator: ChartDefault,
      stageTankVolume: ChartDefault,
      stageBoilOffRate: ChartDefault,
      stagePress: ChartDefault,
      stageTankLiquidTemp: ChartDefault,
      stageCmPressHold: ChartDefault,
      stageCmBlrFlow: ChartDefault,
      stageCmTotalTankVolume: ChartDefault,
      xdfTrend21: ChartDefault,
      xdfInnerSurfaceTemp: ChartDefault,
      xdfTankVolume: ChartDefault,
      xdfBoilOffRate: ChartDefault,
      xdfPress: ChartDefault,
      xdfTankLiquidTemp: ChartDefault,
      xdfCmTotalTankVolume: ChartDefault,
      xdfME: ChartDefault,
      dfdeTrend2: ChartDefault,
      dfdeBlrFlow: ChartDefault,
      dfdeTankVolume: ChartDefault,
    },
    common: {
      stageTrend1: ChartDefault,
      xdfTrend1: ChartDefault,
      ogSpeed: ChartDefault,
      dfdeTrend1: ChartDefault,
    },
    dfde: {
      cargo: ChartCommonDefault,
      engine: ChartCommonDefault,
    },
    stageCargo: ChartCommonDefault,
    stageEngine: ChartCommonDefault,
    xdfCargo: ChartCommonDefault,
    xdfEngine: ChartCommonDefault,
  },
  targetVessel: {
    loading: false,
    error: "",
    data: [],
    isFinished: false,
  },
}

const vesselReducer: Reducer<IDashboardState, DashboardActionTypes> = (
  state = initialState,
  action
) => {
  return produce<IDashboardState>(state, (draft) => {
    switch (action.type) {
      case DashboardActions.FETCH_VESSEL_ALL: {
        draft.vessel.error = null
        draft.vessel.loading = true
        draft.vessel.isFinished = false

        return draft
      }
      case DashboardActions.FETCH_VESSEL_ALL_SUCCESS: {
        const { data } = action.payload
        if (data.length > draft.vessel.data.length) {
          draft.vessel.data = data
        }
        draft.vessel.loading = false
        return draft
      }
      case DashboardActions.FETCH_VESSEL_ALL_FINISHED: {
        const { data } = action.payload
        draft.vessel.data = data
        draft.vessel.loading = false
        draft.vessel.isFinished = true
        return draft
      }
      case DashboardActions.FETCH_VESSEL_ALL_FAILED: {
        const { error } = action.payload

        draft.vessel.loading = false
        draft.vessel.error = error
        draft.vessel.isFinished = false
        return draft
      }
      case DashboardActions.FETCH_TARGET_VESSEL_ALL: {
        draft.targetVessel.error = null
        draft.targetVessel.loading = true
        draft.targetVessel.isFinished = false

        return draft
      }
      case DashboardActions.FETCH_TARGET_VESSEL_ALL_SUCCESS: {
        const { data } = action.payload
        draft.targetVessel.data = data
        return draft
      }
      case DashboardActions.FETCH_TARGET_VESSEL_ALL_FINISHED: {
        const { allData } = action.payload
        draft.targetVessel.data = allData
        draft.targetVessel.loading = false
        draft.targetVessel.isFinished = true

        return draft
      }
      case DashboardActions.FETCH_TARGET_VESSEL_ALL_FAILED: {
        const { error } = action.payload
        draft.targetVessel.loading = false
        draft.targetVessel.error = error
        draft.targetVessel.isFinished = true
        FacenNotification.error({ message: error })

        return draft
      }
      case DashboardActions.RESET_VESSEL_LIST: {
        draft.vessel.isFinished = false
        draft.vessel.data = []
        return draft
      }
      case DashboardActions.SET_VESSEL_SELECTED: {
        const { imo, name, vessel_id } = action.payload
        draft.vessel.selectedVessel.imo = imo
        draft.vessel.selectedVessel.name = name
        draft.vessel.selectedVessel.vessel_id = vessel_id
        return draft
      }

      case DashboardActions.FETCH_VESSEL_ACTUAL_ROUTES: {
        draft.vessel.loading = true
        draft.vessel.selectedVessel.historyRoutes = []

        return draft
      }

      case DashboardActions.FETCH_VESSEL_ACTUAL_ROUTES_SUCCESS: {
        const { historyRoutes } = action.payload
        draft.vessel.loading = false
        draft.vessel.selectedVessel.historyRoutes = historyRoutes

        return draft
      }
      case DashboardActions.FETCH_VESSEL_ACTUAL_ROUTES_FAILED: {
        const { error } = action.payload

        draft.vessel.loading = false
        draft.vessel.error = error

        FacenNotification.error({ message: error })

        return draft
      }

      case DashboardActions.FETCH_HISTORY_ROUTE_POINT_FILTERED: {
        draft.vessel.loading = true
        draft.vessel.selectedVessel.historyRoutePointFiltered = []

        return draft
      }

      case DashboardActions.FETCH_HISTORY_ROUTE_POINT_FILTERED_SUCCESS: {
        const { historyRoutePointFiltered } = action.payload
        draft.vessel.loading = false
        draft.vessel.selectedVessel.historyRoutePointFiltered = historyRoutePointFiltered

        return draft
      }

      case DashboardActions.FETCH_HISTORY_ROUTE_POINT_FILTERED_FAILED: {
        const { error } = action.payload

        draft.vessel.loading = false
        draft.vessel.error = error

        FacenNotification.error({ message: error })

        return draft
      }

      case DashboardActions.FETCH_VESSEL_PLAN_ROUTES: {
        draft.vessel.loading = true
        draft.vessel.selectedVessel.planRoutes.data = []

        return draft
      }

      case DashboardActions.FETCH_VESSEL_PLAN_ROUTES_SUCCESS: {
        const { planRoutes, lastGetFailedAt, lastModifiedAt } = action.payload
        draft.vessel.loading = false
        draft.vessel.selectedVessel.planRoutes.data = planRoutes
        draft.vessel.selectedVessel.planRoutes.last_modified_at = lastModifiedAt
        draft.vessel.selectedVessel.planRoutes.last_get_failed_at = lastGetFailedAt

        return draft
      }
      case DashboardActions.FETCH_VESSEL_PLAN_ROUTES_FAILED: {
        const { error } = action.payload

        draft.vessel.loading = false
        draft.vessel.error = error

        FacenNotification.error({ message: error })

        return draft
      }
      case DashboardActions.FETCH_VESSEL_ECDIS_ROUTES: {
        draft.vessel.selectedVessel.ecdis.routes.loading = true
        draft.vessel.selectedVessel.ecdis.routes.data = []

        return draft
      }

      case DashboardActions.FETCH_VESSEL_ECDIS_ROUTES_SUCCESS: {
        const { ecdisRoutes } = action.payload
        draft.vessel.selectedVessel.ecdis.routes.loading = false
        draft.vessel.selectedVessel.ecdis.routes.data = ecdisRoutes

        return draft
      }
      case DashboardActions.FETCH_VESSEL_ECDIS_ROUTES_FAILED: {
        const { error } = action.payload

        draft.vessel.selectedVessel.ecdis.routes.loading = false
        draft.vessel.selectedVessel.ecdis.routes.error = error

        FacenNotification.error({ message: error })

        return draft
      }
      case DashboardActions.FETCH_VESSEL_ECDIS_POINTS: {
        draft.vessel.selectedVessel.ecdis.points.loading = true
        draft.vessel.selectedVessel.ecdis.points.data = []

        return draft
      }
      case DashboardActions.FETCH_VESSEL_ECDIS_POINTS_SUCCESS: {
        const { ecdisPoints } = action.payload
        draft.vessel.selectedVessel.ecdis.points.loading = false
        draft.vessel.selectedVessel.ecdis.points.data = ecdisPoints

        return draft
      }
      case DashboardActions.FETCH_VESSEL_ECDIS_POINTS_FAILED: {
        const { error } = action.payload

        draft.vessel.selectedVessel.ecdis.points.loading = false
        draft.vessel.selectedVessel.ecdis.points.error = error

        FacenNotification.error({ message: error })

        return draft
      }
      case DashboardActions.FETCH_VESSEL_INFORMATIONS: {
        draft.vessel.loading = true
        draft.vessel.selectedVessel.informations = {}

        return draft
      }

      case DashboardActions.RESET_FETCH_VESSEL_ECDIS_ROUTES_AND_POINTS: {
        draft.vessel.selectedVessel.ecdis.routes.data = []
        draft.vessel.selectedVessel.ecdis.points.data = []

        return draft
      }

      case DashboardActions.FETCH_VESSEL_INFORMATIONS_SUCCESS: {
        const { informations } = action.payload
        draft.vessel.loading = false
        draft.vessel.selectedVessel.informations = informations

        return draft
      }
      case DashboardActions.FETCH_VESSEL_INFORMATIONS_FAILED: {
        const { error } = action.payload

        draft.vessel.loading = false
        draft.vessel.error = error

        FacenNotification.error({ message: error })

        return draft
      }

      case DashboardActions.FETCH_VESSEL_STAGE_INFORMATIONS: {
        draft.vessel.loading = true
        draft.vessel.selectedVessel.vesselSpec.stageSpec = {}

        return draft
      }

      case DashboardActions.FETCH_VESSEL_STAGE_INFORMATIONS_SUCCESS: {
        const { stageSpec } = action.payload
        draft.vessel.loading = false
        draft.vessel.selectedVessel.vesselSpec.stageSpec = stageSpec

        return draft
      }
      case DashboardActions.FETCH_VESSEL_STAGE_INFORMATIONS_FAILED: {
        const { error } = action.payload

        draft.vessel.loading = false
        draft.vessel.error = error

        FacenNotification.error({ message: error })

        return draft
      }

      case DashboardActions.FETCH_VESSEL_XDF_INFORMATIONS: {
        draft.vessel.loading = true
        draft.vessel.selectedVessel.vesselSpec.xdfSpec = {}

        return draft
      }

      case DashboardActions.FETCH_VESSEL_XDF_INFORMATIONS_SUCCESS: {
        const { xdfSpec } = action.payload
        draft.vessel.loading = false
        draft.vessel.selectedVessel.vesselSpec.xdfSpec = xdfSpec

        return draft
      }
      case DashboardActions.FETCH_VESSEL_XDF_INFORMATIONS_FAILED: {
        const { error } = action.payload

        draft.vessel.loading = false
        draft.vessel.error = error

        FacenNotification.error({ message: error })
        return draft
      }

      case DashboardActions.FETCH_VESSEL_DFDE_INFORMATIONS: {
        draft.vessel.loading = true
        draft.vessel.selectedVessel.vesselSpec.xdfSpec = {}

        return draft
      }

      case DashboardActions.FETCH_VESSEL_DFDE_INFORMATIONS_SUCCESS: {
        const { dfdeSpec } = action.payload
        draft.vessel.loading = false
        draft.vessel.selectedVessel.vesselSpec.dfdeSpec = dfdeSpec

        return draft
      }
      case DashboardActions.FETCH_VESSEL_DFDE_INFORMATIONS_FAILED: {
        const { error } = action.payload

        draft.vessel.loading = false
        draft.vessel.error = error

        FacenNotification.error({ message: error })
        return draft
      }

      case DashboardActions.UPDATE_ATA_TIME: {
        draft.vessel.selectedVessel.updatedATATime.success = false
        draft.vessel.selectedVessel.updatedATATime.loading = true
        draft.vessel.selectedVessel.updatedATATime.error = ""
        return draft
      }

      case DashboardActions.UPDATE_MANUAL_ETA_PERIOD: {
        const { manualEtaPeriod } = action.payload
        draft.vessel.selectedVessel.manualEtaPeriod = manualEtaPeriod
        return draft
      }

      case DashboardActions.UPDATE_CALCULATED_ETA_POINTS: {
        const { calculatedEtaPoints } = action.payload
        draft.vessel.selectedVessel.calculatedEtaPoints = calculatedEtaPoints
        return draft
      }

      case DashboardActions.UPDATE_ATA_TIME_SUCCESS: {
        draft.vessel.selectedVessel.updatedATATime.loading = false
        draft.vessel.selectedVessel.updatedATATime.success = true
        draft.vessel.selectedVessel.updatedATATime.error = ""
        FacenNotification.success({
          message: "Saved!",
        })
        return draft
      }
      case DashboardActions.UPDATE_ATA_TIME_FAILED: {
        const { error } = action.payload
        draft.vessel.selectedVessel.updatedATATime.loading = false
        draft.vessel.selectedVessel.updatedATATime.success = true
        draft.vessel.selectedVessel.updatedATATime.error = error
        FacenNotification.error({ message: error })
        return draft
      }

      case DashboardActions.SET_SELECTED_POINT: {
        const { selectedPoint } = action.payload
        if (!draft.vessel.loading) draft.vessel.selectedVessel.selectedPoint = selectedPoint
        return draft
      }

      case DashboardActions.SET_IS_POINT_CHART_CLICK: {
        const { isChartPointClick } = action.payload
        draft.vessel.selectedVessel.isChartPointClick = isChartPointClick
        return draft
      }

      case DashboardActions.FETCH_PORT_ALL: {
        draft.port.error = null
        draft.port.loading = true

        return draft
      }
      case DashboardActions.SHOW_WEATHER_OCEAN_CURRENT: {
        draft.weather.data = state.weather.data
        draft.weather.data.showOcceanCurrent = action.payload.checked

        return draft
      }
      case DashboardActions.SHOW_WEATHER_PRESSURE_PATTERN: {
        draft.weather.data = state.weather.data
        draft.weather.data.showPressurePattern = action.payload.checked

        return draft
      }
      case DashboardActions.SHOW_WEATHER_TROPICAL_STORM: {
        draft.weather.data = state.weather.data
        draft.weather.data.showTropicalStorm = action.payload.checked

        return draft
      }
      case DashboardActions.SHOW_WEATHER_TROPICAL_STORM_TRACK: {
        draft.weather.data = state.weather.data
        draft.weather.data.showTropicalStormTrack = action.payload.checked

        return draft
      }
      case DashboardActions.SHOW_WEATHER_WAVE_HEIGHT: {
        draft.weather.data = state.weather.data
        draft.weather.data.showWaveHeight = action.payload.checked

        return draft
      }
      case DashboardActions.SHOW_WEATHER_WIND: {
        draft.weather.data = state.weather.data
        draft.weather.data.showWind = action.payload.checked

        return draft
      }

      case DashboardActions.HIDE_WEATHERS: {
        draft.weather.data = {
          ...state.weather.data,
          ...{
            showWind: false,
            showWaveHeight: false,
            showTropicalStorm: false,
            showTropicalStormTrack: false,
            showPressurePattern: false,
            showOcceanCurrent: false,
          },
        }
        return draft
      }

      case DashboardActions.SET_WEATHER_DATE: {
        draft.weather.data = state.weather.data
        draft.weather.data.currentDate = action.payload.date

        return draft
      }
      case DashboardActions.SET_FROM_DATE: {
        draft.weather.data = state.weather.data
        draft.weather.data.fromDate = action.payload.fromDate

        return draft
      }
      case DashboardActions.SET_TO_DATE: {
        draft.weather.data = state.weather.data
        draft.weather.data.toDate = action.payload.toDate

        return draft
      }

      case DashboardActions.SHOW_CHART: {
        const { isShowChart } = action.payload
        draft.chart.isShowChart = isShowChart
        return draft
      }

      case DashboardActions.SHOW_CHART_CARGO_MONITOR: {
        const { isShowCargoMonitorChart } = action.payload
        draft.chart.isShowCargoMonitorChart = isShowCargoMonitorChart
        draft.chart.isShowEngineMonitorChart = false
        return draft
      }

      case DashboardActions.SHOW_CHART_ENGINE_MONITOR: {
        const { isShowEngineMonitorChart } = action.payload
        draft.chart.isShowEngineMonitorChart = isShowEngineMonitorChart
        draft.chart.isShowCargoMonitorChart = false

        return draft
      }

      case DashboardActions.FETCH_CHART_STAGE_BOIL_OFF_RATE_DATA_SUCCESS: {
        draft.chart.cargoMonitor.stageBoilOffRate.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_STAGE_TREND1_DATA_SUCCESS: {
        draft.chart.common.stageTrend1.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_STAGE_TREND1_CARGO_DATA_SUCCESS: {
        draft.chart.cargoMonitor.stageTrend1Cargo.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_STAGE_TREND21_DATA_SUCCESS: {
        draft.chart.cargoMonitor.stageTrend21.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_STAGE_TREND33_DATA_SUCCESS: {
        draft.chart.engineMonitor.stageTrend33.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_STAGE_EM_BLR_SUCCESS: {
        draft.chart.engineMonitor.stageEmBlr.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_STAGE_EM_FDUMP_V_OPEN_SUCCESS: {
        draft.chart.engineMonitor.stageEmFdumpVOpen.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_STAGE_TANK_EQUATOR_DATA_SUCCESS: {
        draft.chart.cargoMonitor.stageTankEquator.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_STAGE_TANK_VOLUME_DATA_SUCCESS: {
        draft.chart.cargoMonitor.stageTankVolume.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_STAGE_TANK_LIQUID_TEMP_DATA_SUCCESS: {
        draft.chart.cargoMonitor.stageTankLiquidTemp.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_STAGE_PRESS_DATA_SUCCESS: {
        draft.chart.cargoMonitor.stagePress.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_XDF_TREND1_DATA_SUCCESS: {
        draft.chart.common.xdfTrend1.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_XDF_TREND315_DATA_SUCCESS: {
        draft.chart.engineMonitor.xdfTrend315.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_XDF_EM_OG_SPEED_DATA_SUCCESS: {
        draft.chart.common.ogSpeed.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_XDF_TREND316_DATA_SUCCESS: {
        draft.chart.engineMonitor.xdfTrend316.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_XDF_TREND317_DATA_SUCCESS: {
        draft.chart.engineMonitor.xdfTrend317.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_XDF_TREND21_DATA_SUCCESS: {
        draft.chart.cargoMonitor.xdfTrend21.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_XDF_BOIL_OFF_RATE_DATA_SUCCESS: {
        draft.chart.cargoMonitor.xdfBoilOffRate.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_XDF_INNER_SURFACE_TEMP_DATA_SUCCESS: {
        draft.chart.cargoMonitor.xdfInnerSurfaceTemp.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_XDF_TANK_VOLUME_DATA_SUCCESS: {
        draft.chart.cargoMonitor.xdfTankVolume.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_XDF_TANK_LIQUID_TEMP_DATA_SUCCESS: {
        draft.chart.cargoMonitor.xdfTankLiquidTemp.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_XDF_PRESS_DATA_SUCCESS: {
        draft.chart.cargoMonitor.xdfPress.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_STAGE_CM_PRESS_HOLD_SUCCESS: {
        draft.chart.cargoMonitor.stageCmPressHold.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_STAGE_CM_TOTAL_TANK_VOLUME_SUCCESS: {
        draft.chart.cargoMonitor.stageCmTotalTankVolume.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_STAGE_CM_BLR_FLOW_SUCCESS: {
        draft.chart.cargoMonitor.stageCmBlrFlow.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_XDF_CM_TOTAL_TANK_VOLUME_SUCCESS: {
        draft.chart.cargoMonitor.xdfCmTotalTankVolume.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_XDF_FOC1_DATA_SUCCESS: {
        draft.chart.engineMonitor.xdfFoc1.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_STAGE_FOC1_DATA_SUCCESS: {
        draft.chart.engineMonitor.stageFoc1.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_STAGE_TOTAL_FOC_DATA_SUCCESS: {
        draft.chart.engineMonitor.stageTotalFoc.data = action.payload.data
        return draft
      }

      case DashboardActions.FETCH_CHART_XDF_ME_DATA_SUCCESS: {
        draft.chart.cargoMonitor.xdfME.data = action.payload.data
        return draft
      }

      case DashboardActions.SHOW_NONE_TARGET_VESSEL: {
        draft.vessel.showNoneTargetVessel = action.payload.checked

        return draft
      }
      case DashboardActions.SHOW_LABEL_NONE_TARGET_VESSEL: {
        draft.vessel.showLabelNoneTargetVessel = action.payload.checked

        return draft
      }

      case DashboardActions.SET_HISTORY_SELECTED: {
        draft.vessel.selectedVessel.historyPointSelected = action.payload
        return draft
      }

      case DashboardActions.RESET_MONITORING_INFORMATION: {
        draft.vessel.selectedVessel.vesselSpec.stageSpec = {}
        draft.vessel.selectedVessel.vesselSpec.xdfSpec = {}
        return draft
      }

      case DashboardActions.FETCH_CHART_STAGE_CARGO: {
        draft.chart.stageCargo.error = null
        draft.chart.stageCargo.loading = true
        return draft
      }
      case DashboardActions.FETCH_CHART_STAGE_CARGO_SUCCESS: {
        draft.chart.stageCargo.error = null
        draft.chart.stageCargo.loading = false
        return draft
      }
      case DashboardActions.FETCH_CHART_STAGE_CARGO_FAILED: {
        const { error } = action.payload
        draft.chart.stageCargo.loading = false
        draft.chart.stageCargo.error = error
        FacenNotification.error({ message: error })

        return draft
      }

      case DashboardActions.FETCH_CHART_STAGE_ENGINE: {
        draft.chart.stageEngine.error = null
        draft.chart.stageEngine.loading = true
        return draft
      }
      case DashboardActions.FETCH_CHART_STAGE_ENGINE_SUCCESS: {
        draft.chart.stageEngine.error = null
        draft.chart.stageEngine.loading = false
        return draft
      }
      case DashboardActions.FETCH_CHART_STAGE_ENGINE_FAILED: {
        const { error } = action.payload
        draft.chart.stageEngine.loading = false
        draft.chart.stageEngine.error = error
        FacenNotification.error({ message: error })

        return draft
      }

      case DashboardActions.FETCH_CHART_XDF_CARGO: {
        draft.chart.xdfCargo.error = null
        draft.chart.xdfCargo.loading = true
        return draft
      }
      case DashboardActions.FETCH_CHART_XDF_CARGO_SUCCESS: {
        draft.chart.xdfCargo.error = null
        draft.chart.xdfCargo.loading = false
        return draft
      }
      case DashboardActions.FETCH_CHART_XDF_CARGO_FAILED: {
        const { error } = action.payload
        draft.chart.xdfCargo.loading = false
        draft.chart.xdfCargo.error = error
        FacenNotification.error({ message: error })

        return draft
      }

      case DashboardActions.FETCH_CHART_XDF_ENGINE: {
        draft.chart.xdfEngine.error = null
        draft.chart.xdfEngine.loading = true
        return draft
      }
      case DashboardActions.FETCH_CHART_XDF_ENGINE_SUCCESS: {
        draft.chart.xdfEngine.error = null
        draft.chart.xdfEngine.loading = false
        return draft
      }
      case DashboardActions.FETCH_CHART_XDF_ENGINE_FAILED: {
        const { error } = action.payload
        draft.chart.xdfEngine.loading = false
        draft.chart.xdfEngine.error = error
        FacenNotification.error({ message: error })

        return draft
      }

      case DashboardActions.FETCH_CHART_DFDE_ENGINE: {
        draft.chart.dfde.engine.error = null
        draft.chart.dfde.engine.loading = true
        draft.chart.common.dfdeTrend1.data = []
        draft.chart.common.ogSpeed.data = []
        draft.chart.engineMonitor.dfdeFoc1.data = []
        draft.chart.engineMonitor.dfdeTotalFoc.data = []
        draft.chart.engineMonitor.dfdeDgFuelMode.data = []

        return draft
      }
      case DashboardActions.FETCH_CHART_DFDE_ENGINE_SUCCESS: {
        const { dfdeTrend1, dfdeFoc1, dfdeTotalFoc, ogSpeed, dfdeDgFuelMode } = action.payload

        draft.chart.common.dfdeTrend1.data = dfdeTrend1
        draft.chart.common.ogSpeed.data = ogSpeed
        draft.chart.engineMonitor.dfdeFoc1.data = dfdeFoc1
        draft.chart.engineMonitor.dfdeTotalFoc.data = dfdeTotalFoc
        draft.chart.engineMonitor.dfdeDgFuelMode.data = dfdeDgFuelMode

        draft.chart.dfde.engine.error = null
        draft.chart.dfde.engine.loading = false

        return draft
      }
      case DashboardActions.FETCH_CHART_DFDE_ENGINE_FAILED: {
        const { error } = action.payload
        draft.chart.common.dfdeTrend1.data = []
        draft.chart.common.ogSpeed.data = []
        draft.chart.engineMonitor.dfdeFoc1.data = []
        draft.chart.engineMonitor.dfdeTotalFoc.data = []
        draft.chart.engineMonitor.dfdeDgFuelMode.data = []
        draft.chart.dfde.engine.error = error
        draft.chart.dfde.engine.loading = false
        FacenNotification.error({ message: error })

        return draft
      }

      case DashboardActions.FETCH_CHART_DFDE_CARGO: {
        draft.chart.common.dfdeTrend1.data = []
        draft.chart.cargoMonitor.dfdeTrend2.data = []
        draft.chart.cargoMonitor.dfdeBlrFlow.data = []
        draft.chart.cargoMonitor.stageBoilOffRate.data = []
        draft.chart.cargoMonitor.stageTankLiquidTemp.data = []
        draft.chart.cargoMonitor.stagePress.data = []
        draft.chart.cargoMonitor.dfdeTankVolume.data = []
        draft.chart.cargoMonitor.stageCmPressHold.data = []
        draft.chart.cargoMonitor.stageCmTotalTankVolume.data = []
        draft.chart.cargoMonitor.xdfInnerSurfaceTemp.data = []

        draft.chart.dfde.cargo.error = null
        draft.chart.dfde.cargo.loading = true
        return draft
      }
      case DashboardActions.FETCH_CHART_DFDE_CARGO_SUCCESS: {
        const {
          dfdeTrend1,
          dfdeTrend2,
          dfdeBlrFlow,
          stageBoilOffRate,
          stageTankLiquidTemp,
          stagePress,
          dfdeTankVolume,
          stageCmPressHold,
          stageCmTotalTankVolume,
          xdfInnerSurfaceTemp,
        } = action.payload

        draft.chart.common.dfdeTrend1.data = dfdeTrend1
        draft.chart.cargoMonitor.dfdeTrend2.data = dfdeTrend2
        draft.chart.cargoMonitor.dfdeBlrFlow.data = dfdeBlrFlow
        draft.chart.cargoMonitor.stageBoilOffRate.data = stageBoilOffRate
        draft.chart.cargoMonitor.stageTankLiquidTemp.data = stageTankLiquidTemp
        draft.chart.cargoMonitor.stagePress.data = stagePress
        draft.chart.cargoMonitor.dfdeTankVolume.data = dfdeTankVolume
        draft.chart.cargoMonitor.stageCmPressHold.data = stageCmPressHold
        draft.chart.cargoMonitor.stageCmTotalTankVolume.data = stageCmTotalTankVolume
        draft.chart.cargoMonitor.xdfInnerSurfaceTemp.data = xdfInnerSurfaceTemp

        draft.chart.dfde.cargo.error = null
        draft.chart.dfde.cargo.loading = false

        return draft
      }
      case DashboardActions.FETCH_CHART_DFDE_CARGO_FAILED: {
        const { error } = action.payload

        draft.chart.common.dfdeTrend1.data = []
        draft.chart.cargoMonitor.dfdeTrend2.data = []
        draft.chart.cargoMonitor.dfdeBlrFlow.data = []
        draft.chart.cargoMonitor.stageBoilOffRate.data = []
        draft.chart.cargoMonitor.stageTankLiquidTemp.data = []
        draft.chart.cargoMonitor.stagePress.data = []
        draft.chart.cargoMonitor.dfdeTankVolume.data = []
        draft.chart.cargoMonitor.stageCmPressHold.data = []
        draft.chart.cargoMonitor.stageCmTotalTankVolume.data = []
        draft.chart.cargoMonitor.xdfInnerSurfaceTemp.data = []

        draft.chart.dfde.cargo.loading = false
        draft.chart.dfde.cargo.error = error
        FacenNotification.error({ message: error })

        return draft
      }

      default:
        return genReducers(initialState, BaseActions)(state, action)
    }
  })
}

export default vesselReducer
