import genActions from "services/_base/actions"
import {
  IVesselsFetchAllSuccessAction,
  IVesselsFetchAllFinishedAction,
  IVesselsFetchAllFailedAction,
  IVesselsFetchAllAction,
  IVesselEditAction,
  IVesselEditFailedAction,
  IVesselEditSuccessAction,
  IVesselDeleteAction,
  IVesselDeleteFailedAction,
  IVesselDeleteSuccessAction,
  VesselsManagementActions,
  IVesselCreateAction,
  IVesselCreateSuccessAction,
  IVesselCreateFailedAction,
  INonTargetVesselsFetchAction,
  INonTargetVesselsFetchSuccessAction,
  INonTargetVesselsFetchFinishedAction,
  INonTargetVesselsFetchFailedAction,
  INonTargetVesselDeleteSuccessAction,
  INonTargetVesselDeleteFailedAction,
  INonTargetVesselDeleteAction,
  INonTargetVesselCreateSuccessAction,
  INonTargetVesselCreateFailedAction,
  INonTargetVesselCreateAction,
  IExportGenreMappingAction,
  IExportGenreMappingFailedAction,
  IExportGenreErrorAction,
  IExportGenreErrorFailedAction,
  IImportGenreAction,
  IImportGenreFailedAction,
  IImportGenreSuccessAction,
  IUploadGenreFileFailedAction,
  IUploadGenreFileAction,
  IUploadGenreFileSuccessAction,
  ICleanUploadGenreFileAction,
  ISimsDataTypeAction,
  ISimsDataTypeFailedAction,
  ISimsDataTypeSuccessAction,
  IListCompanyFetchAllSuccess,
  IListCompanyFetchAllFinished,
  IListCompanyFetchAllFailed,
  IListCompanyFetchAll,
  ISetCompanyId,
  IResetAllVessel,
} from "./types"

export const { funcs: BaseFunctions, types: BaseActions } = genActions("vessels_management")

export function fetchVesselsAll(): IVesselsFetchAllAction {
  return {
    type: VesselsManagementActions.FETCH_VESSELS_ALL,
  }
}

export function fetchVesselsAllSuccess(payload: any): IVesselsFetchAllSuccessAction {
  return {
    type: VesselsManagementActions.FETCH_VESSELS_ALL_SUCCESS,
    payload,
  }
}

export function fetchVesselsAllFinished(payload: any): IVesselsFetchAllFinishedAction {
  return {
    type: VesselsManagementActions.FETCH_VESSELS_ALL_FINISHED,
    payload,
  }
}

export function fetchVesselsAllFailed(payload: { error: string }): IVesselsFetchAllFailedAction {
  return {
    type: VesselsManagementActions.FETCH_VESSELS_ALL_FAILED,
    payload,
  }
}

export function createVessel(payload: any): IVesselCreateAction {
  return { type: VesselsManagementActions.CREATE_VESSEL, payload }
}

export function createVesselSuccess(): IVesselCreateSuccessAction {
  return {
    type: VesselsManagementActions.CREATE_VESSEL_SUCCESS,
  }
}

export function createVesselFailed(payload: { error: string }): IVesselCreateFailedAction {
  return {
    type: VesselsManagementActions.CREATE_VESSEL_FAILED,
    payload,
  }
}

export function editVessel(payload: any): IVesselEditAction {
  return { type: VesselsManagementActions.EDIT_VESSEL, payload }
}

export function editVesselSuccess(): IVesselEditSuccessAction {
  return {
    type: VesselsManagementActions.EDIT_VESSEL_SUCCESS,
  }
}

export function editVesselFailed(payload: { error: string }): IVesselEditFailedAction {
  return {
    type: VesselsManagementActions.EDIT_VESSEL_FAILED,
    payload,
  }
}

export function deleteVessel(payload: any): IVesselDeleteAction {
  return { type: VesselsManagementActions.DELETE_VESSEL, payload }
}

export function deleteVesselSuccess(): IVesselDeleteSuccessAction {
  return {
    type: VesselsManagementActions.DELETE_VESSEL_SUCCESS,
  }
}

export function deleteVesselFailed(payload: { error: string }): IVesselDeleteFailedAction {
  return {
    type: VesselsManagementActions.DELETE_VESSEL_FAILED,
    payload,
  }
}

export function fetchNonTargetVessels(): INonTargetVesselsFetchAction {
  return {
    type: VesselsManagementActions.FETCH_NON_TARGET_VESSELS,
  }
}

export function fetchNonTargetVesselsSuccess(payload: any): INonTargetVesselsFetchSuccessAction {
  return {
    type: VesselsManagementActions.FETCH_NON_TARGET_VESSELS_SUCCESS,
    payload,
  }
}

export function fetchNonTargetVesselsFinished(): INonTargetVesselsFetchFinishedAction {
  return {
    type: VesselsManagementActions.FETCH_NON_TARGET_VESSELS_FINISHED,
  }
}

export function fetchNonTargetVesselsFailed(payload: {
  error: string
}): INonTargetVesselsFetchFailedAction {
  return {
    type: VesselsManagementActions.FETCH_NON_TARGET_VESSELS_FAILED,
    payload,
  }
}

export function deleteNonTargetVessel(payload: any): INonTargetVesselDeleteAction {
  return { type: VesselsManagementActions.DELETE_NON_TARGET_VESSEL, payload }
}

export function deleteNonTargetVesselSuccess(): INonTargetVesselDeleteSuccessAction {
  return {
    type: VesselsManagementActions.DELETE_NON_TARGET_VESSEL_SUCCESS,
  }
}

export function deleteNonTargetVesselFailed(payload: {
  error: string
}): INonTargetVesselDeleteFailedAction {
  return {
    type: VesselsManagementActions.DELETE_NON_TARGET_VESSEL_FAILED,
    payload,
  }
}

export function createNonTargetVessel(payload: any): INonTargetVesselCreateAction {
  return { type: VesselsManagementActions.CREATE_NON_TARGET_VESSEL, payload }
}

export function createNonTargetVesselSuccess(): INonTargetVesselCreateSuccessAction {
  return {
    type: VesselsManagementActions.CREATE_NON_TARGET_VESSEL_SUCCESS,
  }
}

export function createNonTargetVesselFailed(payload: {
  error: string
}): INonTargetVesselCreateFailedAction {
  return {
    type: VesselsManagementActions.CREATE_NON_TARGET_VESSEL_FAILED,
    payload,
  }
}

export function exportGenreMapping(payload: any): IExportGenreMappingAction {
  return {
    type: VesselsManagementActions.EXPORT_GENRE_MAPPING,
    payload,
  }
}

export function exportGenreMappingFailed(payload: {
  error: string
}): IExportGenreMappingFailedAction {
  return {
    type: VesselsManagementActions.EXPORT_GENRE_MAPPING_FAILED,
    payload,
  }
}

export function exportGenreError(payload: any): IExportGenreErrorAction {
  return {
    type: VesselsManagementActions.EXPORT_GENRE_ERROR,
    payload,
  }
}

export function exportGenreErrorFailed(payload: { error: string }): IExportGenreErrorFailedAction {
  return {
    type: VesselsManagementActions.EXPORT_GENRE_ERROR_FAILED,
    payload,
  }
}

export function importGenre(payload: any): IImportGenreAction {
  return {
    type: VesselsManagementActions.IMPORT_GENRE,
    payload,
  }
}

export function importGenreFailed(payload: { error: string }): IImportGenreFailedAction {
  return {
    type: VesselsManagementActions.IMPORT_GENRE_FAILED,
    payload,
  }
}

export function importGenreSuccess(): IImportGenreSuccessAction {
  return {
    type: VesselsManagementActions.IMPORT_GENRE_SUCCESS,
  }
}

export function uploadGenreFile(payload: any): IUploadGenreFileAction {
  return {
    type: VesselsManagementActions.UPLOAD_FILE,
    payload,
  }
}

export function uploadGenreFileFailed(payload: { error: string }): IUploadGenreFileFailedAction {
  return {
    type: VesselsManagementActions.UPLOAD_FILE_FAILED,
    payload,
  }
}

export function uploadGenreFileSuccess(payload: any): IUploadGenreFileSuccessAction {
  return {
    type: VesselsManagementActions.UPLOAD_FILE_SUCCESS,
    payload,
  }
}

export function cleanUploadGenreFile(): ICleanUploadGenreFileAction {
  return {
    type: VesselsManagementActions.CLEAN_UPLOAD_FILE,
  }
}

export function fetchSimsDataType(payload): ISimsDataTypeAction {
  return {
    type: VesselsManagementActions.FETCH_SIMS_DATA_TYPE,
    payload,
  }
}

export function fetchSimsDataTypeSuccess(payload: any): ISimsDataTypeSuccessAction {
  return {
    type: VesselsManagementActions.FETCH_SIMS_DATA_TYPE_SUCCESS,
    payload,
  }
}

export function fetchSimsDataTypeFailed(payload: { error: string }): ISimsDataTypeFailedAction {
  return {
    type: VesselsManagementActions.FETCH_SIMS_DATA_TYPE_FAILED,
    payload,
  }
}

export function fetchListCompany(): IListCompanyFetchAll {
  return {
    type: VesselsManagementActions.FETCH_LIST_COMPANY_ALL,
  }
}

export function fetchListCompanySuccess(payload: any): IListCompanyFetchAllSuccess {
  return {
    type: VesselsManagementActions.FETCH_LIST_COMPANY_ALL_SUCCESS,
    payload,
  }
}

export function fetchListCompanyFinished(): IListCompanyFetchAllFinished {
  return {
    type: VesselsManagementActions.FETCH_LIST_COMPANY_ALL_FINISHED,
  }
}

export function fetchListCompanyFailed(payload: { error: string }): IListCompanyFetchAllFailed {
  return {
    type: VesselsManagementActions.FETCH_LIST_COMPANY_ALL_FAILED,
    payload,
  }
}

export function setCompanyId(payload: any): ISetCompanyId {
  return {
    type: VesselsManagementActions.SET_COMPANY_ID,
    payload,
  }
}

export function resetAllVessel(): IResetAllVessel {
  return {
    type: VesselsManagementActions.RESET_ALL_VESSELS,
  }
}
