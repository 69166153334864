import checkStore, { invariant } from "core/utils/redux"
import { isEmpty, isFunction, isString } from "lodash"
import { Reducer } from "redux"
import createReducer from "services/reducers"
import { InjectedStore } from "types/redux"
import { history } from "index"

export function injectReducerFactory(store: InjectedStore, isValid: boolean) {
  return function injectReducer(key: string | number | symbol, reducer: Reducer) {
    if (!isValid) {
      checkStore(store)
    }

    invariant(
      isString(key) && !isEmpty(key) && isFunction(reducer),
      "(app/utils...) injectReducer: Expected `reducer` to be a reducer function"
    )

    if (
      Reflect.has(store?.injectedReducers || {}, key) &&
      store.injectedReducers?.[key] === reducer
    ) {
      return
    }

    // eslint-disable-next-line no-param-reassign
    store.injectedReducers[key] = reducer
    store.replaceReducer(createReducer(store.injectedReducers, history))
  }
}

export default function getInjectors(store: InjectedStore) {
  checkStore(store)

  return {
    injectReducer: injectReducerFactory(store, true),
  }
}
